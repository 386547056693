import { useMemo, useEffect, useRef, useState } from 'react'
import Marquee from 'react-fast-marquee'

import { ampli } from 'ampli'
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg'
import { useLocalization } from 'locales/i18n'
import { TriggeringAction } from 'types/ampli'
import { ImoStatus } from 'types/imo'
import { ImoCountdown } from 'ui/@components/imo-countdown'
import { useWindowSize } from 'ui/@hooks/use-window-size'
import { AvatarGroup } from 'ui/@library/data-display/avatar-group'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { VideoPlayer } from 'ui/@library/multimedia/video-player'
import { VideoPlayerTrigger, VideoPlayerSize } from 'ui/@library/multimedia/video-player/types'
import { useAuthUserMasterSharesLoadable } from 'ui/@store/auth-user-master-shares'
import { useMasterLoadable } from 'ui/@store/master'
import { getOwnersFromOwnerList } from 'ui/master/utils'

import { SocialShare } from '../social-share'

import styles from './styles.module.scss'

const AUTO_SCROLL_THRESHOLD = 45
const MARQUEE_SPEED = 30

export const MasterHeader = () => {
  const { t } = useLocalization('master.masterHeader')
  const { master } = useMasterLoadable()
  const { viewports } = useWindowSize()
  const { renderModal } = useGlobalModal()
  const { authUserMasterShares } = useAuthUserMasterSharesLoadable()

  const canSocialShare = useMemo(() => {
    if (!master) return false
    const ownedShares = authUserMasterShares?.map((share) => share.master.uuid)
    return ownedShares ? ownedShares.includes(master.uuid) : false
  }, [authUserMasterShares, master])
  const [autoScroll, setAutoScroll] = useState(false)

  const masterNameRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (masterNameRef.current) {
      const isOverflowing = masterNameRef.current.clientHeight > AUTO_SCROLL_THRESHOLD
      setAutoScroll(isOverflowing)
    }
  }, [masterNameRef, autoScroll])

  if (!master) return null

  const getTagCopy = () => {
    switch (master.imo.status) {
      case ImoStatus.Active:
      case ImoStatus.Ended:
        return t('tags.imo')
      default:
        return null
    }
  }

  const getBannerAsset = () => {
    if (viewports.isMobile && master.assets.bannerCompact) {
      return master.assets.bannerCompact
    }
    return master.assets.banner
  }

  const handleShare = async () => {
    ampli.openSocialShareModal({
      triggeringAction: TriggeringAction.SocialShareFromMasterPage,
    })
    renderModal({
      content: <SocialShare />,
      modalKey: ModalKey.SocialShare,
      size: ModalSize.Medium,
      noPadding: false,
      closeOnClickingOutside: true,
    })
  }

  return (
    <div className={styles.masterHeaderContainer}>
      <VideoPlayer
        trigger={VideoPlayerTrigger.OnLoad}
        src={getBannerAsset()}
        placeholder={master.assets.placeholder}
        height={VideoPlayerSize.Short}
        autoplay={true}
      />
      <div className={styles.masterInfoContainer}>
        {canSocialShare && <ShareIcon className={styles.shareIcon} onClick={handleShare} />}
        <div className={styles.masterInfo}>
          <span className={styles.tag}>{getTagCopy()}</span>
          <div className={styles.masterDescription}>
            {master.metaData.ownedByWithAvatars &&
              master.metaData.ownedByWithAvatars.length > 0 && (
                <div className={styles.masterOwnerWrapper}>
                  <AvatarGroup masterOwners={master.metaData.ownedByWithAvatars} />
                  <p className={styles.ownerName}>
                    {t(master.metaData.ownedByWithAvatars.length > 1 ? 'bringYou' : 'bringsYou', {
                      owner: getOwnersFromOwnerList(master.metaData.ownedByWithAvatars),
                    })}
                  </p>
                </div>
              )}
            <div className={styles.songDetails}>
              {master.assets.thumbnail && (
                <img src={master.assets.thumbnail} alt='album cover' className={styles.thumbnail} />
              )}
              <div className={styles.songText}>
                {autoScroll ? (
                  <Marquee speed={MARQUEE_SPEED}>
                    <p className={`${styles.masterName} ${styles.autoScroll}`} ref={masterNameRef}>
                      {master.name}
                    </p>
                  </Marquee>
                ) : (
                  <p className={styles.masterName} ref={masterNameRef}>
                    {master.name}
                  </p>
                )}
                <p className={styles.artistName}>{master.artist.name}</p>
              </div>
            </div>
          </div>
        </div>
        {master.imo.launchDate && master.imo.expiryDate && (
          <ImoCountdown
            imoStatus={master.imo.status}
            expiryDate={master.imo.expiryDate}
            launchDate={master.imo.launchDate}
          />
        )}
      </div>
    </div>
  )
}
