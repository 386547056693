import { MasterOwnersWithAvatar } from 'types/master'

import styles from './styles.module.scss'

type AvatarGroupProps = {
  masterOwners: MasterOwnersWithAvatar
}

export const AvatarGroup = ({ masterOwners }: AvatarGroupProps) => {
  return masterOwners.some((owner) => owner.avatarUrl !== null) ? (
    <div className={styles.avatarContainer}>
      {masterOwners.map(
        (masterOwner) =>
          masterOwner.avatarUrl !== null && (
            <img
              key={masterOwner.name}
              src={masterOwner.avatarUrl}
              alt={masterOwner.name}
              className={styles.avatar}
              title={masterOwner.name}
            />
          ),
      )}
    </div>
  ) : null
}
