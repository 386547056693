import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { EMAILS } from 'constants/emails'
import { useLocalization } from 'locales/i18n'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import { SectionContainer, SectionTitle, SectionDescription, SectionHeader } from '../section'

import styles from './styles.module.scss'

export const LegalName = () => {
  const { authUser } = useAuthUserLoadable()
  const { t } = useLocalization('account.legalName')

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{t('title')}</SectionTitle>
      </SectionHeader>
      <SectionDescription>
        <Trans
          i18nKey='account.legalName.description'
          values={{ email: EMAILS.SUPPORT }}
          components={[
            <Link
              key='contactSupport'
              to='#'
              onClick={(event) => {
                window.location.href = `mailto:${EMAILS.SUPPORT}`
                event.preventDefault()
              }}
            />,
          ]}
        />
      </SectionDescription>
      <p className={styles.name}>{`${authUser?.firstName} ${authUser?.lastName}`}</p>
    </SectionContainer>
  )
}
