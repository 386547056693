import { ampli } from 'ampli'
import { useLocalization } from 'locales/i18n'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useMasterLoadable } from 'ui/@store/master'

import styles from './styles.module.scss'

export const Documents = () => {
  const { isAuthenticated } = useMxAuth()
  const { t } = useLocalization('master.overviewTab.sidebar.documents')
  const { master } = useMasterLoadable()
  const shouldNotRenderDocuments =
    !master?.documents?.whitePaper && !master?.documents?.purchaseAgreement

  const handlePreviewWhitepaper = () => {
    ampli.previewWhitepaper({
      authState: isAuthenticated,
      masterName: master?.name || '',
      masterUuid: master?.uuid || '',
    })
  }

  if (shouldNotRenderDocuments) return null

  return (
    <div className={styles.documentsContainer}>
      <h1 className={styles.title}>{t('title')}</h1>
      <ul className={styles.documentsList}>
        {master?.documents?.whitePaper && (
          <li className={styles.documentLink}>
            <a
              href={master.documents.whitePaper}
              target='_blank'
              rel='noreferrer'
              onClick={handlePreviewWhitepaper}
            >
              {t('documentsList.whitepaper')}
            </a>
          </li>
        )}
        {master?.documents?.purchaseAgreement && (
          <li className={styles.documentLink}>
            <a href={master.documents.purchaseAgreement} target='_blank' rel='noreferrer'>
              {t('documentsList.purchaseAgreement')}
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}
