import { Master } from 'types/master'
import { generateNftArtBaseUrl } from 'ui/master/utils'

import styles from './styles.module.scss'

type MasterInfoProps = {
  master: Master
  tokenId: number
}

export const MasterInfo = ({ master, tokenId }: MasterInfoProps) => {
  return (
    <div className={styles.masterInfo}>
      <div className={styles.shareImageContainer}>
        <img src={master.assets.thumbnail} alt='album cover' className={styles.thumbnail} />
        <img
          src={generateNftArtBaseUrl(master.assets.nftArtBaseUrl, tokenId)}
          className={styles.nftImg}
          onError={(e) => {
            e.currentTarget.style.display = 'none'
          }}
          alt='NFT art cover'
        />
      </div>
      <div className={styles.masterTitle}>
        <h1 className={styles.masterName}>{master.name}</h1>
        <p className={styles.artistName}>{master.artist.name}</p>
      </div>
    </div>
  )
}
