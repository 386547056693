import { captureException } from '@sentry/react'
import { AxiosError } from 'axios'
import { useEffect } from 'react'

import { useLocalization } from 'locales/i18n'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { useSignout } from 'ui/@hooks/use-signout'

import { ErrorWithAction } from '../error-with-action'

type FullPageErrorProps = {
  message?: string
  translationKey?: string
  error?: unknown
  isAuthenticated?: boolean
}

export const FullPageError = ({
  message,
  error,
  translationKey = 'feedback.fullPageError',
  isAuthenticated,
}: FullPageErrorProps) => {
  const signOut = useSignout()
  const { t } = useLocalization(translationKey)

  useEffect(() => {
    captureException(error || new Error(message ?? 'FullPageError'), {
      extra: {
        response: error instanceof AxiosError ? error?.response : null,
        responseData: error instanceof AxiosError ? error?.response?.data : null,
      },
    })
    // eslint-disable-next-line
  }, [])

  return (
    <RouteWrapper title={t('header')}>
      <ErrorWithAction
        header={t('header')}
        message={message}
        buttonTitle={t('refresh')}
        secondaryButtonTitle={isAuthenticated ? t('signOut') : undefined}
        secondaryButtonOnClick={isAuthenticated ? signOut : undefined}
      />
    </RouteWrapper>
  )
}
