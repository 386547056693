import { select, pointer, line, scaleLinear, curveMonotoneX, axisTop, axisLeft } from 'd3'
import { useEffect, useRef, useState } from 'react'

import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'
import { PriceWithTimestamp } from 'types/market'
import { useWindowSize } from 'ui/@hooks/use-window-size'
import { useImoLoadable } from 'ui/@store/master'
import { GraphRange } from 'ui/master/@components/master-overview/@components/statistics/@components/price-graph/types'

import { GraphConstants } from '../constants'

import styles from './styles.module.scss'

export enum TooltipSize {
  Large = 'large',
  Small = 'small',
}

type LineGraphProps = {
  className?: string
  data: PriceWithTimestamp[]
  setTooltipValue: (tooltipValue: PriceWithTimestamp) => void
  setIsHovered: (isHovered: boolean) => void
  selectedRange: GraphRange
}

export const LineGraph = ({
  className,
  data,
  setTooltipValue,
  setIsHovered,
  selectedRange,
}: LineGraphProps) => {
  const { windowSize } = useWindowSize()
  const { width } = windowSize
  const { f } = useLocalization()
  const { imo } = useImoLoadable()
  const [isLegendActive, setIsLegendActive] = useState(false)
  const lineGraphRef = useRef(null)
  const legendRef = useRef(null)
  const lineGraphContainerName = className
    ? `${styles.lineGraphContainer} ${className}`
    : styles.lineGraphContainer

  const getDataResolution = () => {
    switch (selectedRange) {
      case GraphRange.Week:
        return 1800
      case GraphRange.Month:
        return 7200
      case GraphRange.Year:
        return 86400
      default:
        return 336
    }
  }

  const getGraphDataLength = () => {
    switch (selectedRange) {
      case GraphRange.Week:
        return 336 // week/resolution = 24*60*60*7/1800
      case GraphRange.Month:
        return 360 // month/resolution = 24*60*60*30/7200
      case GraphRange.Year:
        return 365 // year/resolution = 24*60*60*365/86400
      default:
        return 336
    }
  }

  useEffect(() => {
    const renderLineGraph = () => {
      if (lineGraphRef.current && data && imo) {
        const width = parseInt(select(lineGraphRef.current).style('width'))
        const height = parseInt(select(lineGraphRef.current).style('height'))
        const totalPadding =
          GraphConstants.PADDING.BOTTOM.MEDIUM + GraphConstants.PADDING.TOP.MEDIUM
        const innerWidth = width - GraphConstants.PADDING.RIGHT.MEDIUM
        const innerHeight = height - totalPadding
        const timestampList = data.map(({ timestamp }) => timestamp)
        const priceList = data.map(({ price }) => price)
        const imoData = Array.from({ length: getGraphDataLength() - data.length }, (_, i) => ({
          timestamp: Math.min(...timestampList) - i * getDataResolution(),
          price: imo.price,
        }))
        const imoTimestampList = imoData.map(({ timestamp }) => timestamp)
        const graphTimestampList = [...imoTimestampList, ...timestampList]

        const minGraphTimestamp = Math.min(...graphTimestampList)
        const maxGraphTimestamp = Math.max(...graphTimestampList)

        const maxImoTimestamp = Math.max(...imoTimestampList)

        const minPrice =
          minGraphTimestamp < maxImoTimestamp
            ? Math.min(...priceList, imo.price)
            : Math.min(...priceList)

        const maxPrice =
          minGraphTimestamp < maxImoTimestamp
            ? Math.max(...priceList, imo.price)
            : Math.max(...priceList)

        const scaledMinPrice =
          minPrice === maxPrice
            ? minPrice * (1 - GraphConstants.SCALE_FACTOR.Y_AXIS / 100)
            : minPrice
        const scaledMaxPrice =
          minPrice === maxPrice
            ? maxPrice * (1 + GraphConstants.SCALE_FACTOR.Y_AXIS / 100)
            : maxPrice

        const interpolationData = [
          {
            timestamp: maxImoTimestamp,
            price: imo.price,
          },
          {
            timestamp: Math.min(...timestampList),
            price: data[0]?.price || imo.price,
          },
        ]

        const defaultBisectorCoordinates = [
          {
            timestamp: minGraphTimestamp,
            price: scaledMinPrice,
          },
          {
            timestamp: minGraphTimestamp,
            price: scaledMaxPrice,
          },
        ]

        const xScale = scaleLinear()
          .domain([minGraphTimestamp, maxGraphTimestamp])
          .range([0, innerWidth])

        const yScale = scaleLinear()
          .domain([scaledMinPrice, scaledMaxPrice])
          .range([innerHeight, 0])

        const xAxis = axisTop(xScale)
          .ticks(7)
          .tickSize(0)
          .tickFormat((value) =>
            f.date(new Date(value.valueOf() * 1000), TextFormats.DATE.DATE_WITH_MONTH),
          )

        const yAxis = axisLeft(yScale)
          .ticks(6)
          .tickSize(0)
          .tickFormat((value) => f.number(value.valueOf()))

        const linePath = line<PriceWithTimestamp>()
          .x((dataPoint) => xScale(dataPoint.timestamp))
          .y((dataPoint) => yScale(dataPoint.price))
          .curve(curveMonotoneX)

        const bisectClosest = (data: PriceWithTimestamp[], timestamp: number) => {
          return data.reduce((previous, current) =>
            Math.abs(current.timestamp - timestamp) <= Math.abs(previous.timestamp - timestamp)
              ? current
              : previous,
          )
        }

        const handleTooltipMovement = (event: PointerEvent) => {
          // Get tooltip location from mouse event
          const [xPosition] = pointer(event, lineGraphRef.current)
          const { timestamp, price } = bisectClosest(
            [...imoData, ...data],
            xScale.invert(xPosition ? xPosition : 0),
          )
          const [xPositionBisector, yPositionBisector] = [xScale(timestamp), yScale(price)]
          const isInImoPeriod = imo.expiryDate ? new Date(timestamp * 1000) < imo.expiryDate : true
          setTooltipValue({ timestamp, price })

          // Prevent Legend from overflowing GraphContainer
          const legendLeftOffset = xPositionBisector - GraphConstants.LEGEND.WIDTH / 2
          const legendRightOffset = innerWidth - xPositionBisector - GraphConstants.LEGEND.WIDTH / 2
          const transformLeft =
            100 * (0.5 - Math.abs(legendLeftOffset) / GraphConstants.LEGEND.WIDTH)

          const transformRight =
            100 * (0.5 + Math.abs(legendRightOffset) / GraphConstants.LEGEND.WIDTH)
          const legendTransform =
            legendLeftOffset <= 0 ? transformLeft : legendRightOffset <= 0 ? transformRight : 50

          // Move Legend
          const legend = select(legendRef.current).style(
            'transform',
            `translateX(calc(${xPositionBisector}px - ${legendTransform}%)`,
          )

          // Move Bisector
          const bisector = select(lineGraphRef.current).select(`.${styles.bisector}`)
          bisector
            .select(`.${styles.line}`)
            .datum(defaultBisectorCoordinates)
            .attr('d', linePath)
            .style('transform', `translateX(${xPositionBisector}px)`)
          bisector
            .select(`.${styles.crosshair}`)
            .attr('r', GraphConstants.BISECTOR.LINE_GRAPH.TOOLTIP_SIZE)
            .style('transform', `translate(${xPositionBisector}px, ${yPositionBisector}px)`)
          bisector
            .select(`.${styles.shadow}`)
            .attr('r', GraphConstants.BISECTOR.LINE_GRAPH.SHADOW_SIZE)
            .style('transform', `translate(${xPositionBisector}px, ${yPositionBisector}px)`)

          if (isInImoPeriod) {
            bisector
              .select(`.${styles.crosshair}`)
              .attr('class', `${styles.crosshair} ${styles.gray}`)
            legend
              .select(`.${styles.timestamp}`)
              .html(
                imo.expiryDate
                  ? `Listed on ${f.date(imo.expiryDate, TextFormats.DATE.DATE_ALT)}`
                  : '',
              )
          } else {
            bisector.select(`.${styles.crosshair}`).attr('class', `${styles.crosshair}`)
            legend.select(`.${styles.timestamp}`).html(`${f.date(new Date(timestamp * 1000))}`)
          }
        }

        const handleTooltipActive = (event: PointerEvent) => {
          setIsLegendActive(true)
          setIsHovered(true)
          handleTooltipMovement(event)
        }

        const handleTooltipDeactive = (event: PointerEvent) => {
          setIsLegendActive(false)
          setIsHovered(false)
          handleTooltipMovement(event)
        }

        const lineGraph = select(lineGraphRef.current)
          .on('pointerover', handleTooltipActive)
          .on('pointerleave', handleTooltipDeactive)
          .on('pointermove', handleTooltipMovement)

        const lineGraphGroup = lineGraph.select(`.${styles.lineGraph}`)

        lineGraph.selectAll(`.${styles.xAxis}`).remove()
        lineGraph.selectAll(`.${styles.yAxis}`).remove()

        lineGraphGroup
          .append('g')
          .attr('class', `${styles.xAxis}`)
          .attr('transform', `translate(0, ${height - GraphConstants.PADDING.TOP.MEDIUM})`)
          .call(xAxis)
        lineGraphGroup
          .append('g')
          .attr('class', `${styles.yAxis}`)
          .attr('transform', `translate(${width}, 0)`)
          .call(yAxis)
        lineGraphGroup.selectAll('.domain').remove()

        lineGraph
          .select(`.${styles.lineGraph}`)
          .attr('transform', `translate(0, ${GraphConstants.PADDING.TOP.MEDIUM})`)
        lineGraph.select(`.${styles.linePath}`).datum(data).attr('d', linePath)

        if (imoData.length) {
          lineGraph
            .select(`.${styles.interpolatorPath}`)
            .datum(interpolationData)
            .attr('d', linePath)
          lineGraph.select(`.${styles.imoLinePath}`).datum(imoData).attr('d', linePath)
        } else {
          lineGraph.select(`.${styles.interpolatorPath}`).datum([]).attr('d', linePath)
          lineGraph.select(`.${styles.imoLinePath}`).datum([]).attr('d', linePath)
        }
      }
    }
    renderLineGraph()
    // eslint-disable-next-line
  }, [imo, data, width, isLegendActive])

  return (
    <div className={lineGraphContainerName}>
      <div
        className={`${styles.graphLegend} ${isLegendActive ? styles.active : ''}`}
        ref={legendRef}
      >
        <p className={styles.timestamp} />
      </div>
      <svg className={styles.lineGraphSvg} ref={lineGraphRef}>
        <g className={styles.lineGraph}>
          <path className={styles.linePath} />
          <path className={styles.interpolatorPath} />
          <path className={styles.imoLinePath} />
          <g className={`${styles.bisector} ${isLegendActive ? styles.active : ''}`}>
            <circle className={styles.shadow} />
            <path className={styles.line} />
            <circle className={styles.crosshair} />
          </g>
        </g>
      </svg>
    </div>
  )
}
