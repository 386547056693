import { Preferences } from '@capacitor/preferences'
import { ActionPerformed, PushNotifications, Token } from '@capacitor/push-notifications'
import { captureException, captureMessage } from '@sentry/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { CapacitorPreferences } from 'constants/local-storage'
import { usePlatform } from 'ui/@hooks/use-platform'

export const usePushNotifications = () => {
  const navigate = useNavigate()
  const { isNative } = usePlatform()

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt' || permStatus.receive === 'prompt-with-rationale') {
      permStatus = await PushNotifications.requestPermissions()
    }

    permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive !== 'granted') {
      captureMessage('Push notifications permission denied')
      return
    }

    await PushNotifications.register()
  }

  useEffect(() => {
    if (isNative) {
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        // CAUTION: Do not remove this line, it is used to persist the device token in native storage
        Preferences.set({ key: CapacitorPreferences.DEVICE_TOKEN, value: token.value })
      })

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: unknown) => {
        captureException(error)
      })

      // Method called when tapping on a notification
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (action: ActionPerformed) => {
          const { path } = action.notification.data
          if (path) {
            navigate(path)
          }
        },
      )

      Preferences.get({ key: CapacitorPreferences.ASKED_NOTIFICATION_PERMISSION }).then(
        (askedNotificationPermission) => {
          if (!askedNotificationPermission.value) {
            registerNotifications()
            Preferences.set({
              key: CapacitorPreferences.ASKED_NOTIFICATION_PERMISSION,
              value: 'true',
            })
          }
        },
      )
    }
    // eslint-disable-next-line
  }, [])
}
