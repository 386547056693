import { ReactNode, useState } from 'react'

import { useWindowSize } from 'ui/@hooks/use-window-size'
import { VideoPlayer } from 'ui/@library/multimedia/video-player'
import { VideoPlayerTrigger } from 'ui/@library/multimedia/video-player/types'
import { ExchangeCardSize } from 'ui/exchange/types'

import styles from './styles.module.scss'

type HeroProps = {
  placeholder: string
  mediaSrc: string
  children?: ReactNode
  onClick?: () => void
  size: ExchangeCardSize
}

export const Hero = ({ placeholder, mediaSrc, size, children, onClick }: HeroProps) => {
  const [showChildren, setShowChildren] = useState(false)
  const { viewports } = useWindowSize()

  const updateChildrenDisplay = () => {
    setShowChildren(true)
  }

  return (
    <div className={`${styles.hero} ${styles[size]}`} onClick={onClick}>
      <div className={styles.heroWrapper}>
        <VideoPlayer
          trigger={viewports.isTablet ? VideoPlayerTrigger.InView : VideoPlayerTrigger.OnHover}
          src={mediaSrc}
          placeholder={placeholder}
          className={`${styles[size]}`}
          autoplay={false}
          onLoaded={updateChildrenDisplay}
        />
        {showChildren && <div className={styles.overlay}>{children}</div>}
      </div>
    </div>
  )
}
