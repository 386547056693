import { sanitizeOrderFromRaw } from '../@santizers/order'
import { useMxApi } from '../mx-api'

export const useSellLimit = (
  masterUuid: string,
  shareUuidList: string[],
  pricePerShare: number,
) => {
  const [{ data, loading, error }, requestSellLimit] = useMxApi(
    {
      method: 'POST',
      url: `/masters/${masterUuid}/shares/asks`,
      data: {
        shares: shareUuidList.map((shareUuid) => ({
          share_uuid: shareUuid,
          price: pricePerShare,
          fill_or_kill: false,
        })),
      },
    },
    {
      manual: true,
    },
  )

  return {
    order: sanitizeOrderFromRaw(data),
    requestSellLimit,
    loading,
    error,
  }
}
