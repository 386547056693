import { useState } from 'react'

import { useLocalization } from 'locales/i18n'
import { useMasterStatusLoadable } from 'ui/@store/master'

import styles from '../../styles.module.scss'
import { AmountPicker } from '../amount-picker'
import { QuantityInput } from '../quantity-input'

type TradeQuantityProps = {
  displayErrorMessage?: boolean
  setDisplayErrorMessage?: (displayErrorMessage: boolean) => void
  isDisabled?: boolean
  maxLimit?: number
  label: string
  quantity: number
  setQuantity: (quantity: number) => void
  onFocusChange?: (isFocused: boolean) => void
  displayMaxLimitLabel?: boolean
}

export const TradeQuantity = ({
  maxLimit,
  label,
  quantity,
  setQuantity,
  isDisabled = false,
  displayErrorMessage = false,
  displayMaxLimitLabel = true,
  setDisplayErrorMessage,
  onFocusChange,
}: TradeQuantityProps) => {
  const { isImo } = useMasterStatusLoadable()
  const { t } = useLocalization('master.overviewTab.sidebar.trader')
  const [quantityError, setQuantityError] = useState(false)

  if (isImo)
    return (
      <li>
        <p className={styles.orderAmountItem}>
          <span className={styles.label}>{label}</span>
          {maxLimit && (
            <span className={`${styles.amountLimit} ${quantityError && styles.error}`}>{`${t(
              'imo.information.limit',
              {
                maxLimit,
              },
            )}`}</span>
          )}
        </p>
        {maxLimit && (
          <AmountPicker
            setQuantityError={setQuantityError}
            limit={maxLimit}
            value={quantity}
            setValue={setQuantity}
            onFocusChange={onFocusChange}
          />
        )}
      </li>
    )
  else
    return (
      <li>
        <span>
          {t('market.information.quantity.label')}
          {maxLimit && displayMaxLimitLabel && (
            <span className={styles.maxQuantity}>
              ({t('market.information.quantity.limit', { maxLimit })})
            </span>
          )}
        </span>
        <span>
          <QuantityInput
            limit={maxLimit}
            value={quantity}
            setValue={setQuantity}
            isDisabled={isDisabled}
            displayErrorMessage={displayErrorMessage}
            setDisplayErrorMessage={setDisplayErrorMessage}
            startFromRight={true}
            onFocusChange={onFocusChange}
          />
        </span>
      </li>
    )
}
