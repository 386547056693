export const ExternalLinks = {
  GOOGLE_CALENDAR_EVENT: 'https://calendar.google.com/calendar/r/eventedit',
  PRICING_AND_FEES_INFO:
    'https://support.masterexchange.com/hc/en-us/articles/14697732460561-MasterExchange-pricing-and-fees',
  MANGOPAY_TERMS_AND_CONDITIONS: 'https://links.masterexchange.com/mangopay-tc.pdf',
  LIST_MUSIC_FORM: 'https://rlle5zn50rk.typeform.com/to/xuHcpllF/',
  HELP_CENTER: 'https://support.masterexchange.com',
  SUPPORTED_COUNTRIES:
    'https://support.masterexchange.com/hc/en-us/articles/17446042412561-Supported-countries',
  SUPPORT_SONG_MATURITY_PHASES:
    'https://www.masterexchange.com/articles/learn-what-makes-up-the-value-of-a-song',
  ROYALTIES_SCHEDULE:
    'https://masterexchange.notion.site/4afd383ebe07451294a78ecca1f98fa4?v=04f9f9be9303453595ecf86d1e096416',
  GOLDMAN_SACHS_SOURCE:
    'https://www.goldmansachs.com/intelligence/pages/gs-research/music-in-the-air/report.pdf',
  PRIVACY_POLICY: 'https://links.masterexchange.com/privacy-policy.pdf',
  TERMS_AND_CONDITIONS: 'https://links.masterexchange.com/terms-and-conditions.pdf',
} as const

export const LandingPageLinks = {
  BASE_URL: process.env.REACT_APP_LANDING_PAGE_BASE_URL,
  MAIN: 'https://www.masterexchange.com',
  STAGING: 'https://masterexchange.webflow.io',
  KEVIN: 'https://kevin.masterexchange.com',
} as const

export const SocialMediaLinks = {
  TWITTER: 'https://twitter.com/masterexchange_',
  FACEBOOK: 'https://www.facebook.com/masterexchangecom',
  INSTAGRAM: 'https://www.instagram.com/masterexchange_',
  LINKEDIN: 'https://www.linkedin.com/company/masterexchangecom',
} as const

export const LandingPageRoutes = {
  ABOUT: '/about',
  WHY_MUSIC: '/why-music',
  HOW_IT_WORKS: '/how-it-works',
  JOBS: '/jobs',
  FAQ: '/faq',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms',
  OPUS_TERMS_AND_CONDITIONS: '/opus-terms',
  ARTICLES: '/articles',
  OPUS: '/opus',
  WAITLIST: '/waitlist',
} as const

export const WebflowQueryParams = {
  LANGUAGE: 'lng',
  SIGNED_IN: 'si',
} as const

export const NativeAppLinks = {
  IOS_PRODUCTION: 'https://apps.apple.com/app/id6468983617',
  IOS_STAGING: 'https://testflight.apple.com/join/6N8enVOy',
  ANDROID_PRODUCTION: 'https://play.google.com/store/apps/details?id=com.masterexchange.app',
  ANDROID_STAGING: 'https://play.google.com/apps/internaltest/4700107350127506110',
} as const
