import { useLocalization } from 'locales/i18n'
import { ModalContent } from 'ui/@library/layout/global-modal'
import { useMasterLoadable } from 'ui/@store/master'
import { TradeActionType } from 'ui/master/@components/master-overview/@components/banner-trader/types'
import { useTrader } from 'ui/master/@components/master-overview/@components/banner-trader/use-trader'

import { AccountBalance } from '../../../traders/@components/account-balance'
import { getTraderToRender } from '../../../traders/utils'

import styles from './styles.module.scss'

export function TraderModal() {
  const { isImo, tradeActionType, orderType, sellableShareList } = useTrader()
  const { master } = useMasterLoadable()
  const { t } = useLocalization('master.overviewTab.sidebar.trader.market.title')

  const getTraderTitle = () => {
    if (isImo) return t('imo')
    else {
      switch (tradeActionType) {
        case TradeActionType.Sell:
          return t('sell')
        case TradeActionType.Buy:
          return t('buy')
        default:
          return ''
      }
    }
  }

  return (
    <ModalContent className={styles.traderContainer}>
      <h2 className={styles.title}>{getTraderTitle()}</h2>
      {tradeActionType === TradeActionType.Sell && sellableShareList.length !== 0 && (
        <div className={styles.masterInfo}>
          <img src={master?.assets.thumbnail} alt='master album art' className={styles.thumbnail} />
          <div className={styles.masterDetails}>
            <p className={styles.masterName}>{master?.name}</p>
            <p className={styles.artistName}>{master?.artist.name}</p>
          </div>
        </div>
      )}
      {getTraderToRender({
        isImo,
        orderType,
        tradeActionType,
      })}
      <AccountBalance />
    </ModalContent>
  )
}
