import { AddCard3DSModalProps } from 'ui/@modals/add-funds/@components/add-card-3ds-modal/add-card-3ds-modal'
import { AddCardModalProps } from 'ui/@modals/add-funds/@components/add-card-modal/add-card-modal'
import { AddFundsConfirmProps } from 'ui/@modals/add-funds/@components/add-funds-confirm/add-funds-confirm'
import { CardLimitModalProps } from 'ui/@modals/add-funds/@components/card-limit/card-limit'
import { CardOptionsModalProps } from 'ui/@modals/add-funds/@components/card-options-modal/card-options-modal'
import { PayInThreeDSecureProps } from 'ui/@modals/add-funds/@components/pay-in-three-d-secure/pay-in-three-d-secure'
import { PaymentMethodProps } from 'ui/@modals/add-funds/@components/payment-method-modal/payment-method-modal'
import { CancelOrderModalProps } from 'ui/@modals/cancel-order-modal/cancel-order-modal'
import { KycTriggerModalProps } from 'ui/@modals/kyc/kyc-trigger-modal/kyc-trigger-modal'
import { CancelOrderProps } from 'ui/@modals/order/cancel-order/cancel-order'
import { SuccessProps } from 'ui/@modals/withdraw-funds/@components/success/success'
import { WithdrawalProps } from 'ui/@modals/withdraw-funds/@components/withdrawal/withdrawal'
import { MetricsExplainerModalProps } from 'ui/master/@components/master-overview/@components/information/@components/metrics-explainer-modal/types'
import { DialogBoxProps } from 'ui/master/@components/modals/dialog-box/dialog-box'
import { InsufficientFundsProps } from 'ui/master/@components/modals/insufficient-funds/insufficient-funds'
import { OrderCompletedProps } from 'ui/master/@components/modals/order-completed/order-completed'
import { OrderFailedProps } from 'ui/master/@components/modals/order-failed/order-failed'
import { ReviewImoProps } from 'ui/master/@components/modals/review-imo/review-imo'
import { PreauthThreeDSecureProps } from 'ui/master/@components/modals/review-order/@components/preauth-three-d-secure/preauth-three-d-secure'
import { PlaceImoBidProps } from 'ui/master/@components/modals/review-order/place-imo-bid'
import { ReviewAcceptBidProps } from 'ui/master/@components/modals/review-order/review-accept-bid'
import { ReviewBestPriceBuyProps } from 'ui/master/@components/modals/review-order/review-best-price-buy'
import { ReviewImoBuyProps } from 'ui/master/@components/modals/review-order/review-imo-buy'
import { ReviewLimitBuyProps } from 'ui/master/@components/modals/review-order/review-limit-buy'
import { ReviewLimitSellProps } from 'ui/master/@components/modals/review-order/review-limit-sell'
import { ReviewMakeBidProps } from 'ui/master/@components/modals/review-order/review-make-bid'
import { RoyaltyDistributionInfoProps } from 'ui/master/@components/modals/royalty-distribution-info/types'
import { SingleMasterShareDetailProps } from 'ui/master/@components/shares-table/@components/modals/single-master-share-detail/single-master-share-detail'
import { ListFractionsModalProps } from 'ui/my-assets/@components/overview/@components/modals/list-fractions-modal/list-fractions-modal'
import { RoyaltyPayoutListProps } from 'ui/my-assets/@components/overview/@components/modals/royalty-payouts-list/royalty-payouts-list'

export enum ModalSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ModalKey {
  ReviewKyc = 'ReviewKyc',
  ReviewOrder = 'ReviewOrder',
  ReviewImoBuy = 'ReviewImoBuy',
  ReviewBestPriceBuy = 'ReviewBestPriceBuy',
  ReviewLimitBuy = 'ReviewLimitBuy',
  ReviewLimitSell = 'ReviewLimitSell',
  ReviewMakeBid = 'ReviewMakeBid',
  ReviewAcceptBid = 'ReviewAcceptBid',
  ReviewImo = 'ReviewImo',
  AlreadySold = 'AlreadySold',
  InsufficientFunds = 'InsufficientFunds',
  MetricsDetails = 'MetricsDetails',
  OrderCompleted = 'OrderCompleted',
  OrderFailed = 'OrderFailed',
  OrderType = 'OrderType',
  CancelOrderModal = 'CancelOrderModal',
  ListFractions = 'ListFractions',
  TradingCommissionInfo = 'TradingCommissionInfo',
  TransactionFailed = 'TransactionFailed',
  CancelOrder = 'CancelOrder',
  AddCard = 'AddCard',
  CardLimit = 'CardLimit',
  AddFundsConfirm = 'Confirm',
  Error = 'Error',
  PaymentMethod = 'PaymentMethod',
  Success = 'Success',
  PayInThreeDSecure = 'PayInThreeDSecure',
  SingleMasterShareDetail = 'SingleMasterShareDetail',
  Withdrawal = 'Withdrawal',
  AddIban = 'AddIban',
  RoyaltyDistributionInfo = 'RoyaltyDistributionInfo',
  OnboardingTour = 'OnboardingTour',
  UpdatePassword = 'UpdatePassword',
  WithdrawFunds = 'WithdrawFunds',
  DialogBox = 'DialogBox',
  SocialShare = 'SocialShare',
  WebSharePreview = 'WebSharePreview',
  Trader = 'Trader',
  MaxSharesOrdered = 'MaxSharesOrdered',
  TradingPaused = 'TradingPaused',
  NoTradingForMasterOwner = 'NoTradingForMasterOwner',
  SignInPrompt = 'SignInPrompt',
  UpcomingSignUpPrompt = 'UpcomingSignUpPrompt',
  MetricsExplainerModal = 'MetricsExplainerModal',
  CardOptionsModal = 'CardOptionsModal',
  PreauthThreeDSecureModal = 'PreauthThreeDSecureModal',
  AddCardSuccess = 'AddCardSuccess',
  PlaceImoBid = 'PlaceImoBid',
  RoyaltyPayoutsList = 'RoyaltyPayoutsList',
  KycTriggerModal = 'KycTriggerModal',
  TraderErrorModal = 'TraderErrorModal',
  AddCard3DSModal = 'AddCard3DSModal',
  AddCardError = 'AddCardError',
  WithdrawFundsInfo = 'WithdrawFundsInfo',
}

export type PayloadType = {
  [ModalKey.ReviewKyc]: undefined
  [ModalKey.ReviewOrder]: undefined
  [ModalKey.ReviewImoBuy]: ReviewImoBuyProps
  [ModalKey.ReviewBestPriceBuy]: ReviewBestPriceBuyProps
  [ModalKey.ReviewLimitBuy]: ReviewLimitBuyProps
  [ModalKey.ReviewLimitSell]: ReviewLimitSellProps
  [ModalKey.ReviewMakeBid]: ReviewMakeBidProps
  [ModalKey.ReviewAcceptBid]: ReviewAcceptBidProps
  [ModalKey.ReviewImo]: ReviewImoProps
  [ModalKey.AlreadySold]: undefined
  [ModalKey.InsufficientFunds]: InsufficientFundsProps
  [ModalKey.MetricsDetails]: undefined
  [ModalKey.OrderCompleted]: OrderCompletedProps
  [ModalKey.OrderFailed]: OrderFailedProps
  [ModalKey.OrderType]: undefined
  [ModalKey.CancelOrderModal]: CancelOrderModalProps
  [ModalKey.ListFractions]: ListFractionsModalProps
  [ModalKey.TradingCommissionInfo]: undefined
  [ModalKey.TransactionFailed]: undefined
  [ModalKey.CancelOrder]: CancelOrderProps
  [ModalKey.AddCard]: AddCardModalProps
  [ModalKey.CardLimit]: CardLimitModalProps
  [ModalKey.AddFundsConfirm]: AddFundsConfirmProps
  [ModalKey.Error]: undefined
  [ModalKey.PaymentMethod]: PaymentMethodProps
  [ModalKey.Success]: SuccessProps
  [ModalKey.PayInThreeDSecure]: PayInThreeDSecureProps
  [ModalKey.SingleMasterShareDetail]: SingleMasterShareDetailProps
  [ModalKey.Withdrawal]: WithdrawalProps
  [ModalKey.AddIban]: undefined
  [ModalKey.RoyaltyDistributionInfo]: RoyaltyDistributionInfoProps
  [ModalKey.OnboardingTour]: undefined
  [ModalKey.UpdatePassword]: undefined
  [ModalKey.WithdrawFunds]: undefined
  [ModalKey.DialogBox]: DialogBoxProps
  [ModalKey.SocialShare]: undefined
  [ModalKey.WebSharePreview]: undefined
  [ModalKey.Trader]: undefined
  [ModalKey.MaxSharesOrdered]: undefined
  [ModalKey.TradingPaused]: undefined
  [ModalKey.NoTradingForMasterOwner]: undefined
  [ModalKey.SignInPrompt]: undefined
  [ModalKey.UpcomingSignUpPrompt]: undefined
  [ModalKey.MetricsExplainerModal]: MetricsExplainerModalProps
  [ModalKey.CardOptionsModal]: CardOptionsModalProps
  [ModalKey.PreauthThreeDSecureModal]: PreauthThreeDSecureProps
  [ModalKey.AddCardSuccess]: undefined
  [ModalKey.PlaceImoBid]: PlaceImoBidProps
  [ModalKey.RoyaltyPayoutsList]: RoyaltyPayoutListProps
  [ModalKey.KycTriggerModal]: KycTriggerModalProps
  [ModalKey.TraderErrorModal]: undefined
  [ModalKey.AddCard3DSModal]: AddCard3DSModalProps
  [ModalKey.AddCardError]: undefined
  [ModalKey.WithdrawFundsInfo]: undefined
}
