import { useEffect } from 'react'

import { usePlatform } from 'ui/@hooks/use-platform'

import { RouteWrapperNative } from './route-wrapper-native'
import { RouteWrapperWeb } from './route-wrapper-web'
import { RouteWrapperProps } from './types'

export const RouteWrapper = ({
  headers,
  children,
  title,
  description,
  keywords,
  pageUrl,
  imageUrl,
  allowsBackNavigation,
  onRefresh,
  noPadding = false,
}: RouteWrapperProps) => {
  const { isNative } = usePlatform()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isNative) {
    return (
      <RouteWrapperNative
        headers={headers}
        title={title}
        description={description}
        keywords={keywords}
        pageUrl={pageUrl}
        imageUrl={imageUrl}
        noPadding={noPadding}
        allowsBackNavigation={allowsBackNavigation}
        onRefresh={onRefresh}
      >
        {children}
      </RouteWrapperNative>
    )
  } else {
    return (
      <RouteWrapperWeb
        headers={headers}
        title={title}
        description={description}
        keywords={keywords}
        pageUrl={pageUrl}
        imageUrl={imageUrl}
        noPadding={noPadding}
        allowsBackNavigation={allowsBackNavigation}
      >
        {children}
      </RouteWrapperWeb>
    )
  }
}
