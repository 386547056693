import { useLocalization } from 'locales/i18n'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { Button } from 'ui/@library/inputs/button'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { SignInPrompt } from 'ui/master/@components/master-overview/@components/banner-trader/@components/sign-in-prompt'
import { TradeActionType } from 'ui/master/@components/master-overview/@components/banner-trader/types'
import { useTrader } from 'ui/master/@components/master-overview/@components/banner-trader/use-trader'

import { TraderModal } from '../../../trader-modal'
import styles from '../../styles.module.scss'

type BuyButtonProps = {
  setIsMenuOpen: (isMenuOpen: boolean) => void
}

export const BuyButton = ({ setIsMenuOpen }: BuyButtonProps) => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader')
  const { renderModal } = useGlobalModal()
  const { setTradeActionType } = useTrader()
  const { isAuthenticated } = useMxAuth()

  const renderSignInPrompt = () => {
    renderModal({
      content: <SignInPrompt />,
      enforceAuth: false,
      modalKey: ModalKey.SignInPrompt,
    })
  }

  const handleBuy = () => {
    if (isAuthenticated) {
      setIsMenuOpen(false)
      setTradeActionType(TradeActionType.Buy)
      renderModal({
        content: <TraderModal />,
        enforceAuth: true,
        modalKey: ModalKey.Trader,
      })
    } else {
      renderSignInPrompt()
    }
  }

  return (
    <Button className={styles.buy} onClick={handleBuy} variant='primary'>
      {t('market.title.buy')}
    </Button>
  )
}
