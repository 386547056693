import { Commission, CommissionRaw } from 'types/auth-user'

import { sanitizeCommissionFromRaw } from '../@santizers/auth-user'
import { MxApi } from '../mx-api'

export const fetchCommission = async (): Promise<Commission> => {
  const { data } = await MxApi.get<CommissionRaw>('/auth-users/me/commission')

  return sanitizeCommissionFromRaw(data)
}
