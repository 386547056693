import { IonContent, IonHeader, IonRefresher, IonRefresherContent } from '@ionic/react'
import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

import MXLogoMini from 'assets/images/mx-logo-mini.svg'
import MXLogo from 'assets/images/mx-logo.png'
import GradientBall from 'assets/svgs/gradient-ball.svg'
import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type StaticPageWrapperProps = {
  children: ReactNode
  title?: string
}

export const StaticPageWrapperNative = ({ children, title }: StaticPageWrapperProps) => {
  const { t } = useLocalization('components')

  const handleRefresh = () => {
    window.location.reload()
  }

  return (
    <>
      <IonHeader class={styles.IonHeader}>
        <Helmet>
          <title>{t('pageWrapperTitle', { pageTitle: title })}</title>
        </Helmet>
      </IonHeader>
      <IonContent>
        <IonRefresher
          slot='fixed'
          onIonRefresh={handleRefresh}
          className={styles.refresher}
          pullFactor={0.5}
          pullMin={100}
          pullMax={200}
        >
          <IonRefresherContent />
        </IonRefresher>
        <div className={styles.wrapper}>
          <div className={styles.safeArea} />
          <div className={styles.container}>
            <div className={`${styles.contents}`}>
              <img src={MXLogo} className={styles.logo} alt='' />
              <img src={MXLogoMini} className={styles.logoMini} alt='' />
              <img src={GradientBall} className={styles.gradientBall} alt='' />
              {children}
            </div>
          </div>
        </div>
      </IonContent>
    </>
  )
}
