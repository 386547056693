import { motion } from 'framer-motion'

import { Spinner } from 'ui/@library/feedback/spinner'

import { modalContentVariants } from '../../constants'

import styles from './styles.module.scss'

type ModalContentProps = {
  className?: string
  children: React.ReactNode
  loading?: boolean
}

export const ModalContent = ({ className, children, loading }: ModalContentProps) => {
  return (
    <motion.div
      variants={modalContentVariants}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={`${styles.modalContent} ${className && className}`}
      key='modal'
    >
      {loading ? (
        <div className={styles.loadingWrapper}>
          <Spinner />
        </div>
      ) : (
        children
      )}
    </motion.div>
  )
}
