import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'
import { Divider } from 'ui/@library/layout/divider'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useMasterLoadable, useMasterStatusLoadable } from 'ui/@store/master'
import { MasterStatus } from 'ui/master/types'

import { MetricItem } from '../metric-item'
import { MetricsExplainerModal } from '../metrics-explainer-modal'
import { MetricType } from '../metrics-explainer-modal/types'

import styles from './styles.module.scss'
export const MetricList = () => {
  const { t, f } = useLocalization('master.overviewTab.information.metrics')
  const { master } = useMasterLoadable()
  const { masterStatus } = useMasterStatusLoadable()
  const { renderModal } = useGlobalModal()
  const handleNetMasterRoyaltyOwnershipPerShareInfo = () => {
    renderModal({
      modalKey: ModalKey.MetricsExplainerModal,
      content: <MetricsExplainerModal />,
      enforceAuth: false,
      payload: {
        metricType: MetricType.NetMasterRoyaltyOwnershipPerShare,
      },
    })
  }
  const handleTotalListedMarketCapInfo = () => {
    renderModal({
      modalKey: ModalKey.MetricsExplainerModal,
      content: <MetricsExplainerModal />,
      enforceAuth: false,
      payload: {
        metricType: MetricType.TotalListedMarketCap,
      },
    })
  }
  const handleTotalSharesInfo = () => {
    renderModal({
      modalKey: ModalKey.MetricsExplainerModal,
      content: <MetricsExplainerModal />,
      enforceAuth: false,
      payload: {
        metricType: MetricType.TotalShares,
      },
    })
  }
  if (!master?.metrics) return null
  const volumeQuantity = master.market.volumeQuantity ?? 0
  const marketCap =
    masterStatus === MasterStatus.Market
      ? master.market.lastPrice * volumeQuantity
      : master.imo.price * volumeQuantity
  return (
    <>
      <div className={styles.metricList}>
        {master.metaData.releaseDate && (
          <MetricItem
            title={t('releaseDate.title')}
            value={f.date(master.metaData.releaseDate, TextFormats.DATE.MONTH_WITH_YEAR)}
          />
        )}
        <MetricItem
          title={t('netMasterRoyaltyOwnershipPerShare.title')}
          value={`${master.metrics.ownershipPerShare}%`}
          onClickInfo={handleNetMasterRoyaltyOwnershipPerShareInfo}
          infoIconClassName={styles.netMasterRoyaltyOwnershipPerShareInfoIcon}
        />
        {masterStatus === MasterStatus.Market && marketCap !== 0 && (
          <MetricItem
            title={t('totalListedMarketCap.title')}
            value={f.currency(marketCap)}
            onClickInfo={handleTotalListedMarketCapInfo}
          />
        )}
        {master.market.volumeQuantity && (
          <MetricItem
            title={t('totalShares.title')}
            value={f.number(master.market.volumeQuantity)}
            onClickInfo={handleTotalSharesInfo}
          />
        )}
        <MetricItem
          title={t('royaltyPayout.title')}
          value={t(`details.royaltyPayout.types.${master.metrics.royaltyPayoutType}`)}
        />
      </div>
      <Divider />
    </>
  )
}
