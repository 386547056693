import { useLocalization } from 'locales/i18n'
import { OrderSide, OrderType } from 'types/order'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { ErrorWithHeader } from 'ui/@library/errors/error-with-header'
import { Loader } from 'ui/@library/feedback/loader'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { CancelOrder } from 'ui/@modals/order/cancel-order'
import { useMasterLoadable } from 'ui/@store/master'
import { useShareLoadable } from 'ui/@store/share'
import { SignInPrompt } from 'ui/master/@components/master-overview/@components/banner-trader/@components/sign-in-prompt'
import {
  ReviewLimitSell,
  ReviewMakeBid,
  ReviewBestPriceBuy,
  ReviewAcceptBid,
} from 'ui/master/@components/modals/review-order'

import { MasterInfo } from './@components/master-info'
import { MasterMetadata } from './@components/master-metadata'
import styles from './styles.module.scss'

export type SingleMasterShareDetailProps = {
  shareUuid: string
}

export const SingleMasterShareDetail = () => {
  const { t, f } = useLocalization('order.uniqueOrder')
  const { payload, renderModal } = useGlobalModal()
  const { isAuthenticated } = useMxAuth()
  const { master, loading: masterLoading } = useMasterLoadable()
  const { share, loading: shareLoading } = useShareLoadable(payload?.shareUuid, {
    forceRefetch: true,
  })

  if (masterLoading || shareLoading)
    return (
      <ModalContent>
        <div className={styles.modalLoaderContainer}>
          <Loader />
        </div>
      </ModalContent>
    )

  if (!master || !share) return <ErrorWithHeader />

  const handleSellClick = () => {
    renderModal({
      modalKey: ModalKey.ReviewLimitSell,
      content: <ReviewLimitSell />,
      payload: {
        orderType: OrderType.LimitAsk,
        numberOfShares: 1,
        sharesToTrade: [share],
        shareUuid: share.uuid,
        isUnique: true,
      },
      enforceAuth: true,
      size: ModalSize.Large,
    })
  }

  const handleBidClick = () => {
    if (isAuthenticated) {
      renderModal({
        modalKey: ModalKey.ReviewMakeBid,
        content: <ReviewMakeBid />,
        payload: {
          shareUuid: share.uuid,
          sharesToTrade: [share],
          orderType: OrderType.Bid,
        },
        enforceAuth: true,
        size: ModalSize.Large,
      })
    } else {
      renderModal({
        content: <SignInPrompt />,
        enforceAuth: false,
        modalKey: ModalKey.SignInPrompt,
      })
    }
  }

  const handleBuyClick = () => {
    if (isAuthenticated) {
      renderModal({
        modalKey: ModalKey.ReviewBestPriceBuy,
        content: <ReviewBestPriceBuy />,
        payload: {
          orderType: OrderType.BestBid,
          sharesToTrade: [
            {
              uuid: share.uuid,
              tokenId: share.tokenId,
              price: share.askPrice,
            },
          ],
          pricePerShare: share.askPrice,
          numberOfShares: 1,
          isUnique: true,
        },
        enforceAuth: true,
        size: ModalSize.Large,
      })
    } else {
      renderModal({
        content: <SignInPrompt />,
        enforceAuth: false,
        modalKey: ModalKey.SignInPrompt,
      })
    }
  }

  const handleCancelOrder = (orderSide: OrderSide) => {
    renderModal({
      modalKey: ModalKey.CancelOrder,
      content: <CancelOrder />,
      payload: {
        orderSide,
        masterUuid: master.uuid,
        orderUuid: orderSide === OrderSide.Ask ? share.authUserAsk?.uuid : share.authUserBid?.uuid,
      },
      enforceAuth: true,
    })
  }

  const handleAcceptOffer = () => {
    renderModal({
      modalKey: ModalKey.ReviewAcceptBid,
      content: <ReviewAcceptBid />,
      payload: {
        masterUuid: master.uuid,
        shareUuid: share.uuid,
      },
      enforceAuth: true,
      size: ModalSize.Large,
    })
  }

  return (
    <ModalContent>
      <MasterInfo master={master} tokenId={share.tokenId} />
      <MasterMetadata
        tokenId={share.tokenId}
        price={share.askPrice}
        contractAddress={master.decentralizedAddresses.contractAddress}
        nftPdfBaseUrl={master.documents.nftPdfBaseUrl}
        blockchainExplorerUrl={master.decentralizedAddresses.blockchainExplorerUrl}
      />
      {share?.owned ? (
        <div className={styles.listForSale}>
          <h3 className={styles.uniqueOrderSectionHeader}>{t('listForSale')}</h3>
          {share.askPrice ? (
            <Button
              variant='secondary'
              className={styles.cancelAskButton}
              onClick={() => handleCancelOrder(OrderSide.Ask)}
            >
              {t('cancelListing')}
            </Button>
          ) : (
            <Button className={styles.sellButton} onClick={handleSellClick}>
              {t('orderType.sell')}
            </Button>
          )}
        </div>
      ) : (
        <Button className={styles.orderButton} disabled={!share.askPrice} onClick={handleBuyClick}>
          {share.askPrice
            ? t('orderType.buyForPrice', { price: f.currency(share.askPrice) })
            : t('orderType.buyNoPrice')}
        </Button>
      )}

      <h3 className={styles.uniqueOrderSectionHeader}>{t('offers.heading')}</h3>
      <div className={styles.offer}>
        <div>
          {share.highestBid ? (
            <>
              {t('offers.highestOffer', {
                price: f.currency(share.highestBid.price),
              })}{' '}
              {share.highestBid.uuid === share.authUserBid?.uuid && t('offers.byYou')}
            </>
          ) : (
            <>{t('offers.noOffers')}</>
          )}
        </div>
        {share.authUserBid && share.highestBid?.uuid !== share.authUserBid?.uuid && (
          <div className={styles.offerPrice}>
            {t('offers.yourOffer', {
              price: f.currency(share.authUserBid.price),
            })}{' '}
            {share.highestBid?.price === share.authUserBid.price && t('offers.offersAboveYou')}
          </div>
        )}
      </div>
      {share.authUserBid && (
        <Button
          variant='secondary'
          className={styles.cancelOfferButton}
          onClick={() => handleCancelOrder(OrderSide.Bid)}
        >
          {t('offers.cancelOffer')}
        </Button>
      )}
      {!share?.owned && !share.authUserBid && (
        <Button onClick={handleBidClick} className={styles.orderButton}>
          {t('offers.makeOffer')}
        </Button>
      )}
      {share?.owned && share.highestBid && (
        <Button className={styles.sellButton} onClick={handleAcceptOffer}>
          {t('offers.acceptOffer')}
        </Button>
      )}
    </ModalContent>
  )
}
