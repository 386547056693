import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type PlatformMetricProps = {
  icon?: string
  name: string
  value: number | null
}

export const PlatformMetric = ({ icon, name, value }: PlatformMetricProps) => {
  const { f } = useLocalization()

  if (!value) return null

  return (
    <div className={styles.metric}>
      {icon && <img src={icon} alt={name} className={styles.icon} />}
      <p className={styles.platformName}>{name}</p>
      <p className={styles.platformValue}>{f.number(value, { compact: 'short' })}</p>
    </div>
  )
}
