import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Order } from 'constants/trading'
import { useLocalization } from 'locales/i18n'
import { useGenerateBestBid } from 'repositories/mx-api/hooks/use-generate-best-bid'
import { KycStatus } from 'types/auth-user'
import { OrderType as OrderTypeGlobal } from 'types/order'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { Button } from 'ui/@library/inputs/button'
import { Divider } from 'ui/@library/layout/divider'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { KycTriggerModal } from 'ui/@modals/kyc/kyc-trigger-modal'
import { ReviewKyc } from 'ui/@modals/kyc/review-kyc'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useMarketOverviewLoadable } from 'ui/@store/market-overview'
import { useWalletLoadable } from 'ui/@store/wallet'
import { InsufficientFunds } from 'ui/master/@components/modals/insufficient-funds'
import { ReviewBestPriceBuy } from 'ui/master/@components/modals/review-order'

import { OrderType } from '../../types'
import { useTrader } from '../../use-trader'
import { SignInPrompt } from '../sign-in-prompt'

import { TradeSpecification } from './@components/trade-specification'
import { TradeAmount } from './@components/trade-specification/@components/trade-amount/trade-amount'
import { TradeQuantity } from './@components/trade-specification/@components/trade-quantity'
import { TradeTotal } from './@components/trade-specification/@components/trade-total'
import { TradeType } from './@components/trade-specification/@components/trade-type'
import styles from './styles.module.scss'

export const TraderBestPriceBuy = () => {
  const { t, f } = useLocalization('master.overviewTab.sidebar.trader.market')
  const { marketOverview } = useMarketOverviewLoadable()
  const { uuid: masterUuid } = useParams()
  const { isAuthenticated } = useMxAuth()
  const { renderModal } = useGlobalModal()
  const { wallet } = useWalletLoadable()
  const { authUser } = useAuthUserLoadable()
  const { setOrderType } = useTrader()

  const [numberOfShares, setNumberOfShares] = useState(NaN)
  const {
    shares: bestPriceShares,
    requestGeneratebestBid,
    loading: generateBidLoading,
  } = useGenerateBestBid()
  const [bestPriceTotal, setBestPriceTotal] = useState(0)
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false)
  const [averageSharePrice, setAverageSharePrice] = useState(0)
  const [isKeyboardActive, setIsKeyboardActive] = useState(false)
  const [isInputChanging, setIsInputChanging] = useState(false)

  const accountBalance = wallet?.primaryBalance
  const totalWithoutFees = bestPriceShares.reduce((acc, share) => {
    return acc + share.price
  }, 0)
  const averagePricePerShare = totalWithoutFees / numberOfShares
  const serviceFee = Math.floor(Order.SERVICE_CHARGE.IN_PERCENT * averagePricePerShare) / 100
  const isServiceFeeBelowMinimum = Math.abs(serviceFee) < Order.SERVICE_CHARGE.MINIMUM_PER_SHARE
  const finalServiceFee =
    numberOfShares *
    (isServiceFeeBelowMinimum ? Order.SERVICE_CHARGE.MINIMUM_PER_SHARE : serviceFee)
  const totalOrderAmount = finalServiceFee + totalWithoutFees
  const hasInsufficientFunds = !accountBalance || accountBalance < totalOrderAmount
  const isKycValidated = authUser?.kycStatus === KycStatus.Validated
  const isKycAttempted =
    authUser?.kycStatus === KycStatus.Refused || authUser?.kycStatus === KycStatus.ValidationAsked

  const isValidShareQuantity =
    marketOverview && numberOfShares && numberOfShares <= marketOverview.bestBidMaxQuantity

  useEffect(() => {
    if (marketOverview && marketOverview.bestBidMaxQuantity === 0) {
      setOrderType(OrderType.Limit)
    }
    // eslint-disable-next-line
  }, [marketOverview])

  useEffect(() => {
    setIsInputChanging(true)
    const debounceTimer = setTimeout(() => {
      setIsInputChanging(false)
      if (isValidShareQuantity && masterUuid) {
        requestGeneratebestBid(masterUuid, numberOfShares)
      }
    }, 500)

    return () => clearTimeout(debounceTimer)
    // eslint-disable-next-line
  }, [numberOfShares])

  useEffect(() => {
    const total = bestPriceShares.reduce((acc, share) => acc + share.price, 0)
    const averagePrice = Number((total / bestPriceShares.length).toFixed(2))

    if (isValidShareQuantity) {
      setAverageSharePrice(averagePrice)
      setBestPriceTotal(total)
    } else {
      setAverageSharePrice(0)
      setBestPriceTotal(0)
    }
    // eslint-disable-next-line
  }, [bestPriceShares])

  const renderReviewBestPriceBuyModal = () => {
    renderModal({
      modalKey: ModalKey.ReviewBestPriceBuy,
      content: <ReviewBestPriceBuy />,
      payload: {
        sharesToTrade: bestPriceShares,
        numberOfShares,
        pricePerShare: Number(averageSharePrice),
        orderType: OrderTypeGlobal.BestBid,
      },
      enforceAuth: true,
      size: ModalSize.Large,
    })
  }

  const handleReviewOrder = () => {
    if (isValidShareQuantity) {
      if (!isAuthenticated) {
        renderModal({
          content: <SignInPrompt />,
          modalKey: ModalKey.SignInPrompt,
        })
      } else if (isKycAttempted) {
        renderModal({
          modalKey: ModalKey.ReviewKyc,
          content: <ReviewKyc />,
        })
      } else if (!isKycValidated) {
        renderModal({
          modalKey: ModalKey.KycTriggerModal,
          content: <KycTriggerModal />,
          payload: {
            title: t('kycTrigger.title'),
            description: t('kycTrigger.description'),
          },
        })
      } else if (hasInsufficientFunds) {
        renderModal({
          content: <InsufficientFunds />,
          modalKey: ModalKey.InsufficientFunds,
          payload: {
            sharesCost: accountBalance ? totalOrderAmount - accountBalance : totalOrderAmount,
            retriggerReviewOrder: true,
            onAddFundsSuccessCallback: () => renderReviewBestPriceBuyModal(),
          },
        })
      } else {
        setNumberOfShares(NaN)
        renderReviewBestPriceBuyModal()
      }
    }
  }

  const handleFocusChange = (isFocused: boolean) => {
    setIsKeyboardActive(isFocused)
  }

  if (!marketOverview) return null

  return (
    <form
      className={`${styles.traderVariantWrapper} ${isKeyboardActive ? styles.keyboardActive : ''}`}
      onSubmit={(e) => {
        e.preventDefault()
        handleReviewOrder()
      }}
    >
      <TradeSpecification>
        <TradeType label={t('information.orderType.title')} />
        <TradeQuantity
          setDisplayErrorMessage={setDisplayErrorMessage}
          displayErrorMessage={displayErrorMessage}
          label={t('information.quantity.label')}
          setQuantity={setNumberOfShares}
          quantity={numberOfShares}
          isDisabled={marketOverview.bestBidMaxQuantity === 0}
          maxLimit={marketOverview.bestBidMaxQuantity}
          onFocusChange={handleFocusChange}
        />
        <TradeAmount
          label={t('information.price.buy.market')}
          amount={f.currency(averageSharePrice)}
          onFocusChange={handleFocusChange}
        />
        <Divider />
        <TradeTotal label={t('information.estimated')} price={bestPriceTotal} />
      </TradeSpecification>
      <Button
        width='fluid'
        variant='primary'
        className={styles.buyButton}
        disabled={!isValidShareQuantity || generateBidLoading || isInputChanging}
        type='submit'
      >
        {t('actionCta')}
      </Button>
    </form>
  )
}
