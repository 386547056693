import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg'
import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type ErrorProps = {
  message?: string
}

export const ErrorWithHeader = ({ message }: ErrorProps) => {
  const { t } = useLocalization('feedback.error')

  return (
    <div className={styles.errorContainer}>
      <ExclamationIcon className={styles.errorIcon} />
      <h2 className={styles.errorHeading}>{t('header')}</h2>
      <p className={styles.errorDescription}>{message ?? t('message')}</p>
    </div>
  )
}
