import { useState, useRef, useMemo } from 'react'

import { ReactComponent as Hamburger } from 'assets/icons/hamburger.svg'
import { routes } from 'ui/@components/navbar/routes'
import { useAppEnvironment } from 'ui/@hooks/use-app-environment'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useOnClickOutside } from 'ui/@hooks/use-onclick-outside'

import { RouteLink } from '../../../route-link'
import { SignInLink } from '../sign-in-link'

import styles from './styles.module.scss'

type OptionType = {
  value: JSX.Element
  label: string
}

export const NavbarMobileDropdown = () => {
  const { isAuthenticated } = useMxAuth()
  const { isProduction } = useAppEnvironment()
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef(null)
  useOnClickOutside(ref, () => setIsOpen(false))

  const isMarqueeHidden = isProduction || isAuthenticated

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const options: OptionType[] = useMemo(() => {
    return routes
      .filter((route) => !route.isAuthRequired || (route.isAuthRequired && isAuthenticated))
      .map((route) => {
        return {
          value: <RouteLink route={route} />,
          label: route.path,
        }
      })
  }, [isAuthenticated])

  return (
    <div className={styles.fullPageMobileDropdownContainer} ref={ref}>
      <div className={styles.menuButton} onClick={toggleDropdown}>
        <div className={`${styles.menuButton} ${isOpen && styles.active}`}>
          <Hamburger />
        </div>
      </div>
      <div
        className={`${styles.dropdownWrapper} ${isOpen ? styles.open : styles.close} ${
          !isMarqueeHidden && styles.showPreLaunchBanner
        }`}
      >
        <div
          className={`${styles.dropdownListWrapper} ${
            isOpen ? styles.itemShown : styles.itemHidden
          }`}
        >
          {options.map((option) => (
            <div key={option.label} className={styles.dropdownItem}>
              {option.value}
            </div>
          ))}

          <div className={styles.dropdownItem}>
            <SignInLink />
          </div>
        </div>
      </div>
    </div>
  )
}
