import './index.scss'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import { setupIonicReact } from '@ionic/react'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import { CookieBotComponent } from 'cookiebot'
import { i18nInit } from 'locales/i18n'
import { initSentry } from 'sentry'
import { initTrackingServices } from 'tracking-services'
import { ApplicationWrapper } from 'ui/@components/application-wrapper'
import { IonAppWrapper } from 'ui/@components/ion-app-wrapper/ion-app-wrapper'
import { Crash } from 'ui/@library/errors'
import { AuthProvider } from 'ui/application-routes/@components/auth-provider/auth-provider'

setupIonicReact()
initSentry()
initTrackingServices()
i18nInit()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <IonAppWrapper>
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={() => <Crash />}>
        <RecoilRoot>
          <HelmetProvider>
            <BrowserRouter>
              <AuthProvider>
                <CookieBotComponent noDeclaration />
                <ApplicationWrapper />
              </AuthProvider>
            </BrowserRouter>
          </HelmetProvider>
        </RecoilRoot>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  </IonAppWrapper>,
)
