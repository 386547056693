import { MasterWithFractionsAndMarketRaw } from 'types/master'

import { sanitizeMasterWithFractionsAndMarketFromRaw } from '../@santizers/master'
import { MxApi } from '../mx-api'

export const fetchAuthUserMastersWithFractions = async () => {
  const { data } = await MxApi.get<MasterWithFractionsAndMarketRaw[]>('/masters/me')

  return data.map(sanitizeMasterWithFractionsAndMarketFromRaw)
}
