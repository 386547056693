import { RecentTradesRaw } from 'types/recent-trades'

import { sanitizeRecentTradesRaw } from '../@santizers/recent-trades'
import { MxApi } from '../mx-api'

export const fetchRecentTrades = async (masterUuid: string): Promise<RecentTradesRaw[]> => {
  const { data } = await MxApi.get<RecentTradesRaw[]>(`masters/${masterUuid}/activity`)

  return data.map(sanitizeRecentTradesRaw)
}
