export const ThreeDSecureEvent = {
  ID: 'secure-mode-redirection',
  TYPE: 'message',
  TIMEOUT: 5000,
} as const

export const ThreeDSecureQueryParams = {
  PREAUTHORIZATION_ID: 'preAuthorizationId',
  STATUS: 'status',
  TRANSACTION_ID: 'transactionId',
  CARDVALIDATION_ID: 'cardValidationId',
} as const
