import { useLocalization } from 'locales/i18n'

import styles from '../../styles.module.scss'

type TotalAmountProps = {
  title: string
  amount: number
  highlight?: boolean
}

export const OrderAmount = ({ title, amount, highlight }: TotalAmountProps) => {
  const { f } = useLocalization()
  return (
    <li className={highlight ? styles.highlighted : ''}>
      <span>{title}</span>
      <span>{f.currency(amount)}</span>
    </li>
  )
}
