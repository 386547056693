import { Link } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'

import {
  SectionContainer,
  SectionTitle,
  SectionDescription,
  SectionHeader,
  SectionButton,
} from '../section'

export const AccountDeletion = () => {
  const { t } = useLocalization('account.accountDeletion')

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{t('title')}</SectionTitle>
        <SectionButton>
          <Link to={Paths.DELETE_ACCOUNT}>{t('buttonText')}</Link>
        </SectionButton>
      </SectionHeader>
      <SectionDescription>{t('description')}</SectionDescription>
    </SectionContainer>
  )
}
