import { DocumentType } from './types'

export const getTranslationKey = (documentType: DocumentType) => {
  switch (documentType) {
    case DocumentType.PASSPORT:
      return 'passport'
    case DocumentType.DRIVING_LICENSE:
      return 'drivingLicense'
    case DocumentType.NATIONAL_ID:
      return 'nationalId'
  }
}
