import { ReactComponent as CrossIcon } from 'assets/icons/close.svg'
import { useLocalization } from 'locales/i18n'
import { ImoStatus } from 'types/imo'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { Button } from 'ui/@library/inputs/button'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useMasterLoadable } from 'ui/@store/master'
import { SignInPrompt } from 'ui/master/@components/master-overview/@components/banner-trader/@components/sign-in-prompt'
import { useTrader } from 'ui/master/@components/master-overview/@components/banner-trader/use-trader'
import { ReviewImo } from 'ui/master/@components/modals/review-imo'

import { MaxSharesModal } from '../../../max-shares-modal'
import { NoTradingMasterOwnerModal } from '../../../no-trading-master-owner-modal'
import { TraderErrorModal } from '../../../trader-error-modal'
import { TraderModal } from '../../../trader-modal'
import { TradingPausedModal } from '../../../trading-paused-modal'
import styles from '../../styles.module.scss'

type TradeButtonProps = {
  isMenuOpen: boolean
  setIsMenuOpen: (isMenuOpen: boolean) => void
}

export const TradeButton = ({ isMenuOpen, setIsMenuOpen }: TradeButtonProps) => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader')
  const { renderModal } = useGlobalModal()
  const { master } = useMasterLoadable()
  const { isAuthenticated } = useMxAuth()
  const { isImo, hasAuthUserReachedShareLimit, hideSellOption, hasError } = useTrader()

  const renderMaxSharesModal = () => {
    renderModal({
      content: <MaxSharesModal />,
      enforceAuth: true,
      modalKey: ModalKey.MaxSharesOrdered,
    })
  }

  const renderNoTradingMasterOwnerModal = () => {
    renderModal({
      content: <NoTradingMasterOwnerModal />,
      enforceAuth: true,
      modalKey: ModalKey.NoTradingForMasterOwner,
    })
  }

  const renderTradingPausedModal = () => {
    renderModal({
      content: <TradingPausedModal />,
      enforceAuth: false,
      modalKey: ModalKey.TradingPaused,
    })
  }

  const renderImoReviewModal = () => {
    renderModal({
      content: <ReviewImo />,
      enforceAuth: true,
      modalKey: ModalKey.ReviewImo,
    })
  }

  const renderTraderModal = () => {
    renderModal({
      content: <TraderModal />,
      enforceAuth: true,
      modalKey: ModalKey.Trader,
    })
  }

  const renderSignInPrompt = () => {
    renderModal({
      content: <SignInPrompt />,
      enforceAuth: false,
      modalKey: ModalKey.SignInPrompt,
    })
  }

  const renderTraderErrorModal = () => {
    renderModal({
      content: <TraderErrorModal />,
      enforceAuth: true,
      modalKey: ModalKey.Trader,
    })
  }

  const handleTrade = () => {
    if (isAuthenticated) {
      if (hasError) {
        renderTraderErrorModal()
      } else if (isImo) {
        if (master?.isOwner) {
          renderNoTradingMasterOwnerModal()
        } else if (hasAuthUserReachedShareLimit) {
          renderMaxSharesModal()
        } else {
          renderImoReviewModal()
        }
      } else {
        if (hideSellOption) {
          renderTraderModal()
        } else if (master?.market.isActive) {
          setIsMenuOpen(!isMenuOpen)
        } else {
          renderTradingPausedModal()
        }
      }
    } else {
      renderSignInPrompt()
    }
  }

  const getButtonContent = () => {
    if (isMenuOpen) {
      return <CrossIcon className={styles.cross} />
    } else if (hasError) {
      return t('market.tradeCta')
    } else if (master?.imo.status === ImoStatus.Active) {
      return t('imo.actionCta')
    } else if (hideSellOption) {
      return t('market.title.buy')
    } else {
      return t('market.tradeCta')
    }
  }

  return (
    <Button
      className={styles.trade}
      onClick={handleTrade}
      variant={isMenuOpen ? 'outline' : 'primary'}
    >
      {getButtonContent()}
    </Button>
  )
}
