import { Form, Formik, FormikHelpers } from 'formik'
import { useState } from 'react'
import { number, object, SchemaOf } from 'yup'

import { ampli } from 'ampli'
import { WithdrawFundsAmount as amountLimit } from 'constants/funds'
import { useLocalization } from 'locales/i18n'
import { createWithdrawal } from 'repositories/mx-api'
import { CurrencyInput } from 'ui/@components/currency-input'
import { Loader } from 'ui/@library/feedback/loader'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useBankAccountsLoadable } from 'ui/@store/bank-accounts'
import { useWithdrawableFundsLoadable } from 'ui/@store/withdrawable-funds'

import { AddIban } from '../add-iban'
import { Error } from '../error'
import { Success } from '../success'

import styles from './styles.module.scss'

type FormValues = {
  amount?: number
}

export type WithdrawalProps = {
  prefilledAmount?: number
}

export const Withdrawal = () => {
  const { t, f } = useLocalization('withdrawFunds.withdrawal')
  const {
    loading: bankAccountLoading,
    bankAccounts,
    error: bankAccountError,
  } = useBankAccountsLoadable()
  const {
    withdrawableFundsInfo,
    loading: withdrawableFundsLoading,
    refetch: refetchWithdrawableFunds,
    error: withdrawableFundsError,
  } = useWithdrawableFundsLoadable({ forceRefetch: true })

  const { renderModal, payload: modalPayload } = useGlobalModal()
  const [amountError, setAmountError] = useState(false)

  if (bankAccountError || withdrawableFundsError) return <Error />
  if (bankAccountLoading || withdrawableFundsLoading || !withdrawableFundsInfo) return <Loader />

  const latestBankAccount = bankAccounts[bankAccounts.length - 1]

  const initialValues: FormValues = {
    amount: modalPayload?.prefilledAmount,
  }

  const validationSchema: SchemaOf<FormValues> = object({
    amount: number()
      .required(t('amount.required'))
      .min(amountLimit.MIN, t('amount.min', { min: amountLimit.MIN }))
      .max(
        Math.min(withdrawableFundsInfo.withdrawableFunds, amountLimit.MAX),
        t('amount.max', {
          max: f.currency(Math.min(withdrawableFundsInfo.withdrawableFunds, amountLimit.MAX)),
        }),
      ),
  })

  const editIban = async () => {
    renderModal({
      modalKey: ModalKey.AddIban,
      content: <AddIban />,
      navigateBack: () => {
        renderModal({ modalKey: ModalKey.Withdrawal, content: <Withdrawal /> })
      },
    })
  }

  const onSubmitHandler = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.validateForm(values)
    if (!bankAccounts || !values.amount) return

    try {
      await createWithdrawal(values.amount, latestBankAccount?.id ?? '')
      refetchWithdrawableFunds()
      ampli.withdrawFundsSuccess()
      renderModal({
        modalKey: ModalKey.Success,
        content: <Success />,
        payload: {
          amount: values.amount,
        },
      })
    } catch (error) {
      renderModal({
        modalKey: ModalKey.Error,
        content: <Error />,
      })
    }
  }

  return (
    <ModalContent className={styles.confirmationContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
        validateOnBlur
      >
        {({ isSubmitting, isValid, setFieldValue, values }) =>
          isSubmitting ? (
            <div className={styles.loadingState}>
              <h1 className={styles.title}>{t('processing')}</h1>
              <Loader />
            </div>
          ) : (
            <Form className={styles.form}>
              <h1 className={styles.title}>{t('title')}</h1>
              <CurrencyInput
                title={t('amount.label')}
                value={values.amount ? String(values.amount) : null}
                setValue={(val) => setFieldValue('amount', val, true)}
                displayError={amountError}
                setDisplayError={setAmountError}
                minAmount={amountLimit.MIN}
                maxAmount={amountLimit.MAX}
                disabled={isSubmitting || withdrawableFundsInfo.withdrawableFunds === 0}
              />
              <div className={styles.fundsInfo}>
                <p>
                  {t('availableFunds')}: {f.currency(withdrawableFundsInfo.withdrawableFunds)}
                </p>
                {withdrawableFundsInfo.platformFunds > 0 && (
                  <p>
                    {t('platformFunds')}: {f.currency(withdrawableFundsInfo.platformFunds)}*
                  </p>
                )}
              </div>
              <div className={styles.payOutMethodContainer}>
                <div className={styles.payOutMethod}>
                  <div>{t('bankAccount')}</div>
                  <div className={styles.edit} onClick={editIban}>
                    {t('edit')}
                  </div>
                </div>
                <div className={styles.bankAccount}>
                  <span>{t('iban')}: </span>
                  <span>{latestBankAccount?.name}</span>
                </div>
                <div className={styles.bankAccount}>
                  <span>{latestBankAccount?.iban}</span>
                </div>
              </div>
              <Button
                type='submit'
                disabled={!isValid || isSubmitting || withdrawableFundsInfo.withdrawableFunds === 0}
                width='fluid'
                className={styles.withdrawButton}
              >
                {t('buttonText')}
              </Button>
              {withdrawableFundsInfo.platformFunds > 0 && (
                <p className={styles.disclaimer}>{t('disclaimer')}</p>
              )}
            </Form>
          )
        }
      </Formik>
    </ModalContent>
  )
}
