import { useLocalization } from 'locales/i18n'
import { Timer } from 'ui/@library/utils/timer'

import styles from './styles.module.scss'

type CountdownBannerProps = {
  date: Date
}

export const CountdownBanner = ({ date }: CountdownBannerProps) => {
  const { f, t } = useLocalization('master.overviewTab.sidebar.banner')

  return (
    <div className={styles.countdownContainer}>
      <div className={styles.titleContainer}>
        <p className={styles.countdownHeader}>{t('countdown.title')}</p>
        <Timer date={date} className={styles.counter} />
        {date && <p className={styles.launchDate}>{f.date(date)}</p>}
      </div>
    </div>
  )
}
