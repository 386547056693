import { MxApi } from '../mx-api'
import { downloadBlobAsFile } from '../utils'

export const fetchAuthUserDepositsAndWithdrawals = async (startDate: string, endDate: string) => {
  const { data } = await MxApi.get('/auth-users/me/accounting/deposits-and-withdrawals', {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    responseType: 'arraybuffer',
  })

  downloadBlobAsFile(data, 'mx-deposits-and-withdrawals.pdf')
}
