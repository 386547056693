import { useState } from 'react'

import { useLocalization } from 'locales/i18n'
import { KycStatus } from 'types/auth-user'
import { OrderType as OrderTypeGlobal } from 'types/order'
import { Button } from 'ui/@library/inputs/button'
import { Divider } from 'ui/@library/layout/divider'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { KycTriggerModal } from 'ui/@modals/kyc/kyc-trigger-modal'
import { ReviewKyc } from 'ui/@modals/kyc/review-kyc'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useMarketOverviewLoadable } from 'ui/@store/market-overview'
import { useTrader } from 'ui/master/@components/master-overview/@components/banner-trader/use-trader'
import { ReviewLimitSell } from 'ui/master/@components/modals/review-order/review-limit-sell'

import { BannerNoSellableShares } from '../banners'

import { TradeSpecification } from './@components/trade-specification'
import { TradeAmount } from './@components/trade-specification/@components/trade-amount/trade-amount'
import { TradeQuantity } from './@components/trade-specification/@components/trade-quantity'
import { TradeTotal } from './@components/trade-specification/@components/trade-total'
import styles from './styles.module.scss'

export const TraderLimitSell = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader.market')
  const { renderModal } = useGlobalModal()
  const { sellableShareList } = useTrader()
  const { marketOverview } = useMarketOverviewLoadable()
  const { authUser } = useAuthUserLoadable()
  const [numberOfShares, setNumberOfShares] = useState(NaN)
  const [pricePerShare, setPricePerShare] = useState<string | null>(null)
  const [numberOfSharesError, setNumberOfSharesError] = useState(false)
  const [pricePerShareError, setPricePerShareError] = useState(false)
  const [isKeyboardActive, setIsKeyboardActive] = useState(false)

  const isKycValidated = authUser?.kycStatus === KycStatus.Validated
  const isKycAttempted =
    authUser?.kycStatus === KycStatus.Refused || authUser?.kycStatus === KycStatus.ValidationAsked

  const validateTradeSpecification = () => {
    let isValid = true
    if (numberOfShares > sellableShareList.length || isNaN(numberOfShares) || !numberOfShares) {
      setNumberOfSharesError(true)
      isValid = false
    }
    if (!Number(pricePerShare) || isNaN(Number(pricePerShare))) {
      setPricePerShareError(true)
      isValid = false
    }
    return isValid
  }

  const handleReviewOrder = () => {
    if (isKycAttempted) {
      renderModal({
        modalKey: ModalKey.ReviewKyc,
        content: <ReviewKyc />,
      })
    } else if (!isKycValidated) {
      renderModal({
        modalKey: ModalKey.KycTriggerModal,
        content: <KycTriggerModal />,
        payload: {
          title: t('kycTrigger.title'),
          description: t('kycTrigger.description'),
        },
      })
    } else if (validateTradeSpecification()) {
      const sharesToTrade = sellableShareList.slice(0, numberOfShares)
      renderModal({
        modalKey: ModalKey.ReviewLimitSell,
        content: <ReviewLimitSell />,
        payload: {
          orderType: OrderTypeGlobal.LimitAsk,
          sharesToTrade,
          pricePerShare: pricePerShare ? Number(pricePerShare) : 0,
          numberOfShares,
        },
        enforceAuth: true,
        size: ModalSize.Large,
      })
      setNumberOfShares(NaN)
      setPricePerShare(null)
    }
  }

  const getTotalCost = () => {
    if (isNaN(numberOfShares) || numberOfShares === 0) return 0
    return pricePerShare ? numberOfShares * Number(pricePerShare) : 0
  }

  const handleFocusChange = (isFocused: boolean) => {
    setIsKeyboardActive(isFocused)
  }

  if (marketOverview?.limitAskMaxQuantity === 0) {
    return <BannerNoSellableShares />
  }

  return (
    <form
      className={`${styles.traderVariantWrapper} ${isKeyboardActive ? styles.keyboardActive : ''}`}
      onSubmit={(e) => {
        e.preventDefault()
        handleReviewOrder()
      }}
    >
      <TradeSpecification>
        <TradeQuantity
          label={t('information.quantity.label')}
          setQuantity={setNumberOfShares}
          quantity={numberOfShares}
          isDisabled={!sellableShareList.length}
          maxLimit={sellableShareList.length}
          displayErrorMessage={numberOfSharesError}
          setDisplayErrorMessage={setNumberOfSharesError}
          onFocusChange={handleFocusChange}
        />
        <TradeAmount
          label={t('information.price.sell')}
          inputAmount={pricePerShare}
          setInputAmount={setPricePerShare}
          displayErrorMessage={pricePerShareError}
          setDisplayErrorMessage={setPricePerShareError}
          onFocusChange={handleFocusChange}
        />
        <Divider />
        <TradeTotal label={t('information.estimated')} price={getTotalCost()} />
      </TradeSpecification>
      <Button width='fluid' variant='danger' disabled={!sellableShareList.length} type='submit'>
        {t('actionCta')}
      </Button>
    </form>
  )
}
