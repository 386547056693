import { CardRegistration, CardRegistrationRaw } from 'types/card'

import { sanitizeCardRegistrationFromRaw } from '../@santizers/card'
import { MxApi } from '../mx-api'

export const createCardRegistration = async (): Promise<CardRegistration> => {
  const { data } = await MxApi.post<CardRegistrationRaw>('/auth-users/me/wallet/card-registrations')

  return sanitizeCardRegistrationFromRaw(data)
}
