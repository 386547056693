import { useEffect } from 'react'
import {
  selectorFamily,
  useRecoilRefresher_UNSTABLE,
  useRecoilValueLoadable,
  useRecoilValue,
} from 'recoil'

import { fetchShareList, fetchAuthUserShareList } from 'repositories/mx-api'
import { Share } from 'types/share'

import { authUserState } from './auth-user'
import { masterUuidState } from './master'

const shareListQuery = selectorFamily<Share[], string | null>({
  key: 'shareListQuery',
  get: (masterUuid) => async () => masterUuid ? fetchShareList(masterUuid) : [],
})

const authUserShareListQuery = selectorFamily<Share[], string | null>({
  key: 'authUserShareListQuery',
  get:
    (masterUuid) =>
    async ({ get }) => {
      const authUser = get(authUserState)
      return masterUuid && authUser ? fetchAuthUserShareList(masterUuid) : []
    },
})

export const useShareListLoadable = (
  { isAuthUserShares = false, masterUuid }: { isAuthUserShares: boolean; masterUuid?: string },
  { forceRefetch = false } = {},
) => {
  const masterUuidFromStore = useRecoilValue(masterUuidState)
  const currentMasterUuid = masterUuid ? masterUuid : masterUuidFromStore
  const { state, contents } = useRecoilValueLoadable(
    isAuthUserShares === true
      ? authUserShareListQuery(currentMasterUuid)
      : shareListQuery(currentMasterUuid),
  )
  const refetch = useRecoilRefresher_UNSTABLE(
    isAuthUserShares === true
      ? authUserShareListQuery(currentMasterUuid)
      : shareListQuery(currentMasterUuid),
  )

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    shareList: state === 'hasValue' ? contents : [],
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
