import { LocalizedLink } from 'ui/@library/utils/localized-link'
import { useMasterLoadable } from 'ui/@store/master'

import styles from '../../styles.module.scss'

export const WhitepaperLink = () => {
  const { master } = useMasterLoadable()

  return (
    <LocalizedLink
      href={master?.documents.whitePaper || '#'}
      translationKey='master.overviewTab.sidebar.trader.modals.agreementLinks.whitePaper'
      linkClassName={styles.agreementLink}
    />
  )
}
