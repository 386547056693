import { ImoStatus } from 'types/imo'
import { MasterOwnersWithAvatar, MasterWithImoAndMarket } from 'types/master'
import { MasterStatus } from 'ui/master/types'

export const generateNftArtBaseUrl = (baseUrl: string, tokenId: number) => {
  return `${baseUrl}/${tokenId}_thumbnail.png`
}

export const generateNftPdfBaseUrl = (baseUrl: string, tokenId: number) => {
  return `${baseUrl}/${tokenId}.pdf`
}

export const generateBlockchainContractLink = (
  blockchainExplorerUrl: string,
  contractAddress: string,
  tokenId: number,
) => {
  return `${blockchainExplorerUrl}/nft/${contractAddress}/${tokenId}`
}

export const getOwnersFromOwnerList = (ownerList?: MasterOwnersWithAvatar) => {
  if (!ownerList) return ''
  if (ownerList.length === 1) return ownerList[0]?.name

  const owners = ownerList.reduce((acc, owner, index) => {
    if (index === 0) return owner.name
    if (index === ownerList.length - 1) return `${acc} and ${owner.name}`
    return `${acc}, ${owner.name}`
  }, '')

  return owners
}

export const getRoyaltyYield = (master: MasterWithImoAndMarket) => {
  const masterStatus =
    master?.imo.status !== ImoStatus.Completed ? MasterStatus.Imo : MasterStatus.Market
  const divider = masterStatus === MasterStatus.Market ? master.market.lastPrice : master.imo.price

  return Math.round((master.metrics.royaltiesPerShare / divider) * 100 * 100) / 100
}
