import { useLocalization } from 'locales/i18n'
import { ModalContent } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

export const OrderTypeInfo = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader.modals.orderType')

  return (
    <ModalContent className={styles.orderTypesContainer}>
      <h1 className={styles.title}>{t('title')}</h1>
      <div className={styles.infoContainer}>
        <h3 className={styles.subtitle}>{t('bestPriceOrder.title')}</h3>
        <p className={styles.message}>{t('bestPriceOrder.body')}</p>
      </div>
      <div className={styles.infoContainer}>
        <h3 className={styles.subtitle}>{t('buyOrder.title')}</h3>
        <p className={styles.message}>{t('buyOrder.body')}</p>
      </div>
      <div className={styles.infoContainer}>
        <h3 className={styles.subtitle}>{t('sellOrder.title')}</h3>
        <p className={styles.message}>{t('sellOrder.body')}</p>
      </div>
    </ModalContent>
  )
}
