import { format } from 'date-fns'

import { TextFormats } from './constants'
import { DateFormatType } from './types'

export const getCurrencySymbol = () =>
  (0)
    .toLocaleString(TextFormats.CURRENCY.LOCALE, {
      style: TextFormats.CURRENCY.OPTIONS.style,
      currency: TextFormats.CURRENCY.OPTIONS.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()

export const formatCurrency = (
  amount: number,
  options: { compact?: 'long' | 'short' | 'default'; showSign?: boolean } = {
    compact: 'default',
    showSign: false,
  },
) => {
  if (isNaN(amount)) return '--'

  const getCompactOptions = () => {
    switch (options.compact) {
      case 'long':
        return TextFormats.CURRENCY.COMPACT.LONG
      case 'short':
        return TextFormats.CURRENCY.COMPACT.SHORT
    }
  }

  const currencyFormatter = new Intl.NumberFormat(TextFormats.CURRENCY.LOCALE, {
    ...TextFormats.CURRENCY.OPTIONS,
    ...(options.showSign && { signDisplay: 'exceptZero' }),
    ...getCompactOptions(),
  })

  return currencyFormatter.format(amount)
}

export const formatNumber = (
  value: number,
  options: { compact: 'long' | 'short' | 'default' } = { compact: 'default' },
) => {
  if (isNaN(value)) return '--'
  const getOptions = (compact: 'long' | 'short' | 'default') => {
    switch (compact) {
      case 'long':
        return TextFormats.NUMBER.OPTIONS_COMPACT_LONG
      case 'short':
        return TextFormats.NUMBER.OPTIONS_COMPACT_SHORT
      default:
        return TextFormats.NUMBER.OPTIONS
    }
  }

  const numberFormatter = new Intl.NumberFormat(
    TextFormats.NUMBER.LOCALE,
    getOptions(options.compact),
  )

  return numberFormatter.format(value)
}

export const formatPercentage = (
  value: number,
  options: { showSign: boolean } = { showSign: false },
) => {
  if (isNaN(value)) return '--'

  const numberFormatter = new Intl.NumberFormat(TextFormats.NUMBER.LOCALE, {
    ...TextFormats.PERCENTAGE.OPTIONS,
    ...(options.showSign ? { signDisplay: 'exceptZero' } : { signDisplay: 'auto' }),
  })

  return `${numberFormatter.format(value)}%`
}

export const formatDate = (
  date: Date,
  dateFormat: DateFormatType = TextFormats.DATE.DATE_WITH_TIME_LONG,
) => format(date, dateFormat)
