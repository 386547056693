import { Form, Formik, FormikHelpers } from 'formik'
import { object, SchemaOf, string } from 'yup'

import { SupportedCountries } from 'constants/supported-countries'
import { useLocalization } from 'locales/i18n'
import { createBankAccount } from 'repositories/mx-api'
import { Address } from 'types/auth-user'
import { Button } from 'ui/@library/inputs/button'
import { Input } from 'ui/@library/inputs/input'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useBankAccountsLoadable } from 'ui/@store/bank-accounts'

import { Error } from '../error'
import { Withdrawal } from '../withdrawal'

import styles from './styles.module.scss'
import { isValidIban } from './validate-iban'

export type FormValues = {
  name: string
  iban: string
}

const FormMaxLength = {
  NAME: 19,
  IBAN: 38,
}

const initialValues: FormValues = {
  name: '',
  iban: '',
}

const formatIban = (iban: string) => {
  return iban.trim()
}

const sanitizeIban = (iban: string) => {
  return iban.replaceAll(' ', '')
}

export const AddIban = () => {
  const { t } = useLocalization('withdrawFunds.addIban')
  const { renderModal } = useGlobalModal()
  const { authUser } = useAuthUserLoadable()
  const { refetch: refetchBankAccounts, bankAccounts } = useBankAccountsLoadable()

  const isValidName = (name: string): boolean => {
    return name?.length <= FormMaxLength.NAME
  }

  const validationSchema: SchemaOf<FormValues> = object({
    iban: string()
      .required(t('iban.required'))
      .test('iban', t('iban.invalid'), (iban) => {
        return isValidIban(iban ? sanitizeIban(iban) : '')
      }),
    name: string()
      .required(t('name.required'))
      .test('name', t('name.invalid'), (name) => {
        return isValidName(name ? name : t('name.default'))
      }),
  })

  const formatAuthUserAddress = (address: Address | undefined) => {
    if (address === undefined) return ''
    const { street, zipcode, city, countryCode } = address
    const country = SupportedCountries.filter((value) => value.code === countryCode)[0]
    return `${street}, ${zipcode}, ${city}, ${country?.name}`
  }

  const onSubmitHandler = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    values = {
      ...values,
      iban: sanitizeIban(values.iban),
    }
    actions.validateForm(values)

    try {
      await createBankAccount(values.iban, values.name)
      refetchBankAccounts()
      renderModal({ modalKey: ModalKey.Withdrawal, content: <Withdrawal /> })
    } catch (error) {
      renderModal({ modalKey: ModalKey.Error, content: <Error /> })
    }
  }

  return (
    <ModalContent className={styles.addIbanContainer}>
      <div className={styles.modalHeader}>
        <h1 className={styles.title}>{t('title')}</h1>
        <p className={styles.subtitle}>{t('subtitle')}</p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
        validateOnBlur
      >
        {({ isSubmitting, isValid, values }) => (
          <Form className={styles.form}>
            <div className={styles.ibanNumberContainer}>
              <Input
                name='name'
                label={t('name.label')}
                type='text'
                placeholder={t('name.placeholder')}
                value={values.name}
                maxLength={FormMaxLength.NAME}
              />
            </div>
            <div className={styles.ibanNumberContainer}>
              <Input
                name='iban'
                label={t('iban.label')}
                type='text'
                placeholder={t('iban.placeholder')}
                value={formatIban(values.iban)}
                maxLength={FormMaxLength.IBAN}
              />
            </div>
            <div className={styles.personDetailWrapper}>
              <p className={styles.personalDetailHeader}>{t('personalDetails.receiverName')}</p>
              <p className={styles.personalDetailText}>
                {bankAccounts.length > 0
                  ? bankAccounts[0]?.ownerName
                  : `${authUser?.firstName} ${authUser?.lastName}`}
              </p>
            </div>
            <div className={styles.personDetailWrapper}>
              <p className={styles.personalDetailHeader}>{t('personalDetails.receiverAddress')}</p>
              <p className={styles.personalDetailText}>
                {formatAuthUserAddress(
                  bankAccounts.length > 0 ? bankAccounts[0]?.ownerAddress : authUser?.address,
                )}
              </p>
            </div>
            <Button
              type='submit'
              loading={isSubmitting}
              disabled={!isValid}
              width='fluid'
              className={styles.addButton}
            >
              {t('buttonText')}
            </Button>
          </Form>
        )}
      </Formik>
    </ModalContent>
  )
}
