import styles from './styles.module.scss'

type SkeletonProps = {
  className?: string
}

export const Skeleton = ({ className }: SkeletonProps) => {
  const skeletonClasses = `${styles.skeleton} ${className}`
  return <div className={skeletonClasses} />
}
