export type GTMEventArgs = {
  dataLayer: {
    event: GTMEvents
    userId?: string
  }
}

export enum GTMEvents {
  UserSignUp = 'user-sign-up',
  DepositFunds = 'deposit-funds',
  SuccessfulKYC = 'successful-kyc',
  OrderPlaced = 'order-placed',
  Lead = 'user-lead',
}
