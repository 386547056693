import { useLocalization } from 'locales/i18n'
import { useAuthUserMasterSharesLoadable } from 'ui/@store/auth-user-master-shares'

import { MetricCard, MetricChange, MetricContents, MetricTitle, MetricValue } from '../metric-card'

export const TotalPortfolioValue = () => {
  const { t } = useLocalization('myAssets.overview.cards')

  const { authUserMasterShares, loading } = useAuthUserMasterSharesLoadable()

  const portfolioCurrentValue = authUserMasterShares
    ? authUserMasterShares.reduce((totalValue, master) => totalValue + master.totalValue, 0)
    : 0

  const portfolioOriginalValue = authUserMasterShares
    ? authUserMasterShares.reduce((totalCost, master) => totalCost + master.totalAcquisitionCost, 0)
    : 0

  const portfolioValueChange = portfolioCurrentValue - portfolioOriginalValue

  const portfolioChangePercentageRaw = (portfolioCurrentValue / portfolioOriginalValue - 1) * 100

  const portfolioChangePercentage = portfolioChangePercentageRaw
    ? Math.round(portfolioChangePercentageRaw * 10) / 10
    : 0

  return (
    <MetricCard>
      <MetricTitle>{t('total')}</MetricTitle>
      <MetricContents loading={loading}>
        <MetricValue>{portfolioCurrentValue}</MetricValue>
        <MetricChange value={portfolioValueChange} percentage={portfolioChangePercentage} />
      </MetricContents>
    </MetricCard>
  )
}
