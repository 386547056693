import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchAuthUserOpenOrders } from 'repositories/mx-api'

import { authUserState } from './auth-user'
import { masterUuidState } from './master'

const authUserMasterOpenOrdersQuery = selector({
  key: 'authUserMasterOpenOrdersQuery',
  get: async ({ get }) => {
    const authUser = get(authUserState)
    const masterUuid = get(masterUuidState)
    return authUser && masterUuid ? fetchAuthUserOpenOrders(masterUuid) : []
  },
})

export const useAuthUserMasterOpenOrdersLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(authUserMasterOpenOrdersQuery)
  const refetch = useRecoilRefresher_UNSTABLE(authUserMasterOpenOrdersQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    authUserMasterOpenOrders: state === 'hasValue' ? contents : [],
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
