import { Spinner } from '../spinner'

import styles from './styles.module.scss'

export const Loader = () => {
  return (
    <div className={styles.loader}>
      <Spinner />
    </div>
  )
}
