import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg'
import { useLocalization } from 'locales/i18n'
import { useWindowSize } from 'ui/@hooks/use-window-size'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'

import { TraderModal } from '../../master-overview/@components/banner-trader/@components/mobile-trader/@components/trader-modal'
import { ReviewOrder } from '../review-order'

import styles from './styles.module.scss'

export type OrderFailedProps = {
  message?: string | null
}

export const OrderFailed = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader.modals.orderFailed')
  const { renderModal, payload } = useGlobalModal()
  const { viewports } = useWindowSize()
  const { isTablet } = viewports

  const handleReturn = () => {
    if (isTablet) {
      renderModal({
        content: <TraderModal />,
        enforceAuth: true,
        modalKey: ModalKey.Trader,
      })
    } else {
      renderModal({
        modalKey: ModalKey.ReviewOrder,
        content: <ReviewOrder />,
        enforceAuth: true,
        size: ModalSize.Large,
      })
    }
  }

  return (
    <ModalContent className={styles.orderFailedContainer}>
      <div className={styles.topWrapper}>
        <div className={styles.exclamationIcon}>
          <ExclamationIcon />
        </div>
        <div className={styles.headerContainer}>
          <h1 className={styles.title}>{t('title')}</h1>
          <p className={styles.subtitle}>{payload?.message ?? t('subtitle')}</p>
        </div>
      </div>
      <Button className={styles.returnButton} variant='primary' onClick={handleReturn}>
        {t('button')}
      </Button>
    </ModalContent>
  )
}
