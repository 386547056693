import { useLocalization } from 'locales/i18n'
import { TabBar } from 'ui/@library/layout/tab-bar'
import { TabOption } from 'ui/@library/layout/tab-bar/tab-bar'
import { TradeActionType } from 'ui/master/@components/master-overview/@components/banner-trader/types'
import { useTrader } from 'ui/master/@components/master-overview/@components/banner-trader/use-trader'

import styles from './styles.module.scss'

export const TraderHeader = () => {
  const { tradeActionType, setTradeActionType, isImo, hideSellOption } = useTrader()
  const { t } = useLocalization('master.overviewTab.sidebar.trader')

  const onTabChange = (value: string) => {
    setTradeActionType(value as TradeActionType)
  }

  const tabOptions: TabOption[] = [
    {
      label: t('market.title.buy'),
      value: TradeActionType.Buy,
    },
    {
      label: t('market.title.sell'),
      value: TradeActionType.Sell,
      activeClassName: styles.sell,
      isHidden: hideSellOption,
    },
  ]

  if (isImo)
    return (
      <div className={styles.headerWithoutTabs}>
        <h1 className={styles.title}>{t('imo.title')}</h1>
      </div>
    )
  else if (hideSellOption)
    return (
      <div className={styles.headerWithoutTabs}>
        <h1 className={styles.title}>{t('market.title.buy')}</h1>
      </div>
    )
  else
    return (
      <TabBar
        options={tabOptions}
        onChange={onTabChange}
        className={styles.tabBarContainer}
        tabClassName={styles.tab}
        variant='border'
        activeTab={tradeActionType}
      />
    )
}
