import { CellContext, Row } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { useFetchListedSongs } from 'repositories/mx-api/hooks/use-fetch-listed-songs'
import { Table } from 'ui/@library/data-display/table'
import { useColumnHelper } from 'ui/@library/data-display/table/use-table'
import { Loader } from 'ui/@library/feedback/loader'
import { EmptyStatePlaceholder } from 'ui/@library/layout/empty-state-placeholder'
import { GraphicsToShow } from 'ui/@library/layout/empty-state-placeholder/states'

import { ListedSongsMobileTable } from './@components/listed-songs-mobile-table'
import styles from './styles.module.scss'
import { ListedSongsTableColumns } from './types'

export const ListedSongsTable = () => {
  const { t, f } = useLocalization('myAssets.overview.listedSongsTable')
  const navigate = useNavigate()
  const { data: listedSongsData, loading } = useFetchListedSongs()

  const navigateToMaster = (row: Row<ListedSongsTableColumns> | ListedSongsTableColumns) => {
    const uuid = 'uuid' in row ? row.uuid : row.original.uuid
    navigate(`${Paths.MASTER.MAIN}/${uuid}`)
  }

  const tableData = useMemo(
    (): ListedSongsTableColumns[] =>
      listedSongsData.map((item) => {
        return {
          uuid: item.master.uuid,
          thumbnail: item.master?.assets.thumbnail,
          masterName: item.master.name,
          artistName: item.master.artist.name,
          soldDuringImo: item.soldDuringImo,
          listedDuringImo: item.listedDuringImo,
          totalShares: item.volumeQuantity,
          earningsFromImo: item.earningsFromImo,
          earningsFromTradingFees: item.earningsFromTradingFees,
        }
      }),
    [listedSongsData],
  )

  const renderPrice = (value: number) => {
    if (!value) return f.currency(NaN)
    return f.currency(value)
  }

  const renderNumber = (value: number) => {
    if (!value) return f.number(NaN)
    return f.number(value)
  }

  const showPrice = (cell: CellContext<ListedSongsTableColumns, unknown>) => {
    return renderPrice(cell.getValue() as number)
  }

  const showNumber = (cell: CellContext<ListedSongsTableColumns, unknown>) => {
    return renderNumber(cell.getValue() as number)
  }

  const showThumbnail = (cell: CellContext<ListedSongsTableColumns, unknown>) => {
    return <img className={styles.thumbnailImg} src={cell.getValue() as string} alt='' />
  }

  const columnDefinition = [
    {
      id: 'thumbnail',
      className: styles.thumbnail,
      cell: showThumbnail,
    },
    {
      id: 'masterName',
      label: t('columns.song'),
      className: styles.song,
    },
    {
      id: 'artistName',
      label: t('columns.artist'),
      className: styles.artist,
    },
    {
      id: 'totalShares',
      label: t('columns.totalShares'),
      className: styles.totalShares,
      cell: showNumber,
    },
    {
      id: 'listedDuringImo',
      label: t('columns.listedDuringImo'),
      className: styles.listedDuringImo,
      cell: showNumber,
    },
    {
      id: 'soldDuringImo',
      label: t('columns.soldDuringImo'),
      className: styles.soldDuringImo,
      cell: showNumber,
    },
    {
      id: 'earningsFromImo',
      label: t('columns.earningsFromImo'),
      className: styles.earningsFromImo,
      cell: showPrice,
    },
    {
      id: 'earningsFromTradingFees',
      label: t('columns.earningsFromTradingFees'),
      className: styles.earningsFromTradingFees,
      cell: showPrice,
      type: 'price',
    },
  ]

  const columns = useColumnHelper<ListedSongsTableColumns>(columnDefinition)

  if (loading) return <Loader />

  return (
    <>
      {tableData.length > 0 ? (
        <>
          <Table
            data={tableData}
            columns={columns}
            rowClassName={styles.tableRow}
            rowOnClick={navigateToMaster}
            hideInMobile
          />
          <ListedSongsMobileTable tableData={tableData} />
        </>
      ) : (
        <EmptyStatePlaceholder
          message={t('emptyState.message')}
          button={t('emptyState.button')}
          path={Paths.HOME}
          image={GraphicsToShow.Standard}
        />
      )}
    </>
  )
}
