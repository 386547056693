import { LandingPageRoutes } from 'constants/external-links'
import { Paths } from 'constants/paths'

export type Route = {
  path: string
  label: string
  isAuthRequired?: boolean
  isLandingPage?: boolean
  element?: JSX.Element
  dropdownOnly?: boolean
}

export const routes: Route[] = [
  {
    path: Paths.EXCHANGE.MAIN,
    label: 'exchange.pageTitle',
  },
  {
    path: Paths.MY_ASSETS.MAIN,
    label: 'myAssets.pageTitle',
    isAuthRequired: true,
  },
  {
    path: LandingPageRoutes.WHY_MUSIC,
    label: 'components.navbar.whyMusic',
    isLandingPage: true,
  },
  {
    path: LandingPageRoutes.HOW_IT_WORKS,
    label: 'components.footer.links.howItWorks',
    isLandingPage: true,
  },
  {
    path: LandingPageRoutes.ARTICLES,
    label: 'components.navbar.learn',
    isLandingPage: true,
  },
  {
    path: Paths.ACCOUNT.MAIN,
    label: 'account.pageTitle',
    isAuthRequired: true,
    dropdownOnly: true,
  },
  {
    path: Paths.SIGN_OUT,
    label: 'components.navbar.signOut',
    isAuthRequired: true,
    dropdownOnly: true,
  },
]
