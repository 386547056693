import { MessageVariant } from 'types/message'

import styles from '../styles.module.scss'

type BannerContainerProps = {
  className?: string
  children: React.ReactNode
  variant?: MessageVariant
}

export const BannerContainer = ({
  children,
  className,
  variant = MessageVariant.Regular,
}: BannerContainerProps) => {
  return (
    <div className={`${styles.bannerContainer} ${styles[variant]}`}>
      <div className={`${styles.bannerWrapper} ${className}`}>{children}</div>
    </div>
  )
}
