import { useAuth0 } from '@auth0/auth0-react'

import { useInitAuthSession } from './use-init-auth-session'

export const useMxAuth = () => {
  useInitAuthSession()
  const {
    handleRedirectCallback,
    isAuthenticated,
    isLoading: auth0IsLoading,
    error: auth0Error,
  } = useAuth0()

  return {
    handleRedirectCallback,
    isAuthenticated,
    isLoading: auth0IsLoading,
    error: auth0Error,
  }
}
