import { NotificationSettings, NotificationSettingsRaw } from 'types/auth-user'

import {
  sanitizeNotificationSettingsFromRaw,
  sanitizeNotificationSettingsToRaw,
} from '../@santizers/auth-user'
import { MxApi } from '../mx-api'

export const updateNotificationSettings = async (
  notificationSettings: NotificationSettings,
): Promise<NotificationSettings> => {
  const notificationSettingsPayload = sanitizeNotificationSettingsToRaw(notificationSettings)

  const { data } = await MxApi.patch<NotificationSettingsRaw>(
    '/auth-users/me/notification-settings',
    notificationSettingsPayload,
  )

  return sanitizeNotificationSettingsFromRaw(data)
}
