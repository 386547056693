import { Trans } from 'react-i18next'

import { useLocalization } from 'locales/i18n'
import { useSignInAndSignUp } from 'ui/@hooks/use-signin-and-signup'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

export const UpcomingSignUpPrompt = () => {
  const { t } = useLocalization('exchange.spotlight.signUpPrompt')
  const { signIn, signUp } = useSignInAndSignUp()

  return (
    <ModalContent className={styles.container}>
      <div className={styles.topWrapper}>
        <h2 className={styles.title}>{t('title')}</h2>
        <p>{t('subtitle')}</p>
      </div>
      <div className={styles.buttonWrapper}>
        <Button onClick={() => signUp()}>{t('signUp')}</Button>
        <p className={styles.signIn}>
          <Trans
            i18nKey='exchange.spotlight.signUpPrompt.signIn'
            components={[<span key='signIn' className={styles.signInLink} onClick={signIn} />]}
          />
        </p>
      </div>
    </ModalContent>
  )
}
