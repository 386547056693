import { LandingPageRoutes } from 'constants/external-links'
import { AccessStatus } from 'types/auth-user'
import { useLandingPageLinks } from 'ui/@hooks/use-landing-page-links'
import { LocalizedLink } from 'ui/@library/utils/localized-link'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import styles from '../../styles.module.scss'

export const OpusInvestorLink = () => {
  const { authUser } = useAuthUserLoadable()
  const generateLandingPageLink = useLandingPageLinks()

  if (authUser?.accessStatus !== AccessStatus.ActiveOpus) return null

  return (
    <LocalizedLink
      href={generateLandingPageLink(LandingPageRoutes.OPUS_TERMS_AND_CONDITIONS)}
      translationKey='master.overviewTab.sidebar.trader.modals.agreementLinks.opusInvestor'
      linkClassName={styles.agreementLink}
    />
  )
}
