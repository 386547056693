import { Paths } from 'constants/paths'

import { UtmParams } from './types'

export enum ClientType {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export enum ShareMethod {
  WebShareApi = 'web-share-api',
  CopyLink = 'copy-link',
  ReactShare = 'react-share',
}

export enum UtmCampaign {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Email = 'email',
  Linkedin = 'linkedin',
  WebShareApi = 'web-share-api',
}

export const UTM_SOURCE = 'mx'

export const UTM_MEDIUM = 'product_social_share'

export const utmParams: UtmParams = {
  utm_source: UTM_SOURCE,
  utm_medium: UTM_MEDIUM,
  utm_campaign: UtmCampaign.WebShareApi,
}

export const IMAGE_FORMAT = 'image/jpg'

export const IMAGE_QUALITY = 0.92

export const ABORTION_ERROR = 'AbortError'

export const MASTER_BASE_URL = process.env.REACT_APP_WEB_APP_BASE_URL + Paths.MASTER.MAIN

export const X_OFFSET = 215
export const Y_OFFSET = 560
export const Y_OFFSET_INCREMENT = 40

export const TWITTER_HANDLE_TAG = '@masterexhange_'
