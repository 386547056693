import { useEffect } from 'react'

import { Loader } from 'ui/@library/feedback/loader'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { AddIban } from 'ui/@modals/withdraw-funds'
import { Withdrawal } from 'ui/@modals/withdraw-funds/@components/withdrawal'
import { useBankAccountsLoadable } from 'ui/@store/bank-accounts'

export const WithdrawFundsModal = () => {
  const { renderModal } = useGlobalModal()
  const { loading: bankAccountLoading, bankAccounts } = useBankAccountsLoadable({
    forceRefetch: true,
  })

  useEffect(() => {
    if (bankAccountLoading) return
    if (bankAccounts.length > 0) {
      renderModal({ modalKey: ModalKey.Withdrawal, content: <Withdrawal /> })
    } else {
      renderModal({ modalKey: ModalKey.AddIban, content: <AddIban /> })
    }
    // eslint-disable-next-line
  }, [bankAccountLoading])

  return (
    <ModalContent>
      <Loader />
    </ModalContent>
  )
}
