import { Button } from 'ui/@library/inputs/button'

import styles from '../styles.module.scss'

type BannerButtonProps = {
  children: React.ReactNode
  onClick?: () => void
}

export const BannerButton = ({ children, onClick }: BannerButtonProps) => {
  return (
    <Button className={styles.button} variant='ghost' onClick={onClick}>
      {children}
    </Button>
  )
}
