import camelcaseKeys from 'camelcase-keys'

import { ShareWithOrdersRaw, Share, ShareRaw } from 'types/share'

export const sanitizeShareListFromRaw = (rawList: ShareRaw[]): Share[] => {
  return camelcaseKeys(rawList, { deep: true })
}

export const sanitizeShareWithBidsFromRaw = (raw: ShareWithOrdersRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeShareFromRaw = (raw: ShareRaw) => {
  return camelcaseKeys(raw, { deep: true })
}
