import { BestPriceShare } from 'types/bid'
import { OrderRaw } from 'types/order'

import { sanitizeOrderFromRaw } from '../@santizers/order'
import { useMxApi } from '../mx-api'

export const useBuyShare = (masterUuid: string, shareList: BestPriceShare[]) => {
  const [{ data, loading, error }, requestBuyShare] = useMxApi<OrderRaw>(
    {
      method: 'POST',
      url: `/masters/${masterUuid}/shares/bids`,
      data: {
        shares: shareList.map((share) => ({
          share_uuid: share.uuid,
          price: share.price,
          fill_or_kill: true,
        })),
      },
    },
    {
      manual: true,
    },
  )

  return {
    order: data ? sanitizeOrderFromRaw(data) : null,
    requestBuyShare,
    loading,
    error,
  }
}
