import { Preauth, PreauthRaw } from 'types/card'

import { sanitizePreauthFromRaw } from '../@santizers/card'
import { MxApi } from '../mx-api'

export const fetchCardPreauthoriaztion = async (preauthId: string): Promise<Preauth> => {
  const { data } = await MxApi.get<PreauthRaw>(
    `/auth-users/me/wallet/card-preauthorization/${preauthId}`,
  )
  return sanitizePreauthFromRaw(data)
}
