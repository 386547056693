import { EMAILS } from 'constants/emails'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'

import styles from '../styles.module.scss'

// This component is shown when the app crashes. It's rendered on the ErrorBoundary component.
// Therefor you CANNOT put other things than raw HTML in this component.

export const Crash = () => {
  const { t } = useLocalization('feedback.crash')

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('header')}</h1>
      <p className={styles.description}>
        {t('message')}
        <a href={`mailto:${EMAILS.SUPPORT}`}>{EMAILS.SUPPORT}</a>
      </p>
      <a href={Paths.HOME}>
        <Button>{t('goHome')}</Button>
      </a>
    </div>
  )
}
