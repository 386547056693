export const downloadBlobAsFile = (data: Blob, fileTitle: string) => {
  const pdfUrl = window.URL.createObjectURL(new Blob([data]))
  const downloadLink = document.createElement('a')

  downloadLink.href = pdfUrl
  downloadLink.setAttribute('download', fileTitle)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
  window.URL.revokeObjectURL(pdfUrl)
}
