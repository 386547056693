export enum OnboardingStepIdentifier {
  EMAIL_VERIFICATION = 'verifyEmail',
  ADD_PERSONAL_DETAILS = 'addPersonalDetails',
  VERIFY_IDENTITY = 'verifyIdentity',
  PLATFORM_TOUR = 'platformTour',
  ADD_CARD = 'addCard',
  VALIDATE_CARD = 'validateCard',
}

export type OnboardingStepProps = {
  onStepComplete: () => Promise<void>
  onVerify: () => Promise<void>
}

export const TOTAL_STEPS_WITH_ADD_CARD = 4
export const TOTAL_SUB_STEPS_WITH_ADD_CARD = 6

export const TOTAL_STEPS_WITHOUT_ADD_CARD = 3
export const TOTAL_SUB_STEPS_WITHOUT_ADD_CARD = 5
