export const GraphConstants = {
  SCALE_FACTOR: {
    X_AXIS: 0.05,
    Y_AXIS: 10,
  },
  PADDING: {
    TOP: {
      LARGE: 56,
      MEDIUM: 40,
      SMALL: 16,
    },
    BOTTOM: {
      LARGE: 48,
      MEDIUM: 36,
      SMALL: 24,
    },
    LEFT: {
      LARGE: 60,
      MEDIUM: 40,
      SMALL: 20,
    },
    RIGHT: {
      LARGE: 60,
      MEDIUM: 40,
      SMALL: 20,
    },
  },
  LEGEND: {
    WIDTH: 184,
  },
  BISECTOR: {
    LINE_GRAPH: {
      TOOLTIP_SIZE: 6,
      SHADOW_SIZE: 14,
    },
    SLIDER: {
      TOOLTIP_SIZE: 10,
      SHADOW_SIZE: {
        DEFAULT: 0,
        HOVER: 20,
      },
    },
  },
} as const
