import { ReactNode } from 'react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'

import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'

// eslint-disable-next-line
type PayloadType = { [key: string]: any }

type GlobalModalState = {
  modalKey: ModalKey | null
  content: ReactNode
  displayModal: boolean
  payload: PayloadType | null
  size: ModalSize
  noPadding: boolean
  closeOnClickingOutside: boolean
  navigateBack?: () => void
}

const globalModalState = atom<GlobalModalState>({
  key: 'globalModal',
  default: {
    modalKey: null,
    content: null,
    displayModal: false,
    payload: null,
    size: ModalSize.Medium,
    noPadding: false,
    closeOnClickingOutside: true,
    navigateBack: undefined,
  },
})

export const useGlobalModalState = () => {
  return useRecoilState(globalModalState)
}

export const useGlobalModal = () => {
  return useRecoilValue(globalModalState)
}
