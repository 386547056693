import { MessageTitle, MessageScope } from 'types/message'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'

import { AffiliateBanner } from './@components/affiliate-banner'
import { AnnouncementBanner } from './@components/announcement-banner'
import { KycBanner } from './@components/kyc-banner'
import { ReferralBanner } from './@components/referral-banner/referral-banner'
import { useMessages } from './use-messages'

export const MessageBanner = () => {
  const { isAuthenticated } = useMxAuth()
  const { activeMessage, loading } = useMessages()

  if ((activeMessage?.scope === MessageScope.Personal && !isAuthenticated) || loading) return null

  switch (activeMessage?.title) {
    case MessageTitle.Announcement:
      return <AnnouncementBanner />
    case MessageTitle.Affiliate:
      return <AffiliateBanner />
    case MessageTitle.Referral:
      return <ReferralBanner />
    case MessageTitle.KycRequired:
    case MessageTitle.KycPending:
    case MessageTitle.KycFailed:
      return <KycBanner />
    default:
      return null
  }
}
