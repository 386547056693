import { AxiosError } from 'axios'
import { useState } from 'react'

import { ampli } from 'ampli'
import { CustomErrorCodes } from 'constants/error-codes'
import { SharePrice as TradePrice, Order } from 'constants/trading'
import { useGTM, GTMEvents } from 'gtm'
import { useLocalization } from 'locales/i18n'
import { useSellLimit } from 'repositories/mx-api'
import { KycStatus } from 'types/auth-user'
import { OrderType } from 'types/order'
import { Share, ShareWithOrders } from 'types/share'
import { CurrencyInput } from 'ui/@components/currency-input'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { OrderLoading } from 'ui/@library/feedback/order-loading'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useAuthUserMasterOpenOrdersLoadable } from 'ui/@store/auth-user-master-open-orders'
import { useMarketOverviewLoadable } from 'ui/@store/market-overview'
import { useMasterLoadable } from 'ui/@store/master'
import { useOrderBookLoadable } from 'ui/@store/order-book'
import { useRecentTradesLoadable } from 'ui/@store/recent-trades'
import { useShareListLoadable } from 'ui/@store/share-list'
import { useWalletLoadable } from 'ui/@store/wallet'

import { ReviewKyc } from '../../../../@modals/kyc/review-kyc'
import { TradeActionType } from '../../master-overview/@components/banner-trader/types'
import { OrderCompleted } from '../order-completed'
import { OrderFailed } from '../order-failed'

import { OrderContainer } from './@components/order-container'
import { OrderHandler } from './@components/order-handler'
import { OrderHeader } from './@components/order-header'
import {
  OrderQuantity,
  OrderSpecification,
  ServiceFee,
  SharePrice,
  OrderAmount,
} from './@components/order-specification'
import { ShareList } from './@components/share-list'
import styles from './styles.module.scss'

export type ReviewLimitSellProps = {
  pricePerShare?: number
  numberOfShares?: number
  sharesToTrade: ShareWithOrders[]
  shareUuid?: string
  orderType: OrderType
  isUnique?: boolean
}

export const ReviewLimitSell = () => {
  const { t } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation',
  )
  const { payload: modalPayload, renderModal } = useGlobalModal()
  const { master } = useMasterLoadable()
  const {
    wallet,
    refetch: refetchWallet,
    loading: walletLoading,
  } = useWalletLoadable({ forceRefetch: true })
  const [tradePrice, setTradePrice] = useState<string | null>(modalPayload?.pricePerShare)
  const { authUser } = useAuthUserLoadable()
  const { sendGTMEvent } = useGTM()

  const isKycValidated = authUser?.kycStatus === KycStatus.Validated

  useEffectOnce(() => {
    ampli.viewReviewSellLimitOrderModal({
      masterName: master?.name || '',
      masterUuid: master?.uuid || '',
    })
  })

  if (!isKycValidated)
    renderModal({
      modalKey: ModalKey.ReviewKyc,
      content: <ReviewKyc />,
    })

  const tradeQuantity = modalPayload?.numberOfShares
  const accountBalance = wallet?.primaryBalance
  const totalWithoutFees = tradePrice ? tradeQuantity * Number(tradePrice) : 0
  const serviceFee = -Math.floor(Order.SERVICE_CHARGE.IN_PERCENT * Number(tradePrice)) / 100
  const isServiceFeeBelowMinimum = Math.abs(serviceFee) < Order.SERVICE_CHARGE.MINIMUM_PER_SHARE
  const finalServiceFee =
    tradeQuantity *
    (isServiceFeeBelowMinimum ? -Order.SERVICE_CHARGE.MINIMUM_PER_SHARE : serviceFee)
  const totalOrderAmount = finalServiceFee + totalWithoutFees
  const sharesToTrade: Share[] = modalPayload?.sharesToTrade || []
  const shareUuidList = sharesToTrade.map((share) => share.uuid)

  const { requestSellLimit, loading: sellLimitLoading } = useSellLimit(
    master?.uuid || '',
    shareUuidList,
    tradePrice ? Number(tradePrice) : 0,
  )
  const { refetch: refetchAuthUserShareList } = useShareListLoadable({
    isAuthUserShares: true,
  })
  const { refetch: refetchShareList } = useShareListLoadable({
    isAuthUserShares: false,
  })
  const { refetch: refetchAuthUserMasterOpenOrders } = useAuthUserMasterOpenOrdersLoadable()
  const { refetch: refetchOrderBook } = useOrderBookLoadable()
  const { refetch: refetchRecentTrades } = useRecentTradesLoadable()
  const { refetch: refetchMarketOverview } = useMarketOverviewLoadable()

  const handleSellLimitOrder = async () => {
    try {
      await requestSellLimit()
      if (modalPayload?.isUnique) {
        ampli.placeUniqueSellOfferSuccess({
          masterName: master?.name || '',
          masterUuid: master?.uuid || '',
        })
      } else {
        ampli.sellLimitOrderPlacedSuccess({
          masterName: master?.name || '',
          masterUuid: master?.uuid || '',
        })
        sendGTMEvent(GTMEvents.OrderPlaced, authUser?.uuid)
      }
      renderModal({
        modalKey: ModalKey.OrderCompleted,
        content: <OrderCompleted />,
        payload: {
          tradeActionType: TradeActionType.Sell,
          orderType: OrderType.LimitAsk,
        },
      })
      refetchWallet()
      refetchShareList()
      refetchAuthUserShareList()
      refetchAuthUserMasterOpenOrders()
      refetchOrderBook()
      refetchRecentTrades()
      refetchMarketOverview()
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.data.code === CustomErrorCodes.MASTER_OWNER_ORDER_NOT_ACCEPTABLE
      ) {
        renderModal({
          modalKey: ModalKey.OrderFailed,
          content: <OrderFailed />,
          payload: {
            message: error?.response?.data?.message,
          },
        })
      } else
        renderModal({
          modalKey: ModalKey.OrderFailed,
          content: <OrderFailed />,
        })
    }
  }

  const getSharesToTrade = () =>
    sharesToTrade.map((share) => {
      return {
        price: share.askPrice,
        tokenId: share.tokenId,
        uuid: share.uuid,
      }
    })

  if (sellLimitLoading) {
    return (
      <ModalContent>
        <OrderLoading />
      </ModalContent>
    )
  }

  return (
    <ModalContent className={styles.reviewOrderContainer}>
      <OrderContainer>
        <OrderHeader
          title={modalPayload?.isUnique ? t('title.sellUnique') : t('title.sellLimit')}
          accountBalance={accountBalance}
        />
        <CurrencyInput
          displayError={true}
          title={t('priceInput.title')}
          value={tradePrice}
          setValue={setTradePrice}
        />
        <OrderSpecification>
          <OrderQuantity quantity={tradeQuantity} />
          <SharePrice title={t('price.limit')} price={tradePrice ? Number(tradePrice) : 0} />
          <OrderAmount title={t('total.withoutFees')} amount={totalWithoutFees} />
          <ServiceFee title={t('serviceFee')} serviceFee={finalServiceFee} />
          <hr className={styles.dividerHorizontal} />
          <OrderAmount title={t('total.withFees')} amount={totalOrderAmount} highlight />
        </OrderSpecification>
        <OrderHandler
          title={t('buttons.confirmOrder')}
          handleOrderAction={handleSellLimitOrder}
          isLoading={sellLimitLoading || walletLoading}
          isDisabled={!tradePrice || Number(tradePrice) > TradePrice.UPPER_LIMIT}
          isSellFlow={true}
        />
      </OrderContainer>
      <hr className={styles.dividerVertical} />
      <ShareList
        tradeQuantity={tradeQuantity}
        tradePrice={tradePrice ? Number(tradePrice) : 0}
        sharesToTrade={getSharesToTrade()}
        orderType={OrderType.LimitAsk}
      />
    </ModalContent>
  )
}
