import { useLocalization } from 'locales/i18n'
import { OrderType } from 'types/order'
import { Loader } from 'ui/@library/feedback/loader'
import { useMasterLoadable } from 'ui/@store/master'
import { generateNftArtBaseUrl } from 'ui/master/utils'

import styles from './styles.module.scss'

type ShareListItem = {
  uuid: string
  tokenId: number
  price: number
}

type ShareListProps = {
  tradePrice: number
  sharesToTrade?: ShareListItem[]
  tradeQuantity: number
  orderType?: OrderType
}

export const ShareList = ({
  tradePrice,
  sharesToTrade,
  orderType,
  tradeQuantity,
}: ShareListProps) => {
  const { t, f } = useLocalization('master.overviewTab.sidebar.trader.modals.reviewOrder.orderList')
  const { master, loading } = useMasterLoadable()
  const isSpecifiedOrder = sharesToTrade && sharesToTrade.length !== 0
  const isListOfShares = isSpecifiedOrder && sharesToTrade.length > 1
  const alternateStyling = orderType === OrderType.ImoBid || orderType === OrderType.BestBid

  if (!master) return null
  if (loading) return <Loader />

  return (
    <div className={`${styles.orderListWrapper} ${alternateStyling ? styles.noGap : ''}`}>
      <h1 className={styles.title}>{t('title')}</h1>
      {alternateStyling && (
        <p className={styles.subtitle}>
          {orderType === OrderType.BestBid ? t('subtitle.market') : t('subtitle.imo')}
        </p>
      )}
      <div
        className={`${styles.orderListContainer}  ${isListOfShares ? styles.withGradient : ''} ${
          orderType === OrderType.LimitAsk ? styles.heightLarge : ''
        }`}
      >
        <ul className={styles.orderList}>
          {isSpecifiedOrder ? (
            sharesToTrade.map((share) => (
              <li className={styles.orderItem} key={`${share.uuid}`}>
                <div className={styles.shareImageContainer}>
                  <img
                    src={master.assets.thumbnail}
                    alt='Album art cover'
                    className={styles.thumbnail}
                  />
                  {master?.assets.nftArtBaseUrl ? (
                    <img
                      src={generateNftArtBaseUrl(master?.assets.nftArtBaseUrl, share.tokenId)}
                      alt='nft art cover'
                      className={styles.nftImg}
                      onError={(e) => {
                        e.currentTarget.style.display = 'none'
                      }}
                    />
                  ) : null}
                </div>
                <p>
                  {master.name} <span className={styles.tokenId}>#{share.tokenId}</span>
                </p>
                <span className={styles.tradePrice}>
                  {f.currency(share.price ? share.price : tradePrice)}
                </span>
              </li>
            ))
          ) : (
            <li className={styles.orderItem}>
              <img
                src={master.assets.thumbnail}
                alt='Album art cover'
                className={styles.thumbnail}
              />
              <p>{`${master.name} x ${tradeQuantity}`}</p>
              <span>{f.currency(tradePrice * tradeQuantity)}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
