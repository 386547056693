import { motion, PanInfo } from 'framer-motion'
import { useState } from 'react'

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import SlideOne from 'assets/svgs/platform-tour-slide-1-desktop.svg'
import SlideOneMobile from 'assets/svgs/platform-tour-slide-1-mobile.svg'
import SlideTwo from 'assets/svgs/platform-tour-slide-2-desktop.svg'
import SlideTwoMobile from 'assets/svgs/platform-tour-slide-2-mobile.svg'
import { ViewportWidth } from 'constants/layout'
import { useLocalization } from 'locales/i18n'
import { updateOnboardingChecklist } from 'repositories/mx-api'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

export const PlatformTourModal = () => {
  const { t } = useLocalization('onboarding.platformTour')
  const { setDisplayModal } = useGlobalModal()
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const onboardingSlides = [
    {
      title: t('slideOne.title'),
      subtitle: t('slideOne.subtitle'),
      buttonPrimary: t('slideOne.buttonPrimary'),
      image: window.innerWidth > ViewportWidth.SM ? SlideOne : SlideOneMobile,
    },
    {
      title: t('slideTwo.title'),
      subtitle: t('slideTwo.subtitle'),
      buttonPrimary: t('slideTwo.buttonPrimary'),
      image: window.innerWidth > ViewportWidth.SM ? SlideTwo : SlideTwoMobile,
    },
  ]

  const handlePan = (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if (window.innerWidth > ViewportWidth.SM) {
      return
    }

    if (info.offset.x > 90) {
      if (currentSlideIndex > 0) prevSlide()
    } else if (info.offset.x < -90) {
      if (currentSlideIndex < 2) nextSlide()
    }
  }
  const handleDotClick = (index: number) => {
    setCurrentSlideIndex(index)
  }

  const closeModal = async () => {
    setDisplayModal(false)
    await updateOnboardingChecklist({ platformTour: true })
  }

  const prevSlide = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1)
    }
  }

  const nextSlide = () => {
    if (currentSlideIndex < onboardingSlides.length - 1) {
      setCurrentSlideIndex(currentSlideIndex + 1)
    }
    if (currentSlideIndex === onboardingSlides.length - 1) {
      closeModal()
    }
  }

  const currentSlide = onboardingSlides[currentSlideIndex]

  return (
    <ModalContent className={styles.modalContainer}>
      <motion.div
        className={styles.slideContainer}
        onPanEnd={handlePan}
        transition={{ duration: 0.3 }}
        initial={{ opacity: 1 }}
      >
        <div className={styles.closeIcon}>
          <CloseIcon onClick={closeModal} />
        </div>
        <div className={styles.leftContainer}>
          <div className={styles.carousel}>
            {onboardingSlides.map((_, index) => (
              <div
                key={index}
                className={`${styles.dot} ${currentSlideIndex === index ? styles.activeDot : ''}`}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </div>
          <motion.div
            key={currentSlideIndex}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: 'easeOut' }}
          >
            <div className={styles.slideContent}>
              <p className={styles.slideTitle}>{currentSlide?.title}</p>
              <p className={styles.slideSubtitle}>{currentSlide?.subtitle}</p>
            </div>
          </motion.div>
          {currentSlide?.buttonPrimary && (
            <Button className={styles.button} onClick={nextSlide} width='fixed'>
              {currentSlide?.buttonPrimary}
            </Button>
          )}
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.imageContainer}>
            {currentSlide && (
              <img
                className={styles.slideImage}
                src={currentSlide.image}
                alt={currentSlide.title}
              />
            )}
          </div>
        </div>
      </motion.div>
    </ModalContent>
  )
}
