import { useLocalization } from 'locales/i18n'
import { useDiscoverLoadable } from 'ui/@store/discover'

import { UpcomingSongCard } from './@components/upcoming-song-card'
import styles from './styles.module.scss'

export const UpcomingSongs = () => {
  const { t } = useLocalization('exchange.spotlight')
  const { discover } = useDiscoverLoadable()
  const { comingSoon: masters } = discover

  if (masters.length === 0) return null

  return (
    <div className={styles.upcomingContainer}>
      <h1 className={styles.sectionTitle}>{t('title.upcoming')}</h1>
      <div className={styles.upcomingSongsContainer}>
        {masters.map((master) => (
          <UpcomingSongCard master={master} key={master.name} />
        ))}
      </div>
    </div>
  )
}
