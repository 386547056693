export enum MasterStatus {
  Market = 'market',
  Imo = 'imo',
}

export enum MasterTab {
  Overview = 'overview',
  Shares = 'shares',
}

export type TabParamType = {
  uuid: string
  tab: MasterTab
}

export enum TradeOrigin {
  Trader = 'trader',
  Table = 'table',
}
