import { useNavigate } from 'react-router-dom'

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as GiftIcon } from 'assets/icons/gift.svg'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { MessageAction } from 'types/message'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { PaymentMethodModal } from 'ui/@modals/add-funds'

import styles from '../../styles.module.scss'
import { useMessages } from '../../use-messages'
import { BannerContainer, BannerMessage, BannerButton } from '../banner'

export const ReferralBanner = () => {
  const { t } = useLocalization('components.banner.actions')
  const navigate = useNavigate()
  const { activeMessage, closeBanner } = useMessages()
  const { renderModal } = useGlobalModal()

  if (!activeMessage) return null

  const handleKycVerification = () => {
    navigate(Paths.KYC, { state: { setInitStep: true } })
  }

  const handleConnectCard = () => {
    renderModal({ modalKey: ModalKey.PaymentMethod, content: <PaymentMethodModal /> })
  }

  return (
    <BannerContainer
      className={`${styles.bannerWrapper} ${styles[activeMessage.variant]}`}
      variant={activeMessage.variant}
    >
      <BannerMessage content={activeMessage.content} icon={<GiftIcon />} />
      {!activeMessage.isSticky && (
        <div className={styles.closeButton} onClick={closeBanner}>
          <CloseIcon />
        </div>
      )}
      {activeMessage.action && (
        <BannerButton
          onClick={
            activeMessage.action === MessageAction.VerifyIdentity
              ? handleKycVerification
              : handleConnectCard
          }
        >
          {activeMessage.action === MessageAction.VerifyIdentity
            ? t('verifyIdentity')
            : t('connectCard')}
        </BannerButton>
      )}
    </BannerContainer>
  )
}
