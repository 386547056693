import { ReactNode } from 'react'

import styles from './styles.module.scss'

type SectionHeaderProps = {
  children: ReactNode
  className?: string
}

export const SectionHeader = ({ children, className }: SectionHeaderProps) => {
  return <h2 className={`${styles.sectionHeader} ${className}`}>{children}</h2>
}
