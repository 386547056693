import { useLocalization } from 'locales/i18n'
import { useCancelOrder } from 'repositories/mx-api/hooks/use-cancel-order'
import { OrderSide } from 'types/order'
import { ErrorWithHeader } from 'ui/@library/errors/error-with-header'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useShareListLoadable } from 'ui/@store/share-list'
import { useWalletLoadable } from 'ui/@store/wallet'

import styles from './styles.module.scss'

export type CancelOrderProps = {
  orderUuid?: string
  shareUuid?: string
  orderSide: OrderSide
  masterUuid?: string
}

export const CancelOrder = () => {
  const { t } = useLocalization('order.cancelOrder')
  const { setDisplayModal, payload, renderModal } = useGlobalModal()
  const { refetch: refetchWallet } = useWalletLoadable()

  const { requestCancelOrder, loading } = useCancelOrder(payload?.orderUuid)
  const { refetch: refetchShareList } = useShareListLoadable({
    masterUuid: payload?.masterUuid,
    isAuthUserShares: false,
  })

  const handleKeepClick = () => {
    setDisplayModal(false)
  }

  const handleCancelClick = async () => {
    try {
      await requestCancelOrder()
      refetchShareList()
      setDisplayModal(false)
    } catch (error) {
      renderModal({
        modalKey: ModalKey.Error,
        content: <ErrorWithHeader />,
      })
    }
    refetchWallet()
  }

  return (
    <ModalContent className={styles.orderFailedContainer}>
      <div className={styles.headerContainer}>
        <h1 className={styles.title}>
          {payload?.orderSide === OrderSide.Bid ? t('bid.title') : t('ask.title')}
        </h1>
        <p className={styles.subtitle}>
          {payload?.orderSide === OrderSide.Bid ? t('bid.subtitle') : t('ask.subtitle')}
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          variant='primary'
          onClick={handleCancelClick}
          loading={loading}
          disabled={!payload?.orderUuid}
        >
          {payload?.orderSide === OrderSide.Bid ? t('bid.cancel') : t('ask.cancel')}
        </Button>
        <Button variant='ghost' onClick={handleKeepClick}>
          {payload?.orderSide === OrderSide.Bid ? t('bid.keep') : t('ask.keep')}
        </Button>
      </div>
    </ModalContent>
  )
}
