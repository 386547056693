import { useEffect } from 'react'

import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { usePayIn } from 'ui/@store/pay-in'
import { useWalletLoadable } from 'ui/@store/wallet'

import styles from './styles.module.scss'

export const Success = () => {
  const { t, f } = useLocalization('addFunds.success')
  const { setDisplayModal, payload: modalPayload } = useGlobalModal()
  const { refetch: refetchWallet } = useWalletLoadable()
  const { payIn } = usePayIn()

  const handleCloseModal = () => {
    if (modalPayload?.onSuccessCallback) modalPayload?.onSuccessCallback()
    else setDisplayModal(false)
  }

  useEffect(() => {
    refetchWallet()
    // eslint-disable-next-line
  }, [])

  return (
    <ModalContent className={styles.sucessContainer}>
      <div className={styles.topWrapper}>
        <h1 className={styles.title}>{t('title')}</h1>
        {payIn ? (
          <h2 className={styles.subtitle}>{`+ ${f.currency(payIn?.creditAmount)}`}</h2>
        ) : null}
        <p className={styles.description}>{t('description')}</p>
      </div>
      <Button width='fluid' onClick={handleCloseModal} className={styles.doneButton}>
        {t('done')}
      </Button>
    </ModalContent>
  )
}
