import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { ReactComponent as ShieldCrossIcon } from 'assets/icons/shield-cross.svg'
import { ReactComponent as ShieldExclamationIcon } from 'assets/icons/shield-exclamation.svg'
import { EMAILS } from 'constants/emails'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { KycStatus as KycStatusValues } from 'types/auth-user'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import styles from './styles.module.scss'

export const ReviewKyc = () => {
  const { t } = useLocalization('modals.reviewKyc')
  const { authUser } = useAuthUserLoadable()
  const { setDisplayModal } = useGlobalModal()
  const navigate = useNavigate()

  const isKycRequired =
    authUser?.kycStatus === KycStatusValues.Created ||
    authUser?.kycStatus === KycStatusValues.NotCreated ||
    authUser?.kycStatus === KycStatusValues.OutOfDate
  const isKycPending = authUser?.kycStatus === KycStatusValues.ValidationAsked
  const isKycFailed = authUser?.kycStatus === KycStatusValues.Refused

  const handlePrimaryAction = () => {
    setDisplayModal(false)
    if (!isKycPending) navigate(Paths.KYC, { state: { setInitStep: true } })
  }

  const handleSecondaryAction = () => {
    setDisplayModal(false)
  }

  if (isKycRequired) handlePrimaryAction()

  return (
    <ModalContent className={styles.reviewKycContainer}>
      <div className={styles.mainContent}>
        {isKycPending ? <ShieldExclamationIcon /> : <ShieldCrossIcon />}
        <h1 className={styles.title}>{isKycPending ? t('pending.title') : t('failed.title')}</h1>
        <p className={styles.subtitle}>
          {isKycPending
            ? t('pending.subtitle')
            : t('failed.subtitle', { errorMessage: authUser?.kycFailureReason })}
        </p>
        {isKycFailed && <p className={styles.footnote}>{t('failed.footnote')}</p>}
        <p className={styles.supportText}>
          <Trans
            i18nKey='modals.reviewKyc.pending.contactSupport'
            components={[
              <Link
                key='contactSupport'
                to='#'
                onClick={(event) => {
                  window.location.href = `mailto:${EMAILS.SUPPORT}`
                  event.preventDefault()
                }}
              />,
            ]}
          />
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.closeButton}
          variant='primary'
          width='fluid'
          onClick={handlePrimaryAction}
        >
          {isKycPending ? t('pending.actions.primary') : t('failed.actions.primary')}
        </Button>
        {isKycFailed && (
          <Button
            className={styles.closeButton}
            variant='ghost'
            width='fluid'
            onClick={handleSecondaryAction}
          >
            {t('failed.actions.secondary')}
          </Button>
        )}
      </div>
    </ModalContent>
  )
}
