import FacebookIcon from 'assets/icons/facebook.svg'
import InstagramIcon from 'assets/icons/instagram.svg'
import TiktokIcon from 'assets/icons/tiktok.svg'
import TwitterIcon from 'assets/icons/x.svg'
import YoutubeIcon from 'assets/icons/youtube.svg'
import { useLocalization } from 'locales/i18n'
import { useMasterLoadable } from 'ui/@store/master'

import { PlatformMetric } from '../platform-metric'

import styles from './styles.module.scss'

export const SocialMediaFollowers = () => {
  const { t } = useLocalization('master.overviewTab.information')
  const { master } = useMasterLoadable()

  if (!master) return null

  const hasFollowers = Object.values(master?.artist.socialMedia).some((value) => value && value > 0)

  const totalFollowers = Object.values(master?.artist.socialMedia).reduce(
    (acc, value) => (acc || 0) + (value || 0),
    0,
  )

  return (
    <>
      {hasFollowers && totalFollowers && (
        <div className={styles.socialMediaFollowersContainer}>
          <h1 className={styles.socialMediaTitle}>{t('socialMediaFollowers.title')}</h1>
          <div className={styles.socialMediaContent}>
            <div className={styles.platformMetricsContainer}>
              <PlatformMetric
                icon={YoutubeIcon}
                name={t('socialMediaFollowers.platforms.youtube')}
                value={master.artist.socialMedia.youtube}
              />
              <PlatformMetric
                icon={InstagramIcon}
                name={t('socialMediaFollowers.platforms.instagram')}
                value={master.artist.socialMedia.instagram}
              />
              <PlatformMetric
                icon={TiktokIcon}
                name={t('socialMediaFollowers.platforms.tiktok')}
                value={master.artist.socialMedia.tiktok}
              />
              <PlatformMetric
                icon={FacebookIcon}
                name={t('socialMediaFollowers.platforms.facebook')}
                value={master.artist.socialMedia.facebook}
              />
              <PlatformMetric
                icon={TwitterIcon}
                name={t('socialMediaFollowers.platforms.twitter')}
                value={master.artist.socialMedia.twitter}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
