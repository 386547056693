import { ExchangeCardSize } from 'ui/exchange/types'

import styles from './styles.module.scss'

type MasterInfoProps = {
  masterName: string
  artistName: string
  size: ExchangeCardSize
  className?: string
}

export const MasterInfo = ({ masterName, artistName, size, className }: MasterInfoProps) => {
  return (
    <div className={`${className} ${styles.masterContents} `}>
      <p className={styles.artistName}>{artistName}</p>
      <p
        className={` ${
          size === ExchangeCardSize.Large ? styles.largeMasterName : styles.smallMasterName
        } ${styles.masterName}`}
      >
        {masterName}
      </p>
    </div>
  )
}
