import { useEffect, useMemo, useState } from 'react'
import TextTransition from 'react-text-transition'

import { useLocalization } from 'locales/i18n'
import { PriceWithTimestamp } from 'types/market'
import { Spinner } from 'ui/@library/feedback/spinner'
import { LineGraph } from 'ui/@library/graphs'
import { useImoLoadable } from 'ui/@store/master'
import { usePriceHistoryLoadable } from 'ui/@store/price-history'

import { Paper } from '../paper'

import { RangeSelector } from './@components/rangeSelector'
import styles from './styles.module.scss'
import { GraphRange } from './types'

const OPTIONS = [
  {
    label: '7d',
    value: '7d',
  },
  {
    label: '1m',
    value: '1m',
  },
  {
    label: '1y',
    value: '1y',
  },
]

export const PriceGraph = () => {
  const { t, f } = useLocalization('master.overviewTab.priceGraph')
  const [selectedRange, setSelectedRange] = useState(GraphRange.Week)
  const [graphTooltipValue, setGraphTooltipValue] = useState<PriceWithTimestamp>({
    timestamp: 0,
    price: 0,
  })
  const { imo } = useImoLoadable()
  const [isHovered, setIsHovered] = useState(false)
  const [currentPrice, setCurrentPrice] = useState(0)
  const [priceChange, setPriceChange] = useState(0)
  const [priceChangePercentage, setPriceChangePercentage] = useState(0)
  const { priceHistory, loading } = usePriceHistoryLoadable({
    forceRefetch: true,
    range: selectedRange,
  })

  const selectedRangeStartTimestamp = useMemo(() => {
    const date = new Date()
    switch (selectedRange) {
      case GraphRange.Year:
        date.setFullYear(date.getFullYear() - 1)
        break
      case GraphRange.Month:
        date.setMonth(date.getMonth() - 1)
        break
      case GraphRange.Week:
      default:
        date.setDate(date.getDate() - 7)
        break
    }
    return date
  }, [selectedRange])

  const graphPeriodText = useMemo(() => {
    switch (selectedRange) {
      case GraphRange.Week:
        return t('graphRange.week')
      case GraphRange.Month:
        return t('graphRange.month')
      case GraphRange.Year:
        return t('graphRange.year')
      default:
        break
    }
    // eslint-disable-next-line
  }, [selectedRange])

  const priceHistoryWithImoPrice = useMemo(() => {
    if (priceHistory.length && imo) {
      let graphData = priceHistory
      const [first, second] = priceHistory.slice(0, 2)
      const timestampList = priceHistory.map(({ timestamp }) => timestamp)
      const hasData = first !== undefined && second !== undefined
      const needsBackfilling = imo.expiryDate && imo.expiryDate > selectedRangeStartTimestamp
      if (hasData && needsBackfilling) {
        const timeResolution = second.timestamp - first.timestamp
        graphData = [
          {
            timestamp: Math.min(...timestampList) - timeResolution,
            price: imo.price,
          },
          ...priceHistory,
        ]
      }
      return graphData
    } else return []
  }, [priceHistory, imo, selectedRangeStartTimestamp])

  const [oldestDataPoint, newestDataPoint] = useMemo(() => {
    if (priceHistoryWithImoPrice.length) {
      return [
        priceHistoryWithImoPrice.reduce((previous, current) =>
          previous.timestamp < current.timestamp ? previous : current,
        ),
        priceHistoryWithImoPrice.reduce((previous, current) =>
          previous.timestamp > current.timestamp ? previous : current,
        ),
      ]
    } else return []
  }, [priceHistoryWithImoPrice])

  useEffect(() => {
    if (priceHistoryWithImoPrice.length && imo && newestDataPoint && oldestDataPoint) {
      const hasIncompleteData = imo.expiryDate && imo.expiryDate > selectedRangeStartTimestamp
      const priceToDisplay = isHovered ? graphTooltipValue.price : newestDataPoint.price
      const oldestPrice = hasIncompleteData ? imo.price : oldestDataPoint.price
      const priceChange = priceToDisplay - oldestPrice
      const priceChangePercentage = Number(((priceChange / oldestPrice) * 100).toFixed(2))
      setCurrentPrice(priceToDisplay)
      setPriceChange(priceChange)
      setPriceChangePercentage(priceChangePercentage)
    }
  }, [
    selectedRangeStartTimestamp,
    priceHistoryWithImoPrice,
    graphTooltipValue,
    isHovered,
    imo,
    newestDataPoint,
    oldestDataPoint,
    selectedRange,
  ])

  if (loading) return <Spinner className={styles.spinner} width='fluid' />

  return (
    <Paper isPadded={false}>
      <div className={styles.priceGraphHeader}>
        <div className={styles.priceInformation}>
          <p className={styles.title}>{t('title')}</p>
          <div className={styles.subtitle}>
            <div className={styles.priceChange}>
              <TextTransition inline={true} delay={100}>
                {f.currency(currentPrice)}
              </TextTransition>
            </div>
            <p
              className={`${styles.priceChangePercentage} ${
                priceChangePercentage < 0 && styles.red
              }`}
            >
              <span>{`${priceChange >= 0 ? '+' : '-'}${f.currency(
                Math.abs(priceChange),
              )} (${f.percentage(Math.abs(priceChangePercentage))})`}</span>
              {
                <span className={`${styles.graphRange} ${isHovered && styles.hide}`}>
                  {graphPeriodText}
                </span>
              }
            </p>
          </div>
        </div>
        <RangeSelector
          setCurrentSelection={setSelectedRange}
          currentSelection={selectedRange}
          options={OPTIONS}
        />
      </div>
      {priceHistoryWithImoPrice.length > 0 && (
        <LineGraph
          selectedRange={selectedRange}
          data={priceHistoryWithImoPrice}
          setTooltipValue={setGraphTooltipValue}
          setIsHovered={setIsHovered}
        />
      )}
    </Paper>
  )
}
