import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { KycStatus } from 'types/auth-user'
import { useAuthUserOnboardingStatus } from 'ui/@hooks/use-auth-user-onboarding-status'
import { ErrorWithHeader } from 'ui/@library/errors/error-with-header'
import { FullPageError } from 'ui/@library/errors/full-page-error'
import { ProgressBar } from 'ui/@library/feedback/progress-bar'
import { FormContainer } from 'ui/@library/layout/form-container'
import { StaticPageWrapper } from 'ui/@library/layout/static-page-wrapper'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import {
  TOTAL_STEPS_WITHOUT_ADD_CARD,
  TOTAL_STEPS_WITH_ADD_CARD,
  TOTAL_SUB_STEPS_WITHOUT_ADD_CARD,
  TOTAL_SUB_STEPS_WITH_ADD_CARD,
} from 'ui/onboarding/types'

import { ApplicationSuccess } from './@components/application-success'
import { DocumentInformation } from './@components/document-information'
import { SelectDocumentType } from './@components/select-document-type'
import { UploadDocuments } from './@components/upload-documents'
import { UserAddress } from './@components/user-address'
import { VerifyIdentity } from './@components/verify-identity'
import styles from './styles.module.scss'
import { DocumentType, Steps } from './types'

export const Kyc = () => {
  const {
    authUser,
    refetchAuthUser,
    isEarlyAccessUser,
    error: authUserError,
  } = useAuthUserLoadable()
  const { firstUnfinishedStepRoute } = useAuthUserOnboardingStatus()
  useEffect(() => {
    refetchAuthUser()
    // eslint-disable-next-line
  }, [])

  const navigate = useNavigate()
  const location = useLocation()

  const steps: Steps = {
    verifyIdentity: {
      title: 'Verify Identity',
      component: VerifyIdentity,
    },
    userAddress: {
      title: 'User Address',
      component: UserAddress,
    },
    selectDocumentType: {
      title: 'Select Document Type',
      component: SelectDocumentType,
    },
    documentInformation: {
      title: 'Document Information',
      component: DocumentInformation,
    },
    uploadDocuments: {
      title: 'Upload Documents',
      component: UploadDocuments,
    },
    applicationSuccess: {
      title: 'Application Success',
      component: ApplicationSuccess,
    },
  }

  if (firstUnfinishedStepRoute) {
    navigate(`${Paths.ONBOARDING.MAIN}${firstUnfinishedStepRoute}`)
  } else if (
    authUser?.kycStatus === KycStatus.Validated ||
    authUser?.kycStatus === KycStatus.ValidationAsked
  ) {
    navigate(Paths.EXCHANGE.MAIN)
  }

  const [currentStep, setCurrentStep] = useState(
    location?.state?.setInitStep ? steps.verifyIdentity : steps.userAddress,
  )

  const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentType | undefined>(
    undefined,
  )

  const [error, setError] = useState(false)

  const getCurrentStepIndex = () => {
    switch (currentStep?.title) {
      case steps.verifyIdentity?.title:
        return { step: 2, subStep: 1 }
      case steps.userAddress?.title:
        return { step: 2, subStep: 2 }
      case steps.selectDocumentType?.title:
        return { step: 2, subStep: 3 }
      case steps.documentInformation?.title:
        return { step: 2, subStep: 4 }
      case steps.uploadDocuments?.title:
        return { step: 2, subStep: 5 }
      case steps.applicationSuccess?.title:
        return { step: 2, subStep: 6 }
      default:
        return { step: 0, subStep: 0 }
    }
  }

  useEffect(() => {
    if (
      isEarlyAccessUser &&
      (authUser?.kycStatus === KycStatus.Validated ||
        authUser?.kycStatus === KycStatus.ValidationAsked)
    ) {
      navigate(`${Paths.ONBOARDING.MAIN}${Paths.ONBOARDING.ADD_CARD}`)
    }
    // eslint-disable-next-line
  }, [authUser?.kycStatus])

  const handleKycSkip = () => {
    if (isEarlyAccessUser) {
      navigate(`${Paths.ONBOARDING.MAIN}${Paths.ONBOARDING.ADD_CARD}`)
    } else {
      navigate(Paths.EXCHANGE.MAIN)
    }
  }

  const TOTAL_STEPS = isEarlyAccessUser ? TOTAL_STEPS_WITH_ADD_CARD : TOTAL_STEPS_WITHOUT_ADD_CARD
  const TOTAL_SUB_STEPS = isEarlyAccessUser
    ? TOTAL_SUB_STEPS_WITH_ADD_CARD
    : TOTAL_SUB_STEPS_WITHOUT_ADD_CARD

  if (authUserError) return <FullPageError error={authUserError} />

  return (
    <StaticPageWrapper title={currentStep?.title}>
      <ProgressBar
        currentStep={getCurrentStepIndex()}
        totalSteps={{ step: TOTAL_STEPS, subStep: TOTAL_SUB_STEPS }}
      />
      <FormContainer className={styles.kycContainer}>
        {error ? (
          <ErrorWithHeader />
        ) : (
          currentStep && (
            <currentStep.component
              steps={steps}
              setCurrentStep={setCurrentStep}
              selectedDocumentType={selectedDocumentType}
              setSelectedDocumentType={setSelectedDocumentType}
              setError={setError}
              onSkip={handleKycSkip}
            />
          )
        )}
      </FormContainer>
    </StaticPageWrapper>
  )
}
