import { ampli } from 'ampli'
import { useGTM, GTMEvents } from 'gtm'
import { useBuyImo } from 'repositories/mx-api'
import { OrderType } from 'types/order'
import { useMessages } from 'ui/@components/message-banner/use-messages'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { OrderLoading } from 'ui/@library/feedback/order-loading'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useAuthUserMasterOpenOrdersLoadable } from 'ui/@store/auth-user-master-open-orders'
import { useMasterLoadable } from 'ui/@store/master'
import { useWalletLoadable } from 'ui/@store/wallet'

import { OrderCompleted } from '../order-completed'
import { OrderFailed } from '../order-failed'

import styles from './styles.module.scss'

export type PlaceImoBidProps = {
  orderType: OrderType
  paymentIdentifier?: string
  tradeQuantity: number
  tradePrice: number
}

export const PlaceImoBid = () => {
  const { refetchMessages } = useMessages()
  const { master } = useMasterLoadable()
  const { refetch: refetchWallet } = useWalletLoadable({ forceRefetch: true })
  const { payload, renderModal } = useGlobalModal()
  const { authUser } = useAuthUserLoadable()
  const { sendGTMEvent } = useGTM()

  const { requestBuyImo, loading: buyImoLoading, error: buyImoError } = useBuyImo()

  const { refetch: refetchAuthUserMasterOpenOrders } = useAuthUserMasterOpenOrdersLoadable()

  useEffectOnce(() => {
    handleBuyImo()
  })

  const handleBuyImo = async () => {
    if (payload && master && !buyImoLoading) {
      try {
        await requestBuyImo(
          master.uuid,
          payload.tradeQuantity,
          payload.tradePrice,
          payload.paymentIdentifier,
        )
      } catch {
        renderModal({
          modalKey: ModalKey.OrderFailed,
          content: <OrderFailed />,
        })
      }
    }
    if (!buyImoError && master) {
      ampli.imoOrderPlacedSuccess({
        masterName: master.name,
        masterUuid: master.uuid,
      })
      sendGTMEvent(GTMEvents.OrderPlaced, authUser?.uuid)
      refetchAuthUserMasterOpenOrders()
      refetchWallet()
      refetchMessages()
      renderModal({
        modalKey: ModalKey.OrderCompleted,
        content: <OrderCompleted />,
        payload: {
          isImoFlow: true,
          orderType: OrderType.ImoBid,
        },
      })
    }
  }

  return (
    <ModalContent className={styles.reviewOrderContainer}>
      <OrderLoading />
    </ModalContent>
  )
}
