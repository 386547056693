import { useMemo, useState } from 'react'

import { useLocalization } from 'locales/i18n'
import { AccessStatus } from 'types/auth-user'
import { OrderType } from 'types/order'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useWindowSize } from 'ui/@hooks/use-window-size'
import { Loader } from 'ui/@library/feedback/loader'
import { Button } from 'ui/@library/inputs/button'
import { Divider } from 'ui/@library/layout/divider'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useAuthUserMasterOpenOrdersLoadable } from 'ui/@store/auth-user-master-open-orders'
import { useAuthUserMastersWithFractionsLoadable } from 'ui/@store/auth-user-masters-with-fractions'
import { useMasterLoadable, useMasterStatusLoadable } from 'ui/@store/master'
import { ReviewImo } from 'ui/master/@components/modals/review-imo'
import { ReviewImoBuy } from 'ui/master/@components/modals/review-order'
import { MasterStatus } from 'ui/master/types'

import { SignInPrompt } from '../sign-in-prompt'

import { TradeSpecification } from './@components/trade-specification'
import { TradeAmount } from './@components/trade-specification/@components/trade-amount/trade-amount'
import { TradeQuantity } from './@components/trade-specification/@components/trade-quantity'
import { TradeTotal } from './@components/trade-specification/@components/trade-total'
import styles from './styles.module.scss'

export const TraderImoBuy = () => {
  const { f, t } = useLocalization('master.overviewTab.sidebar.trader.imo')
  const { isAuthenticated } = useMxAuth()
  const { viewports } = useWindowSize()
  const { isTablet } = viewports
  const { renderModal } = useGlobalModal()
  const { master } = useMasterLoadable()
  const { masterStatus } = useMasterStatusLoadable()
  const { authUserMastersWithFractions, loading: masterLoading } =
    useAuthUserMastersWithFractionsLoadable()
  const [numberOfShares, setNumberOfShares] = useState(NaN)
  const { authUserMasterOpenOrders, loading: masterOpenOrdersLoading } =
    useAuthUserMasterOpenOrdersLoadable()
  const { authUser } = useAuthUserLoadable()
  const [isKeyboardActive, setIsKeyboardActive] = useState(false)

  const numberOfSharesOrdered = useMemo(
    () => authUserMasterOpenOrders.reduce((acc, order) => acc + order.shareOrders.length, 0),
    [authUserMasterOpenOrders],
  )

  if (!master) return null
  if (masterLoading || masterOpenOrdersLoading)
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    )

  const { imo, market } = master
  const totalOrderAmount = numberOfShares ? numberOfShares * imo.price : 0

  const handleTradeAction = () => {
    if (!isAuthenticated) {
      renderModal({
        modalKey: ModalKey.InsufficientFunds,
        content: <SignInPrompt />,
      })
    } else if (isTablet) {
      renderModal({
        modalKey: ModalKey.ReviewImoBuy,
        content: <ReviewImoBuy />,
        payload: {
          pricePerShare: imo.price,
          numberOfShares,
          orderType: OrderType.ImoBid,
          totalOrderAmount,
        },
      })
    } else {
      renderModal({
        modalKey: ModalKey.ReviewImo,
        content: <ReviewImo />,
        payload: {
          pricePerShare: imo.price,
          numberOfShares,
          totalOrderAmount,
        },
        enforceAuth: true,
      })
    }
    setNumberOfShares(NaN)
  }

  const getMaxLimit = () => {
    if (authUserMastersWithFractions) {
      const [currentMaster] = authUserMastersWithFractions.filter(
        (currentMaster) => currentMaster.uuid === master.uuid,
      )
      const numberOfSharesOwned = currentMaster?.fractions.length || 0

      if (authUser?.accessStatus === AccessStatus.ActiveOpus) {
        return imo.maxAvailableShares - numberOfSharesOwned - numberOfSharesOrdered
      }

      return imo.maxPerUser - numberOfSharesOwned - numberOfSharesOrdered
    }
    return imo.maxPerUser
  }

  const handleFocusChange = (isFocused: boolean) => {
    setIsKeyboardActive(isFocused)
  }

  return (
    <form
      className={`${styles.traderVariantWrapper} ${isKeyboardActive ? styles.keyboardActive : ''}`}
      onSubmit={(e) => {
        e.preventDefault()
        handleTradeAction()
      }}
    >
      <TradeSpecification>
        <TradeQuantity
          label={t('information.amount')}
          maxLimit={getMaxLimit()}
          quantity={numberOfShares}
          setQuantity={setNumberOfShares}
          onFocusChange={handleFocusChange}
        />
        <TradeAmount
          amount={f.currency(masterStatus === MasterStatus.Imo ? imo.price : market.lastPrice)}
          label={t('information.price')}
        />
        <Divider />
        <TradeTotal label={t('information.total')} price={totalOrderAmount} />
      </TradeSpecification>
      <Button width='fluid' variant='primary' disabled={!numberOfShares} type='submit'>
        {t('actionCta')}
      </Button>
    </form>
  )
}
