import { useEffect } from 'react'
import {
  selector,
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil'

import { fetchWallet } from 'repositories/mx-api'
import { Wallet } from 'types/wallet'

import { authUserState } from './auth-user'

const walletQuery = selector<Wallet | null>({
  key: 'walletQuery',
  get: async ({ get }) => {
    const authUser = get(authUserState)
    return authUser ? fetchWallet() : null
  },
})

// This can be used everywhere without Suspense
export const useWalletLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(walletQuery)
  const refetch = useRecoilRefresher_UNSTABLE(walletQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    wallet: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}

// Needs the suspense logic. Keep this for further reference.
export const useWallet = ({ forceRefetch = false } = {}) => {
  const wallet = useRecoilValue(walletQuery)
  const refetch = useRecoilRefresher_UNSTABLE(walletQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    wallet,
    refetch,
  }
}
