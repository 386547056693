import { ReactComponent as ShieldCheckIcon } from 'assets/icons/shield-check.svg'
import { useLocalization } from 'locales/i18n'
import { AccessStatus, KycStatus } from 'types/auth-user'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import { SectionDescription } from '../section'

import styles from './styles.module.scss'

export const UserInfo = () => {
  const { authUser } = useAuthUserLoadable()
  const { t } = useLocalization('account.userInfo')

  const isKycVerified = authUser?.kycStatus === KycStatus.Validated

  const getAccountType = () => {
    if (authUser?.hasBusiness) {
      return t('accountType.business')
    } else {
      return t('accountType.personal')
    }
  }

  const getAccessType = () => {
    switch (authUser?.accessStatus) {
      case AccessStatus.Regular:
        return t('accessType.regular')
      case AccessStatus.Vip:
        return t('accessType.vip')
      case AccessStatus.Opus:
        return t('accessType.opus')
      case AccessStatus.ActiveOpus:
        return t('accessType.opus')
      case AccessStatus.Priority:
        return t('accessType.priority')
      case AccessStatus.EarlyAccess:
        return t('accessType.earlyAccess')
      default:
        return ''
    }
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{`${authUser?.firstName} ${authUser?.lastName}`}</h1>
      {isKycVerified && (
        <SectionDescription className={styles.kycStatusContainer}>
          <ShieldCheckIcon className={styles.icon} />
          <p>{t('verifiedIdentity')}</p>
        </SectionDescription>
      )}
      <SectionDescription className={styles.userDescription}>{authUser?.email}</SectionDescription>
      <SectionDescription
        className={styles.userDescription}
      >{`${getAccountType()} | ${getAccessType()}`}</SectionDescription>
    </div>
  )
}
