import { CopyToCliploard } from 'ui/@library/inputs/copy-to-clipboard'

import styles from './styles.module.scss'

type AccountDetailProps = {
  label: string
  value?: string
  disableCopy?: boolean
}

export const AccountDetail = ({ label, value, disableCopy = false }: AccountDetailProps) => {
  if (!value) return null

  return (
    <div className={styles.accountDetailWrapper}>
      <p className={styles.label}>{label}</p>
      {disableCopy ? (
        <p className={styles.value}>{value}</p>
      ) : (
        <CopyToCliploard text={value} className={styles.value} />
      )}
    </div>
  )
}
