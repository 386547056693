import { useMxApi } from '../mx-api'

const RESEND_VERIFICATION_EMAIL_URL = '/auth-users/me/resend-verification-email'

export const useResendVerificationEmail = () => {
  const [{ loading, error }, makeRequest] = useMxApi(
    {
      method: 'POST',
      url: RESEND_VERIFICATION_EMAIL_URL,
    },
    {
      manual: true,
    },
  )

  return {
    loading,
    error,
    resendVerificationEmail: makeRequest,
  }
}
