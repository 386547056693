import { Spinner } from 'ui/@library/feedback/spinner'

import styles from './styles.module.scss'

type MetricContentsProps = {
  loading?: boolean
  children: React.ReactNode
}

export const MetricContents = ({ loading, children }: MetricContentsProps) => {
  if (loading) return <Spinner />
  return <div className={styles.metricContents}>{children}</div>
}
