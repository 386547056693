import { useState, useEffect } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { FullPageError } from 'ui/@library/errors'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'
import { TabBar } from 'ui/@library/layout/tab-bar'
import { TabOption } from 'ui/@library/layout/tab-bar/tab-bar'
import { useDiscoverLoadable } from 'ui/@store/discover'

import styles from './styles.module.scss'

export const Exchange = () => {
  const { t } = useLocalization('exchange')
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState(location.pathname.split('/').pop())
  const { loading, error, refetch: refetchDiscover } = useDiscoverLoadable({ forceRefetch: true })

  const tabOptions: TabOption[] = [
    {
      label: t('tabs.all'),
      value: Paths.EXCHANGE.ALL,
    },
    {
      label: t('tabs.exchange'),
      value: Paths.EXCHANGE.BROWSE_SONGS,
    },
    {
      label: t('tabs.imo'),
      value: Paths.EXCHANGE.IMO,
    },
  ]

  const onTabChange = (tab: string) => {
    navigate(`${Paths.EXCHANGE.MAIN}/${tab}`)
  }

  useEffect(() => {
    if (location.pathname === Paths.EXCHANGE.MAIN) {
      navigate(Paths.EXCHANGE.ALL)
    }
    setActiveTab(location.pathname.split('/').pop())
  }, [location.pathname, navigate])

  if (loading) return <FullPageLoader variant='secondary' pageTitle={t('pageTitle')} />

  if (error) return <FullPageError error={error} />

  return (
    <RouteWrapper
      title={t('pageTitle')}
      description={t('pageDescription')}
      onRefresh={refetchDiscover}
      noPadding
    >
      <div className={styles.pageWrapper}>
        <TabBar
          options={tabOptions}
          activeTab={activeTab}
          className={styles.tabBarContainer}
          onChange={onTabChange}
          variant='fill'
        />
        <Outlet />
      </div>
    </RouteWrapper>
  )
}
