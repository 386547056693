import { ReactNode } from 'react'

import { useLocalization } from 'locales/i18n'
import { PercentageChange } from 'ui/@library/data-display/percentage-change'
import { Loader } from 'ui/@library/feedback/loader'
import { Divider } from 'ui/@library/layout/divider'

import styles from './styles.module.scss'
import { StatisticType } from './types'

export enum ColumnWidth {
  Fixed = 'fixed',
  Flex = 'flex',
}

/* eslint-disable */
type MobileTableProps = {
  loading?: boolean
  columns: Array<any>
  rowOnClick?: (row?: any) => void
  tableData: Array<any>
  buttonElement: ReactNode
  buttonOnClick?: (data: any) => void
  columnWidth?: ColumnWidth
}
/* eslint-enable */

export const MobileTable = ({
  loading,
  tableData,
  columns,
  rowOnClick,
  buttonElement,
  buttonOnClick,
  columnWidth = ColumnWidth.Fixed,
}: MobileTableProps) => {
  const { f } = useLocalization()

  const renderPercentageChange = (value: number) => {
    return <PercentageChange percentage={value} />
  }

  const renderPrice = (value: number) => {
    return <span>{f.currency(value)}</span>
  }

  // eslint-disable-next-line
  const renderValue = (col: any, item: any) => {
    switch (col.type) {
      case StatisticType.Percentage:
        return renderPercentageChange(item[col.id])
      case StatisticType.Price:
        return renderPrice(item[col.id])
      default:
        return item[col.id]
    }
  }

  if (loading) return <Loader />

  return (
    <div className={styles.mobileTableContainer}>
      {tableData.map((item) => {
        return (
          <div
            className={`${styles.mobileRowWrapper} ${rowOnClick ? styles.rowClick : ''}`}
            key={item.uuid}
            onClick={() => {
              rowOnClick && rowOnClick(item)
            }}
          >
            <div className={styles.mobileHeader}>
              <img
                src={item.thumbnail}
                alt={`${item.masterName} thumbnail`}
                className={styles.mobileThumbnail}
              />
              <p className={styles.mobileSongInfo}>{`${item.masterName} - ${item.artistName}`}</p>
              {(item.isCancellable || item.isAcceptable) && (
                <div
                  className={styles.buttonWrapper}
                  onClick={(e) => {
                    e.stopPropagation()
                    buttonOnClick && buttonOnClick(item)
                  }}
                >
                  {buttonElement}
                </div>
              )}
            </div>
            <div className={styles.mobileStatContainer}>
              {columns
                .filter((col) => col.type)
                .map((col) => {
                  return (
                    <div
                      className={
                        columnWidth === ColumnWidth.Flex
                          ? styles.mobileStatWrapper
                          : styles.mobileStatWrapperFixed
                      }
                      key={col.id}
                    >
                      <p className={styles.mobileStatTitle}>{col.label}</p>
                      <p className={styles.mobileStatValue}>{renderValue(col, item)}</p>
                    </div>
                  )
                })}
            </div>
            <Divider />
          </div>
        )
      })}
    </div>
  )
}
