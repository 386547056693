import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { PartialDeep } from 'type-fest'

import {
  AuthUser,
  AuthUserRaw,
  AuthUserRoyaltiesRaw,
  CommissionRaw,
  RoyaltyPayoutsRaw,
  ReferralCodeValidationRaw,
} from 'types/auth-user'

export const sanitizeAuthUserFromRaw = (raw: AuthUserRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeAuthUserToRaw = (authUser: PartialDeep<AuthUser>) => {
  return snakecaseKeys(authUser, { deep: true })
}

export const sanitizeNotificationSettingsFromRaw = (
  raw: PartialDeep<AuthUserRaw['notification_settings']>,
) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeNotificationSettingsToRaw = (
  notificationSettings?: PartialDeep<AuthUser['notificationSettings']>,
) => {
  if (!notificationSettings) return undefined
  return snakecaseKeys(notificationSettings, { deep: true })
}

export const sanitizeCommissionFromRaw = (raw: CommissionRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeRoyaltyPayoutsFromRaw = (raw: RoyaltyPayoutsRaw) => {
  return {
    ...camelcaseKeys(raw),
    date: new Date(raw.date),
  }
}

export const sanitizeAuthUserRoyaltiesFromRaw = (raw: AuthUserRoyaltiesRaw) => {
  return {
    ...camelcaseKeys(raw, { deep: true }),
    royaltyPayouts: raw.royalty_payouts.map(sanitizeRoyaltyPayoutsFromRaw),
  }
}

export const sanitizeReferralCodeValidationFromRaw = (raw: ReferralCodeValidationRaw) => {
  return camelcaseKeys(raw, { deep: true })
}
