import { PayIn, PayInRaw } from 'types/card'

import { sanitizePayInFromRaw } from '../@santizers/card'
import { MxApi } from '../mx-api'

export const fetchPayIn = async (payInId: string): Promise<PayIn> => {
  const { data } = await MxApi.get<PayInRaw>(`/auth-users/me/wallet/pay-in/${payInId}`)

  return sanitizePayInFromRaw(data)
}
