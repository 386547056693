import { ReactComponent as TooltipIcon } from 'assets/icons/info.svg'
import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'
import { PayoutStatus, RoyaltyPayoutData } from 'types/royalty-payouts'
import { Loader } from 'ui/@library/feedback/loader'

import { RoyaltyTableRow } from './@components/royalty-table-row'
import styles from './styles.module.scss'

type MobileRoyaltyPayoutsProps = {
  handlePayoutsInfo: (information?: string) => void
  loading: boolean
  royaltyPayouts: RoyaltyPayoutData[]
}

export const MobileRoyaltyPayouts = ({
  handlePayoutsInfo,
  loading,
  royaltyPayouts,
}: MobileRoyaltyPayoutsProps) => {
  const { f, t } = useLocalization('master.overviewTab.sidebar.royaltyPayouts')

  return loading ? (
    <Loader />
  ) : (
    <ul className={styles.royaltyPayoutList}>
      {royaltyPayouts?.map(({ value, payoutAt, status, timestamp, comment }) => (
        <li key={`${value}-${timestamp}`} className={styles.listWrapper}>
          <RoyaltyTableRow header={t('listHeader.payoutDate')}>
            {payoutAt ? f.date(payoutAt, TextFormats.DATE.DATE) : '-'}
          </RoyaltyTableRow>
          <RoyaltyTableRow
            header={t('listHeader.status')}
            isUpcoming={status === PayoutStatus.Upcoming}
          >
            {status === PayoutStatus.Upcoming ? t('payoutStatus.upcoming') : t('payoutStatus.paid')}
          </RoyaltyTableRow>
          <RoyaltyTableRow header={t('listHeader.value')}>{f.currency(value)}</RoyaltyTableRow>
          <RoyaltyTableRow header={t('listHeader.timestamp')}>
            {f.date(timestamp, TextFormats.DATE.DATE)}
          </RoyaltyTableRow>
          <RoyaltyTableRow header={t('listHeader.royaltyPeriod')}>
            {comment ? (
              <span className={styles.infoIcon}>
                <TooltipIcon onClick={() => handlePayoutsInfo(comment)} />
              </span>
            ) : (
              t('payoutPeriod.unavailable')
            )}
          </RoyaltyTableRow>
        </li>
      ))}
    </ul>
  )
}
