import { useLocalization } from 'locales/i18n'

import styles from '../../styles.module.scss'

type TradeTotalProps = {
  label: string
  price: number
}

export const TradeTotal = ({ label, price }: TradeTotalProps) => {
  const { f } = useLocalization()
  return (
    <li className={styles.orderTotalItem}>
      <span>{label}</span>
      <span>{f.currency(price)}</span>
    </li>
  )
}
