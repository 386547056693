import { Browser } from '@capacitor/browser'
import { useEffect, useState } from 'react'

import { ThreeDSecureEventStatus } from 'types/card'
import { useMessages } from 'ui/@components/message-banner/use-messages'
import { ThreeDSecureEvent } from 'ui/@components/three-d-secure-redirector/constants'
import { usePlatform } from 'ui/@hooks/use-platform'
import { IframeRenderer } from 'ui/@library/utils/iframe-renderer'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useCardPreAuthorization } from 'ui/@store/card-pre-authorization'

type PreAuth3DSProps = {
  onSuccess: () => void
  onError: () => void
}

export const PreAuth3DS = ({ onSuccess, onError }: PreAuth3DSProps) => {
  const { isIOS } = usePlatform()
  const { cardPreAuthorization, refetchCardPreAuthorization, resetState } =
    useCardPreAuthorization()
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [secureModeRedirectUrl, setSecureModeRedirectUrl] = useState('')
  const { refetchAuthUser } = useAuthUserLoadable()
  const { refetchMessages } = useMessages()

  const refetchPreAuthWithTimeout = () => {
    const timeoutId = setTimeout(async () => {
      const preAuth = await refetchCardPreAuthorization()
      if (preAuth?.secureModeRedirectUrl) {
        if (isIOS) {
          Browser.open({ url: preAuth.secureModeRedirectUrl })
        } else {
          setSecureModeRedirectUrl(preAuth.secureModeRedirectUrl)
        }
      }
    }, ThreeDSecureEvent.TIMEOUT)
    setTimeoutId(timeoutId)
  }

  const refetchMessagesWithTimeout = () => {
    refetchMessages()
    setTimeout(() => {
      refetchMessages()
    }, 5000)
  }

  const handlePreAuth = async () => {
    if (cardPreAuthorization) {
      switch (cardPreAuthorization.status) {
        case ThreeDSecureEventStatus.Succeeded:
          isIOS && Browser.close()
          refetchAuthUser()
          refetchMessagesWithTimeout()
          resetState()
          clearTimeout(timeoutId)
          onSuccess()
          break
        case ThreeDSecureEventStatus.Created:
          if (cardPreAuthorization.secureModeRedirectUrl) {
            if (isIOS) {
              Browser.open({ url: cardPreAuthorization.secureModeRedirectUrl })
            } else {
              setSecureModeRedirectUrl(cardPreAuthorization.secureModeRedirectUrl)
            }
          } else {
            refetchPreAuthWithTimeout()
          }
          break
        case ThreeDSecureEventStatus.Failed:
          isIOS && Browser.close()
          resetState()
          onError()
          break
        default:
          isIOS && Browser.close()
          resetState()
          onError()
      }
    }
  }

  useEffect(() => {
    const preAuthEventHandler = async (
      event: MessageEvent<{ id: string; cardPreAuthorizationId: ThreeDSecureEventStatus }>,
    ) => {
      if (event.data.id !== ThreeDSecureEvent.ID) return
      await refetchCardPreAuthorization()
    }
    window.addEventListener(ThreeDSecureEvent.TYPE, preAuthEventHandler)

    handlePreAuth()

    return () => {
      window.removeEventListener(ThreeDSecureEvent.TYPE, preAuthEventHandler)
      clearTimeout(timeoutId)
      resetState()
    }
    // eslint-disable-next-line
  }, [cardPreAuthorization])

  return <IframeRenderer url={secureModeRedirectUrl} />
}
