import { useEffect } from 'react'

import { useAuthUserOnboardingStatus } from 'ui/@hooks/use-auth-user-onboarding-status'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { OnboardingStepIdentifier } from 'ui/onboarding'
import { PlatformTourModal } from 'ui/onboarding/plaform-tour'

export const usePlatformTourSetup = () => {
  const { authUser } = useAuthUserLoadable()
  const { firstUnfinishedStep } = useAuthUserOnboardingStatus()

  const { renderModal, modalKey } = useGlobalModal()

  useEffect(() => {
    if (authUser) {
      setTimeout(() => {
        if (
          !authUser.onboardingChecklist.platformTour &&
          firstUnfinishedStep === OnboardingStepIdentifier.PLATFORM_TOUR &&
          modalKey !== ModalKey.OnboardingTour // Prevents double rendering of the modal
        )
          renderModal({
            content: <PlatformTourModal />,
            modalKey: ModalKey.OnboardingTour,
            size: ModalSize.Large,
            noPadding: true,
            closeOnClickingOutside: false,
          })
      }, 1000)
    }
    // eslint-disable-next-line
  }, [authUser])
}
