import { intervalToDuration } from 'date-fns'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLocalization } from 'locales/i18n'

type CountdownBannerProps = {
  date: Date
  className?: string
}

type CountdownState = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const Timer = ({ date, className }: CountdownBannerProps) => {
  const navigate = useNavigate()
  const { t } = useLocalization()

  const [timeLeft, setTimeLeft] = useState<CountdownState>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      if (now >= date) {
        clearInterval(interval)
      }
      const { years, months, days, hours, minutes, seconds } = intervalToDuration({
        start: now,
        end: date,
      })
      const totalDays = (years || 0) * 365 + (months || 0) * 30 + (days || 0)
      setTimeLeft({
        days: totalDays,
        hours: hours || 0,
        minutes: minutes || 0,
        seconds: seconds || 0,
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [date, navigate])

  return (
    <p className={`${className}`}>
      {t('components.countdown.timer', {
        days: timeLeft.days,
        hours: timeLeft.hours,
        minutes: timeLeft.minutes,
        seconds: timeLeft.seconds,
      })}
    </p>
  )
}
