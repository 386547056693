import camelcaseKeys from 'camelcase-keys'

import { OnboardingChecklistRaw, OnboardingChecklist } from 'types/auth-user'

export const sanitizeOnboardingToRaw = (
  onboardingChecklist: Partial<OnboardingChecklist>,
): Partial<OnboardingChecklistRaw> => {
  return {
    verify_email: onboardingChecklist.verifyEmail || undefined,
    add_personal_details: onboardingChecklist.addPersonalDetails || undefined,
    verify_identity: onboardingChecklist.verifyIdentity || undefined,
    add_card: onboardingChecklist.addCard || undefined,
    add_funds: onboardingChecklist.addFunds || undefined,
    platform_tour: onboardingChecklist.platformTour || undefined,
    validate_card: onboardingChecklist.validateCard || undefined,
  }
}

export const sanitizeOnboardingFromRaw = (raw: OnboardingChecklistRaw): OnboardingChecklist => {
  return camelcaseKeys(raw, { deep: true })
}
