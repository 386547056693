import mangoPay from 'mangopay-cardregistration-js-kit'

import { createCardRegistration } from 'repositories/mx-api'

import { AddCardFormValues, CreditCardDetails, MangoPayCardType } from './types'

mangoPay.cardRegistration.clientId = process.env.REACT_APP_MANGOPAY_CLIENT_ID
mangoPay.cardRegistration.baseURL = process.env.REACT_APP_MANGOPAY_BASE_URL

const registerCardAtMangopay = async (cardData: CreditCardDetails) => {
  return new Promise((resolve, reject) => {
    mangoPay.cardRegistration.registerCard(
      cardData,
      function (res: unknown) {
        resolve(res)
      },
      function (res: unknown) {
        reject(res)
      },
    )
  })
}

export const registerNewCard = async (
  cardDetails: Omit<AddCardFormValues, 'cardHolderName'>,
): Promise<void> => {
  const cardRegistration = await createCardRegistration()

  mangoPay.cardRegistration.init({
    cardRegistrationURL: cardRegistration.cardRegistrationUrl,
    preregistrationData: cardRegistration.preregistrationData,
    accessKey: cardRegistration.accessKey,
    Id: cardRegistration.id,
  })

  await registerCardAtMangopay({
    cardNumber: cardDetails.cardNumber,
    cardExpirationDate: `${cardDetails.expiryMonth}${cardDetails.expiryYear}`,
    cardCvx: cardDetails.securityCode,
    cardType: MangoPayCardType.CbVisaMasterCard,
  })
}

export const isValidCardNumber = (cardNumber?: string): boolean => {
  return mangoPay._validation._cardNumberValidator._validate(cardNumber) === true ? true : false
}

export const isValidCardHolderName = (cardHolderName?: string): boolean => {
  const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ -]+$/
  return regex.test(cardHolderName || '') === true ? true : false
}

export const isValidSecurityCode = (
  secureCode?: string,
  cardType = MangoPayCardType.CbVisaMasterCard,
): boolean => {
  return mangoPay._validation._cvvValidator._validate(secureCode, cardType) === true ? true : false
}

export const formatCardNumber = (cardNumber: string) => {
  return cardNumber
    .replace(/\D/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim()
}

export const sanitizeCardNumber = (cardNumber?: string) => {
  return cardNumber?.replaceAll(' ', '') || ''
}

export const sanitizeExpiryYear = (expiryYear?: string) => {
  return expiryYear?.slice(-2) || ''
}

export const expiryMonthOptions = Array.from({ length: 12 }, (_, index) => ({
  label: (index + 1).toString().padStart(2, '0'),
  value: (index + 1).toString().padStart(2, '0'),
}))

export const expiryYearOptions = Array.from(
  { length: 2099 - new Date().getFullYear() + 1 },
  (_, index) => {
    const year = new Date().getFullYear() + index
    return {
      label: year.toString(),
      value: year.toString(),
    }
  },
)
