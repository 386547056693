/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull mx-frontend'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 3
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/masterexchange/MasterExchange/implementation/mx-frontend)
 */

import * as amplitude from '@amplitude/analytics-browser'

export type Environment = 'production' | 'staging' | 'development'

export const ApiKey: Record<Environment, string> = {
  production: '2bb4901fafa20f44f6d2f9d40ab7e877',
  staging: 'd5860d23168896fcd69550166d02308f',
  development: 'd8e25dde4fbde0612863eca4af671974',
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '3',
    branch: 'main',
    source: 'mx-frontend',
    versionId: '6dd8e235-1b30-49b3-8601-ee8f8983f2e5',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
}

export interface LoadOptionsBase {
  disabled?: boolean
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment
  client?: { configuration?: BrowserOptions }
}
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions }
}
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient }
}

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance

export interface IdentifyProperties {
  /**
   * This identifies if a user is signed in (authenticated, i.e. True) or not (False).
   */
  authState?: boolean
}

export interface AmplitudePageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page Domain'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page Location'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page Path'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page Title'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page URL'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'referrer'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'referring_domain'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'utm_medium'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'utm_source'?: string
}

export interface AcceptUniqueBuyOfferSuccessProperties {
  masterName: string
  masterUuid: string
}

export interface BestPriceBuyOrderPlacedSuccessProperties {
  masterName: string
  masterUuid: string
}

export interface BuyLimitOrderPlacedSuccessProperties {
  masterName: string
  masterUuid: string
}

export interface ClickBrowseCardProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  index: number
  masterName: string
  masterUuid: string
}

export interface ClickImoCardProperties {
  imoName: string
  imoUuid: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  index: number
}

export interface ImoOrderPlacedSuccessProperties {
  masterName: string
  masterUuid: string
}

export interface OpenSocialShareModalProperties {
  triggeringAction: string
}

export interface PlaceUniqueBuyForListedPriceSuccessProperties {
  masterName: string
  masterUuid: string
}

export interface PlaceUniqueBuyOfferSuccessProperties {
  masterName: string
  masterUuid: string
}

export interface PlaceUniqueSellOfferSuccessProperties {
  masterName: string
  masterUuid: string
}

export interface PreviewSongProperties {
  masterName: string
  masterUuid: string
}

export interface PreviewWhitepaperProperties {
  authState: boolean
  masterName: string
  masterUuid: string
}

export interface SellLimitOrderPlacedSuccessProperties {
  masterName: string
  masterUuid: string
}

export interface ShareOnSocialMediaProperties {
  clientType: string
  method: string
}

export interface ViewImoInformationModalProperties {
  masterName: string
  masterUuid: string
}

export interface ViewModalProperties {
  /**
   * Specifies the key for the current Modal.
   */
  modalKey: string
}

export interface ViewPageProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page Path'?: string
}

export interface ViewReviewBestPriceBuyModalProperties {
  masterName: string
  masterUuid: string
}

export interface ViewReviewBuyLimitOrderModalProperties {
  masterName: string
  masterUuid: string
}

export interface ViewReviewImoOrderModalProperties {
  masterName: string
  masterUuid: string
}

export interface ViewReviewSellLimitOrderModalProperties {
  masterName: string
  masterUuid: string
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties
  }
}

export class AmplitudePageViewed implements BaseEvent {
  event_type = '[Amplitude] Page Viewed'

  constructor(public event_properties?: AmplitudePageViewedProperties) {
    this.event_properties = event_properties
  }
}

export class AcceptUniqueBuyOfferSuccess implements BaseEvent {
  event_type = 'accept unique buy offer success'

  constructor(public event_properties: AcceptUniqueBuyOfferSuccessProperties) {
    this.event_properties = event_properties
  }
}

export class BestPriceBuyOrderPlacedSuccess implements BaseEvent {
  event_type = 'best price buy order placed success'

  constructor(public event_properties: BestPriceBuyOrderPlacedSuccessProperties) {
    this.event_properties = event_properties
  }
}

export class BuyLimitOrderPlacedSuccess implements BaseEvent {
  event_type = 'buy limit order placed success'

  constructor(public event_properties: BuyLimitOrderPlacedSuccessProperties) {
    this.event_properties = event_properties
  }
}

export class ClickBrowseCard implements BaseEvent {
  event_type = 'click browse card'

  constructor(public event_properties: ClickBrowseCardProperties) {
    this.event_properties = event_properties
  }
}

export class ClickImoCard implements BaseEvent {
  event_type = 'click imo card'

  constructor(public event_properties: ClickImoCardProperties) {
    this.event_properties = event_properties
  }
}

export class ClickLanguagePicker implements BaseEvent {
  event_type = 'click language picker'
}

export class EditLanguage implements BaseEvent {
  event_type = 'edit language'
}

export class FundsAddedSuccess implements BaseEvent {
  event_type = 'funds added success'
}

export class ImoOrderOrTradingOrderPlaced implements BaseEvent {
  event_type = 'IMO order or trading order placed'
}

export class ImoOrderPlacedSuccess implements BaseEvent {
  event_type = 'imo order placed success'

  constructor(public event_properties: ImoOrderPlacedSuccessProperties) {
    this.event_properties = event_properties
  }
}

export class OpenSocialShareModal implements BaseEvent {
  event_type = 'open social share modal'

  constructor(public event_properties: OpenSocialShareModalProperties) {
    this.event_properties = event_properties
  }
}

export class PlaceUniqueBuyForListedPriceSuccess implements BaseEvent {
  event_type = 'place unique buy for listed price success'

  constructor(public event_properties: PlaceUniqueBuyForListedPriceSuccessProperties) {
    this.event_properties = event_properties
  }
}

export class PlaceUniqueBuyOfferSuccess implements BaseEvent {
  event_type = 'place unique buy offer success'

  constructor(public event_properties: PlaceUniqueBuyOfferSuccessProperties) {
    this.event_properties = event_properties
  }
}

export class PlaceUniqueSellOfferSuccess implements BaseEvent {
  event_type = 'place unique sell offer success'

  constructor(public event_properties: PlaceUniqueSellOfferSuccessProperties) {
    this.event_properties = event_properties
  }
}

export class PreviewSong implements BaseEvent {
  event_type = 'preview song'

  constructor(public event_properties: PreviewSongProperties) {
    this.event_properties = event_properties
  }
}

export class PreviewWhitepaper implements BaseEvent {
  event_type = 'preview whitepaper'

  constructor(public event_properties: PreviewWhitepaperProperties) {
    this.event_properties = event_properties
  }
}

export class RequestAddFunds implements BaseEvent {
  event_type = 'request add funds'
}

export class SelectAddFunds implements BaseEvent {
  event_type = 'select add funds'
}

export class SelectDrivingLicense implements BaseEvent {
  event_type = 'select driving license'
}

export class SelectLanguage implements BaseEvent {
  event_type = 'select language'
}

export class SelectNationalIdCard implements BaseEvent {
  event_type = 'select national id card'
}

export class SelectPassport implements BaseEvent {
  event_type = 'select passport'
}

export class SellLimitOrderPlacedSuccess implements BaseEvent {
  event_type = 'sell limit order placed success'

  constructor(public event_properties: SellLimitOrderPlacedSuccessProperties) {
    this.event_properties = event_properties
  }
}

export class SessionEnd implements BaseEvent {
  event_type = 'session_end'
}

export class SessionStart implements BaseEvent {
  event_type = 'session_start'
}

export class ShareOnSocialMedia implements BaseEvent {
  event_type = 'share on social media'

  constructor(public event_properties: ShareOnSocialMediaProperties) {
    this.event_properties = event_properties
  }
}

export class SignIn implements BaseEvent {
  event_type = 'sign in'
}

export class SignOut implements BaseEvent {
  event_type = 'sign out'
}

export class SignUp implements BaseEvent {
  event_type = 'sign up'
}

export class SubmitCardDetailsSuccess implements BaseEvent {
  event_type = 'submit card details success'
}

export class SubmitDrivingLicenseSuccess implements BaseEvent {
  event_type = 'submit driving license success'
}

export class SubmitNationalIdCardSuccess implements BaseEvent {
  event_type = 'submit national id card success'
}

export class SubmitPassportSuccess implements BaseEvent {
  event_type = 'submit passport success'
}

export class SubmitPersonalDetailsSuccess implements BaseEvent {
  event_type = 'submit personal details success'
}

export class SubmitUserAddressSuccess implements BaseEvent {
  event_type = 'submit user address success'
}

export class UploadDrivingLicense implements BaseEvent {
  event_type = 'upload driving license'
}

export class UploadNationalIdCard implements BaseEvent {
  event_type = 'upload National ID card'
}

export class UploadPassport implements BaseEvent {
  event_type = 'upload passport'
}

export class ViewAddFundsModal implements BaseEvent {
  event_type = 'view add funds modal'
}

export class ViewImoInformationModal implements BaseEvent {
  event_type = 'view imo information modal'

  constructor(public event_properties: ViewImoInformationModalProperties) {
    this.event_properties = event_properties
  }
}

export class ViewModal implements BaseEvent {
  event_type = 'view modal'

  constructor(public event_properties: ViewModalProperties) {
    this.event_properties = event_properties
  }
}

export class ViewPage implements BaseEvent {
  event_type = 'view page'

  constructor(public event_properties?: ViewPageProperties) {
    this.event_properties = event_properties
  }
}

export class ViewReviewBestPriceBuyModal implements BaseEvent {
  event_type = 'view review best price buy modal'

  constructor(public event_properties: ViewReviewBestPriceBuyModalProperties) {
    this.event_properties = event_properties
  }
}

export class ViewReviewBuyLimitOrderModal implements BaseEvent {
  event_type = 'view review buy limit order modal'

  constructor(public event_properties: ViewReviewBuyLimitOrderModalProperties) {
    this.event_properties = event_properties
  }
}

export class ViewReviewImoOrderModal implements BaseEvent {
  event_type = 'view review imo order modal'

  constructor(public event_properties: ViewReviewImoOrderModalProperties) {
    this.event_properties = event_properties
  }
}

export class ViewReviewSellLimitOrderModal implements BaseEvent {
  event_type = 'view review sell limit order modal'

  constructor(public event_properties: ViewReviewSellLimitOrderModalProperties) {
    this.event_properties = event_properties
  }
}

export class ViewSelectPaymentMethodModal implements BaseEvent {
  event_type = 'view select payment method modal'
}

export class WithdrawFundsSuccess implements BaseEvent {
  event_type = 'withdraw funds success'
}

export type PromiseResult<T> = { promise: Promise<T | void> }

const getVoidPromiseResult = () => ({ promise: Promise.resolve() })

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Amplitude] Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/%5BAmplitude%5D%20Page%20Viewed)
   *
   * Owner: marcus@masterexchange.com
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Domain)
   * @param options Amplitude event options.
   */
  amplitudePageViewed(
    properties?: AmplitudePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudePageViewed(properties), options);
  }

  /**
   * accept unique buy offer success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/accept%20unique%20buy%20offer%20success)
   *
   * **User accepts a bid on their unique share successfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  acceptUniqueBuyOfferSuccess(
    properties: AcceptUniqueBuyOfferSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new AcceptUniqueBuyOfferSuccess(properties), options);
  }

  /**
   * best price buy order placed success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/best%20price%20buy%20order%20placed%20success)
   *
   * **user sucessfully places the best price buy order**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  bestPriceBuyOrderPlacedSuccess(
    properties: BestPriceBuyOrderPlacedSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new BestPriceBuyOrderPlacedSuccess(properties), options);
  }

  /**
   * buy limit order placed success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/buy%20limit%20order%20placed%20success)
   *
   * **user sucessfully places the buy limit price order**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  buyLimitOrderPlacedSuccess(
    properties: BuyLimitOrderPlacedSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new BuyLimitOrderPlacedSuccess(properties), options);
  }

  /**
   * click browse card
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/click%20browse%20card)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. index)
   * @param options Amplitude event options.
   */
  clickBrowseCard(
    properties: ClickBrowseCardProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickBrowseCard(properties), options);
  }

  /**
   * click imo card
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/click%20imo%20card)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. imoName)
   * @param options Amplitude event options.
   */
  clickImoCard(
    properties: ClickImoCardProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickImoCard(properties), options);
  }

  /**
   * click language picker
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/click%20language%20picker)
   *
   * This event tracks when a user clicks on the language picker
   *
   * @param options Amplitude event options.
   */
  clickLanguagePicker(
    options?: EventOptions,
  ) {
    return this.track(new ClickLanguagePicker(), options);
  }

  /**
   * edit language
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/edit%20language)
   *
   * This event tracks when a user clicks on **Edit** language preference in the app
   *
   * @param options Amplitude event options.
   */
  editLanguage(
    options?: EventOptions,
  ) {
    return this.track(new EditLanguage(), options);
  }

  /**
   * funds added success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/funds%20added%20success)
   *
   * **funds has been added succesfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  fundsAddedSuccess(
    options?: EventOptions,
  ) {
    return this.track(new FundsAddedSuccess(), options);
  }

  /**
   * IMO order or trading order placed
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/IMO%20order%20or%20trading%20order%20placed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  imoOrderOrTradingOrderPlaced(
    options?: EventOptions,
  ) {
    return this.track(new ImoOrderOrTradingOrderPlaced(), options);
  }

  /**
   * imo order placed success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/imo%20order%20placed%20success)
   *
   * **user places an imo order sucesssfully during the imo phase**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  imoOrderPlacedSuccess(
    properties: ImoOrderPlacedSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImoOrderPlacedSuccess(properties), options);
  }

  /**
   * open social share modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/open%20social%20share%20modal)
   *
   * This event tracks when a user opens the social share modal
   *
   * @param properties The event's properties (e.g. triggeringAction)
   * @param options Amplitude event options.
   */
  openSocialShareModal(
    properties: OpenSocialShareModalProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenSocialShareModal(properties), options);
  }

  /**
   * place unique buy for listed price success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/place%20unique%20buy%20for%20listed%20price%20success)
   *
   * **User place a buy order for a listed share at listed price successfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  placeUniqueBuyForListedPriceSuccess(
    properties: PlaceUniqueBuyForListedPriceSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlaceUniqueBuyForListedPriceSuccess(properties), options);
  }

  /**
   * place unique buy offer success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/place%20unique%20buy%20offer%20success)
   *
   * **User place a unique bid sucessfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  placeUniqueBuyOfferSuccess(
    properties: PlaceUniqueBuyOfferSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlaceUniqueBuyOfferSuccess(properties), options);
  }

  /**
   * place unique sell offer success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/place%20unique%20sell%20offer%20success)
   *
   * **User place a unique sell sucessfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  placeUniqueSellOfferSuccess(
    properties: PlaceUniqueSellOfferSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlaceUniqueSellOfferSuccess(properties), options);
  }

  /**
   * preview song
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/preview%20song)
   *
   * **flipped the spotify player through pressing play button on the album cover**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  previewSong(
    properties: PreviewSongProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreviewSong(properties), options);
  }

  /**
   * preview whitepaper
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/preview%20whitepaper)
   *
   * This event tracks when a user previews a whitepaper on our platform
   *
   * @param properties The event's properties (e.g. authState)
   * @param options Amplitude event options.
   */
  previewWhitepaper(
    properties: PreviewWhitepaperProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreviewWhitepaper(properties), options);
  }

  /**
   * request add funds
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/request%20add%20funds)
   *
   * **Pressed confirm button on add funds (both success and failure)**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  requestAddFunds(
    options?: EventOptions,
  ) {
    return this.track(new RequestAddFunds(), options);
  }

  /**
   * select add funds
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/select%20add%20funds)
   *
   * **tapping the add funds button on my overview page**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  selectAddFunds(
    options?: EventOptions,
  ) {
    return this.track(new SelectAddFunds(), options);
  }

  /**
   * select driving license
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/select%20driving%20license)
   *
   * **User has selected driving license in KYC flow**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  selectDrivingLicense(
    options?: EventOptions,
  ) {
    return this.track(new SelectDrivingLicense(), options);
  }

  /**
   * select language
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/select%20language)
   *
   * This event tracks when a user saves a language preference within the application
   *
   * @param options Amplitude event options.
   */
  selectLanguage(
    options?: EventOptions,
  ) {
    return this.track(new SelectLanguage(), options);
  }

  /**
   * select national id card
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/select%20national%20id%20card)
   *
   * **User has selected national id in KYC flow**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  selectNationalIdCard(
    options?: EventOptions,
  ) {
    return this.track(new SelectNationalIdCard(), options);
  }

  /**
   * select passport
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/select%20passport)
   *
   * **User has selected passport in KYC flow**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  selectPassport(
    options?: EventOptions,
  ) {
    return this.track(new SelectPassport(), options);
  }

  /**
   * sell limit order placed success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/sell%20limit%20order%20placed%20success)
   *
   * **user sucessfully places the sell limit price order**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  sellLimitOrderPlacedSuccess(
    properties: SellLimitOrderPlacedSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new SellLimitOrderPlacedSuccess(properties), options);
  }

  /**
   * session_end
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/session_end)
   *
   * Owner: marcus@masterexchange.com
   *
   * @param options Amplitude event options.
   */
  sessionEnd(
    options?: EventOptions,
  ) {
    return this.track(new SessionEnd(), options);
  }

  /**
   * session_start
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/session_start)
   *
   * Owner: marcus@masterexchange.com
   *
   * @param options Amplitude event options.
   */
  sessionStart(
    options?: EventOptions,
  ) {
    return this.track(new SessionStart(), options);
  }

  /**
   * share on social media
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/share%20on%20social%20media)
   *
   * User have share his order on social media
   *
   * @param properties The event's properties (e.g. clientType)
   * @param options Amplitude event options.
   */
  shareOnSocialMedia(
    properties: ShareOnSocialMediaProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShareOnSocialMedia(properties), options);
  }

  /**
   * sign in
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/sign%20in)
   *
   * Owner: pradhan@masterexchange.com
   *
   * @param options Amplitude event options.
   */
  signIn(
    options?: EventOptions,
  ) {
    return this.track(new SignIn(), options);
  }

  /**
   * sign out
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/sign%20out)
   *
   * This event tracks when a user signs out of their account
   *
   * @param options Amplitude event options.
   */
  signOut(
    options?: EventOptions,
  ) {
    return this.track(new SignOut(), options);
  }

  /**
   * sign up
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/sign%20up)
   *
   * This event tracks when a user creates a new account on the platform
   *
   * @param options Amplitude event options.
   */
  signUp(
    options?: EventOptions,
  ) {
    return this.track(new SignUp(), options);
  }

  /**
   * submit card details success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/submit%20card%20details%20success)
   *
   * **user submit card details sucessfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  submitCardDetailsSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SubmitCardDetailsSuccess(), options);
  }

  /**
   * submit driving license success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/submit%20driving%20license%20success)
   *
   * **Driving license front and back submitted succesfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  submitDrivingLicenseSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SubmitDrivingLicenseSuccess(), options);
  }

  /**
   * submit national id card success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/submit%20national%20id%20card%20success)
   *
   * **National ID front and back submitted succesfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  submitNationalIdCardSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SubmitNationalIdCardSuccess(), options);
  }

  /**
   * submit passport success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/submit%20passport%20success)
   *
   * **Passport submitted succesfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  submitPassportSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SubmitPassportSuccess(), options);
  }

  /**
   * submit personal details success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/submit%20personal%20details%20success)
   *
   * **user submits personal details in the onboarding flow sucessfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  submitPersonalDetailsSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SubmitPersonalDetailsSuccess(), options);
  }

  /**
   * submit user address success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/submit%20user%20address%20success)
   *
   * **user submits address details in the KYC flow sucessfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  submitUserAddressSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SubmitUserAddressSuccess(), options);
  }

  /**
   * upload driving license
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/upload%20driving%20license)
   *
   * **Driving license front page uploaded succesfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  uploadDrivingLicense(
    options?: EventOptions,
  ) {
    return this.track(new UploadDrivingLicense(), options);
  }

  /**
   * upload National ID card
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/upload%20National%20ID%20card)
   *
   * **National ID front page uploaded succesfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  uploadNationalIdCard(
    options?: EventOptions,
  ) {
    return this.track(new UploadNationalIdCard(), options);
  }

  /**
   * upload passport
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/upload%20passport)
   *
   * **Passport uploaded succesfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  uploadPassport(
    options?: EventOptions,
  ) {
    return this.track(new UploadPassport(), options);
  }

  /**
   * view add funds modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/view%20add%20funds%20modal)
   *
   * **View add funds & confirm modal**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  viewAddFundsModal(
    options?: EventOptions,
  ) {
    return this.track(new ViewAddFundsModal(), options);
  }

  /**
   * view imo information modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/view%20imo%20information%20modal)
   *
   * **user views the IMO information modal**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  viewImoInformationModal(
    properties: ViewImoInformationModalProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewImoInformationModal(properties), options);
  }

  /**
   * view modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/view%20modal)
   *
   * This event tracks when a user views a modal on the platform
   *
   * @param properties The event's properties (e.g. modalKey)
   * @param options Amplitude event options.
   */
  viewModal(
    properties: ViewModalProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewModal(properties), options);
  }

  /**
   * view page
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/view%20page)
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Path)
   * @param options Amplitude event options.
   */
  viewPage(
    properties?: ViewPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewPage(properties), options);
  }

  /**
   * view review best price buy modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/view%20review%20best%20price%20buy%20modal)
   *
   * **user views the review best price buy modal**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  viewReviewBestPriceBuyModal(
    properties: ViewReviewBestPriceBuyModalProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewReviewBestPriceBuyModal(properties), options);
  }

  /**
   * view review buy limit order modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/view%20review%20buy%20limit%20order%20modal)
   *
   * **user views the review buy limit order modal**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  viewReviewBuyLimitOrderModal(
    properties: ViewReviewBuyLimitOrderModalProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewReviewBuyLimitOrderModal(properties), options);
  }

  /**
   * view review imo order modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/view%20review%20imo%20order%20modal)
   *
   * **user views the review IMO order modal**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  viewReviewImoOrderModal(
    properties: ViewReviewImoOrderModalProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewReviewImoOrderModal(properties), options);
  }

  /**
   * view review sell limit order modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/view%20review%20sell%20limit%20order%20modal)
   *
   * **user views the review sell limit order modal**
   *
   * Owner: Ehtesham Faraz
   *
   * @param properties The event's properties (e.g. masterName)
   * @param options Amplitude event options.
   */
  viewReviewSellLimitOrderModal(
    properties: ViewReviewSellLimitOrderModalProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewReviewSellLimitOrderModal(properties), options);
  }

  /**
   * view select payment method modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/view%20select%20payment%20method%20modal)
   *
   * **View select payment method modal**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  viewSelectPaymentMethodModal(
    options?: EventOptions,
  ) {
    return this.track(new ViewSelectPaymentMethodModal(), options);
  }

  /**
   * withdraw funds success
   *
   * [View in Tracking Plan](https://data.amplitude.com/masterexchange/MasterExchange/events/main/latest/withdraw%20funds%20success)
   *
   * **user withdraws some amount sucessfully**
   *
   * Owner: Ehtesham Faraz
   *
   * @param options Amplitude event options.
   */
  withdrawFundsSuccess(
    options?: EventOptions,
  ) {
    return this.track(new WithdrawFundsSuccess(), options);
  }
}

export const ampli = new Ampli()

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions

export type BrowserClient = amplitude.Types.BrowserClient
export type BaseEvent = amplitude.Types.BaseEvent
export type IdentifyEvent = amplitude.Types.IdentifyEvent
export type GroupEvent = amplitude.Types.GroupIdentifyEvent
export type Event = amplitude.Types.Event
export type EventOptions = amplitude.Types.EventOptions
export type Result = amplitude.Types.Result
