import { ExternalLinks } from 'constants/external-links'
import { useLocalization } from 'locales/i18n'
import { useMasterLoadable } from 'ui/@store/master'
import { getRoyaltyYield } from 'ui/master/utils'

import { Paper } from '../paper'

import styles from './styles.module.scss'

export const RoyaltyYield = () => {
  const { t, f } = useLocalization('master.overviewTab.royaltyYield')
  const { master } = useMasterLoadable()

  if (!master) return null

  const { period } = master.metrics

  return (
    <Paper className={styles.yieldContainer}>
      <p className={styles.title}>{t('yield')}</p>
      {master.isNewRelease ? (
        <>
          <p className={styles.period}>{t('yieldDisclaimer')}</p>
          <a href={ExternalLinks.SUPPORT_SONG_MATURITY_PHASES} target='_blank' rel='noreferrer'>
            {t('readMore')}
          </a>
        </>
      ) : (
        <>
          {period && <p className={styles.period}>{t('inPeriod', { period })}</p>}
          <h3 className={styles.percentage}>{f.percentage(getRoyaltyYield(master))}</h3>
        </>
      )}
    </Paper>
  )
}
