import { useMxApi } from '../mx-api'

export const useBuyImo = () => {
  const [{ loading, error }, execute] = useMxApi({}, { manual: true })

  const requestBuyImo = (
    masterUuid: string,
    shareQuantity: number,
    pricePerShare: number,
    paymentIdentifier?: string,
  ) => {
    const data = {
      quantity: shareQuantity,
      price: pricePerShare,
      payment_identifier: paymentIdentifier,
    }

    return execute({
      method: 'POST',
      url: `/masters/${masterUuid}/imo/bids`,
      data,
    })
  }

  return {
    requestBuyImo,
    loading,
    error,
  }
}
