import { useRecoilValue } from 'recoil'

import { useLocalization } from 'locales/i18n'
import { useFetchAuthUserRoyalties } from 'repositories/mx-api/hooks/use-fetch-auth-user-royalties'
import { AddFundsButton } from 'ui/@modals/add-funds/@components/add-funds-button'
import { authUserState } from 'ui/@store/auth-user'

import { AvailableFunds } from './@components/available-funds'
import { Commission } from './@components/commission'
import { ListedSongsTable } from './@components/listed-songs-table'
import { OrdersAndOffers } from './@components/orders-and-offers'
import { RoyaltiesEarned } from './@components/royalties-earned'
import { RoyaltySharesTable } from './@components/royalty-shares-table'
import { TotalPortfolioValue } from './@components/total-portfolio-value'
import styles from './styles.module.scss'

export const Overview = () => {
  const { t } = useLocalization('myAssets.overview')

  const authUser = useRecoilValue(authUserState)
  const { data: authUserRoyalties } = useFetchAuthUserRoyalties()

  return (
    <div className={styles.overviewContainer}>
      <div className={styles.metrics}>
        <TotalPortfolioValue />
        <AvailableFunds />
        {authUser?.isMasterOwner && <Commission />}
        <AddFundsButton className={styles.addFundsButton} />
      </div>

      {authUser?.isMasterOwner && (
        <div className={styles.tableGroupWrapper}>
          <h1 className={styles.heading}>{t('listedSongsTable.title')}</h1>
          <ListedSongsTable />
        </div>
      )}

      <div className={styles.tableGroupWrapper}>
        <h1 className={styles.heading}>{t('heading.royaltyShares')}</h1>
        <RoyaltySharesTable />
      </div>

      {authUserRoyalties.length > 0 && (
        <div className={styles.tableGroupWrapper}>
          <h1 className={styles.heading}>{t('heading.royaltiesEarned')}</h1>
          <RoyaltiesEarned />
        </div>
      )}

      <div className={styles.tableGroupWrapper}>
        <h1 className={styles.heading}>{t('heading.openOrders')}</h1>
        <OrdersAndOffers />
      </div>
    </div>
  )
}
