import { ReactComponent as DocumentIcon } from 'assets/icons/document-text.svg'
import { ReactComponent as PolygonScanIcon } from 'assets/icons/polygon-matic.svg'
import { useLocalization } from 'locales/i18n'
import { generateNftPdfBaseUrl, generateBlockchainContractLink } from 'ui/master/utils'

import styles from './styles.module.scss'

type MasterMetadataProps = {
  contractAddress: string | null
  tokenId: number
  price?: number
  nftPdfBaseUrl?: string
  blockchainExplorerUrl: string | null
}

export const MasterMetadata = ({
  tokenId,
  price,
  contractAddress,
  nftPdfBaseUrl,
  blockchainExplorerUrl,
}: MasterMetadataProps) => {
  const { t, f } = useLocalization('order.uniqueOrder.metadata')

  return (
    <div className={styles.masterMetadataContainer}>
      <div className={styles.masterMetadataText}>
        <div className={styles.masterMetadata}>
          <span>{t('master')}</span>
          <span className={styles.metadataValue}>#{tokenId ?? '--'}</span>
        </div>
        <div className={styles.masterMetadata}>
          <span>{t('price')}</span>
          <span className={styles.metadataValue}>{price ? f.currency(price) : t('notListed')}</span>
        </div>
      </div>
      <div className={styles.iconWrapper}>
        {nftPdfBaseUrl && (
          <a
            target='_blank'
            href={generateNftPdfBaseUrl(nftPdfBaseUrl, tokenId)}
            className={styles.documentIcon}
            rel='noreferrer'
          >
            <DocumentIcon />
          </a>
        )}
        {contractAddress && blockchainExplorerUrl && (
          <a
            target='_blank'
            href={generateBlockchainContractLink(blockchainExplorerUrl, contractAddress, tokenId)}
            className={styles.polygonScanIcon}
            rel='noreferrer'
          >
            <PolygonScanIcon />
          </a>
        )}
      </div>
    </div>
  )
}
