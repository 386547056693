import { Wallet, WalletRaw } from 'types/wallet'

import { sanitizeWalletFromRaw } from '../@santizers/wallet'
import { MxApi } from '../mx-api'

export const fetchWallet = async (): Promise<Wallet> => {
  const { data } = await MxApi.get<WalletRaw>('/auth-users/me/wallet')

  return sanitizeWalletFromRaw(data)
}
