import { BaseType, Line, Selection } from 'd3'
import { useEffect, useRef } from 'react'

import { CompositeTimeSeriesDataArray, CompositeTimeSeriesData } from 'types/metrics'
import { useWindowSize } from 'ui/@hooks/use-window-size'

import styles from './styles.module.scss'

type LinePlotProps<T extends BaseType> = {
  graph: Selection<T, unknown, null, undefined>
  data: CompositeTimeSeriesDataArray
  linePlot: Line<CompositeTimeSeriesData>
}

export const LinePlot = <T extends BaseType>({ graph, data, linePlot }: LinePlotProps<T>) => {
  const { windowSize } = useWindowSize()
  const { width: windowWidth } = windowSize

  const pathRef = useRef<SVGPathElement>(null)

  useEffect(() => {
    if (graph && pathRef.current) {
      graph.select(`.${styles.linePlotPath}`).datum(data).attr('d', linePlot)
    }
    // eslint-disable-next-line
  }, [graph, windowWidth, pathRef.current])

  return <path ref={pathRef} className={styles.linePlotPath} />
}
