import { Capacitor } from '@capacitor/core'
import { AppTrackingTransparency, IOSAppTrackingResponse } from 'capacitor-ios-app-tracking'
import gtm from 'react-gtm-module'

import { ampli, Environment } from 'ampli'
import { checkCookie } from 'cookiebot/utils'
import { AppEnvironment } from 'ui/@hooks/use-app-environment'

const initServices = () => {
  if (
    process.env.REACT_APP_ENVIRONMENT === AppEnvironment.Production ||
    process.env.REACT_APP_ENVIRONMENT === AppEnvironment.Staging
  ) {
    ampli.load({
      environment: process.env.REACT_APP_ENVIRONMENT as Environment,
      client: { configuration: { appVersion: process.env.REACT_APP_CLIENT_VERSION } },
    })

    if (process.env.REACT_APP_GTM_ID) {
      gtm.initialize({ gtmId: process.env.REACT_APP_GTM_ID })
    }
  }
}

const initializeOnConsent = () => {
  if (Cookiebot?.consented && Cookiebot?.consent?.statistics) {
    initServices()
  }
}

export const initTrackingServices = () => {
  if (Capacitor.getPlatform() === 'web') {
    if (process.env.REACT_APP_ENVIRONMENT === AppEnvironment.Staging) {
      initServices()
      return
    }

    if (!checkCookie('CookieConsent')) initServices()
    window.addEventListener('CookiebotOnLoad', initializeOnConsent)
  }

  if (Capacitor.getPlatform() === 'ios') {
    AppTrackingTransparency.getTrackingStatus().then(async (value: IOSAppTrackingResponse) => {
      if (value.status === 'unrequested') {
        await AppTrackingTransparency.requestPermission()
      }
      if (value.status === 'authorized') initServices()
    })
  }
}
