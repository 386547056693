import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

export const LiveTag = () => {
  const { t } = useLocalization('exchange.spotlight.hero')

  return (
    <div className={styles.liveNow}>
      <div className={styles.liveStatus} />
      <div>{t('liveNow')}</div>
    </div>
  )
}
