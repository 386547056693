import { motion, AnimatePresence } from 'framer-motion'
import { useEffect, useRef } from 'react'

import { ReactComponent as LeftArrowIcon } from 'assets/icons/chevron-left.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { useOnClickOutside } from 'ui/@hooks/use-onclick-outside'
import { useWindowSize } from 'ui/@hooks/use-window-size'

import { modalVariants, modalVariantsMobile, backgroundVariants } from './constants'
import styles from './styles.module.scss'
import { ModalSize } from './types'
import { useGlobalModal } from './use-global-modal'

export const GlobalModal = () => {
  const {
    displayModal,
    setDisplayModal,
    content: modalContent,
    size,
    noPadding,
    closeOnClickingOutside,
    navigateBack,
  } = useGlobalModal()
  const modalRef = useRef(null)

  const { viewports } = useWindowSize()

  useOnClickOutside(modalRef, () => {
    if (closeOnClickingOutside) {
      setDisplayModal(false)
    }
  })

  const getModalSizeClassName = () => {
    switch (size) {
      case ModalSize.Small:
        return styles.modalContainerSmall
      case ModalSize.Medium:
        return styles.modalContainerMedium
      case ModalSize.Large:
        return styles.modalContainerLarge
      default:
        return styles.modalContainerMedium
    }
  }

  useEffect(() => {
    window.onkeydown = (e) => {
      if (e.key === 'Escape') {
        setDisplayModal(false)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.onpopstate = () => {
      setDisplayModal(false)
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    document.body.style.overflow = displayModal ? 'hidden' : 'unset'
  }, [displayModal])

  return (
    <>
      <AnimatePresence>
        {displayModal && modalContent && (
          <div className={styles.modalWrapper}>
            <motion.div
              className={styles.background}
              variants={backgroundVariants}
              initial='hidden'
              animate='visible'
              exit='exit'
              key='background'
            />
            <div className={getModalSizeClassName()} ref={modalRef}>
              <motion.div
                variants={viewports.isMobile ? modalVariantsMobile : modalVariants}
                initial='hidden'
                animate='visible'
                exit='exit'
                className={`${styles.modal} ${noPadding ? styles.noPadding : ''}`}
                key='modal'
              >
                {!noPadding && (
                  <div className={styles.closeIcon}>
                    {navigateBack !== undefined ? (
                      <LeftArrowIcon onClick={() => navigateBack()} />
                    ) : (
                      <CloseIcon onClick={() => setDisplayModal(false)} />
                    )}
                  </div>
                )}
                {modalContent}
              </motion.div>
            </div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}
