import { ReactComponent as TooltipIcon } from 'assets/icons/info.svg'
import { useLocalization } from 'locales/i18n'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useMasterStatusLoadable } from 'ui/@store/master'
import { useRoyaltyPayoutsLoadable } from 'ui/@store/royalty-payouts'
import { DialogBox } from 'ui/master/@components/modals/dialog-box'
import { MasterStatus } from 'ui/master/types'

import { DesktopRoyaltyPayouts } from './@components/desktop-royalty-payouts'
import { MobileRoyaltyPayouts } from './@components/mobile-royalty-payouts'
import styles from './styles.module.scss'

export const RoyaltyPayouts = () => {
  const { renderModal } = useGlobalModal()
  const { t } = useLocalization('master.overviewTab.sidebar.royaltyPayouts')
  const { masterStatus } = useMasterStatusLoadable()
  const { royaltyPayouts, loading } = useRoyaltyPayoutsLoadable({ forceRefetch: true })

  const handlePayoutsInfo = (information?: string) => {
    renderModal({
      modalKey: ModalKey.DialogBox,
      content: <DialogBox />,
      payload: {
        information: information ? information : t('modal.title'),
      },
      closeOnClickingOutside: true,
    })
  }

  if (masterStatus !== MasterStatus.Market || !royaltyPayouts?.length) return null

  return (
    <div className={styles.royaltyPayoutsContainer}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>{t('title')}</h1>
        <div className={styles.infoIcon}>
          <TooltipIcon onClick={() => handlePayoutsInfo()} />
        </div>
      </div>
      <MobileRoyaltyPayouts
        handlePayoutsInfo={handlePayoutsInfo}
        royaltyPayouts={royaltyPayouts}
        loading={loading}
      />
      <DesktopRoyaltyPayouts
        handlePayoutsInfo={handlePayoutsInfo}
        royaltyPayouts={royaltyPayouts}
        loading={loading}
      />
    </div>
  )
}
