import { useEffect, useState } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { useMessages } from 'ui/@components/message-banner/use-messages'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'
import { TabBar } from 'ui/@library/layout/tab-bar'
import { TabOption } from 'ui/@library/layout/tab-bar/tab-bar'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import styles from './styles.module.scss'

export const Account = () => {
  const navigate = useNavigate()
  const { t } = useLocalization('account')
  const location = useLocation()

  const [activeTab, setActiveTab] = useState(location.pathname.split('/').pop())

  const { loading, refetchAuthUser } = useAuthUserLoadable()
  const { refetchMessages } = useMessages()

  useEffectOnce(() => {
    refetchAuthUser()
    refetchMessages()
  })

  const handleRefresh = () => {
    refetchAuthUser()
    refetchMessages()
  }

  const tabOptions: TabOption[] = [
    {
      label: t('tabs.account'),
      value: Paths.ACCOUNT.DETAILS,
    },
    {
      label: t('tabs.notifications'),
      value: Paths.ACCOUNT.NOTIFICATION_SETTINGS,
    },
  ]

  const handleTabChange = (value: string) => {
    navigate(`${Paths.ACCOUNT.MAIN}/${value}`)
  }

  useEffect(() => {
    if (location.pathname === Paths.ACCOUNT.MAIN) {
      navigate(Paths.ACCOUNT.DETAILS, { replace: true })
    }
    setActiveTab(location.pathname.split('/').pop())
  }, [location.pathname, navigate])

  if (loading) return <FullPageLoader variant='secondary' pageTitle={t('pageTitle')} />

  return (
    <RouteWrapper
      title={t('pageTitle')}
      description={t('pageDescription')}
      onRefresh={handleRefresh}
    >
      <div className={styles.accountPage}>
        <TabBar
          options={tabOptions}
          onChange={handleTabChange}
          variant='border'
          activeTab={activeTab}
          className={styles.tabBar}
        />
        <Outlet />
      </div>
    </RouteWrapper>
  )
}
