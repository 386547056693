import { sanitizeOrderFromRaw } from '../@santizers/order'
import { useMxApi } from '../mx-api'
export const useBidShare = (masterUuid: string, shareUuid: string, price: number) => {
  const [{ data, loading, error }, requestBidShare] = useMxApi(
    {
      method: 'POST',
      url: `/masters/${masterUuid}/shares/bids`,
      data: {
        shares: [
          {
            share_uuid: shareUuid,
            price: price,
            fill_or_kill: false,
          },
        ],
      },
    },
    {
      manual: true,
    },
  )

  return {
    order: sanitizeOrderFromRaw(data),
    requestBidShare,
    loading,
    error,
  }
}
