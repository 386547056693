import { useLocalization } from 'locales/i18n'
import { ImoStatus } from 'types/imo'
import { Loader } from 'ui/@library/feedback/loader'
import { useMasterLoadable } from 'ui/@store/master'
import { useTrader } from 'ui/master/@components/master-overview/@components/banner-trader/use-trader'

import { Trader } from '../traders'

import { Banner } from './@components/banner'
import styles from './styles.module.scss'

export const BannerTraderImo = () => {
  const { t, f } = useLocalization('master.overviewTab.sidebar.banner')
  const { hasAuthUserReachedShareLimit, isLoading } = useTrader()
  const { master } = useMasterLoadable()

  if (!master || !master.imo.launchDate || !master.imo.expiryDate) return null
  if (isLoading)
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    )

  const imoStatus = master.imo.status

  switch (imoStatus) {
    case ImoStatus.Upcoming:
      return <Banner launchDate={master.imo.launchDate} logo={master.assets.logo} />
    case ImoStatus.Active:
      return hasAuthUserReachedShareLimit ? (
        <Banner
          title={t('imoLimitReached.title')}
          logo={master.assets.logo}
          description={t('imoLimitReached.description', {
            expireDate: f.date(master?.imo.expiryDate),
          })}
        />
      ) : (
        <Trader />
      )
    case ImoStatus.Ended:
      return (
        <Banner
          title={t('imoEnded.title')}
          logo={master.assets.logo}
          description={t('imoEnded.description')}
        />
      )
    default:
      return null
  }
}
