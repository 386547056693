import { Card, CardRaw } from 'types/card'

import { sanitizeCardFromRaw } from '../@santizers/card'
import { MxApi } from '../mx-api'

export const fetchCards = async (): Promise<Card[]> => {
  const { data } = await MxApi.get<CardRaw[]>('/auth-users/me/wallet/cards')

  return data.map(sanitizeCardFromRaw)
}
