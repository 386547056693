import { ReactComponent as SignedOutImage } from 'assets/svgs/my-assets-signed-out.svg'
import { LandingPageRoutes } from 'constants/external-links'
import { useLocalization } from 'locales/i18n'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { useLandingPageLinks } from 'ui/@hooks/use-landing-page-links'
import { useSignInAndSignUp } from 'ui/@hooks/use-signin-and-signup'
import { Button } from 'ui/@library/inputs/button'

import styles from './styles.module.scss'

export const SignedOut = () => {
  const { t } = useLocalization('myAssets')
  const { signIn } = useSignInAndSignUp()
  const generateLandingPageLink = useLandingPageLinks()

  return (
    <RouteWrapper title={t('pageTitle')}>
      <div className={styles.singedOutContainer}>
        <SignedOutImage />
        <p className={styles.singedOutMessage}>{t('signedOut.message')}</p>
        <div className={styles.buttonsContainer}>
          <Button onClick={signIn}>{t('signedOut.signIn')}</Button>
          <a href={generateLandingPageLink(LandingPageRoutes.WAITLIST)}>
            <Button variant='ghost'>{t('signedOut.joinWaitlist')}</Button>
          </a>
        </div>
      </div>
    </RouteWrapper>
  )
}
