import Lottie from 'lottie-react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ampli } from 'ampli'
import OrderCompletedAnimation from 'assets/lotties/order-completed-confetti.json'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { TriggeringAction } from 'types/ampli'
import { OrderType } from 'types/order'
import { OrderLoading } from 'ui/@library/feedback/order-loading'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { useMasterLoadable } from 'ui/@store/master'
import { TradeActionType } from 'ui/master/@components/master-overview/@components/banner-trader/types'
import { QUERY_SCROLL } from 'ui/my-assets/@components/overview/constants'
import { AccordionType } from 'ui/my-assets/@components/overview/types'

import { SocialShare } from '../../social-share'

import styles from './styles.module.scss'

export type OrderCompletedProps = {
  tradeActionType?: TradeActionType
  isBulkMarketFlow?: boolean
  isImoFlow?: boolean
  resolvedShares?: number
  totalShares?: number
  orderType?: OrderType
}

export const OrderCompleted = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader.modals.orderCompleted')
  const { master } = useMasterLoadable()
  const navigate = useNavigate()
  const { setDisplayModal, payload: modalPayload } = useGlobalModal()
  const { renderModal } = useGlobalModal()
  const location = useLocation()
  const currentPage = location.pathname

  if (!master)
    return (
      <ModalContent>
        <OrderLoading />
      </ModalContent>
    )

  const isBidFlow = modalPayload?.tradeActionType === TradeActionType.Bid
  const isAcceptFlow = modalPayload?.tradeActionType === TradeActionType.Accept
  const isSellFlow = modalPayload?.tradeActionType === TradeActionType.Sell
  const isBuyFlow = modalPayload?.tradeActionType === TradeActionType.Buy
  const isImoFlow = modalPayload?.isImoFlow
  const isPartialBuyOrder = modalPayload?.totalShares !== modalPayload?.resolvedShares
  const isBulkBuyFlow = modalPayload?.isBulkMarketFlow && isPartialBuyOrder

  const handleShare = async () => {
    ampli.openSocialShareModal({
      triggeringAction: TriggeringAction.OrderPlacedModal,
    })
    renderModal({
      content: <SocialShare />,
      modalKey: ModalKey.SocialShare,
      size: ModalSize.Medium,
      noPadding: false,
      closeOnClickingOutside: true,
    })
  }

  const navigateToMyAssets = () => {
    setDisplayModal(false)
    switch (modalPayload?.orderType) {
      case OrderType.ImoBid:
      case OrderType.LimitBid:
      case OrderType.LimitAsk:
        navigate(
          `${Paths.MY_ASSETS.MAIN}/${Paths.MY_ASSETS.OVERVIEW}?${QUERY_SCROLL}=${AccordionType.OpenOrders}`,
        )
        break
      case OrderType.Bid:
        navigate(
          `${Paths.MY_ASSETS.MAIN}/${Paths.MY_ASSETS.OVERVIEW}?${QUERY_SCROLL}=${AccordionType.OffersMade}`,
        )
        break
      default:
        navigate(`${Paths.MY_ASSETS.MAIN}/${Paths.MY_ASSETS.OVERVIEW}`)
        break
    }
  }

  const getHeaderTitle = () => {
    if (isImoFlow) return t('imo.title')
    if (isBidFlow) return t('offerTitle')
    if (isAcceptFlow) return t('acceptTitle')
    if (isSellFlow) return t('sellOrder.title')
    if (isBuyFlow) {
      if (modalPayload?.orderType === OrderType.LimitBid) return t('limitBuyOrder.title')
      return t('buyOrder.title')
    }
  }

  const getBodyText = () => {
    if (isImoFlow) return t('imo.body')
    if (isSellFlow) return t('sellOrder.body')
    return ''
  }

  const getSecondaryButtonLabel = () => {
    if (isBulkBuyFlow) return t('buttons.bulkBuyFlow')
    else if (currentPage.includes(Paths.MY_ASSETS.MAIN)) return t('buttons.close')
    else return t('buttons.myAssets')
  }

  return (
    <ModalContent className={styles.orderCompletedContainer}>
      <div className={styles.headerContainer}>
        <h1 className={styles.title}>{getHeaderTitle()}</h1>
        {modalPayload?.isBulkMarketFlow && (
          <p className={styles.sharesCount}>
            {t('orderDetails', {
              totalShares: modalPayload?.totalShares,
              resolvedShares: modalPayload?.resolvedShares,
            })}
          </p>
        )}
        <div className={styles.imageContainer}>
          <img className={styles.albumCover} src={master?.assets.thumbnail} alt='Album art cover' />
          <Lottie
            className={styles.orderCompletedAnimation}
            animationData={OrderCompletedAnimation}
            loop={false}
          />
        </div>
        <p className={styles.masterInfo}>
          <span className={styles.masterName}>{master.name}</span>
          <span className={styles.artistName}>{`${t('by')} ${master.artist.name}`}</span>
        </p>
        {(isImoFlow || isSellFlow) && <p className={styles.bodyText}>{getBodyText()}</p>}
      </div>
      <div className={styles.buttonContainer}>
        <Button width='fluid' variant='primary' onClick={handleShare}>
          {t('buttons.share')}
        </Button>
        <Button width='fluid' variant='ghost' onClick={navigateToMyAssets}>
          {getSecondaryButtonLabel()}
        </Button>
      </div>
    </ModalContent>
  )
}
