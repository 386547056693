import { ErrorMessage, Field } from 'formik'

import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg'
import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type Option = {
  value: string
  label: string
}

type SelectProps = {
  name: string
  label?: string
  options: Option[]
  placeholder?: string
  currentValue: string
  autoComplete?: string
  xAutocompletetype?: string
  customClassName?: string
  noErrorMessage?: boolean
  onClick?: () => void
}

export const Select = (props: SelectProps) => {
  const {
    label,
    options,
    name,
    placeholder,
    currentValue,
    autoComplete,
    customClassName,
    noErrorMessage = false,
    onClick,
  } = props
  const { t } = useLocalization('components.collections.form.select')

  return (
    <div className={styles.menuContainer}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`${styles.fieldContainer} ${customClassName}`}>
        <Field
          onClick={onClick}
          id={name}
          name={name}
          as='select'
          className={`${styles.menu} ${!currentValue ? styles.placeholder : ''}`}
          autoComplete={autoComplete}
          x-autocompletetype={autoComplete}
        >
          <option value='' disabled>
            {placeholder ?? t('placeholder')}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value} className={styles.option}>
              {option.label}
            </option>
          ))}
        </Field>
        {!noErrorMessage && (
          <ErrorMessage name={name}>
            {(message: string) => <span className={styles.errorMessage}>{message}</span>}
          </ErrorMessage>
        )}
        <div className={styles.icon}>
          <ChevronDown />
        </div>
      </div>
    </div>
  )
}
