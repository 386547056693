import { ReactNode } from 'react'

import styles from './styles.module.scss'

type SectionDescriptionProps = {
  children: ReactNode
  className?: string
}

export const SectionDescription = ({ children, className }: SectionDescriptionProps) => {
  return <p className={`${styles.sectionDescription} ${className}`}>{children}</p>
}
