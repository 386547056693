import { MasterWithEarningsRaw } from 'types/master'

import { sanitizeMasterWithEarningsFromRaw } from '../@santizers/master'
import { useMxApi } from '../mx-api'

export const useFetchListedSongs = () => {
  const [{ data, loading, error }] = useMxApi<MasterWithEarningsRaw[]>({
    method: 'GET',
    url: `/auth-users/me/masters`,
  })

  return {
    data: data ? data.map(sanitizeMasterWithEarningsFromRaw) : [],
    loading,
    error,
  }
}
