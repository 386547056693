import { ReactElement } from 'react'
import { Helmet } from 'react-helmet-async'

type HeaderTagsProps = {
  title?: string
  description?: string
  keywords?: string
  pageUrl?: string
  imageUrl?: string
}

export const HeaderTags = ({
  title,
  description,
  keywords,
  pageUrl,
  imageUrl,
}: HeaderTagsProps) => {
  const seoTags: ReactElement[] = []

  const addLinkTag = (rel: string, href: string) => {
    seoTags.push(<link rel={rel} href={href} key={rel} />)
  }

  const addNameMetaTag = (name: string, content: string) => {
    seoTags.push(<meta name={name} content={content} key={name} />)
  }

  const addPropertyMetaTag = (property: string, content: string) => {
    seoTags.push(<meta property={property} content={content} key={property} />)
  }

  if (title) {
    addPropertyMetaTag('og:title', title)
    addNameMetaTag('twitter:title', title)
  }

  if (description) {
    addPropertyMetaTag('og:description', description)
    addNameMetaTag('description', description)
    addNameMetaTag('twitter:description', description)
  }

  if (keywords) {
    addNameMetaTag('keywords', keywords)
  }

  if (pageUrl) {
    addLinkTag('canonical', pageUrl)
    addPropertyMetaTag('og:url', pageUrl)
    addNameMetaTag('twitter:url', pageUrl)
  }

  if (imageUrl) {
    addPropertyMetaTag('og:image', imageUrl)
    addNameMetaTag('twitter:image', imageUrl)
  }

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {seoTags}
    </Helmet>
  )
}
