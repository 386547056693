export type KycStepProps = {
  steps: Steps
  setCurrentStep: (step: Step | undefined) => void
  selectedDocumentType: DocumentType | undefined
  setSelectedDocumentType: (documentType: DocumentType) => void
  setError: (error: boolean) => void
  onSkip: () => void
}

export type Step = {
  title: string
  component: (object: KycStepProps) => JSX.Element
}

export type Steps = {
  [key: string]: Step
}

export enum DocumentType {
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  NATIONAL_ID = 'NATIONAL_ID',
}
