import { useLocalization } from 'locales/i18n'
import { useMasterLoadable } from 'ui/@store/master'

import styles from './styles.module.scss'

type OrderHeaderProps = {
  title: string
  accountBalance?: number
}

export const OrderHeader = ({ title, accountBalance }: OrderHeaderProps) => {
  const { t, f } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation',
  )
  const { master } = useMasterLoadable()

  return (
    <div className={styles.headerContainer}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>{title}</h1>
        {accountBalance && (
          <p className={styles.subtitle}>{`${t('subtitle')} ${
            accountBalance !== undefined ? f.currency(accountBalance) : '-'
          }`}</p>
        )}
      </div>
      <div className={styles.songInfoContainer}>
        <img className={styles.albumCover} src={master?.assets.thumbnail} alt='Album art cover' />

        <div className={styles.songInfo}>
          <h2 className={styles.songTitle}>{master?.name}</h2>
          <p className={styles.artistName}>{master?.artist.name}</p>
        </div>
      </div>
    </div>
  )
}
