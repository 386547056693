import { Capacitor } from '@capacitor/core'

export enum Platform {
  Web = 'web',
  Android = 'android',
  IOS = 'ios',
}

export const usePlatform = () => {
  const currentPlatform = Capacitor.getPlatform()

  const isWeb = currentPlatform === Platform.Web

  const isAndroid = currentPlatform === Platform.Android
  const isIOS = currentPlatform === Platform.IOS

  const isNative = isIOS || isAndroid

  return {
    isWeb,
    isAndroid,
    isIOS,
    isNative,
  }
}
