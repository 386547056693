import { useLocalization } from 'locales/i18n'
import { MasterOwnersWithAvatar } from 'types/master'
import { AvatarGroup } from 'ui/@library/data-display/avatar-group'
import { getOwnersFromOwnerList } from 'ui/master/utils'

import styles from './styles.module.scss'

type MasterOwnersProps = {
  masterOwners: MasterOwnersWithAvatar
  className?: string
}

export const MasterOwners = ({ masterOwners, className }: MasterOwnersProps) => {
  const { t } = useLocalization('exchange.spotlight')
  const translationKey = masterOwners.length > 1 ? 'hero.bringYou' : 'hero.bringsYou'

  if (!masterOwners || !masterOwners.length) return null

  return (
    <div className={`${styles.avatarWrapper} ${className}`}>
      <AvatarGroup masterOwners={masterOwners} />
      <p className={styles.owner}>
        {t(translationKey, {
          owner: getOwnersFromOwnerList(masterOwners),
        })}
      </p>
    </div>
  )
}
