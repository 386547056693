import { ampli } from 'ampli'
import { useLocalization } from 'locales/i18n'
import { useDiscoverLoadable } from 'ui/@store/discover'

import { BrowseSongsCard } from './@components'
import styles from './styles.module.scss'

export const BrowseSongs = () => {
  const { t } = useLocalization('exchange.spotlight')
  const { discover } = useDiscoverLoadable()
  const { masters } = discover

  if (masters.length === 0) return null

  return (
    <div className={styles.browseSongsContainer}>
      <h1 className={styles.sectionTitle}>{t('title.browseSongs')}</h1>
      <div className={styles.browseSongCardsGroup}>
        {masters.map((master, index) => (
          <BrowseSongsCard
            key={master.uuid}
            master={master}
            onCardClick={() =>
              ampli.clickBrowseCard({
                masterName: master.name,
                masterUuid: master.uuid,
                index,
              })
            }
          />
        ))}
      </div>
    </div>
  )
}
