import { useLocalization } from 'locales/i18n'
import { OrderSide } from 'types/order'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'

import { OpenOrdersTableColumns } from '../../orders-and-offers/@components/open-orders-table/types'
import { Fraction } from '../../royalty-shares-table/types'

import styles from './styles.module.scss'

export type ListFractionsModalProps = OpenOrdersTableColumns

export const ListFractionsModal = () => {
  const { t } = useLocalization('myAssets.overview.orderDetailsModal')
  const { payload: modalPayload } = useGlobalModal()

  return (
    <ModalContent className={styles.orderTypesContainer}>
      <h1 className={styles.heading}>{t('heading')}</h1>
      {modalPayload?.type === OrderSide.ImoBid ? (
        <p className={styles.subHeading}>{t('imoHeading')}</p>
      ) : null}
      <div className={styles.listContainer}>
        {modalPayload?.shares?.length ? (
          modalPayload?.shares?.map((item: Fraction) => {
            return (
              <div className={styles.fractionWrapper} key={item.uuid}>
                <img src={modalPayload.thumbnail} alt='' className={styles.thumbnailImg} />
                <p>
                  <span className={styles.title}>{modalPayload.masterName}</span>{' '}
                  <span className={styles.id}>#{item.tokenId}</span>
                </p>
                <p className={styles.price}>{modalPayload?.price}</p>
              </div>
            )
          })
        ) : modalPayload?.shares?.length === 0 ? (
          <div className={styles.fractionWrapper}>
            <img src={modalPayload.thumbnail} alt='' className={styles.thumbnailImg} />
            <p className={styles.title}>{modalPayload.masterName}</p>
            <p className={styles.price}>x{modalPayload.quantity}</p>
          </div>
        ) : (
          <p className={styles.emptyState}>{t('emptyState')}</p>
        )}
      </div>
    </ModalContent>
  )
}
