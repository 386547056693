import { useNavigate } from 'react-router-dom'

import { ReactComponent as ImoEmptyStateIllustration } from 'assets/svgs/imo-empty-state.svg'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'

import styles from './styles.module.scss'

export const ImoEmptyState = () => {
  const { t } = useLocalization('exchange.emptyState.imo')
  const navigate = useNavigate()

  const onClickExchange = () => {
    navigate(`${Paths.EXCHANGE.MAIN}/${Paths.EXCHANGE.BROWSE_SONGS}`)
  }

  return (
    <div className={styles.imoEmptyStateContainer}>
      <ImoEmptyStateIllustration />
      <div className={styles.content}>
        <h1 className={styles.sectionTitle}>{t('title')}</h1>
        <p className={styles.subtitle}>{t('subtitle')}</p>
        <Button variant='primary' onClick={onClickExchange}>
          {t('cta')}
        </Button>
      </div>
    </div>
  )
}
