import { useState } from 'react'

import { ReactComponent as DocumentIcon } from 'assets/icons/document-outlined.svg'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { useMasterLoadable } from 'ui/@store/master'

import styles from './styles.module.scss'

export const InvestmentOpportunity = () => {
  const { master } = useMasterLoadable()
  const { t } = useLocalization('master.overviewTab.information.investmentOpportunity')

  const [clamped, setClamped] = useState(true)

  if (!master?.investmentOpportunity) return null

  return (
    <>
      <div className={styles.investmentOpportunityContainer}>
        <h1 className={styles.title}>{t('title')}</h1>
        <p>
          <span>
            {clamped
              ? master.investmentOpportunity.slice(0, 320) + '... '
              : master.investmentOpportunity}
          </span>
          {clamped && (
            <span onClick={() => setClamped(false)} className={styles.readMore}>
              {t('readMore')}
            </span>
          )}
        </p>
        {!clamped && master?.documents?.whitePaper && (
          <a href={master.documents.whitePaper} target='_blank' rel='noreferrer'>
            <Button variant='secondary' className={styles.whitePaperButton}>
              <DocumentIcon />
              {t('whitePaper')}
            </Button>
          </a>
        )}
      </div>
    </>
  )
}
