import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useBankAccountsLoadable } from 'ui/@store/bank-accounts'

import { AddIban } from '../add-iban'
import { Withdrawal } from '../withdrawal'

import styles from './styles.module.scss'

export const Error = () => {
  const { t } = useLocalization('withdrawFunds.addIban')
  const { renderModal } = useGlobalModal()
  const { bankAccounts } = useBankAccountsLoadable()

  const handleRetryAction = () => {
    if (bankAccounts.length) {
      return renderModal({ modalKey: ModalKey.Withdrawal, content: <Withdrawal /> })
    } else {
      renderModal({ modalKey: ModalKey.AddIban, content: <AddIban /> })
    }
  }

  return (
    <ModalContent className={styles.errorContainer}>
      <h1 className={styles.title}>{t('errorTitle')}</h1>
      <span className={styles.errorDescription}>{t('errorDescription')}</span>
      <Button width='fluid' onClick={handleRetryAction} className={styles.retryButton}>
        {t('retry')}
      </Button>
    </ModalContent>
  )
}
