import { Spinner } from 'ui/@library/feedback/spinner'
import { useTrader } from 'ui/master/@components/master-overview/@components/banner-trader/use-trader'

import { AccountBalance } from './@components/account-balance'
import { TraderError } from './@components/trader-error'
import { TraderHeader } from './@components/trader-header'
import styles from './styles.module.scss'
import { getTraderToRender } from './utils'

export const Trader = () => {
  const { isImo, tradeActionType, orderType, isLoading, hasError } = useTrader()

  if (isLoading) return <Spinner className={styles.traderSpinner} />

  if (hasError && !isImo) return <TraderError />

  return (
    <>
      <TraderHeader />
      <div className={styles.traderContainer}>
        <div className={styles.traderWrapper}>
          {getTraderToRender({ isImo, orderType, tradeActionType })}
        </div>
        <AccountBalance />
      </div>
    </>
  )
}
