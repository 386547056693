import { Trans } from 'react-i18next'

import { LandingPageRoutes } from 'constants/external-links'
import { useLandingPageLinks } from 'ui/@hooks/use-landing-page-links'

import styles from './styles.module.scss'

type TermsAndConditionsProps = {
  translationKey?: string
}

export const TermsAndConditions = ({ translationKey }: TermsAndConditionsProps) => {
  const generateLandingPageLink = useLandingPageLinks()

  /* eslint-disable */
  const termsAndConditionsList = [
    <a
      className={styles.link}
      href={generateLandingPageLink(LandingPageRoutes.TERMS_AND_CONDITIONS)}
      key='service'
      target='_blank'
      rel='noopener noreferrer'
    />,
    <a
      className={styles.link}
      href={generateLandingPageLink(LandingPageRoutes.PRIVACY_POLICY)}
      key='policy'
      target='_blank'
      rel='noopener noreferrer'
    />,
  ]
  /* eslint-enable */

  return (
    <Trans
      i18nKey={translationKey || 'components.termsAndConditions'}
      components={termsAndConditionsList}
    />
  )
}
