import { useLocalization } from 'locales/i18n'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { PaymentMethodModal } from 'ui/@modals/add-funds'

import {
  SectionContainer,
  SectionHeader,
  SectionTitle,
  SectionButton,
  SectionDescription,
} from '../section'

export const AddFunds = () => {
  const { t } = useLocalization('account.addFunds')
  const { renderModal } = useGlobalModal()

  const handleAddFunds = () => {
    renderModal({ modalKey: ModalKey.PaymentMethod, content: <PaymentMethodModal /> })
  }

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{t('title')}</SectionTitle>
        <SectionButton onClick={handleAddFunds}>{t('buttonText')}</SectionButton>
      </SectionHeader>
      <SectionDescription>{t('description')}</SectionDescription>
    </SectionContainer>
  )
}
