import { AuthUserMasterSharesRaw } from 'types/auth-user'

import { sanitizeAuthUserMasterSharesFromRaw } from '../@santizers/master'
import { MxApi } from '../mx-api'

export const fetchAuthUserMasterShares = async () => {
  const { data } = await MxApi.get<AuthUserMasterSharesRaw[]>('/auth-users/me/shares')

  return data.map(sanitizeAuthUserMasterSharesFromRaw)
}
