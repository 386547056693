import { useLocalization } from 'locales/i18n'
import { Footer } from 'ui/@components/footer'
import { Navbar } from 'ui/@components/navbar'
import { HeaderTags } from 'ui/@components/route-wrapper/@components/header-tags'
import { MarqueeBanner } from 'ui/@components/route-wrapper/@components/marquee-banner'
import { GlobalModal } from 'ui/@library/layout/global-modal'

import { BottomNavbar } from '../bottom-navbar'
import { MessageBanner } from '../message-banner'

import styles from './styles.module.scss'
import { RouteWrapperProps } from './types'

export const RouteWrapperWeb = ({
  headers,
  children,
  title,
  description,
  keywords,
  pageUrl,
  imageUrl,
  allowsBackNavigation,
  noPadding = false,
}: RouteWrapperProps) => {
  const { t } = useLocalization('components')

  return (
    <div className={styles.wrapper}>
      <HeaderTags
        title={t('pageWrapperTitle', { pageTitle: title })}
        description={description}
        keywords={keywords}
        pageUrl={pageUrl}
        imageUrl={imageUrl}
      />
      <MarqueeBanner />
      <Navbar allowsBackNavigation={allowsBackNavigation} />
      <MessageBanner />
      {headers}
      <div className={`${styles.container} ${noPadding && styles.noPaddingContainer}`}>
        {children}
      </div>
      <BottomNavbar />
      <Footer />
      <GlobalModal />
    </div>
  )
}
