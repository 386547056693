import { useSearchParams } from 'react-router-dom'

import { ReactComponent as ShieldCheck } from 'assets/icons/shield-check.svg'
import { QueryParams, Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { IconWrapper } from 'ui/@library/data-display/icon-wrapper'
import { Button } from 'ui/@library/inputs/button'
import { KycStepProps } from 'ui/kyc/types'

import styles from './styles.module.scss'

export const VerifyIdentity = ({ steps, setCurrentStep, onSkip }: KycStepProps) => {
  const [searchParams] = useSearchParams()
  const isPaymentFlow = searchParams.get(QueryParams[Paths.KYC].PAYMENT_FLOW)
  const { t } = useLocalization('kyc.verifyIdentity')

  const onVerify = () => {
    setCurrentStep(steps.userAddress)
  }

  return (
    <>
      <div className={styles.verifyIdentityHeader}>
        <IconWrapper>
          <ShieldCheck />
        </IconWrapper>
        <div>
          <h2 className={styles.title}>{isPaymentFlow ? t('paymentTitle') : t('title')}</h2>
          <p className={styles.description}>
            {isPaymentFlow ? t('paymentDescription') : t('description')}
          </p>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button width='fluid' onClick={onVerify}>
          {t('continue')}
        </Button>
        <Button width='fluid' variant='ghost' onClick={onSkip}>
          {t('skip')}
        </Button>
      </div>
    </>
  )
}
