import camelcaseKeys from 'camelcase-keys'

import { AuthUserMasterSharesRaw } from 'types/auth-user'
import {
  MasterBasicRaw,
  MasterRaw,
  MasterWithEarningsRaw,
  MasterWithFractionsAndMarketRaw,
  MasterWithImoAndMarketRaw,
  MasterComingSoonRaw,
} from 'types/master'

import { sanitizeImoFromRaw } from './imo'
import { sanitizeMarketFromRaw } from './market'

export const sanitizeMasterBasicFromRaw = (raw: MasterBasicRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeMasterComingSoonFromRaw = (raw: MasterComingSoonRaw) => {
  return {
    ...camelcaseKeys(raw, { deep: true }),
    metaData: {
      ...camelcaseKeys(raw.meta_data, { deep: true }),
      releaseDate: raw.meta_data.release_date ? new Date(raw.meta_data.release_date) : null,
    },
    createdAt: new Date(raw.created_at),
  }
}

export const sanitizeAuthUserMasterSharesFromRaw = (raw: AuthUserMasterSharesRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeMasterFromRaw = (raw: MasterRaw) => {
  return {
    ...camelcaseKeys(raw, { deep: true }),
    metaData: {
      ...camelcaseKeys(raw.meta_data, { deep: true }),
      releaseDate: raw.meta_data.release_date ? new Date(raw.meta_data.release_date) : null,
    },
    createdAt: new Date(raw.created_at),
    updatedAt: raw.updated_at !== null ? new Date(raw.updated_at) : null,
  }
}

export const sanitizeMasterWithFractionsAndMarketFromRaw = (
  raw: MasterWithFractionsAndMarketRaw,
) => {
  return {
    ...camelcaseKeys(raw, { deep: true }),
    metaData: {
      ...camelcaseKeys(raw.meta_data, { deep: true }),
      releaseDate: raw.meta_data.release_date ? new Date(raw.meta_data.release_date) : null,
    },
    createdAt: new Date(raw.created_at),
    updatedAt: raw.updated_at !== null ? new Date(raw.updated_at) : null,
  }
}

export const sanitizeMasterWithImoAndMarketFromRaw = (raw: MasterWithImoAndMarketRaw) => {
  return {
    ...camelcaseKeys(raw, { deep: true }),
    metaData: {
      ...camelcaseKeys(raw.meta_data, { deep: true }),
      releaseDate: raw.meta_data.release_date ? new Date(raw.meta_data.release_date) : null,
    },
    createdAt: new Date(raw.created_at),
    updatedAt: raw.updated_at !== null ? new Date(raw.updated_at) : null,
    imo: sanitizeImoFromRaw(raw.imo),
    market: sanitizeMarketFromRaw(raw.market),
  }
}

export const sanitizeMasterWithEarningsFromRaw = (raw: MasterWithEarningsRaw) => {
  return { ...camelcaseKeys(raw, { deep: true }) }
}
