import { WithdrawalRaw, WithdrawalReturn } from 'types/bank-accounts'

import { sanitizeWithdrawalReturnFromRaw } from '../@santizers/bank-accounts'
import { MxApi } from '../mx-api'

export const createWithdrawal = async (
  debitAmount: number,
  ibanId: string,
): Promise<WithdrawalReturn> => {
  const { data } = await MxApi.post<WithdrawalRaw>('/auth-users/me/wallet/withdrawals', {
    amount: debitAmount,
    bank_account_id: ibanId,
  })

  return sanitizeWithdrawalReturnFromRaw(data)
}
