import { ShareRaw } from 'types/share'

import { sanitizeShareListFromRaw } from '../@santizers/share'
import { MxApi } from '../mx-api'

export const fetchAuthUserShareList = async (masterUuid: string) => {
  const { data } = await MxApi.get<ShareRaw[]>(`/masters/${masterUuid}/shares/me`)

  return sanitizeShareListFromRaw(data)
}
