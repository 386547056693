import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg'
import { Pill } from 'ui/@library/data-display/pill'

import styles from './styles.module.scss'

type MethodProps = {
  icon: React.ReactNode
  title: string
  description: string
  onClick: () => void
  pillText?: string | null
}

export const Method = ({ icon, title, description, onClick, pillText }: MethodProps) => {
  return (
    <div className={styles.methodContainer} onClick={onClick}>
      <div className={styles.methodIcon}>{icon}</div>
      <div className={styles.methodContent}>
        <h3 className={styles.methodTitle}>{title}</h3>
        <p className={styles.methodDescription}>{description}</p>
        {pillText && <Pill className={styles.pill} variant='info' text={pillText} />}
      </div>
      <ChevronRight className={styles.chevron} />
    </div>
  )
}
