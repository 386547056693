import { useMxApi } from '../mx-api'

export const useCreateKyc = (front?: File, back?: File) => {
  const [{ loading, error }, requestKyc] = useMxApi(
    {
      method: 'POST',
      url: `/auth-users/me/kyc`,
      data: {
        front,
        back,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    {
      manual: true,
    },
  )

  return {
    requestKyc,
    loading,
    error,
  }
}
