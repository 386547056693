import { useLocalization } from 'locales/i18n'
import { ImoStatus } from 'types/imo'
import { Timer } from 'ui/@library/utils/timer'

import styles from './styles.module.scss'

type ImoCountdownProps = {
  imoStatus: ImoStatus
  launchDate: Date
  expiryDate: Date
  className?: string
}

export const ImoCountdown = ({
  imoStatus,
  launchDate,
  expiryDate,
  className,
}: ImoCountdownProps) => {
  const { t } = useLocalization('exchange.spotlight.countdown')
  const isUpcoming = imoStatus === ImoStatus.Upcoming

  return (
    <div className={`${styles.countdownWrapper} ${className}`}>
      {imoStatus === ImoStatus.Ended && (
        <div className={styles.statusAllocatingWrapper}>
          <p className={styles.statusLabel}>{t('allocating')}</p>
        </div>
      )}

      {(imoStatus === ImoStatus.Active || isUpcoming) && (
        <div className={styles.timerContainer}>
          <div className={styles.statusLabelWrapper}>
            <p className={styles.statusLabel}>{isUpcoming ? t('startsIn') : t('endsIn')}</p>
          </div>
          <div className={styles.timerWrapper}>
            <Timer date={isUpcoming ? launchDate : expiryDate} className={styles.timer} />
          </div>
        </div>
      )}
    </div>
  )
}
