import { useLocation } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { ImoStatus } from 'types/imo'
import { useMasterLoadable } from 'ui/@store/master'

import { ImoEndedBanner } from './@components/imo-ended-banner'
import { StickyTradingBar } from './@components/sticky-trading-bar'

export function MobileTrader() {
  const { master } = useMasterLoadable()
  const location = useLocation()
  const isMasterPage = location.pathname.includes(Paths.MASTER.MAIN)

  if (!isMasterPage) return null

  if (master?.imo.status === ImoStatus.Upcoming && !master?.isOwner) {
    return null
  } else if (master?.imo.status === ImoStatus.Ended && !master?.isOwner) {
    return <ImoEndedBanner />
  }

  return <StickyTradingBar />
}
