import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'
import { Spinner } from 'ui/@library/feedback/spinner'
import { useMasterStatusLoadable } from 'ui/@store/master'
import { useRecentTradesLoadable } from 'ui/@store/recent-trades'
import { MasterStatus } from 'ui/master/types'

import styles from './styles.module.scss'

export const RecentTrades = () => {
  const { t, f } = useLocalization('master.overviewTab.sidebar.recentTrades')
  const { recentTrades, loading } = useRecentTradesLoadable({
    forceRefetch: true,
  })
  const { masterStatus } = useMasterStatusLoadable()

  if (masterStatus !== MasterStatus.Market) return null

  return (
    <div className={styles.recentTradesContainer}>
      <h1 className={styles.title}>{t('title')}</h1>

      {!loading ? (
        <div className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <p className={styles.date}>{t('tableHeader.date')}</p>
            <p className={styles.quantity}>{t('tableHeader.quantity')}</p>
            <p className={styles.price}>{t('tableHeader.price')}</p>
          </div>
          {recentTrades?.map((item, index) => (
            <div
              className={styles.tableRow}
              key={`${item.date}-${item.price}-${item.quantity}-${index}`}
            >
              <p className={styles.date}>
                {f.date(new Date(item.date), TextFormats.DATE.DATE_WITH_MONTH_AND_TIME)}
              </p>
              <p className={styles.quantity}>{item.quantity}</p>
              <p className={styles.price}>{f.currency(item.price)}</p>
            </div>
          ))}
        </div>
      ) : (
        <Spinner className={styles.spinner} width='fluid' />
      )}
    </div>
  )
}
