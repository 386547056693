import { useLocalization } from 'locales/i18n'
import { ModalContent } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

export const TradingCommissionInfo = () => {
  const { t } = useLocalization('modals.tradingCommissionInfo')

  return (
    <ModalContent className={styles.tradingCommissionInfoContainer}>
      <h1 className={styles.header}>{t('header')}</h1>
      <div className={styles.info}>
        <p className={styles.subtitle}>{t('earningsPayoutFrequency')}</p>
        <p className={styles.subtitle}>{t('potentialAccountDiscrepancy')}</p>
      </div>
    </ModalContent>
  )
}
