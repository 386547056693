import { ReactComponent as ShieldCross } from 'assets/icons/shield-cross.svg'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'

import styles from './styles.module.scss'

type AddCardErrorProps = {
  onRetry: () => void
}

export const AddCardError = ({ onRetry }: AddCardErrorProps) => {
  const { t } = useLocalization('addFunds.addCardError')

  return (
    <div className={styles.container}>
      <div className={styles.topWrapper}>
        <div className={styles.iconWrapper}>
          <ShieldCross className={styles.shieldIcon} />
        </div>
        <h2 className={styles.title}>{t('title')}</h2>
        <p className={styles.description}>{t('description')}</p>
      </div>
      <Button onClick={onRetry} className={styles.button}>
        {t('buttonText')}
      </Button>
    </div>
  )
}
