import { useLocalization } from 'locales/i18n'
import { Loader } from 'ui/@library/feedback/loader'
import { useMasterLoadable } from 'ui/@store/master'

import { Banner } from './@components/banner'

export const BannerNoTradingForMasterCustodian = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.bannerNoTrading')
  const { master, loading } = useMasterLoadable()

  if (loading && !master) return <Loader />

  return <Banner title={t('title')} logo={master?.assets.logo} description={t('description')} />
}
