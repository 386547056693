import { useLocalization } from 'locales/i18n'

type ServiceFeeProps = {
  title: string
  serviceFee: number
}

export const ServiceFee = ({ title, serviceFee }: ServiceFeeProps) => {
  const { f } = useLocalization()
  return (
    <li>
      <span>{title}</span>
      <span>{f.currency(serviceFee)}</span>
    </li>
  )
}
