import { createColumnHelper, ColumnDef, AccessorFn, CellContext } from '@tanstack/react-table'
import { useMemo } from 'react'

import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type ColumnDefinition<TData> = {
  id: string
  label?: string
  className?: string
  isDisplayColumn?: boolean
  cell?: (info: CellContext<TData, unknown>) => JSX.Element | string
}

type UseColumnDefinitionProps<T extends object> = {
  tableData: T[]
  styles: {
    readonly [key: string]: string
  }
  translationKey: string
}

export const useColumnHelper = <TData extends object>(
  columnDefinitions: ColumnDefinition<TData>[],
) => {
  const columnHelper = createColumnHelper<TData>()

  // https://github.com/TanStack/table/issues/4241
  // eslint-disable-next-line
  const columns: ColumnDef<TData, any>[] = columnDefinitions.map((columnDefinition) => {
    const cellClassName = columnDefinition.className
      ? `${styles.td} ${columnDefinition.className}`
      : styles.td
    const headerClassName = columnDefinition.className
      ? `${styles.th} ${columnDefinition.className}`
      : styles.th

    return columnDefinition.isDisplayColumn
      ? columnHelper.display({
          id: columnDefinition.id,
          header: (header) => (
            <div
              key={`${header.header.id}`}
              className={headerClassName}
              onClick={header.column.getToggleSortingHandler()}
            >
              <span
                className={
                  header.column.getIsSorted()
                    ? header.column.getIsSorted() === 'asc'
                      ? styles.asc
                      : styles.dsc
                    : undefined
                }
              >
                {columnDefinition.label}
              </span>
            </div>
          ),
          cell: (info) => (
            <div key={info.cell.id} className={cellClassName}>
              {columnDefinition.cell && columnDefinition.cell(info)}
            </div>
          ),
        })
      : columnHelper.accessor(columnDefinition.id as unknown as AccessorFn<TData>, {
          id: columnDefinition.id,
          header: (header) => (
            <div
              key={`${header.header.id}`}
              className={headerClassName}
              onClick={header.column.getToggleSortingHandler()}
            >
              <span
                className={
                  header.column.getIsSorted()
                    ? header.column.getIsSorted() === 'asc'
                      ? styles.asc
                      : styles.dsc
                    : undefined
                }
              >
                {columnDefinition.label}
              </span>
            </div>
          ),
          cell: (info) => (
            <div key={info.cell.id} className={cellClassName}>
              {columnDefinition.cell ? columnDefinition.cell(info) : info.getValue()}
            </div>
          ),
        })
  })

  return columns
}

export const useColumnDefinition = <TData extends object>({
  tableData,
  styles,
  translationKey,
}: UseColumnDefinitionProps<TData>): ColumnDefinition<TData>[] => {
  const { t } = useLocalization()

  const columnDefinition = useMemo(() => {
    if (tableData.length === 0 || !tableData[0]) {
      return []
    }

    return Object.keys(tableData[0]).map((column) => ({
      id: column,
      label: t(`${translationKey}.${column}`),
      className: styles[column] || '',
    }))
    // eslint-disable-next-line
  }, [tableData, styles, translationKey])

  return columnDefinition
}
