import { RoyaltyPayoutDataRaw } from 'types/royalty-payouts'

import { sanitizeRoyaltyPayoutListFromRaw } from '../@santizers/royalty-payouts'
import { MxApi } from '../mx-api'

export const fetchRoyaltyPayouts = async (masterUuid?: string) => {
  const { data } = await MxApi.get<RoyaltyPayoutDataRaw[]>(`/masters/${masterUuid}/royalty-payouts`)

  return sanitizeRoyaltyPayoutListFromRaw(data)
}
