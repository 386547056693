import camelcaseKeys from 'camelcase-keys'

import { RoyaltyPayoutDataRaw, RoyaltyPayoutData } from 'types/royalty-payouts'

export const sanitizeRoyaltyPayoutListFromRaw = (
  rawList: RoyaltyPayoutDataRaw[],
): RoyaltyPayoutData[] => {
  return rawList.map(sanitizeRoyaltyPayoutFromRaw)
}

export const sanitizeRoyaltyPayoutFromRaw = (raw: RoyaltyPayoutDataRaw) => {
  return {
    ...camelcaseKeys(raw, { deep: true }),
    payoutAt: raw.payout_at ? new Date(raw.payout_at * 1000) : null,
    timestamp: new Date(raw.timestamp * 1000),
  }
}
