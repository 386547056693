import Marquee from 'react-fast-marquee'

import { useLocalization } from 'locales/i18n'
import { useAppEnvironment } from 'ui/@hooks/use-app-environment'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'

import styles from './styles.module.scss'

export const MarqueeBanner = () => {
  const { isAuthenticated } = useMxAuth()
  const { t } = useLocalization('components.marqueeBanner')
  const { isProduction } = useAppEnvironment()

  if (isAuthenticated) {
    return null
  }

  if (isProduction) {
    return null
  }

  return (
    <div className={styles.marqueeWrapper}>
      <Marquee speed={60} autoFill>
        <p className={styles.marqueeBannerText}>{t('internalPreview')}</p>
      </Marquee>
    </div>
  )
}
