import {
  sanitizeImoFromRaw,
  sanitizeImoWithMasterFromRaw,
} from 'repositories/mx-api/@santizers/imo'

import { MasterRaw } from './master'

export enum ImoStatus {
  Upcoming = 'upcoming',
  Active = 'active',
  Ended = 'ended',
  Completed = 'completed',
}

export const ImoStatusOrder = {
  [ImoStatus.Active]: 1,
  [ImoStatus.Upcoming]: 2,
  [ImoStatus.Ended]: 3,
  [ImoStatus.Completed]: 4,
}

export type ImoRaw = {
  uuid: string
  status: ImoStatus
  launch_date: string | null
  price: number
  max_per_user: number
  expiry_date: string | null
  max_available_shares: number
  is_sold_out: boolean
}

export type Imo = ReturnType<typeof sanitizeImoFromRaw>

export type ImoWithMasterRaw = ImoRaw & {
  master: MasterRaw
}

export type ImoWithMaster = ReturnType<typeof sanitizeImoWithMasterFromRaw>
