type Country = {
  name: string
  nationality: string
  code: string
}

export const SupportedCountries: Country[] = [
  {
    name: 'Austria',
    nationality: 'Austrian',
    code: 'AT',
  },
  {
    name: 'Belgium',
    nationality: 'Belgian',
    code: 'BE',
  },
  {
    name: 'Bulgaria',
    nationality: 'Bulgarian',
    code: 'BG',
  },
  {
    name: 'Switzerland',
    nationality: 'Swiss',
    code: 'CH',
  },
  {
    name: 'Cyprus',
    nationality: 'Cypriot',
    code: 'CY',
  },
  {
    name: 'Czechia',
    nationality: 'Czech',
    code: 'CZ',
  },
  {
    name: 'Germany',
    nationality: 'German',
    code: 'DE',
  },
  {
    name: 'Denmark',
    nationality: 'Danish',
    code: 'DK',
  },
  {
    name: 'Estonia',
    nationality: 'Estonian',
    code: 'EE',
  },
  {
    name: 'Spain',
    nationality: 'Spanish',
    code: 'ES',
  },
  {
    name: 'Finland',
    nationality: 'Finnish',
    code: 'FI',
  },
  {
    name: 'France',
    nationality: 'French',
    code: 'FR',
  },
  {
    name: 'Greece',
    nationality: 'Greek',
    code: 'GR',
  },
  {
    name: 'Croatia',
    nationality: 'Croatian',
    code: 'HR',
  },
  {
    name: 'Hungary',
    nationality: 'Hungarian',
    code: 'HU',
  },
  {
    name: 'Ireland',
    nationality: 'Irish',
    code: 'IE',
  },
  {
    name: 'Iceland',
    nationality: 'Icelandic',
    code: 'IS',
  },
  {
    name: 'Italy',
    nationality: 'Italian',
    code: 'IT',
  },
  {
    name: 'Liechtenstein',
    nationality: 'Liechtensteinian',
    code: 'LI',
  },
  {
    name: 'Lithuania',
    nationality: 'Lithuanian',
    code: 'LT',
  },
  {
    name: 'Luxembourg',
    nationality: 'Luxembourger',
    code: 'LU',
  },
  {
    name: 'Latvia',
    nationality: 'Latvian',
    code: 'LV',
  },
  {
    name: 'Malta',
    nationality: 'Maltese',
    code: 'MT',
  },
  {
    name: 'Netherlands',
    nationality: 'Dutch',
    code: 'NL',
  },
  {
    name: 'Norway',
    nationality: 'Norwegian',
    code: 'NO',
  },
  {
    name: 'Poland',
    nationality: 'Polish',
    code: 'PL',
  },
  {
    name: 'Portugal',
    nationality: 'Portuguese',
    code: 'PT',
  },
  {
    name: 'Romania',
    nationality: 'Romanian',
    code: 'RO',
  },
  {
    name: 'Sweden',
    nationality: 'Swedish',
    code: 'SE',
  },
  {
    name: 'Slovenia',
    nationality: 'Slovenian',
    code: 'SI',
  },
  {
    name: 'Slovakia',
    nationality: 'Slovak',
    code: 'SK',
  },
  {
    name: 'United Kingdom',
    nationality: 'British',
    code: 'GB',
  },
]
