import { useState } from 'react'
import { atom, useRecoilState, useResetRecoilState } from 'recoil'

import { createCardValidation as createCardValidationRequest } from 'repositories/mx-api'
import { fetchCardValidation } from 'repositories/mx-api/queries/fetch-card-validation'
import { CardValidation } from 'types/card'

import { selectedCardState } from './cards'

export const cardValidationState = atom<CardValidation | null>({
  key: 'cardValidation',
  default: null,
})

type UseCardValidationProps = {
  onError?: () => void
}

export const useCardValidation = ({ onError }: UseCardValidationProps = {}) => {
  const [selectedCard] = useRecoilState(selectedCardState)
  const [cardValidation, setCardValidation] = useRecoilState(cardValidationState)
  const [loading, setLoading] = useState(false)

  const resetState = useResetRecoilState(cardValidationState)

  const handleError = (error: unknown) => {
    if (onError) {
      onError()
    } else {
      throw error
    }
  }

  const createCardValidation = async (card: string) => {
    setLoading(true)
    try {
      const cardValidationRes = await createCardValidationRequest(card)
      setCardValidation(cardValidationRes)
      return cardValidationRes
    } catch (error) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  }

  const refetchCardValidation = async () => {
    if (!cardValidation?.id) {
      handleError(new Error('Card validation not found'))
      return
    }
    if (selectedCard) {
      setLoading(true)
      try {
        const updatedCardValidation = await fetchCardValidation(cardValidation.id, selectedCard.id)
        setCardValidation(updatedCardValidation)
        return updatedCardValidation
      } catch (error) {
        handleError(error)
      } finally {
        setLoading(false)
      }
    }
  }

  return {
    cardValidation,
    loading,
    createCardValidation,
    refetchCardValidation,
    resetState,
  }
}
