import { MarketOverview, MarketOverviewRaw } from 'types/market'

import { sanitizeMarketOverviewFromRaw } from '../@santizers/market'
import { MxApi } from '../mx-api'

export const fetchMarketOverview = async (masterUuid: string): Promise<MarketOverview> => {
  const { data } = await MxApi.get<MarketOverviewRaw>(`/masters/${masterUuid}/market/overview`)

  return sanitizeMarketOverviewFromRaw(data)
}
