import { Paths } from 'constants/paths'
import { useAuthUserLoadable, useOnboardingChecklist } from 'ui/@store/auth-user'
import { OnboardingStepIdentifier } from 'ui/onboarding'

import { useMxAuth } from './use-mx-auth'

export const useAuthUserOnboardingStatus = () => {
  const { isEarlyAccessUser } = useAuthUserLoadable()
  const { isAuthenticated } = useMxAuth()
  const { onboardingChecklist } = useOnboardingChecklist()

  if (!onboardingChecklist) return { loading: isAuthenticated, isOnboardingFinished: false }

  let onboardingChecklistUpdated = onboardingChecklist
  if (!isEarlyAccessUser) {
    onboardingChecklistUpdated = {
      ...onboardingChecklist,
      [OnboardingStepIdentifier.ADD_CARD]: true,
    }
  }

  const [firstUnfinishedStepKey, firstUnfinishedStep] =
    Object.entries(OnboardingStepIdentifier).find(
      ([, stepId]) => onboardingChecklistUpdated[stepId] === false,
    ) || []

  const firstUnfinishedStepRoute =
    Paths.ONBOARDING[firstUnfinishedStepKey as keyof typeof Paths.ONBOARDING]

  return {
    loading: false,
    isOnboardingFinished: !firstUnfinishedStepRoute,
    firstUnfinishedStep,
    firstUnfinishedStepRoute,
  }
}
