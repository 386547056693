import styles from './styles.module.scss'

type SpinnerProps = {
  className?: string
  color?: 'primary' | 'black' | 'white'
  width?: 'fixed' | 'fluid'
}

export const Spinner = ({ className, width, color = 'black' }: SpinnerProps) => {
  const colorClass = styles[`${color}Spinner`]
  const sizeClass = width === 'fluid' ? styles.spinnerFill : styles.spinnerContain

  return (
    <div className={`${className} ${sizeClass}`}>
      <div className={`${styles.spinner} ${colorClass}`} />
    </div>
  )
}
