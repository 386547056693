import { RefObject, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useLocalization } from 'locales/i18n'
import { useScrollToComponent } from 'ui/@hooks/use-scroll-to-component'
import { Loader } from 'ui/@library/feedback/loader'
import { useAuthUserMasterOffersMadeLoadable } from 'ui/@store/auth-user-masters-offers-made'
import { useAuthUserMastersOffersReceivedLoadable } from 'ui/@store/auth-user-masters-offers-received'
import { useAuthUserOpenOrdersLoadable } from 'ui/@store/auth-user-open-orders'

import { QUERY_SCROLL } from '../../constants'
import { AccordionType } from '../../types'

import { OffersMadeTable } from './@components/offers-made-table'
import { OffersReceivedTable } from './@components/offers-received-table'
import { OpenOrdersTable } from './@components/open-orders-table'
import styles from './styles.module.scss'

const SCROLL_OFFSET = 160 // in px

export const OrdersAndOffers = () => {
  const { t } = useLocalization('myAssets.overview')
  const [URLSearchParams] = useSearchParams()
  const scrollTo = useScrollToComponent()
  const scrollToSectionNew = URLSearchParams.get(QUERY_SCROLL)
  const openOrdersRef = useRef<HTMLDivElement>(null)
  const offersMadeRef = useRef<HTMLDivElement>(null)
  const offersReceivedRef = useRef<HTMLDivElement>(null)
  const [isAccordionOpen, setIsAccordionOpen] = useState('')

  const { authUserOpenOrders, loading: isOpenOrdersLoading } = useAuthUserOpenOrdersLoadable({
    forceRefetch: true,
  })
  const { authUserMasterOffersMade, loading: isOffersMadeLoading } =
    useAuthUserMasterOffersMadeLoadable({
      forceRefetch: true,
    })
  const { authUserMastersOffersReceived, loading: isOffersReceivedLoading } =
    useAuthUserMastersOffersReceivedLoadable({
      forceRefetch: true,
    })

  const hasOffersReceived =
    authUserMastersOffersReceived && authUserMastersOffersReceived.length > 0
  const hasOffersMade = authUserMasterOffersMade && authUserMasterOffersMade.length > 0
  const hasOpenOrders = authUserOpenOrders && authUserOpenOrders.length > 0

  const scrollToRef = (ref: RefObject<HTMLDivElement>, accordionType: string) => {
    ref.current && scrollTo(ref.current, SCROLL_OFFSET)
    setIsAccordionOpen(accordionType)
  }

  useEffect(() => {
    if (scrollToSectionNew) {
      switch (scrollToSectionNew) {
        case AccordionType.OffersMade:
          scrollToRef(offersMadeRef, AccordionType.OffersMade)
          break
        case AccordionType.OffersReceieved:
          scrollToRef(offersReceivedRef, AccordionType.OffersReceieved)
          break
        case AccordionType.OpenOrders:
          scrollToRef(openOrdersRef, AccordionType.OpenOrders)
          break
        default:
          break
      }
    }
    // eslint-disable-next-line
  }, [scrollToSectionNew, isOpenOrdersLoading, isOffersMadeLoading, isOffersReceivedLoading])

  if (isOpenOrdersLoading || isOffersMadeLoading || isOffersReceivedLoading) return <Loader />

  if (!hasOffersReceived && !hasOffersMade && !hasOpenOrders) {
    return <p className={styles.emptyStateMessage}>{t('emptyStateMessage.openOrders')}</p>
  }

  return (
    <div className={styles.ordersAndOffersContainer}>
      {hasOffersReceived && (
        <OffersReceivedTable
          isAccordionOpen={isAccordionOpen}
          setIsAccordionOpen={setIsAccordionOpen}
          ref={offersReceivedRef}
        />
      )}
      {hasOffersMade && (
        <OffersMadeTable
          isAccordionOpen={isAccordionOpen}
          setIsAccordionOpen={setIsAccordionOpen}
          ref={offersMadeRef}
        />
      )}
      {hasOpenOrders && (
        <OpenOrdersTable
          isAccordionOpen={isAccordionOpen}
          setIsAccordionOpen={setIsAccordionOpen}
          ref={openOrdersRef}
        />
      )}
    </div>
  )
}
