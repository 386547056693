import { ReactComponent as ShieldExclamationIcon } from 'assets/icons/shield-exclamation.svg'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { ReviewKyc } from 'ui/@modals/kyc/review-kyc'

import styles from './styles.module.scss'

export type KycTriggerModalProps = {
  title?: string
  description?: string
  button?: string
}

export const KycTriggerModal = () => {
  const { t } = useLocalization('components.kycTriggerModal')
  const { renderModal, payload } = useGlobalModal()

  const handleKYC = () => {
    renderModal({
      modalKey: ModalKey.ReviewKyc,
      content: <ReviewKyc />,
    })
  }

  return (
    <ModalContent className={styles.container}>
      <ShieldExclamationIcon />
      <div className={styles.topWrapper}>
        <h2 className={styles.title}>{payload?.title ?? t('title')}</h2>
        <p className={styles.text}>{payload?.description ?? t('description')}</p>
      </div>
      <Button onClick={handleKYC} className={styles.button}>
        {payload?.button ?? t('button')}
      </Button>
    </ModalContent>
  )
}
