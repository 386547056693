import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { AuthUserAccountAuthenticationProvider } from 'types/auth-user'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useSignInAndSignUp } from 'ui/@hooks/use-signin-and-signup'
import { ErrorWithAction } from 'ui/@library/errors/error-with-action'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'

const getTranslationKey = (
  provider: AuthUserAccountAuthenticationProvider | null,
): string | undefined => {
  switch (provider) {
    case AuthUserAccountAuthenticationProvider.GoogleSso:
      return 'googleSso'
    case AuthUserAccountAuthenticationProvider.AppleSso:
      return 'appleSso'
    case AuthUserAccountAuthenticationProvider.EmailPassword:
      return 'emailPassword'
    default:
      return 'unknown'
  }
}

export const LoginMethodConflict = () => {
  const { t } = useLocalization('feedback.auth0UserAccountConflict')
  const navigate = useNavigate()
  const { isAuthenticated } = useMxAuth()
  const { signIn } = useSignInAndSignUp()
  const location = useLocation()

  const signInMethodKey = location.state?.method as AuthUserAccountAuthenticationProvider
  const loginMethod = t(`method.${getTranslationKey(signInMethodKey)}`)

  useEffect(() => {
    if (isAuthenticated) {
      navigate(Paths.EXCHANGE.MAIN, { replace: true })
    }
    // eslint-disable-next-line
  }, [])

  if (isAuthenticated) return <FullPageLoader />

  return (
    <RouteWrapper title={t('pageTitle')}>
      <ErrorWithAction
        header={t('header', { loginMethod })}
        message={t('message', { loginMethod })}
        buttonTitle={t('retrySignIn', { loginMethod })}
        buttonOnClick={signIn}
        buttonSubtitle={t('signInAgain', { loginMethod })}
      />
    </RouteWrapper>
  )
}
