import { BaseType, Selection } from 'd3'
import { MutableRefObject, ReactNode, useEffect } from 'react'

import { useWindowSize } from 'ui/@hooks/use-window-size'

import { GraphConstants } from '../../constants'
import { GraphTransformationsType } from '../../types'
import { Tooltip } from '../tooltip'

import styles from './styles.module.scss'

type SvgCanvasProps<T extends BaseType> = {
  graphRef: MutableRefObject<SVGSVGElement | null>
  children: ReactNode
  isTooltipActive: boolean
  getTransformations: GraphTransformationsType
  graph: Selection<T, unknown, null, undefined>
}

export const SvgCanvas = <T extends BaseType>({
  children,
  graphRef,
  graph,
  isTooltipActive,
  getTransformations,
}: SvgCanvasProps<T>) => {
  const { windowSize } = useWindowSize()
  const { width: windowWidth } = windowSize
  useEffect(() => {
    if (graph) {
      graph
        .select(`.${styles.mainGroup}`)
        .attr('transform', `translate(0, ${GraphConstants.PADDING.TOP.MEDIUM})`)
    }
  }, [graph, windowWidth])

  return (
    <svg className={styles.graphCanvas} ref={graphRef}>
      <g className={styles.mainGroup}>{children}</g>
      <Tooltip
        graph={graph}
        isTooltipActive={isTooltipActive}
        getTransformations={getTransformations}
      />
    </svg>
  )
}
