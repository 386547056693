import { ShareWithOrders } from 'types/share'

export enum ActionType {
  CancelOpenOrder = 'cancel_open',
  CancelUniqueOrder = 'cancel_unique',
  Accept = 'accept',
}

export type OngoingOrder = {
  type: string
  qty: number
  price: number
  id: string
  total: number
  uuid: string
  actionType?: ActionType
  share?: ShareWithOrders
}
