import { Trans } from 'react-i18next'

import { ReactComponent as ExclamationIcon } from 'assets/icons/shield-exclamation.svg'
import { EMAILS } from 'constants/emails'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { Divider } from 'ui/@library/layout/divider'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

export type CardLimitModalProps = {
  backNavigationCallback: () => void
}

export const CardLimitModal = () => {
  const { t } = useLocalization('addFunds.cardLimit')
  const { payload } = useGlobalModal()

  const handleGoBack = () => {
    if (payload) {
      payload.backNavigationCallback()
    }
  }

  return (
    <ModalContent className={styles.cardLimitWrapper}>
      <ExclamationIcon />
      <h1 className={styles.title}>{t('title')}</h1>
      <Trans
        t={t}
        components={[
          // eslint-disable-next-line
          <a className={styles.email} href={`mailto:${EMAILS.SUPPORT}`} key={EMAILS.SUPPORT} />,
        ]}
      >
        <p>
          {t('description', {
            emailAddress: EMAILS.SUPPORT,
          })}
        </p>
      </Trans>
      <div className={styles.footerWrapper}>
        <Divider className={styles.divider} />
        <Button onClick={handleGoBack} variant='ghost' width='fluid'>
          {t('goBack')}
        </Button>
      </div>
    </ModalContent>
  )
}
