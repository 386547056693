import { ReactComponent as Logo } from 'assets/svgs/logo.svg'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { Loader } from 'ui/@library/feedback/loader'

import { Spinner } from '../spinner'

import styles from './styles.module.scss'

type FullPageLoaderProps = {
  variant?: 'primary' | 'secondary'
  pageTitle?: string
}

export const FullPageLoader = ({ variant = 'primary', pageTitle }: FullPageLoaderProps) => {
  if (variant === 'primary') {
    return (
      <div className={`${styles.fullPageLoader}`}>
        <Logo />
        <Spinner />
      </div>
    )
  }

  return (
    <RouteWrapper title={pageTitle}>
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    </RouteWrapper>
  )
}
