import { useLocalization } from 'locales/i18n'
import { AvatarGroup } from 'ui/@library/data-display/avatar-group'
import { useMasterLoadable } from 'ui/@store/master'
import { getOwnersFromOwnerList } from 'ui/master/utils'

import styles from './styles.module.scss'

export const SongOwners = () => {
  const { t } = useLocalization('master.overviewTab.signInPrompt')
  const { master } = useMasterLoadable()

  if (
    !master ||
    !master.metaData.ownedByWithAvatars ||
    master.metaData.ownedByWithAvatars.length === 0
  ) {
    return null
  }

  return (
    <div className={styles.ownersWrapper}>
      <AvatarGroup masterOwners={master.metaData.ownedByWithAvatars} />
      <p className={styles.ownerName}>
        {t('masterOwner', {
          ownersList: getOwnersFromOwnerList(master.metaData.ownedByWithAvatars),
        })}
      </p>
    </div>
  )
}
