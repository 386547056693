import { ReactElement, useState, useRef } from 'react'

import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg'
import { ReactComponent as Hamburger } from 'assets/icons/hamburger.svg'
import { useOnClickOutside } from 'ui/@hooks/use-onclick-outside'

import styles from './styles.module.scss'

export type DropdownListItem = {
  value: ReactElement | string
  label: string
  onClick?: () => void
  disabled?: boolean
  description?: boolean
}

type DropdownProps = {
  children?: ReactElement | string
  options: DropdownListItem[]
  color?: 'primary' | 'secondary'
  type?: 'dropdown' | 'menu'
  width?: 'fixed' | 'fluid'
  horizontalAlign?: 'left' | 'right'
  className?: string
}

export const Dropdown = ({
  children,
  options,
  type,
  className = '',
  width = 'fluid',
  horizontalAlign = 'left',
  color = 'primary',
}: DropdownProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const ref = useRef(null)
  useOnClickOutside(ref, () => setIsActive(false))

  return (
    <div className={`${styles.dropdownContainer} ${className} ${styles[width]}`} ref={ref}>
      <div
        className={`${styles.dropdownButton} ${styles[type || '']} ${
          isActive ? styles.active : ''
        } ${styles[color]}`}
        onClick={() => setIsActive(!isActive)}
      >
        {children}
        <div className={`${styles.icon} ${!children ? styles.noMargin : ''}`}>
          {type === 'dropdown' ? <ChevronDown /> : <Hamburger />}
        </div>
      </div>
      {isActive && (
        <ul className={`${styles.optionsList} ${styles[horizontalAlign]}`}>
          {options.map((option) => (
            <li
              key={option.label}
              className={`${styles.option} ${option.disabled ? styles.disabled : ''} ${
                option.description ? styles.description : ''
              }`}
              onClick={() => {
                if (!option.disabled && option.onClick) {
                  option.onClick()
                  setIsActive(false)
                }
              }}
            >
              {option.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
