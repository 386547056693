import { useMxApi } from '../mx-api'

export const useBuyLimit = (masterUuid: string, shareQuantity: number, pricePerShare: number) => {
  const [{ loading, error }, requestBuyLimit] = useMxApi(
    {
      method: 'POST',
      url: `/masters/${masterUuid}/bids`,
      data: {
        quantity: shareQuantity,
        price: pricePerShare,
      },
    },
    {
      manual: true,
    },
  )

  return {
    requestBuyLimit,
    loading,
    error,
  }
}
