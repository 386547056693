import { useNavigate } from 'react-router-dom'

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as GiftIcon } from 'assets/icons/gift.svg'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { MessageAction } from 'types/message'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { AddCardModal } from 'ui/@modals/add-funds'
import { CardOptionsModal } from 'ui/@modals/add-funds/@components/card-options-modal'
import { useCardsLoadable } from 'ui/@store/cards'

import styles from '../../styles.module.scss'
import { useMessages } from '../../use-messages'
import { BannerContainer, BannerMessage, BannerButton } from '../banner'

export const AffiliateBanner = () => {
  const { t } = useLocalization('components.banner.actions')
  const { renderModal } = useGlobalModal()
  const { activeMessage, closeBanner } = useMessages()
  const { cards } = useCardsLoadable()
  const navigate = useNavigate()

  const handleMessageAction = () => {
    switch (activeMessage?.action) {
      case MessageAction.VerifyIdentity:
        navigate(Paths.KYC, { state: { setInitStep: true } })
        break
      case MessageAction.ConnectCard:
        if (cards.length === 0) {
          renderModal({
            modalKey: ModalKey.AddCard,
            content: <AddCardModal />,
            payload: { validateCard: true },
          })
        } else {
          renderModal({
            modalKey: ModalKey.CardOptionsModal,
            content: <CardOptionsModal />,
            payload: { validateCard: true },
          })
        }
        break
      default:
        break
    }
  }

  const getButtonText = () => {
    switch (activeMessage?.action) {
      case MessageAction.ConnectCard:
        return t('connectCard')
      case MessageAction.VerifyIdentity:
        return t('verifyIdentity')
      default:
        return ''
    }
  }

  if (!cards || !activeMessage) return null

  return (
    <BannerContainer
      className={`${styles.bannerWrapper} ${styles[activeMessage.variant]}`}
      variant={activeMessage.variant}
    >
      <BannerMessage content={activeMessage.content} icon={<GiftIcon />} />
      {!activeMessage.isSticky && (
        <div className={styles.closeButton} onClick={closeBanner}>
          <CloseIcon />
        </div>
      )}
      {activeMessage.action && (
        <BannerButton onClick={handleMessageAction}>{getButtonText()}</BannerButton>
      )}
    </BannerContainer>
  )
}
