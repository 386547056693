import { TradeActionType, OrderType } from '../../types'

import { TraderBestPriceBuy } from './trader-best-price-buy'
import { TraderImoBuy } from './trader-imo-buy'
import { TraderLimitBuy } from './trader-limit-buy'
import { TraderLimitSell } from './trader-limit-sell'

type GetTraderToRenderProps = {
  isImo: boolean
  orderType: OrderType | null
  tradeActionType: TradeActionType
}

export const getTraderToRender = ({
  isImo,
  orderType,
  tradeActionType,
}: GetTraderToRenderProps) => {
  if (isImo) return <TraderImoBuy />
  else {
    switch (tradeActionType) {
      default:
        return null
      case TradeActionType.Sell:
        return <TraderLimitSell />
      case TradeActionType.Buy:
        switch (orderType) {
          case OrderType.Limit:
            return <TraderLimitBuy />
          case OrderType.BestPrice:
            return <TraderBestPriceBuy />
        }
    }
  }
}
