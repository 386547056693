import { CountdownBanner } from '../countdown-banner'

import styles from './styles.module.scss'

export enum BannerSize {
  Small = 'small',
  Large = 'large',
}

type BannerProps = {
  title?: string
  launchDate?: Date
  logo?: string | null
  description?: string
  size?: BannerSize
}

export const Banner = ({
  title,
  logo,
  description,
  launchDate,
  size = BannerSize.Large,
}: BannerProps) => {
  return (
    <div className={`${styles.bannerContainer} ${styles[size]}`}>
      {title && <p className={styles.title}>{title}</p>}

      {launchDate && <CountdownBanner date={launchDate} />}

      {logo && <img className={styles.artistLogo} src={logo ?? undefined} alt='Artist logo' />}

      {description && <p className={styles.description}>{description}</p>}
    </div>
  )
}
