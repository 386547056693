import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg'
import { useLocalization } from 'locales/i18n'
import { Order, OrderSide } from 'types/order'
import { Loader } from 'ui/@library/feedback/loader'
import {
  AccordionContainer as Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from 'ui/@library/layout/accordion'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { CancelOrderModal, CancelType } from 'ui/@modals/cancel-order-modal'
import { useAuthUserMasterOpenOrdersLoadable } from 'ui/@store/auth-user-master-open-orders'
import { useAuthUserMasterOffersMadeLoadable } from 'ui/@store/auth-user-masters-offers-made'
import { useAuthUserMastersOffersReceivedLoadable } from 'ui/@store/auth-user-masters-offers-received'
import { useMasterLoadable } from 'ui/@store/master'
import { ReviewAcceptBid } from 'ui/master/@components/modals/review-order'

import styles from './styles.module.scss'
import { ActionType, OngoingOrder } from './types'

export const OngoingOrders = () => {
  const { renderModal } = useGlobalModal()
  const { t, f } = useLocalization('master.overviewTab.ongoingOrders')
  const { uuid } = useParams()
  const { master } = useMasterLoadable()
  const [isAccordionOpen, setIsAccordionOpen] = useState('')
  const { authUserMasterOpenOrders, loading: isMasterOpenOrdersLoading } =
    useAuthUserMasterOpenOrdersLoadable()
  const { authUserMasterOffersMade, loading: isOffersMadeLoading } =
    useAuthUserMasterOffersMadeLoadable()
  const { authUserMastersOffersReceived, loading: isOffersReceivedLoading } =
    useAuthUserMastersOffersReceivedLoadable()

  const getOrderType = (type: OrderSide) => {
    switch (type) {
      case OrderSide.Bid:
        return t('orderType.limitBuy')
      case OrderSide.Ask:
        return t('orderType.limitSell')
      case OrderSide.ImoBid:
        return t('orderType.imoRequest')
      default:
        return '-'
    }
  }

  const openOrdersFormatted = useMemo((): OngoingOrder[] => {
    return authUserMasterOpenOrders
      ? authUserMasterOpenOrders.map((row: Order) => ({
          type: getOrderType(row.orderSide),
          qty: row.shareOrders.length,
          price: row.shareOrders[0]?.price || 0,
          id: row.shareOrders.every((shareOrder) => !shareOrder.share)
            ? '-'
            : row.shareOrders
                .map((shareOrder) => (shareOrder.share ? `#${shareOrder.share.tokenId}` : '-'))
                .join(', '),
          total:
            row.shareOrders.length !== 0 && row.shareOrders[0]
              ? row.shareOrders[0]?.price * row.shareOrders.length
              : 0,
          uuid: row.uuid,
          actionType: row.isCancellable ? ActionType.CancelOpenOrder : undefined,
        }))
      : []
    // eslint-disable-next-line
  }, [authUserMasterOpenOrders])

  const offersMadeFormatted = useMemo((): OngoingOrder[] => {
    return authUserMasterOffersMade
      ? authUserMasterOffersMade
          .filter((row) => row.master.uuid === uuid)
          .map((row) => ({
            type: t('orderType.offerMade'),
            qty: 1,
            price: row.price,
            id: `#${row.share.tokenId}`,
            total: row.price,
            uuid: row.uuid,
            share: row.share,
            actionType: ActionType.CancelUniqueOrder,
          }))
      : []
    // eslint-disable-next-line
  }, [authUserMasterOffersMade])

  const offersReceivedFormatted = useMemo((): OngoingOrder[] => {
    return authUserMastersOffersReceived
      ? authUserMastersOffersReceived
          .filter((row) => row.master.uuid === uuid)
          .map((row) => ({
            type: t('orderType.offerReceived'),
            qty: 1,
            price: row.price,
            id: `#${row.share.tokenId}`,
            total: row.price,
            uuid: row.uuid,
            actionType: ActionType.Accept,
            share: row.share,
          }))
      : []
    // eslint-disable-next-line
  }, [authUserMastersOffersReceived])

  const tableData = useMemo(() => {
    return [...openOrdersFormatted, ...offersMadeFormatted, ...offersReceivedFormatted]
  }, [openOrdersFormatted, offersMadeFormatted, offersReceivedFormatted])

  useEffect(() => {
    if (tableData.length <= 5) {
      setIsAccordionOpen('ongoingOrders')
    }
  }, [tableData])

  const handleCancelUniqueOffer = (bidUuid: string, fractionUuid: string) => {
    renderModal({
      modalKey: ModalKey.CancelOrderModal,
      content: <CancelOrderModal />,
      enforceAuth: true,
      payload: {
        type: CancelType.OfferMade,
        shareUuid: fractionUuid,
        bidUuid: bidUuid,
      },
    })
  }

  const handleCancelOpenOrder = (uuid: string) => {
    renderModal({
      modalKey: ModalKey.CancelOrderModal,
      content: <CancelOrderModal />,
      enforceAuth: true,
      payload: {
        type: CancelType.OpenOrder,
        orderUuid: uuid,
        fetchOpenOrdersWithMaster: true,
      },
    })
  }

  const handleAcceptOrder = (shareUuid: string) => {
    renderModal({
      modalKey: ModalKey.ReviewAcceptBid,
      content: <ReviewAcceptBid />,
      enforceAuth: true,
      size: ModalSize.Large,
      payload: {
        masterUuid: master?.uuid || '',
        shareUuid: shareUuid,
      },
    })
  }

  if (tableData.length === 0) return null

  if (isMasterOpenOrdersLoading || isOffersMadeLoading || isOffersReceivedLoading) return <Loader />

  return (
    <>
      <Accordion setValue={(value) => setIsAccordionOpen(value)} value={isAccordionOpen}>
        <AccordionItem value='ongoingOrders' className={styles.accordionItem}>
          <AccordionHeader>
            <div className={styles.accordionHeaderWrapper}>
              <p className={styles.accordionTitle}>{t('title')}</p>
              {tableData.length > 0 ? (
                <div className={styles.rowCount}>
                  <p>{tableData.length}</p>
                </div>
              ) : null}
            </div>
          </AccordionHeader>
          <AccordionContent>
            <div className={styles.accordionContent}>
              <div className={styles.tableContainer}>
                <div className={`${styles.tableRow} ${styles.tableHeader}`}>
                  <p className={styles.type}>{t('tableColumns.type')}</p>
                  <p className={styles.qty}>{t('tableColumns.qty')}</p>
                  <p className={`${styles.price} ${styles.desktopOnlyRow}`}>
                    {t('tableColumns.price')}
                  </p>
                  <p className={`${styles.id} ${styles.desktopOnlyRow}`}>{t('tableColumns.id')}</p>
                  <p className={styles.total}>{t('tableColumns.total')}</p>
                  <div className={styles.buttonWrapper} />
                </div>
                {tableData.map((row) => (
                  <div className={styles.tableRow} key={row.uuid}>
                    <p className={styles.type}>{row.type}</p>
                    <p className={styles.qty}>{row.qty === 0 ? '-' : row.qty}</p>
                    <p className={`${styles.price} ${styles.desktopOnlyRow}`}>
                      {f.currency(row.price)}
                    </p>
                    <p className={`${styles.id} ${styles.desktopOnlyRow}`}>{row.id}</p>
                    <p className={styles.total}>{f.currency(row.total)}</p>
                    <div className={styles.buttonWrapper}>
                      {row.actionType === ActionType.Accept ? (
                        <p
                          className={styles.button}
                          onClick={() => {
                            row.share && handleAcceptOrder(row.share.uuid)
                          }}
                        >
                          {t('accept')}
                        </p>
                      ) : row.actionType === ActionType.CancelOpenOrder ? (
                        <BinIcon
                          onClick={() => {
                            handleCancelOpenOrder(row.uuid)
                          }}
                          className={styles.button}
                        />
                      ) : row.actionType === ActionType.CancelUniqueOrder ? (
                        <BinIcon
                          onClick={() => {
                            row.share && handleCancelUniqueOffer(row.uuid, row.share.uuid)
                          }}
                          className={styles.button}
                        />
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  )
}
