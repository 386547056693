import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'

import styles from './styles.module.scss'

export const TraderError = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader.error')

  const handleReload = () => {
    window.location.reload()
  }

  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.iconWrapper}>
          <ExclamationIcon className={styles.icon} />
        </div>
        <p className={styles.description}>{t('description')}</p>
      </div>
      <Button variant='outline' onClick={handleReload} width='fluid'>
        {t('buttonText')}
      </Button>
    </div>
  )
}
