export enum MetricType {
  RoyaltiesPerShare = 'royaltiesPerShare',
  ForecastedRoyaltiesPerShare = 'forecastedRoyaltiesPerShare',
  AnnualYield = 'annualYield',
  NetMasterRoyaltyOwnershipPerShare = 'netMasterRoyaltyOwnershipPerShare',
  TotalListedMarketCap = 'totalListedMarketCap',
  TotalShares = 'totalShares',
  RoyaltyPayoutPeriod = 'royaltyPayoutPeriod',
  ApproximateStreams = 'approximateStreams',
}

export type MetricsExplainerModalProps = {
  metricType: MetricType
}
