import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchOrderBook } from 'repositories/mx-api/'
import { OrderBook } from 'types/order'

import { masterUuidState } from './master'

const orderBookQuery = selector<OrderBook | null>({
  key: 'orderBookQuery',
  get: async ({ get }) => {
    const masterUuid = get(masterUuidState)
    if (!masterUuid) return null
    return fetchOrderBook(masterUuid)
  },
})

export const useOrderBookLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(orderBookQuery)
  const refetch = useRecoilRefresher_UNSTABLE(orderBookQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    orderBook: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
