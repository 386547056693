import { useNavigate } from 'react-router-dom'

import { ReactComponent as EmptyRealeaseImage } from 'assets/svgs/new-releases-empty.svg'
import { ReactComponent as EmptyStandardImage } from 'assets/svgs/shares-table-empty.svg'
import { ReactComponent as EmptyTransactionsImage } from 'assets/svgs/transactions-empty.svg'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'

import { GraphicsToShow } from './states'
import styles from './styles.module.scss'

type EmptyStateProps = {
  image?: GraphicsToShow
  button?: string
  message?: string
  path?: string
}

export const EmptyStatePlaceholder = ({ image, button, message, path }: EmptyStateProps) => {
  const { t } = useLocalization('components.emptyState')
  const navigate = useNavigate()

  const getEmptyStateImage = () => {
    switch (image) {
      case GraphicsToShow.Releases:
        return <EmptyRealeaseImage />
      case GraphicsToShow.Transactions:
        return <EmptyTransactionsImage />
      default:
        return <EmptyStandardImage />
    }
  }

  return (
    <div className={styles.tableEmptyState}>
      {getEmptyStateImage()}
      <div className={styles.tableEmptyStateMessage}>
        <span className={styles.tableEmptyStateMessageText}>
          {message ? message : t('message')}
        </span>
        <p
          className={styles.tableEmptyStateButton}
          onClick={() => {
            navigate(path ? path : Paths.HOME)
          }}
        >
          {button ? button : t('button')}
        </p>
      </div>
    </div>
  )
}
