import { ReactNode } from 'react'

import styles from './styles.module.scss'

type FormContainerProps = {
  children: ReactNode
  className?: string
}

export const FormContainer = ({ children, className = '' }: FormContainerProps) => {
  return <div className={`${styles.container} ${className}`}>{children}</div>
}
