import { ReactElement, useEffect } from 'react'

import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useSignInAndSignUp } from 'ui/@hooks/use-signin-and-signup'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'

type EnforceAuthProps = {
  element: ReactElement
}

export const EnforceAuth = ({ element }: EnforceAuthProps) => {
  const { isAuthenticated } = useMxAuth()
  const { signIn } = useSignInAndSignUp()

  useEffect(() => {
    if (!isAuthenticated) {
      signIn()
    }
  }, [isAuthenticated, signIn])

  if (!isAuthenticated) return <FullPageLoader />

  return element
}
