import { NavLink, useLocation } from 'react-router-dom'

import { useLocalization } from 'locales/i18n'
import { LandingPageRouteType, useLandingPageLinks } from 'ui/@hooks/use-landing-page-links'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'

import { Route } from '../../routes'

import styles from './styles.module.scss'

type RouteLinkProps = {
  route: Route
}

export const RouteLink = ({ route }: RouteLinkProps) => {
  const { t } = useLocalization()
  const { isAuthenticated } = useMxAuth()
  const generateLandingPageLink = useLandingPageLinks()
  const location = useLocation()

  const isNestedLinkActive = location.pathname.split('/').includes(route.path.substring(1))
  const landingPageLink = generateLandingPageLink(route.path as LandingPageRouteType)

  const getClassName = (isActive: boolean) => {
    const classNames = [styles.routeLink]
    if (isActive || isNestedLinkActive) {
      classNames.push(styles.routeLinkActive)
    }
    if (route.dropdownOnly) {
      classNames.push(styles.dropdown)
    }
    return classNames.join(' ')
  }

  if (route.isAuthRequired && !isAuthenticated) {
    return null
  } else if (route.element) {
    return route.element
  } else if (route.isLandingPage) {
    return (
      <a key={route.label} href={landingPageLink} className={styles.routeLink}>
        {t(route.label)}
      </a>
    )
  } else
    return (
      <NavLink className={({ isActive }) => getClassName(isActive)} to={route.path} end={true}>
        {t(route.label)}
      </NavLink>
    )
}
