import { Discover, DiscoverRaw } from 'types/discover'

import { sanitizeDiscoverFromRaw } from '../@santizers/discover'
import { MxApi } from '../mx-api'

export const fetchDiscover = async (): Promise<Discover> => {
  const { data } = await MxApi.get<DiscoverRaw>('/masters/discover')

  return sanitizeDiscoverFromRaw(data)
}
