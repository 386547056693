export const Health = () => {
  const health = {
    msg: 'App is healthy',
    release_version: process.env.REACT_APP_RELEASE_VERSION || null,
    client_version: process.env.REACT_APP_CLIENT_VERSION || null,
    api_url: process.env.REACT_APP_MX_API_BASE_URL || null,
  }

  return (
    <p>
      <pre>{JSON.stringify(health, null, 2)}</pre>
    </p>
  )
}
