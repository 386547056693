import { useMxApi } from '../mx-api'

export const useCancelOrder = (shareOrderUuid: string) => {
  const [{ loading, error }, requestCancelOrder] = useMxApi(
    {
      method: 'PUT',
      url: `/orders/shares/${shareOrderUuid}/cancel`,
    },
    {
      manual: true,
    },
  )

  return {
    requestCancelOrder,
    loading,
    error,
  }
}
