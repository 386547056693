import CreditCardType from 'credit-card-type'
import { Form, Formik, FormikHelpers } from 'formik'
import { SchemaOf, object, string } from 'yup'

import { useLocalization } from 'locales/i18n'
import { CreditCardBrand } from 'types/card'
import { TermsAndConditions } from 'ui/@components/terms-and-conditions'
import { CreditCardBrandingIcon } from 'ui/@library/data-display/credit-card-branding-icon'
import { Button } from 'ui/@library/inputs/button'
import { Input } from 'ui/@library/inputs/input'
import { Select } from 'ui/@library/inputs/select'

import styles from './styles.module.scss'
import {
  AddCardFormValues,
  CARD_HOLDER_NAME_MAX_LENGTH,
  CARD_NUMBER_MAX_LENGTH,
  SECURITY_CODE_MAX_LENGTH,
  SECURITY_CODE_MIN_LENGTH,
} from './types'
import {
  expiryMonthOptions,
  expiryYearOptions,
  formatCardNumber,
  isValidCardHolderName,
  isValidCardNumber,
  isValidSecurityCode,
  sanitizeCardNumber,
  sanitizeExpiryYear,
} from './utils'

const getCardIcon = (cardNumber: string) => {
  const cardType = CreditCardType(cardNumber)
  const cardBrand = cardType[0]?.type

  return (
    <CreditCardBrandingIcon
      brand={cardBrand && cardNumber !== '' ? (cardBrand.toUpperCase() as CreditCardBrand) : null}
    />
  )
}

const initialValues: AddCardFormValues = {
  cardHolderName: '',
  cardNumber: '',
  expiryMonth: '',
  expiryYear: '',
  securityCode: '',
}

export type AddCardProps = {
  onSubmit: (values: AddCardFormValues) => void
  loading: boolean
}

export const AddCardForm = ({ onSubmit, loading }: AddCardProps) => {
  const { t } = useLocalization('addFunds.addCard')

  const validationSchema: SchemaOf<AddCardFormValues> = object({
    cardHolderName: string()
      .required(t('cardHolderName.required'))
      .test('card-holder-name', t('cardHolderName.invalid'), (cardHolderName) => {
        return isValidCardHolderName(cardHolderName)
      }),
    cardNumber: string()
      .required(t('cardNumber.required'))
      .test('card-number', t('cardNumber.invalid'), (cardNumber) => {
        return isValidCardNumber(sanitizeCardNumber(cardNumber))
      })
      .test('card-type', t('cardNumber.unsupported'), (cardNumber) => {
        const sanitizedCardNumber = sanitizeCardNumber(cardNumber)
        const cardType = CreditCardType(sanitizedCardNumber)
        const cardBrand = cardType[0]?.type || ''
        return [CreditCardBrand.Visa, CreditCardBrand.MasterCard].includes(
          cardBrand.toUpperCase() as CreditCardBrand,
        )
      }),
    expiryMonth: string().required(t('expiryDate.required')),
    expiryYear: string().required(t('expiryDate.required')),
    securityCode: string()
      .required(t('securityCode.required'))
      .min(SECURITY_CODE_MIN_LENGTH, t('securityCode.invalid'))
      .max(SECURITY_CODE_MAX_LENGTH, t('securityCode.invalid'))
      .test('security-code', t('securityCode.invalid'), (securityCode) => {
        return isValidSecurityCode(securityCode)
      }),
  })

  const handleSubmit = async (
    values: AddCardFormValues,
    actions: FormikHelpers<AddCardFormValues>,
  ) => {
    actions.validateForm(values)

    const sanitizedFormValues = {
      ...values,
      cardNumber: sanitizeCardNumber(values.cardNumber),
      expiryYear: sanitizeExpiryYear(values.expiryYear),
    }
    onSubmit(sanitizedFormValues)
  }

  return (
    <div className={styles.addCardContainer}>
      <h1 className={styles.title}>{t('title')}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur
      >
        {({ isSubmitting, isValid, values }) => (
          <Form className={styles.form}>
            <div className={styles.topWrapper}>
              <div>
                <Input
                  name='cardHolderName'
                  label={t('cardHolderName.label')}
                  type='text'
                  placeholder={t('cardHolderName.placeholder')}
                  autoComplete='cc-name'
                  maxLength={CARD_HOLDER_NAME_MAX_LENGTH}
                />
              </div>
              <div className={styles.cardNumberContainer}>
                <Input
                  name='cardNumber'
                  label={t('cardNumber.label')}
                  type='text'
                  inputMode='numeric'
                  placeholder={t('cardNumber.placeholder')}
                  autoComplete='cc-number'
                  value={formatCardNumber(values.cardNumber)}
                  endAdornment={getCardIcon(values.cardNumber)}
                  maxLength={CARD_NUMBER_MAX_LENGTH}
                />
              </div>
              <div className={styles.dateAndSecurity}>
                <div className={styles.expiryDate}>
                  <Select
                    name='expiryMonth'
                    label={t('expiryDate.monthLabel')}
                    options={expiryMonthOptions}
                    placeholder={t('expiryDate.monthPlaceholder')}
                    currentValue={values.expiryMonth}
                    autoComplete='cc-exp-month'
                  />
                  <Select
                    name='expiryYear'
                    label={t('expiryDate.yearLabel')}
                    options={expiryYearOptions}
                    placeholder={t('expiryDate.yearPlaceholder')}
                    currentValue={values.expiryYear}
                    autoComplete='cc-exp-year'
                  />
                </div>
                <Input
                  name='securityCode'
                  label={t('securityCode.label')}
                  type='text'
                  inputMode='numeric'
                  placeholder={t('securityCode.placeholder')}
                  autoComplete='cc-csc'
                />
              </div>
            </div>

            <div className={styles.bottomWrapper}>
              <div className={styles.info}>
                <p className={styles.terms}>
                  <TermsAndConditions />
                </p>
                <p className={styles.terms}>{t('connectCardDisclaimer')}</p>
              </div>
              <Button
                type='submit'
                loading={isSubmitting || loading}
                disabled={!isValid}
                width='fluid'
              >
                {t('addCard')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
