import { ReactComponent as MxLogoCompact } from 'assets/svgs/logo-compact.svg'
import { ReactComponent as MxLogo } from 'assets/svgs/logo.svg'

import styles from './styles.module.scss'

export const NavbarLogo = () => {
  return (
    <div className={styles.mxLogoWrapper}>
      <MxLogo className={styles.mxLogo} />
      <MxLogoCompact className={styles.mxLogoCompact} />
    </div>
  )
}
