import { useLocalization } from 'locales/i18n'
import { useMasterLoadable } from 'ui/@store/master'

import styles from './styles.module.scss'

export const MasterDescription = () => {
  const { t } = useLocalization('master.overviewTab.description')
  const { master } = useMasterLoadable()

  if (!master) return null

  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.masterSection}>
        <h1 className={styles.title}>{t('masterTitle')}</h1>
        <p className={styles.description}>{master.description}</p>
      </div>
      {master.assets.previewLink && (
        <iframe
          title={master.name}
          src={master.assets.previewLink}
          height='152'
          frameBorder='0'
          loading='lazy'
          className={styles.spotifyPlayer}
          allow="autoplay 'none'"
        />
      )}
    </div>
  )
}
