import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'
import { useMasterLoadable } from 'ui/@store/master'

import { MetricList } from './@components/metric-list'
import { RevenueBreakdown } from './@components/revenue-breakdown/revenue-breakdown'
import { SocialMediaFollowers } from './@components/social-media-followers'
import { StreamMetrics } from './@components/stream-metrics'
import styles from './styles.module.scss'

export const Information = () => {
  const { master } = useMasterLoadable()
  const { t, f } = useLocalization('master.overviewTab.information')

  if (!master) return null

  return (
    <div className={styles.informationContainer}>
      <div className={styles.informationTitleContainer}>
        <h1 className={styles.title}>{t('title')}</h1>
        <h2 className={styles.subtitle}>{`${t('subtitle')} ${f.date(
          master.updatedAt !== null ? master.updatedAt : master.createdAt,
          TextFormats.DATE.DATE_WITH_MONTH_LONG,
        )}`}</h2>
      </div>
      <RevenueBreakdown />
      <MetricList />
      <StreamMetrics />
      <SocialMediaFollowers />
    </div>
  )
}
