import { useEffect } from 'react'

import { usePlatform } from 'ui/@hooks/use-platform'

import { DesktopTrader } from './@components/desktop-trader'
import { MobileTrader } from './@components/mobile-trader'
import { useTrader } from './use-trader'

export const BannerTrader = () => {
  const { setOrderType } = useTrader()

  const { isWeb } = usePlatform()

  // Cleanup function that will reset the value of OrderType when new song page is opened. This will be used to set default order type in trader based on shares available for sale
  useEffect(() => {
    return () => {
      setOrderType(null)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <DesktopTrader />
      {isWeb && <MobileTrader />}
    </>
  )
}
