import { useNavigate } from 'react-router-dom'

import { ampli } from 'ampli'
import { ReactComponent as CardIcon } from 'assets/icons/credit-card.svg'
import { ReactComponent as MoneyTransferIcon } from 'assets/icons/money-transfer.svg'
import { ExternalLinks } from 'constants/external-links'
import { Paths, QueryParams } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { KycStatus } from 'types/auth-user'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { Loader } from 'ui/@library/feedback/loader'
import { Divider } from 'ui/@library/layout/divider'
import { useGlobalModal, ModalContent } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { ReviewKyc } from 'ui/@modals/kyc/review-kyc'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useCardsLoadable } from 'ui/@store/cards'
import { useMasterStatusLoadable } from 'ui/@store/master'
import { MasterStatus } from 'ui/master/types'

import { AddCardModal } from '../add-card-modal'
import { AddCardSuccess } from '../add-card-success'
import { AddFundsConfirm } from '../add-funds-confirm'
import { CardOptionsModal } from '../card-options-modal'
import { Iban } from '../iban'

import { Method } from './@componets/method'
import styles from './styles.module.scss'

enum PaymentMethods {
  Card = 'card',
  Iban = 'iban',
}

export type PaymentMethodProps = {
  onAddFundsSuccessCallback?: () => void
}

export const PaymentMethodModal = () => {
  const { t } = useLocalization('addFunds.paymentMethods')
  const { renderModal, payload: modalPayload } = useGlobalModal()
  const { authUser } = useAuthUserLoadable()
  const { cards, loading: cardsLoading } = useCardsLoadable()
  const { masterStatus } = useMasterStatusLoadable()
  const navigate = useNavigate()

  useEffectOnce(() => {
    ampli.viewSelectPaymentMethodModal()
  })

  const isKycValidated = authUser?.kycStatus === KycStatus.Validated
  const isKycPending = authUser?.kycStatus === KycStatus.ValidationAsked
  const isKycRefused = authUser?.kycStatus === KycStatus.Refused

  const handlePaymentMethodBackNavigation = () => {
    renderModal({ modalKey: ModalKey.PaymentMethod, content: <PaymentMethodModal /> })
  }

  const handleConnectCard = () => {
    if (cards.length === 0) {
      renderModal({
        modalKey: ModalKey.AddCard,
        content: <AddCardModal />,
        navigateBack: handlePaymentMethodBackNavigation,
        payload: {
          onSuccessCallback: () => {
            renderModal({ modalKey: ModalKey.AddCardSuccess, content: <AddCardSuccess /> })
          },
          validateCard: true,
        },
      })
    } else {
      renderModal({
        modalKey: ModalKey.CardOptionsModal,
        content: <CardOptionsModal />,
        navigateBack: handlePaymentMethodBackNavigation,
        payload: {
          validateCard: true,
        },
      })
    }
  }

  const handleKycPaymentMethod = (paymentMethod: PaymentMethods) => {
    if (isKycValidated) {
      switch (paymentMethod) {
        case PaymentMethods.Card:
          if (cards.length === 0) {
            renderModal({
              modalKey: ModalKey.AddCard,
              content: <AddCardModal />,
              navigateBack: handlePaymentMethodBackNavigation,
              payload: {
                onSuccessCallback: () => {
                  renderModal({
                    modalKey: ModalKey.AddFundsConfirm,
                    content: <AddFundsConfirm />,
                    navigateBack: handlePaymentMethodBackNavigation,
                    payload: { onAddFundsSuccessCallback: modalPayload?.onAddFundsSuccessCallback },
                  })
                },
              },
            })
          } else {
            renderModal({
              modalKey: ModalKey.AddFundsConfirm,
              content: <AddFundsConfirm />,
              navigateBack: handlePaymentMethodBackNavigation,
            })
          }
          break

        case PaymentMethods.Iban:
          renderModal({
            modalKey: ModalKey.AddIban,
            content: <Iban />,
            navigateBack: handlePaymentMethodBackNavigation,
            size: ModalSize.Large,
          })
          break

        default:
          break
      }
    } else if (isKycPending || isKycRefused) {
      renderModal({
        modalKey: ModalKey.ReviewKyc,
        content: <ReviewKyc />,
        navigateBack: handlePaymentMethodBackNavigation,
      })
    } else {
      const queryParams = new URLSearchParams()
      queryParams.set(QueryParams[Paths.KYC].PAYMENT_FLOW, 'true')
      const pathWithQueryParams = `${Paths.KYC}?${queryParams}`
      navigate(pathWithQueryParams, { state: { setInitStep: true } })
    }
  }

  return (
    <ModalContent className={styles.paymentMethodContainer}>
      <div className={styles.topWrapper}>
        <h1 className={styles.title}>{t('stepTitle')}</h1>
        {cardsLoading ? (
          <Loader />
        ) : (
          <div className={styles.paymentMethods}>
            {masterStatus === MasterStatus.Imo && (
              <Method
                onClick={handleConnectCard}
                icon={<CardIcon />}
                title={t('connectCardTitle')}
                description={t('connectCardDescription')}
              />
            )}
            <Method
              onClick={() => {
                handleKycPaymentMethod(PaymentMethods.Card)
              }}
              icon={<CardIcon />}
              title={t('cardTitle')}
              description={t('cardDescription')}
              pillText={!isKycValidated ? t('pill') : null}
            />
            <Method
              onClick={() => {
                handleKycPaymentMethod(PaymentMethods.Iban)
              }}
              icon={<MoneyTransferIcon />}
              title={t('bankTitle')}
              description={t('bankDescription')}
              pillText={!isKycValidated ? t('pill') : null}
            />
          </div>
        )}
      </div>
      <div className={styles.bottomWrapper}>
        <Divider />
        <div className={styles.footer}>
          <a href={ExternalLinks.PRICING_AND_FEES_INFO} target='_blank' rel='noreferrer'>
            {t('learnMore')}
          </a>
        </div>
      </div>
    </ModalContent>
  )
}
