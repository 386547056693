import { Route, Routes, useNavigate, Navigate } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { AccessStatus } from 'types/auth-user'
import { useAuthUserOnboardingStatus } from 'ui/@hooks/use-auth-user-onboarding-status'
import { ProgressBar } from 'ui/@library/feedback/progress-bar'
import { StaticPageWrapper } from 'ui/@library/layout/static-page-wrapper'
import { useAuthUserLoadable, useOnboardingChecklist } from 'ui/@store/auth-user'

import { AddCardInOnboarding } from './add-card-onboarding'
import { EmailVerification } from './email-verification'
import { PersonalDetails } from './personal-details'
import {
  OnboardingStepIdentifier,
  TOTAL_SUB_STEPS_WITH_ADD_CARD,
  TOTAL_SUB_STEPS_WITHOUT_ADD_CARD,
  TOTAL_STEPS_WITH_ADD_CARD,
  TOTAL_STEPS_WITHOUT_ADD_CARD,
} from './types'
import { VerifyIdentity } from './verify-identity'

export const Onboarding = () => {
  const navigate = useNavigate()
  const { t } = useLocalization('onboarding')
  const { updateOnboardingChecklist } = useOnboardingChecklist()
  const { authUser, isEarlyAccessUser } = useAuthUserLoadable()
  const { firstUnfinishedStepRoute = Paths.ONBOARDING.EMAIL_VERIFICATION, isOnboardingFinished } =
    useAuthUserOnboardingStatus()

  const onboardingStepsWithoutAddCard = [
    {
      title: t('verifyEmail.pageTitle'),
      identifier: OnboardingStepIdentifier.EMAIL_VERIFICATION,
      path: Paths.ONBOARDING.EMAIL_VERIFICATION,
      component: EmailVerification,
      isActive: false,
      currentStep: null,
    },
    {
      title: t('personalDetails.pageTitle'),
      identifier: OnboardingStepIdentifier.ADD_PERSONAL_DETAILS,
      path: Paths.ONBOARDING.ADD_PERSONAL_DETAILS,
      component: PersonalDetails,
      isActive: false,
      currentStep: { step: 1, subStep: 6 },
    },
    {
      title: t('verifyIdentity.pageTitle'),
      identifier: OnboardingStepIdentifier.VERIFY_IDENTITY,
      path: Paths.ONBOARDING.VERIFY_IDENTITY,
      component: VerifyIdentity,
      isActive: false,
      currentStep: { step: 2, subStep: 1 },
    },
  ]

  const onboardingStepsWithAddCard = [
    ...onboardingStepsWithoutAddCard,
    {
      title: t('addCard.disclaimer.pageTitle'),
      identifier: OnboardingStepIdentifier.ADD_CARD,
      path: Paths.ONBOARDING.ADD_CARD,
      component: AddCardInOnboarding,
      isActive: false,
      currentStep: null,
    },
  ]

  const onboardingSteps =
    authUser?.accessStatus === AccessStatus.EarlyAccess
      ? onboardingStepsWithAddCard
      : onboardingStepsWithoutAddCard

  const completeStep = async (stepIdentifier: OnboardingStepIdentifier) => {
    await updateOnboardingChecklist({ [stepIdentifier]: true })

    if (!isEarlyAccessUser && stepIdentifier === OnboardingStepIdentifier.VERIFY_IDENTITY) {
      await updateOnboardingChecklist({ addCard: true })
    }

    const currentStepIndex = onboardingSteps.findIndex((step) => step.identifier === stepIdentifier)
    const nextStep = onboardingSteps[currentStepIndex + 1]

    if (nextStep) {
      navigate(nextStep.path)
    } else {
      navigate(Paths.HOME)
    }
  }

  const onVerify = async () => {
    await updateOnboardingChecklist({ verifyIdentity: true })
    navigate(Paths.KYC)
  }

  const TOTAL_STEPS = isEarlyAccessUser ? TOTAL_STEPS_WITH_ADD_CARD : TOTAL_STEPS_WITHOUT_ADD_CARD

  const TOTAL_SUB_STEPS = isEarlyAccessUser
    ? TOTAL_SUB_STEPS_WITH_ADD_CARD
    : TOTAL_SUB_STEPS_WITHOUT_ADD_CARD

  if (isOnboardingFinished) {
    return <Navigate to={Paths.HOME} replace />
  }

  return (
    <Routes>
      {onboardingSteps.map((step) => (
        <Route
          key={step.identifier}
          path={step.path}
          element={
            <StaticPageWrapper title={step.title}>
              {step.currentStep && (
                <ProgressBar
                  currentStep={step.currentStep}
                  totalSteps={{ step: TOTAL_STEPS, subStep: TOTAL_SUB_STEPS }}
                />
              )}
              <step.component
                onStepComplete={() => completeStep(step.identifier)}
                onVerify={onVerify}
              />
            </StaticPageWrapper>
          }
        />
      ))}
      <Route path='*' element={<Navigate to={firstUnfinishedStepRoute} />} />
    </Routes>
  )
}
