import { Resource } from 'i18next'

import { TextFormats } from './constants'

export type DateFormatType = (typeof TextFormats.DATE)[keyof typeof TextFormats.DATE]

export enum LanguageCode {
  EN = 'en',
}

export type LanguageListType = {
  code: LanguageCode
  name: string
}

export type LanguageResourceType = {
  resources: {
    [key: string]: Resource
  }
}
