import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { AuthenticationError } from '@auth0/auth0-spa-js'
import { Capacitor } from '@capacitor/core'
import { captureException } from '@sentry/react'
import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'
import { NavigateFunction } from 'react-router-dom'

import { CustomErrorCodes } from 'constants/error-codes'
import { Paths } from 'constants/paths'
import { Platform } from 'ui/@hooks/use-platform'

const API_URL = process.env.REACT_APP_MX_API_BASE_URL
const API_VERSION = 'v1'
const platform: Platform = Capacitor.getPlatform() as Platform
let isInterceptorsSet = false

export const MxApi = axios.create({
  baseURL: `${API_URL}/${API_VERSION}`,
  headers: {
    'MX-App-Platform': platform,
    'MX-App-Client-Version': process.env.REACT_APP_CLIENT_VERSION ?? '',
  },
})

MxApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.data?.code === CustomErrorCodes.UNDER_MAINTENANCE &&
      window.location.pathname !== Paths.MAINTENANCE
    ) {
      window.location.href = Paths.MAINTENANCE
    }

    if (
      error.response?.data?.code === CustomErrorCodes.UNSUPPORTED_CLIENT &&
      window.location.pathname !== Paths.UNSUPPORTED_CLIENT
    ) {
      window.location.href = Paths.UNSUPPORTED_CLIENT
    }
    return Promise.reject(error)
  },
)

let requestInterceptorId: number | null = null

export const setInterceptors = (
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>,
  navigate: NavigateFunction,
) => {
  if (isInterceptorsSet) return

  requestInterceptorId = MxApi.interceptors.request.use(
    async (config) => {
      let token = ''
      const source = axios.CancelToken.source()
      config.cancelToken = source.token
      try {
        token = await getAccessTokenSilently()
      } catch (error) {
        if (
          !['missing_refresh_token', 'invalid_grant'].includes(
            (error as AuthenticationError)?.error,
          )
        )
          captureException(error)
        source.cancel()
        navigate(Paths.SIGN_OUT)
      }
      config.headers = config.headers || {}
      if (token) config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  MxApi.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        [CustomErrorCodes.INVALIDATED_SESSION, CustomErrorCodes.BLOCKED_USER].includes(
          error.response?.data?.code,
        ) &&
        window.location.pathname !== Paths.SIGN_OUT
      ) {
        navigate(Paths.SIGN_OUT)
      }
      if (
        error.response?.data?.code === CustomErrorCodes.EXPIRED_TOKEN &&
        window.location.pathname !== Paths.SIGN_OUT
      ) {
        // This should never happen, but just in case
        captureException(new Error('Expired token'))
        window.location.reload()
      }

      return Promise.reject(error)
    },
  )

  isInterceptorsSet = true
}

export const removeInterceptors = () => {
  if (requestInterceptorId !== null) {
    MxApi.interceptors.request.eject(requestInterceptorId)
    requestInterceptorId = null
  }
  isInterceptorsSet = false
}

export const useMxApi = makeUseAxios({
  axios: MxApi,
  cache: false,
})
