import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchSubscriberToken } from 'repositories/mx-api'

const authUserSubscriberTokenQuery = selector({
  key: 'authUserSubscriberTokenQuery',
  get: async () => {
    const token = await fetchSubscriberToken()
    return token
  },
})

export const useAuthUserSubscriberToken = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(authUserSubscriberTokenQuery)
  const refetch = useRecoilRefresher_UNSTABLE(authUserSubscriberTokenQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    token: state === 'hasValue' ? contents : undefined,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
