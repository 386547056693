import { useState } from 'react'
import { atom, useRecoilState, useResetRecoilState } from 'recoil'

import { createCardPreAuthorization as createCardPreAuthorizationRequest } from 'repositories/mx-api'
import { fetchCardPreauthoriaztion } from 'repositories/mx-api/queries/fetch-card-preauthorization'
import { Preauth } from 'types/card'

export const cardPreAuthorizationState = atom<Preauth | null>({
  key: 'cardPreAuthorization',
  default: null,
})

export const useCardPreAuthorization = () => {
  const [cardPreAuthorization, setCardPreAuthorization] = useRecoilState(cardPreAuthorizationState)
  const resetState = useResetRecoilState(cardPreAuthorizationState)
  const [preAuthLoading, setPreAuthLoading] = useState(false)

  const createCardPreAuthorization = async (amount: number, card: string) => {
    setPreAuthLoading(true)
    const cardPreAuthorization = await createCardPreAuthorizationRequest(amount, card)
    setCardPreAuthorization(cardPreAuthorization)
    setPreAuthLoading(false)
    return cardPreAuthorization
  }

  const refetchCardPreAuthorization = async () => {
    if (!cardPreAuthorization?.id) throw new Error('Cannot refetch before creating')
    const updatedcardPreAuthorization = await fetchCardPreauthoriaztion(cardPreAuthorization.id)
    setCardPreAuthorization(updatedcardPreAuthorization)
    return updatedcardPreAuthorization
  }

  return {
    cardPreAuthorization,
    createCardPreAuthorization,
    refetchCardPreAuthorization,
    loading: preAuthLoading,
    resetState,
  }
}
