type RemoveAllCookieOptions = {
  except?: string[]
}

export const checkCookie = (name: string): boolean => {
  const cookiesArray = document.cookie.split(';')
  return cookiesArray.some((cookie) => cookie.trim().startsWith(name + '='))
}

export const removeAllCookies = (options?: RemoveAllCookieOptions) => {
  const exceptionArray = options?.except || []
  document.cookie.split(';').forEach((cookie) => {
    const name = cookie.split('=')[0]?.trim()
    if (name && !exceptionArray.includes(name)) {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
  })
}
