import { Divider } from 'ui/@library/layout/divider'

import { TransactionHistoryTableColumns } from '../../types'

import styles from './styles.module.scss'

type mobileTableProps = {
  tableData: Array<TransactionHistoryTableColumns>
}

export const MobileTable = ({ tableData }: mobileTableProps) => {
  return (
    <div className={styles.mobileTableContainer}>
      {tableData.map((item) => {
        return (
          <div
            className={styles.mobileRowWrapper}
            key={`${item.title}-${item.date}-${item.tokenId}`}
          >
            <p className={styles.date}>{item.date}</p>
            <div className={styles.flexWrapper}>
              <p>{item.title}</p>
              <p>{item.total}</p>
            </div>
            <div className={styles.flexWrapper}>
              <p>{item.tokenId}</p>
              <p>{item.transactionType}</p>
            </div>
            <Divider className={styles.divider} />
          </div>
        )
      })}
    </div>
  )
}
