import { ReactNode } from 'react'

import styles from './styles.module.scss'

type OrderSpecificationProps = {
  children?: ReactNode
}

export const OrderSpecification = ({ children }: OrderSpecificationProps) => {
  return <ul className={styles.informationList}>{children}</ul>
}
