import { TransactionRaw } from 'types/transaction'

import { sanitizeTransactionRaw } from '../@santizers/transaction'
import { useMxApi } from '../mx-api'

export const useFetchTransactionHistory = () => {
  const [{ data, loading, error }] = useMxApi<TransactionRaw[]>(
    {
      method: 'GET',
      url: '/auth-users/me/transactions',
    },
    {
      useCache: false,
    },
  )

  return {
    data: data ? data.map(sanitizeTransactionRaw) : [],
    loading,
    error,
  }
}
