import { Preauth, PreauthRaw } from 'types/card'

import { sanitizePreauthFromRaw } from '../@santizers/card'
import { MxApi } from '../mx-api'

export const createCardPreAuthorization = async (
  amount: number,
  cardId: string,
): Promise<Preauth> => {
  const { data } = await MxApi.post<PreauthRaw>('/auth-users/me/wallet/card-preauthorization', {
    method_identifier: cardId,
    amount,
    browser_info: {
      java_enabled: window.navigator.javaEnabled(),
      language: window.navigator.language || 'en',
      color_depth: window.screen.colorDepth,
      screen_height: window.screen.height,
      screen_width: window.screen.width,
      timezone_offset: new Date().getTimezoneOffset(),
      user_agent: window.navigator.userAgent,
      javascript_enabled: true,
    },
  })

  return sanitizePreauthFromRaw(data)
}
