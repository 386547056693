import { useNavigate } from 'react-router-dom'

import { ReactComponent as DisconnectedIllustration } from 'assets/svgs/disconnected.svg'
import { EMAILS } from 'constants/emails'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'

import styles from '../styles.module.scss'

type ErrorWithActionProps = {
  header: string
  message?: string
  buttonOnClick?: () => void
  buttonTitle: string
  buttonSubtitle?: string
  secondaryButtonOnClick?: () => void
  secondaryButtonTitle?: string
  showSupportLink?: boolean
}

export const ErrorWithAction = (props: ErrorWithActionProps) => {
  const navigate = useNavigate()
  const {
    header,
    message,
    buttonOnClick = () => navigate(0),
    buttonTitle,
    buttonSubtitle,
    secondaryButtonOnClick,
    secondaryButtonTitle,
    showSupportLink = true,
  } = props
  const { t } = useLocalization('feedback')

  return (
    <div className={styles.container}>
      <DisconnectedIllustration className={styles.errorIcon} />
      <h1 className={styles.title}>{header}</h1>
      <p className={styles.description}>
        {message && <span>{message}</span>}
        {showSupportLink && (
          <span>
            {t('fullPageError.message')} <a href={`mailto:${EMAILS.SUPPORT}`}>{EMAILS.SUPPORT}</a>
          </span>
        )}
      </p>
      <div className={styles.actionButtons}>
        <Button variant='primary' onClick={buttonOnClick}>
          {buttonTitle}
        </Button>
        {secondaryButtonTitle && (
          <Button variant='secondary' onClick={secondaryButtonOnClick}>
            {secondaryButtonTitle}
          </Button>
        )}
      </div>
      <p className={styles.description}>{buttonSubtitle}</p>
    </div>
  )
}
