import { useLocalization } from 'locales/i18n'
import { useMasterStatusLoadable } from 'ui/@store/master'
import { MasterStatus } from 'ui/master/types'

import styles from './styles.module.scss'

type ImoBriefProps = {
  isPadded?: boolean
}

export const ImoBrief = ({ isPadded = true }: ImoBriefProps) => {
  const { t } = useLocalization('master.overviewTab.sidebar.imoBrief')
  const { masterStatus } = useMasterStatusLoadable()

  if (masterStatus !== MasterStatus.Imo) return null

  return (
    <div className={`${styles.imoBriefContainer} ${isPadded ? styles.withPadding : ''}`}>
      <h1 className={styles.header}>{t('header')}</h1>
      <div className={styles.imoBriefList}>
        <h1 className={styles.title}>{t('description.title')}</h1>
        <p className={styles.subtitle}>{t('description.subtitle')}</p>
        <p className={styles.subtitle}>{t('description.footnote')}</p>
      </div>
    </div>
  )
}
