import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'

import { ampli } from 'ampli'
import { validateCardHolderName } from 'repositories/mx-api'
import { AddCardForm, registerNewCard } from 'ui/@components/add-card'
import { AddCardFormValues } from 'ui/@components/add-card/@components/add-card-form/types'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useCardsLoadable } from 'ui/@store/cards'

import { AddCard3DSModal } from '../add-card-3ds-modal'
import { AddCardErrorModal } from '../add-card-error-modal'

export type AddCardModalProps = {
  onSuccessCallback?: () => void
  validateCard?: boolean
}

export const AddCardModal = () => {
  const { renderModal, payload } = useGlobalModal()
  const { refetch: refetchCards, cards, setSelectedCard } = useCardsLoadable()
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const handleSubmit = async (values: AddCardFormValues) => {
    setHasSubmitted(true)
    await validateCardHolderName(values.cardHolderName)
    try {
      await registerNewCard({
        cardNumber: values.cardNumber,
        expiryMonth: values.expiryMonth,
        expiryYear: values.expiryYear,
        securityCode: values.securityCode,
      })
      setSelectedCard(null)
      refetchCards()
      ampli.submitCardDetailsSuccess()

      if (!payload?.validateCard && payload?.onSuccessCallback) {
        payload.onSuccessCallback()
      }
    } catch (error) {
      captureException(error)
      renderModal({ modalKey: ModalKey.AddCardError, content: <AddCardErrorModal /> })
    }
  }

  useEffect(() => {
    if (payload?.validateCard) {
      if (hasSubmitted && cards[0]) {
        renderModal({
          modalKey: ModalKey.AddCard3DSModal,
          content: <AddCard3DSModal />,
          payload: { cardId: cards[0].id },
        })
      }
    }
    // eslint-disable-next-line
  }, [cards])

  return (
    <ModalContent>
      <AddCardForm onSubmit={handleSubmit} loading={hasSubmitted} />
    </ModalContent>
  )
}
