import { useRef, useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'

import { useLocalization } from 'locales/i18n'
import { MasterWithImoAndMarket, MasterComingSoon } from 'types/master'
import { useWindowSize } from 'ui/@hooks/use-window-size'
import { Skeleton } from 'ui/@library/feedback/skeleton'

import { Yield } from '../yield'

import styles from './styles.module.scss'

type MasterDetailsStripProps = {
  className?: string
  master: MasterWithImoAndMarket | MasterComingSoon
  autoScrollThreshold?: number
}

const MIN_TRUNCATION = 5
const MARQUEE_SPEED = 30
const DEFAULT_AUTO_SCROLL_THRESHOLD = 0.5
const WIDTH_OFFSET = 64

const isMasterWithImoAndMarket = (
  master: MasterWithImoAndMarket | MasterComingSoon,
): master is MasterWithImoAndMarket => {
  return 'imo' in master
}

export const MasterImoDetailsStrip = ({
  className,
  master,
  autoScrollThreshold = DEFAULT_AUTO_SCROLL_THRESHOLD,
}: MasterDetailsStripProps) => {
  const { f } = useLocalization('exchange.spotlight.masterInfo')
  const { windowSize } = useWindowSize()
  const [artistNameOverflow, setArtistNameOverflow] = useState(0)
  const [autoScroll, setAutoScroll] = useState(false)
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false)

  const artistDetailRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)
  const masterNameRef = useRef<HTMLParagraphElement>(null)

  const masterIsWithImoAndMarket = isMasterWithImoAndMarket(master)

  const [artistName, setArtistName] = useState(master.artist.name)

  const getTruncatedArtistName = () => {
    let charactersToTruncate = Math.ceil(artistNameOverflow / MIN_TRUNCATION)
    if (charactersToTruncate < MIN_TRUNCATION) charactersToTruncate = MIN_TRUNCATION
    let truncatedArtistName = master.artist.name
    if (master.artist.name.length > charactersToTruncate) {
      truncatedArtistName = truncatedArtistName.slice(0, charactersToTruncate) + '...'
    }
    return truncatedArtistName
  }

  useEffect(() => {
    if (parentRef.current && artistDetailRef.current) {
      const isOverflowing =
        artistDetailRef.current.clientWidth + WIDTH_OFFSET > parentRef.current.clientWidth
      setArtistNameOverflow(
        isOverflowing
          ? artistDetailRef.current.clientWidth + WIDTH_OFFSET - parentRef.current.clientWidth
          : 0,
      )
    }
    if (artistNameOverflow > 0) {
      setArtistName(getTruncatedArtistName())
    }
    // eslint-disable-next-line
  }, [artistDetailRef, windowSize.width, artistNameOverflow])

  useEffect(() => {
    if (parentRef.current && masterNameRef.current) {
      const isOverflowing =
        masterNameRef.current.clientWidth > parentRef.current.clientWidth * autoScrollThreshold
      setAutoScroll(isOverflowing)
    }
  }, [masterNameRef, autoScrollThreshold, windowSize.width])

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.info}>
        <div className={styles.infoWrapper} ref={parentRef}>
          {!thumbnailLoaded && <Skeleton className={styles.skeleton} />}
          <img
            src={master.assets.thumbnail}
            alt={`${master.name} thumbnail`}
            className={`${styles.thumbnail} ${thumbnailLoaded ? styles.visible : styles.hidden}`}
            onLoad={() => setThumbnailLoaded(true)}
          />
          <div className={styles.songInfo}>
            <div className={styles.subLine}>
              {autoScroll ? (
                <div className={`${masterIsWithImoAndMarket && styles.autoScrollWrapper}`}>
                  <Marquee speed={MARQUEE_SPEED} className={styles.marquee}>
                    <p className={`${styles.subHeading} ${styles.autoScroll}`} ref={masterNameRef}>
                      {master.name}
                    </p>
                  </Marquee>
                </div>
              ) : (
                <p className={styles.subHeading} ref={masterNameRef}>
                  {master.name}
                </p>
              )}
              <p className={styles.subHeading}>
                {masterIsWithImoAndMarket && f.currency(master.imo.price)}
              </p>
            </div>
            <div className={styles.subLine} ref={artistDetailRef}>
              <p className={styles.artistName}>{artistName}</p>
              {masterIsWithImoAndMarket && <Yield master={master} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
