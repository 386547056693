import { useEffect } from 'react'
import { selectorFamily, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchShare } from 'repositories/mx-api'
import { ShareWithOrders } from 'types/share'

const shareQuery = selectorFamily<ShareWithOrders | null, string>({
  key: 'shareQuery',
  get: (shareUuid: string) => async () => shareUuid ? fetchShare(shareUuid) : null,
})

export const useShareLoadable = (shareUuid: string, { forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(shareQuery(shareUuid))
  const refetch = useRecoilRefresher_UNSTABLE(shareQuery(shareUuid))

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    share: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
