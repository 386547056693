import { ReactComponent as ShieldCheck } from 'assets/icons/shield-check.svg'
import { useLocalization } from 'locales/i18n'
import { IconWrapper } from 'ui/@library/data-display/icon-wrapper'
import { Button } from 'ui/@library/inputs/button'
import { FormContainer } from 'ui/@library/layout/form-container'

import styles from '../styles.module.scss'
import { OnboardingStepProps } from '../types'

export const VerifyIdentity = ({ onStepComplete, onVerify }: OnboardingStepProps) => {
  const { t } = useLocalization('onboarding')

  return (
    <div>
      <FormContainer className={styles.onboardingContainer}>
        <IconWrapper className={styles.iconSpacing}>
          <ShieldCheck />
        </IconWrapper>
        <h2 className={styles.title}>{t('verifyIdentity.heading')}</h2>
        <p className={styles.description}>{t('verifyIdentity.description')}</p>
        <div className={styles.buttonContainer}>
          <Button width='fluid' onClick={onVerify}>
            {t('actionButtons.verify')}
          </Button>
          <Button width='fluid' variant='ghost' onClick={onStepComplete}>
            {t('actionButtons.skip')}
          </Button>
        </div>
      </FormContainer>
    </div>
  )
}
