import { Link } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { Button } from 'ui/@library/inputs/button'

import styles from '../styles.module.scss'

export const NotFound = () => {
  const { t } = useLocalization('feedback.notFound')
  return (
    <RouteWrapper title={t('pageTitle')}>
      <div className={styles.container}>
        <h1 className={styles.title}>{t('header')}</h1>
        <p>{t('message')}</p>
        <Link to={Paths.HOME}>
          <Button>{t('goHome')}</Button>
        </Link>
      </div>
    </RouteWrapper>
  )
}
