import { CellContext, Row } from '@tanstack/react-table'
import { useMemo } from 'react'

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right-purple.svg'
import { useLocalization } from 'locales/i18n'
import { useFetchAuthUserRoyalties } from 'repositories/mx-api/hooks/use-fetch-auth-user-royalties'
import { RoyaltyPayouts } from 'types/auth-user'
import { Table } from 'ui/@library/data-display/table'
import { useColumnHelper } from 'ui/@library/data-display/table/use-table'
import { Loader } from 'ui/@library/feedback/loader'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useGlobalModal } from 'ui/@library/layout/global-modal/use-global-modal'

import { ColumnWidth, MobileTable } from '../mobile-table'
import { RoyaltyPayoutsList } from '../modals/royalty-payouts-list'

import styles from './styles.module.scss'

export type RoyaltyEarnedTableColumns = {
  masterName: string
  artistName: string
  amount: number
  thumbnail: string
  royalties: RoyaltyPayouts[]
}

export const RoyaltiesEarned = () => {
  const { t, f } = useLocalization('myAssets.overview.royaltiesEarnedTable')
  const { data: authUserRoyalties, loading } = useFetchAuthUserRoyalties()
  const { renderModal } = useGlobalModal()

  const royaltiesEarnedTableData = useMemo(() => {
    return authUserRoyalties.map((payout) => {
      return {
        thumbnail: payout.master.assets.thumbnail,
        masterName: payout.master.name,
        artistName: payout.master.artist.name,
        amount: payout.totalAmount,
        royalties: payout.royaltyPayouts,
      }
    })
    // eslint-disable-next-line
  }, [authUserRoyalties])

  const showThumbnail = (cell: CellContext<RoyaltyEarnedTableColumns, unknown>) => {
    return <img className={styles.thumbnailImg} src={cell.getValue() as string} alt='' />
  }

  const showAmount = (cell: CellContext<RoyaltyEarnedTableColumns, unknown>) => {
    return <span>{f.currency(cell.getValue() as number)}</span>
  }

  const columnDefinition = [
    {
      id: 'thumbnail',
      className: styles.thumbnail,
      cell: showThumbnail,
    },
    {
      id: 'masterName',
      className: styles.masterName,
      label: t('masterName'),
    },
    {
      id: 'artistName',
      className: styles.artistName,
      label: t('artistName'),
    },
    {
      id: 'amount',
      className: styles.amount,
      label: t('amount'),
      type: 'price',
      cell: showAmount,
    },
    {
      id: 'royalties',
      isDisplayColumn: true,
    },
  ]

  const columns = useColumnHelper<RoyaltyEarnedTableColumns>(columnDefinition)

  const navigateToMaster = (row: Row<RoyaltyEarnedTableColumns>) =>
    renderModal({
      content: <RoyaltyPayoutsList />,
      modalKey: ModalKey.RoyaltyPayoutsList,
      payload: row.original,
    })

  if (loading) return <Loader />

  if (!authUserRoyalties.length) return null

  return (
    <>
      <Table
        data={royaltiesEarnedTableData}
        columns={columns}
        headerRowClassName={styles.headerRow}
        rowClassName={styles.tableRow}
        rowOnClick={navigateToMaster}
        hideInMobile
      />
      <MobileTable
        columns={columnDefinition}
        tableData={royaltiesEarnedTableData}
        buttonElement={<ChevronRight />}
        rowOnClick={navigateToMaster}
        columnWidth={ColumnWidth.Flex}
      />
    </>
  )
}
