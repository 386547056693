import { OnboardingChecklist, OnboardingChecklistRaw } from 'types/auth-user'

import {
  sanitizeOnboardingFromRaw,
  sanitizeOnboardingToRaw,
} from '../@santizers/onboarding-checklist'
import { MxApi } from '../mx-api'

export const updateOnboardingChecklist = async (
  onboardingChecklist: Partial<OnboardingChecklist>,
): Promise<OnboardingChecklist> => {
  const onboardingChecklistPayload = sanitizeOnboardingToRaw(onboardingChecklist)

  const { data } = await MxApi.patch<OnboardingChecklistRaw>(
    '/auth-users/me/onboarding-checklist',
    onboardingChecklistPayload,
  )

  return sanitizeOnboardingFromRaw(data)
}
