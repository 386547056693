import { Browser } from '@capacitor/browser'
import { useEffect } from 'react'

import { ReactComponent as UnsupportedClientIllustration } from 'assets/svgs/maintenance.svg'
import { ExternalLinks, NativeAppLinks } from 'constants/external-links'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { fetchRoot } from 'repositories/mx-api'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { useAppEnvironment } from 'ui/@hooks/use-app-environment'
import { usePlatform } from 'ui/@hooks/use-platform'
import { Button } from 'ui/@library/inputs/button'

import styles from './styles.module.scss'

export const UnsupportedClient = () => {
  const { t } = useLocalization('unsupportedClient')
  const { isAndroid, isIOS, isWeb } = usePlatform()
  const { isProduction } = useAppEnvironment()

  const handleUpdate = async () => {
    if (isAndroid) {
      await Browser.open({
        url: isProduction ? NativeAppLinks.ANDROID_PRODUCTION : NativeAppLinks.ANDROID_STAGING,
      })
    } else if (isIOS) {
      await Browser.open({
        url: isProduction ? NativeAppLinks.IOS_PRODUCTION : NativeAppLinks.IOS_STAGING,
      })
    } else if (isWeb) {
      window.location.replace(Paths.HOME)
    }
  }

  const handleSupport = async () => {
    await Browser.open({ url: ExternalLinks.HELP_CENTER })
  }

  const init = async () => {
    try {
      const data = await fetchRoot()
      if (data) {
        window.location.replace(Paths.HOME)
      }
    } catch (error) {
      // Do nothing
    }
  }

  useEffect(() => {
    init()
  }, [])

  const getButtonLabel = () => {
    if (isAndroid) {
      return t('update.android')
    } else if (isIOS) {
      if (isProduction) {
        return t('update.ios.production')
      } else {
        return t('update.ios.staging')
      }
    } else {
      return t('refresh')
    }
  }

  return (
    <RouteWrapper title={t('pageTitle')}>
      <div className={styles.unsupportedClientContainer}>
        <UnsupportedClientIllustration className={styles.illustration} />
        <h2 className={styles.heading}>{isWeb ? t('heading.web') : t('heading.native')}</h2>
        <p className={styles.subHeading}>{isWeb ? t('subHeading.web') : t('subHeading.native')}</p>
        <Button variant='primary' onClick={handleUpdate}>
          {getButtonLabel()}
        </Button>
        {(isAndroid || isIOS) && (
          <Button onClick={handleSupport} variant='ghost'>
            {t('support')}
          </Button>
        )}
      </div>
    </RouteWrapper>
  )
}
