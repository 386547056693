import { atom, useRecoilState, useResetRecoilState } from 'recoil'

import { createPayIn as createPayInRequest, fetchPayIn } from 'repositories/mx-api'
import { Card, PayIn } from 'types/card'

import { useWalletLoadable } from './wallet'

export const payInState = atom<PayIn | null>({
  key: 'payIn',
  default: null,
})

export const usePayIn = () => {
  const [payIn, setPayIn] = useRecoilState(payInState)
  const { refetch: refetchWallet } = useWalletLoadable()

  const resetState = useResetRecoilState(payInState)

  const createPayIn = async (amount: number, card: Card) => {
    const payIn = await createPayInRequest(amount, card)
    setPayIn(payIn)
    refetchWallet()
    return payIn
  }

  const refetch = async () => {
    if (!payIn?.id) throw new Error('Cannot refetch before creating')
    const updatedPayIn = await fetchPayIn(payIn.id)
    setPayIn(updatedPayIn)
    return updatedPayIn
  }

  return { payIn, createPayIn, refetch, resetState }
}
