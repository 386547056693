import { BankAccounts, BankAccountsRaw } from 'types/bank-accounts'

import { sanitizeBankAccountsFromRaw } from '../@santizers/bank-accounts'
import { MxApi } from '../mx-api'

export const fetchBankAccounts = async (): Promise<BankAccounts[]> => {
  const { data } = await MxApi.get<BankAccountsRaw[]>('/auth-users/me/wallet/bankaccounts')

  return data.map(sanitizeBankAccountsFromRaw)
}
