import { useLocalization } from 'locales/i18n'
import { CardSelector } from 'ui/@components/card-selector'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useCardsLoadable } from 'ui/@store/cards'

import styles from './styles.module.scss'

export type CardOptionsModalProps = {
  onSaveCallBack?: () => void
  onAddCardSuccess?: () => void
  validateCard?: boolean
}

export const CardOptionsModal = () => {
  const { t } = useLocalization('addFunds.paymentOptions')
  const { loading } = useCardsLoadable({ forceRefetch: true })
  const { payload, setDisplayModal } = useGlobalModal()

  const handleSave = () => {
    if (payload?.onSaveCallBack) {
      payload.onSaveCallBack()
    } else {
      setDisplayModal(false)
    }
  }

  return (
    <ModalContent className={styles.container} loading={loading}>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>{t('title')}</h2>
        <div className={styles.descriptionWrapper}>
          <p className={styles.description}>{t('unverifiedCards')}</p>
          <p className={styles.description}>{t('sepaTransfer')}</p>
        </div>
      </div>
      <CardSelector
        cardLimitCallbackModal={ModalKey.CardOptionsModal}
        validateCard={payload?.validateCard}
        addCardSuccessCallback={payload?.onAddCardSuccess}
      />
      <Button onClick={handleSave} width='fluid'>
        {t('buttonText')}
      </Button>
    </ModalContent>
  )
}
