import { OrderBookRaw } from 'types/order'

import { sanitizeOrderBookFromRaw } from '../@santizers/order'
import { MxApi } from '../mx-api'

export const fetchOrderBook = async (masterUuid: string): Promise<OrderBookRaw> => {
  const { data } = await MxApi.get<OrderBookRaw>(`/masters/${masterUuid}/orderbook`)

  return sanitizeOrderBookFromRaw(data)
}
