import { useEffect } from 'react'

import { ampli } from 'ampli'
import { Order } from 'constants/trading'
import { useGTM, GTMEvents } from 'gtm'
import { useLocalization } from 'locales/i18n'
import { useBuyShare } from 'repositories/mx-api'
import { KycStatus } from 'types/auth-user'
import { BestPriceShare } from 'types/bid'
import { OrderType, ShareOrderStatus } from 'types/order'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { OrderLoading } from 'ui/@library/feedback/order-loading'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useAuthUserMasterSharesLoadable } from 'ui/@store/auth-user-master-shares'
import { useAuthUserMastersWithFractionsLoadable } from 'ui/@store/auth-user-masters-with-fractions'
import { useMarketOverviewLoadable } from 'ui/@store/market-overview'
import { useMasterLoadable } from 'ui/@store/master'
import { useOrderBookLoadable } from 'ui/@store/order-book'
import { useRecentTradesLoadable } from 'ui/@store/recent-trades'
import { useShareListLoadable } from 'ui/@store/share-list'
import { useWalletLoadable } from 'ui/@store/wallet'

import { ReviewKyc } from '../../../../@modals/kyc/review-kyc'
import { TradeActionType } from '../../master-overview/@components/banner-trader/types'
import { useTrader } from '../../master-overview/@components/banner-trader/use-trader'
import { AlreadySold } from '../already-sold'
import { OrderCompleted } from '../order-completed'
import { OrderFailed } from '../order-failed'

import { AgreementLinks } from './@components/agreement-links'
import { OrderContainer } from './@components/order-container'
import { OrderHandler } from './@components/order-handler'
import { OrderHeader } from './@components/order-header'
import {
  OrderQuantity,
  OrderSpecification,
  ServiceFee,
  SharePrice,
  OrderAmount,
} from './@components/order-specification'
import { ShareList } from './@components/share-list'
import styles from './styles.module.scss'

export type ReviewBestPriceBuyProps = {
  sharesToTrade: BestPriceShare[]
  numberOfShares?: number
  pricePerShare: number
  orderType: OrderType
  isUnique?: boolean
}

export const ReviewBestPriceBuy = () => {
  const { t } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation',
  )
  const { payload: modalPayload, renderModal } = useGlobalModal()
  const { refetch: refetchMarketOverview } = useMarketOverviewLoadable()
  const { master } = useMasterLoadable()
  const {
    wallet,
    refetch: refetchWallet,
    loading: walletLoading,
  } = useWalletLoadable({ forceRefetch: true })
  const { authUser } = useAuthUserLoadable()
  const { sendGTMEvent } = useGTM()
  const { setOrderType } = useTrader()

  const isKycValidated = authUser?.kycStatus === KycStatus.Validated

  useEffectOnce(() => {
    ampli.viewReviewBestPriceBuyModal({
      masterName: master?.name || '',
      masterUuid: master?.uuid || '',
    })
  })

  if (!isKycValidated)
    renderModal({
      modalKey: ModalKey.ReviewKyc,
      content: <ReviewKyc />,
    })

  const sharesToTrade: BestPriceShare[] = modalPayload?.sharesToTrade || []
  const tradeQuantity = modalPayload?.numberOfShares
  const accountBalance = wallet?.primaryBalance
  const totalWithoutFees = sharesToTrade.reduce((acc, share) => {
    return acc + share.price
  }, 0)
  const averagePricePerShare = totalWithoutFees / tradeQuantity
  const serviceFee = Math.floor(Order.SERVICE_CHARGE.IN_PERCENT * averagePricePerShare) / 100
  const isServiceFeeBelowMinimum = Math.abs(serviceFee) < Order.SERVICE_CHARGE.MINIMUM_PER_SHARE
  const finalServiceFee =
    tradeQuantity * (isServiceFeeBelowMinimum ? Order.SERVICE_CHARGE.MINIMUM_PER_SHARE : serviceFee)
  const totalOrderAmount = finalServiceFee + totalWithoutFees

  const {
    order: buyShareResponse,
    requestBuyShare,
    loading: buyShareLoading,
  } = useBuyShare(master?.uuid ?? '', sharesToTrade)
  const { refetch: refetchShareList } = useShareListLoadable({
    isAuthUserShares: false,
  })
  const { refetch: refetchAuthUserShareList } = useShareListLoadable({
    isAuthUserShares: true,
  })
  const { refetch: refetchAuthUserMastersWithFractions } = useAuthUserMastersWithFractionsLoadable()
  const { refetch: refetchAuthUserMasterShares } = useAuthUserMasterSharesLoadable()
  const { refetch: refetchOrderBook } = useOrderBookLoadable()
  const { refetch: refetchRecentTrades } = useRecentTradesLoadable()

  const handleBuyMarketOrder = async () => {
    try {
      await requestBuyShare()
      sendGTMEvent(GTMEvents.OrderPlaced, authUser?.uuid)
      setOrderType(null)
    } catch (error) {
      renderModal({
        modalKey: ModalKey.OrderFailed,
        content: <OrderFailed />,
      })
    }
  }

  useEffect(() => {
    if (buyShareResponse) {
      const filledShareOrders = buyShareResponse.shareOrders.filter(
        ({ status }) => status === ShareOrderStatus.Filled,
      )
      if (filledShareOrders.length === 0) {
        renderModal({
          modalKey: ModalKey.AlreadySold,
          content: <AlreadySold />,
        })
      } else {
        if (modalPayload?.isUnique) {
          ampli.placeUniqueBuyForListedPriceSuccess({
            masterName: master?.name || '',
            masterUuid: master?.uuid || '',
          })
        } else {
          ampli.bestPriceBuyOrderPlacedSuccess({
            masterName: master?.name || '',
            masterUuid: master?.uuid || '',
          })
        }
        renderModal({
          modalKey: ModalKey.OrderCompleted,
          content: <OrderCompleted />,
          payload: {
            tradeActionType: TradeActionType.Buy,
            isBulkMarketFlow: true,
            totalShares: filledShareOrders.length,
            resolvedShares: sharesToTrade.length,
          },
        })
      }
      refetchAuthUserMastersWithFractions()
      refetchAuthUserMasterShares()
      refetchShareList()
      refetchAuthUserShareList()
      refetchWallet()
      refetchOrderBook()
      refetchRecentTrades()
      refetchMarketOverview()
    }
    // eslint-disable-next-line
  }, [buyShareResponse])

  if (buyShareLoading) {
    return (
      <ModalContent>
        <OrderLoading />
      </ModalContent>
    )
  }

  return (
    <ModalContent className={styles.reviewOrderContainer}>
      <OrderContainer>
        <OrderHeader
          title={modalPayload?.isUnique ? t('title.buyUnique') : t('title.buyMarket')}
          accountBalance={accountBalance}
        />
        <OrderSpecification>
          <OrderQuantity quantity={tradeQuantity} />
          <SharePrice
            title={tradeQuantity === 1 ? t('price.unique') : t('price.market')}
            price={averagePricePerShare}
          />
          <OrderAmount title={t('total.withoutFees')} amount={totalWithoutFees} />
          <ServiceFee title={t('serviceFee')} serviceFee={finalServiceFee} />
          <hr className={styles.dividerHorizontal} />
          <OrderAmount title={t('total.withFees')} amount={totalOrderAmount} highlight />
        </OrderSpecification>
        <OrderHandler
          title={t('buttons.confirmOrder')}
          handleOrderAction={handleBuyMarketOrder}
          isLoading={buyShareLoading || walletLoading}
        />
        <AgreementLinks />
      </OrderContainer>
      <hr className={styles.dividerVertical} />
      <ShareList
        tradeQuantity={tradeQuantity}
        tradePrice={averagePricePerShare}
        sharesToTrade={sharesToTrade}
        orderType={OrderType.BestBid}
      />
    </ModalContent>
  )
}
