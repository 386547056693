import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

import { useMessages } from '../../use-messages'
import { BannerContainer, BannerMessage } from '../banner'

import styles from './styles.module.scss'

export const AnnouncementBanner = () => {
  const { activeMessage } = useMessages()

  if (!activeMessage) return null

  return (
    <BannerContainer variant={activeMessage.variant}>
      <BannerMessage
        content={activeMessage.content}
        contentType='html'
        icon={<InfoIcon className={styles.infoIcon} />}
      />
    </BannerContainer>
  )
}
