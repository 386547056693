import { useLocalization } from 'locales/i18n'
import { Spinner } from 'ui/@library/feedback/spinner'
import { useMasterStatusLoadable } from 'ui/@store/master'
import { useOrderBookLoadable } from 'ui/@store/order-book'
import { MasterStatus } from 'ui/master/types'

import styles from './styles.module.scss'

export const OrderBook = () => {
  const { t, f } = useLocalization('master.overviewTab.sidebar.orderBook')
  const { masterStatus } = useMasterStatusLoadable()
  const { orderBook, loading } = useOrderBookLoadable({ forceRefetch: true })

  if (masterStatus !== MasterStatus.Market) return null

  return (
    <div className={styles.orderbookContainer}>
      <h1 className={styles.title}>{t('title')}</h1>

      {!loading ? (
        <div className={styles.tableContainer}>
          <div className={styles.bidTable}>
            <div className={styles.tableHeader}>
              <p className={styles.tableHeading}>{t('size')}</p>
              <p className={styles.tableHeading}>{t('tableBidHeader')}</p>
            </div>
            <div className={styles.tableRowContainer}>
              {orderBook?.bids.map((item) => (
                <div className={styles.tableRowWrapper} key={item.price}>
                  <p className={styles.size}>{item.quantity}</p>
                  <p className={styles.amount}>{f.currency(item.price)}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.askTable}>
            <div className={styles.tableHeader}>
              <p className={styles.tableHeading}>{t('tableAskHeader')}</p>
              <p className={styles.tableHeading}>{t('size')}</p>
            </div>
            <div className={styles.tableRowContainer}>
              {orderBook?.asks.map((item) => (
                <div className={styles.tableRowWrapper} key={item.price}>
                  <p className={styles.amount}>{f.currency(item.price)}</p>
                  <p className={styles.size}>{item.quantity}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Spinner className={styles.spinner} width='fluid' />
      )}
    </div>
  )
}
