import { MxApi } from '../mx-api'
import { downloadBlobAsFile } from '../utils'

export const fetchAuthUserTaxStatement = async (startDate: string, endDate: string) => {
  const { data } = await MxApi.get('/auth-users/me/accounting/tax-statement', {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    responseType: 'blob',
  })

  downloadBlobAsFile(data, 'mx-tax-statement.csv')
}
