import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'

import { ampli } from 'ampli'
import { useLocalization } from 'locales/i18n'
import { validateCardHolderName } from 'repositories/mx-api'
import { AddCardForm, registerNewCard } from 'ui/@components/add-card/@components/add-card-form'
import { AddCardFormValues } from 'ui/@components/add-card/@components/add-card-form/types'
import { Button } from 'ui/@library/inputs/button'
import { useCardsLoadable } from 'ui/@store/cards'

import { AddCardStepProps } from '../../types'

export const AddCardOnboardingForm = ({ steps, setCurrentStep, onSkip }: AddCardStepProps) => {
  const { t } = useLocalization('onboarding.addCard')
  const { cards, refetch: refetchCards } = useCardsLoadable()
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const handleSubmit = async (values: AddCardFormValues) => {
    setHasSubmitted(true)
    await validateCardHolderName(values.cardHolderName)
    try {
      await registerNewCard({
        cardNumber: values.cardNumber,
        expiryMonth: values.expiryMonth,
        expiryYear: values.expiryYear,
        securityCode: values.securityCode,
      })
      refetchCards()
      ampli.submitCardDetailsSuccess()
    } catch (error) {
      captureException(error)
      setCurrentStep(steps.addCardError)
    }
  }

  useEffect(() => {
    if (hasSubmitted && cards.length) {
      setCurrentStep(steps.addCardValidation)
    }
    // eslint-disable-next-line
  }, [cards])

  return (
    <>
      <AddCardForm onSubmit={handleSubmit} loading={hasSubmitted} />
      <Button width='fluid' variant='ghost' onClick={onSkip}>
        {t('skipForNow')}
      </Button>
    </>
  )
}
