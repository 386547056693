import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { useMasterLoadable } from 'ui/@store/master'

import { Banner } from '../../../banners/@components/banner'

import styles from './styles.module.scss'

export const MaxSharesModal = () => {
  const { setDisplayModal } = useGlobalModal()
  const { master } = useMasterLoadable()
  const { t, f } = useLocalization('master.overviewTab.sidebar.banner')

  const handleClose = () => {
    setDisplayModal(false)
  }

  if (!master || !master.imo.launchDate || !master.imo.expiryDate) return null

  return (
    <ModalContent className={styles.container}>
      <Banner
        title={t('imoLimitReached.title')}
        logo={master.assets.logo}
        description={t('imoLimitReached.description', {
          expireDate: f.date(master.imo.expiryDate),
        })}
      />
      <Button onClick={handleClose} variant='ghost'>
        {t('close')}
      </Button>
    </ModalContent>
  )
}
