import { Trans } from 'react-i18next'

import { EMAILS } from 'constants/emails'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'

import { PaymentMethodModal } from '../payment-method-modal'

import styles from './styles.module.scss'

export const Error = () => {
  const { t } = useLocalization('addFunds.error')
  const { renderModal } = useGlobalModal()

  const handleRetryAction = () => {
    renderModal({ modalKey: ModalKey.PaymentMethod, content: <PaymentMethodModal /> })
  }

  return (
    <ModalContent className={styles.errorContainer}>
      <div className={styles.topWrapper}>
        <h1 className={styles.title}>{t('title')}</h1>
        <Trans
          t={t}
          components={[
            // eslint-disable-next-line
            <a className={styles.email} href={`mailto:${EMAILS.SUPPORT}`} key={EMAILS.SUPPORT} />,
          ]}
        >
          <p>
            {t('message', {
              emailAddress: EMAILS.SUPPORT,
            })}
          </p>
        </Trans>
      </div>
      <Button width='fluid' onClick={handleRetryAction}>
        {t('retry')}
      </Button>
    </ModalContent>
  )
}
