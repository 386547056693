import { useLocalization } from 'locales/i18n'
import { AuthUserAccountAuthenticationProvider } from 'types/auth-user'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { UpdatePasswordModal } from 'ui/account/@components/update-password/@components/update-password-modal'

import { SectionContainer, SectionHeader, SectionTitle, SectionButton } from '../section'

export const UpdatePassword = () => {
  const { t } = useLocalization('account.updatePassword')
  const { renderModal } = useGlobalModal()
  const { authUser } = useAuthUserLoadable()

  const handleUpdatePassword = () => {
    renderModal({ modalKey: ModalKey.UpdatePassword, content: <UpdatePasswordModal /> })
  }

  if (authUser?.authMethod !== AuthUserAccountAuthenticationProvider.EmailPassword) return null

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{t('title')}</SectionTitle>
        <SectionButton onClick={handleUpdatePassword}>{t('buttonText')}</SectionButton>
      </SectionHeader>
    </SectionContainer>
  )
}
