import { CSVLink } from 'react-csv'

import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'

import { TransactionHistoryTableColumns } from '../../types'

import styles from './styles.module.scss'

type ExportAsCsvProps = {
  tableData: Array<TransactionHistoryTableColumns>
}

export const ExportAsCsv = ({ tableData }: ExportAsCsvProps) => {
  const { t } = useLocalization('myAssets.transactionHistory')
  const CSVheaders = [
    { key: 'date', label: t('columns.date') },
    { key: 'transactionType', label: t('columns.transactionType') },
    { key: 'title', label: t('columns.title') },
    { key: 'tokenId', label: t('columns.tokenId') },
    { key: 'fees', label: t('columns.fees') },
    { key: 'price', label: t('columns.price') },
    { key: 'total', label: t('columns.total') },
  ]

  return (
    <CSVLink
      data={tableData}
      headers={CSVheaders}
      filename='Transaction-history.csv'
      className={styles.buttonWrapper}
    >
      <Button variant='ghost' className={styles.exportButton}>
        <DownloadIcon />
        {t('download')}
      </Button>
    </CSVLink>
  )
}
