import { axisLeft, BaseType, ScaleLinear, Selection } from 'd3'
import { useEffect } from 'react'

import { useLocalization } from 'locales/i18n'
import { useWindowSize } from 'ui/@hooks/use-window-size'

import { GraphConstants } from '../../constants'

import styles from './styles.module.scss'

type YAxisProps<T extends BaseType> = {
  graph: Selection<T, unknown, null, undefined>
  width: number
  yScale: ScaleLinear<number, number, never>
}

export const YAxis = <T extends BaseType>({ graph, width, yScale }: YAxisProps<T>) => {
  const { windowSize } = useWindowSize()
  const { width: windowWidth } = windowSize
  const { f } = useLocalization()

  useEffect(() => {
    if (graph) {
      const yAxis = axisLeft(yScale.nice())
        .ticks(5)
        .tickSize(0)
        .tickFormat((value) => f.number(value.valueOf(), { compact: 'short' }))
      graph.select(`.${styles.yAxis}`).remove()
      graph
        .append('g')
        .attr('class', `${styles.yAxis}`)
        .attr('transform', `translate(${width}, ${GraphConstants.PADDING.TOP.MEDIUM})`)
        .call(yAxis)
      graph.selectAll('.domain').remove()
    }
    // eslint-disable-next-line
  }, [graph, windowWidth])

  return <g className={styles.yAxis} />
}
