import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

import MXLogoMini from 'assets/images/mx-logo-mini.svg'
import MXLogo from 'assets/images/mx-logo.png'
import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type StaticPageWrapperProps = {
  children: ReactNode
  title?: string
}

export const StaticPageWrapperWeb = ({ children, title }: StaticPageWrapperProps) => {
  const { t } = useLocalization('components')
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{t('pageWrapperTitle', { pageTitle: title })}</title>
      </Helmet>
      <div className={styles.container}>
        <div className={`${styles.contents}`}>
          <img src={MXLogo} className={styles.logo} alt='' />
          <img src={MXLogoMini} className={styles.logoMini} alt='' />
          {children}
        </div>
      </div>
    </div>
  )
}
