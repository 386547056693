import { MutableRefObject } from 'react'

import {
  AggregatedTimeSeriesData,
  CompositeTimeSeriesData,
  CompositeTimeSeriesDataArray,
} from 'types/metrics'

export type UseGraphProps<T> = {
  data: CompositeTimeSeriesDataArray
  graphRef: MutableRefObject<T>
}

export type GraphTransformationsType = (
  event: Touch | MouseEvent | undefined,
  tooltipWidth?: number,
) => {
  tooltipPosition: number
  dataPoint: CompositeTimeSeriesData
  bisectorPosition: {
    posX: number
    posY: number
  }
}

export const isAggregatedTimeSeriesDataPoint = (
  dataPoint: CompositeTimeSeriesData,
): dataPoint is AggregatedTimeSeriesData => {
  return (
    typeof dataPoint === 'object' &&
    'timestamp' in dataPoint &&
    'value' in dataPoint &&
    Array.isArray(dataPoint.timestamp) &&
    dataPoint.timestamp.length === 2
  )
}

export const isAggregatedTimeSeriesData = (
  data: CompositeTimeSeriesDataArray,
): data is AggregatedTimeSeriesData[] => {
  const [dataPoint] = data
  return dataPoint ? isAggregatedTimeSeriesDataPoint(dataPoint) : false
}
