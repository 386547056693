// Maintain consistency with the error codes in the backend
export const CustomErrorCodes = {
  INVALID_HEADER: '101',
  NOT_AUTHENTICATED: '102',
  EXPIRED_TOKEN: '103',
  INVALID_CLAIMS: '104',
  INVALID_TOKEN: '105',
  RESOURCE_EXISTS: '106',
  ONBOARDING_INCOMPLETE: '107',
  NOT_FOR_SALE: '108',
  MANGOPAY_ERROR: '109',
  FILE_SIZE_INCORRECT: '110',
  FILE_FORMAT_INCORRECT: '111',
  BLOCKED_USER: '112',
  FILL_OR_KILL: '113',
  UNDER_MAINTENANCE: '114',
  AUTH0_USER_ACCOUNT_CONFLICT: '115',
  MASTER_OWNER_ORDER_NOT_ACCEPTABLE: '116',
  UNSUPPORTED_CLIENT: '117',
  ACCOUNT_PENDING_FOR_DELETION: '119',
  INVALID_REFERRAL_CODE: '120',
  REFERRAL_CODES_CANNOT_BE_USED_BY_EXISTING_ACCOUNTS: '121',
  TOO_MANY_VERIFICATION_EMAILS_SENT: '122',
  INVALIDATED_SESSION: '123',
  SIGNUPS_DISABLED: '124',
}
