import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
  ButtonTypeEnum,
} from '@novu/notification-center'
import { useNavigate } from 'react-router-dom'

import { AccessStatus } from 'types/auth-user'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useAuthUserSubscriberToken } from 'ui/@store/auth-user-subscriber-token'

import { notificationCenterStyles } from './styles'
import styles from './styles.module.scss'

const NOVU_APPLICATION_IDENTIFIER = process.env.REACT_APP_NOVU_APPLICATION_IDENTIFIER

export const NotificationCenter = () => {
  const { authUser } = useAuthUserLoadable()
  const { token: subscriberHash } = useAuthUserSubscriberToken()
  const navagate = useNavigate()

  if (
    !NOVU_APPLICATION_IDENTIFIER ||
    !authUser ||
    !subscriberHash ||
    authUser.accessStatus !== AccessStatus.EarlyAccess
  )
    return null

  const handleOnNotificationClick = (message: IMessage) => {
    const redirectUrl = message?.cta?.data?.url
    if (redirectUrl) {
      navagate(redirectUrl)
    }
  }

  const handleOnActionClick = async (
    _templateIdentifier: string,
    _btnType: ButtonTypeEnum,
    notification: IMessage,
  ) => {
    const redirectUrl = notification?.cta?.data?.url
    if (redirectUrl) {
      navagate(redirectUrl)
    }
  }

  return (
    <NovuProvider
      subscriberId={authUser.uuid}
      backendUrl='https://eu.api.novu.co'
      socketUrl='https://eu.ws.novu.co'
      applicationIdentifier={NOVU_APPLICATION_IDENTIFIER || ''}
      styles={notificationCenterStyles}
      subscriberHash={subscriberHash}
    >
      <PopoverNotificationCenter
        colorScheme='light'
        footer={() => <></>}
        showUserPreferences={false}
        onNotificationClick={handleOnNotificationClick}
        onActionClick={handleOnActionClick}
      >
        {({ unseenCount }: { unseenCount?: number }) => (
          <div className={styles.bellWrapper}>
            <NotificationBell unseenCount={unseenCount} />
          </div>
        )}
      </PopoverNotificationCenter>
    </NovuProvider>
  )
}
