import { useEffect } from 'react'
import {
  atom,
  selector,
  useRecoilRefresher_UNSTABLE,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'

import { fetchMaster } from 'repositories/mx-api'
import { Imo, ImoStatus } from 'types/imo'
import { Market } from 'types/market'
import { MasterWithImoAndMarket } from 'types/master'
import { MasterStatus } from 'ui/master/types'

export const masterUuidState = atom<string | null>({
  key: 'masterUuid',
  default: null,
})

export const masterQuery = selector<MasterWithImoAndMarket | null>({
  key: 'masterQuery',
  get: async ({ get }) => {
    const masterUuid = get(masterUuidState)
    if (!masterUuid) return null
    return fetchMaster(masterUuid)
  },
})

export const useMasterLoadable = (masterUuid?: string, { forceRefetch = false } = {}) => {
  const setMasterUuid = useSetRecoilState(masterUuidState)
  const { state, contents } = useRecoilValueLoadable(masterQuery)
  const refetch = useRecoilRefresher_UNSTABLE(masterQuery)

  useEffect(() => {
    if (masterUuid) setMasterUuid(masterUuid)
  }, [masterUuid, setMasterUuid])

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    master: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}

const imoSelector = selector<Imo | null>({
  key: 'imoSelector',
  get: async ({ get }) => {
    const master = get(masterQuery)
    return master?.imo ?? null
  },
})

export const useImoLoadable = () => {
  const { state, contents } = useRecoilValueLoadable(imoSelector)

  return {
    imo: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
  }
}

export const marketSelector = selector<Market | null>({
  key: 'marketSelector',
  get: async ({ get }) => {
    const master = get(masterQuery)
    return master?.market ?? null
  },
})

export const useMarketLoadable = () => {
  const { state, contents } = useRecoilValueLoadable(marketSelector)

  return {
    market: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
  }
}

export const useMasterStatusLoadable = () => {
  const { master, loading, error } = useMasterLoadable()
  // Need to update this hook

  return {
    masterStatus:
      master?.imo.status !== ImoStatus.Completed ? MasterStatus.Imo : MasterStatus.Market,
    isImo: master?.imo.status !== ImoStatus.Completed,
    isMarket: master?.imo.status === ImoStatus.Completed,
    loading,
    error,
  }
}
