import { Link } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { ImoStatus } from 'types/imo'
import { MasterWithImoAndMarket } from 'types/master'
import { ImoCountdown } from 'ui/@components/imo-countdown'
import { useWindowSize } from 'ui/@hooks/use-window-size'
import {
  Hero,
  MasterOwners,
  LiveTag,
  MasterInfo,
  MasterImoDetailsStrip,
} from 'ui/exchange/@components/exchange-card'
import { ExchangeCardSize } from 'ui/exchange/types'

import styles from './styles.module.scss'

type ImoProps = {
  master: MasterWithImoAndMarket
  onCardClick: () => void
}

export const ImoCard = ({ master, onCardClick }: ImoProps) => {
  const { viewports } = useWindowSize()
  const getHeroAsset = () => {
    if (viewports.isMobile && master.assets.heroCompact) {
      return master.assets.heroCompact
    }
    return master.assets.hero
  }

  return (
    <Link to={`${Paths.MASTER.MAIN}/${master.uuid}`} key={master.uuid} onClick={onCardClick}>
      <div className={styles.imoCard}>
        <Hero
          mediaSrc={getHeroAsset()}
          placeholder={master.assets.placeholder}
          size={ExchangeCardSize.Large}
        >
          {master.imo.status === ImoStatus.Active && <LiveTag />}
          <MasterInfo
            masterName={master.name}
            artistName={master.artist.name}
            size={ExchangeCardSize.Large}
            className={styles.masterInfo}
          />
          <MasterOwners
            masterOwners={master.metaData.ownedByWithAvatars}
            className={styles.masterOwnerWrapper}
          />

          {master.imo.launchDate && master.imo.expiryDate && (
            <ImoCountdown
              imoStatus={master.imo.status}
              expiryDate={master.imo.expiryDate}
              launchDate={master.imo.launchDate}
              className={styles.countdownWrapper}
            />
          )}
        </Hero>
        <MasterImoDetailsStrip master={master} className={styles.masterDetailsWrapper} />
      </div>
    </Link>
  )
}
