import { INotificationCenterStyles } from '@novu/notification-center'

const colorPrimary = '#7e19bf'
const colorPrimaryAlt = '#a844e7'
const colorRed = '#e85656'
const colorRedLight = '#f47171'
const textColorPrimary = 'black'

const transitionBase = 'all 200ms ease-in-out'

export const notificationCenterStyles: INotificationCenterStyles = {
  layout: {
    root: {
      '.mantine-Popover-dropdown': {
        backgroundColor: colorRed,
      },
      'top': '0px',
      'left': '0px',
      'borderRadius': '4px',
      '@media screen and (max-width: 1024px)': {
        width: '100vw',
        height: '100dvh',
      },
    },
  },
  bellButton: {
    root: {
      svg: {
        width: '24px',
        height: '24px',
      },
    },
    dot: {
      rect: {
        strokeWidth: '0',
        width: '6px',
        height: '6px',
        x: 9,
      },
    },
  },
  header: {
    markAsRead: {
      color: colorPrimary,
    },
    title: { color: textColorPrimary },
  },
  unseenBadge: {
    root: { background: colorRed, borderRadius: '50%', width: '24px', height: '24px', margin: 0 },
  },
  loader: {
    root: {
      stroke: colorPrimary,
    },
  },
  notifications: {
    listItem: {
      layout: {
        borderRadius: '4px',
        color: textColorPrimary,
      },
      title: {
        fontSize: '15px',
        fontWeight: '400',
      },
      unread: {
        '::before': { background: colorPrimary },
      },
      buttons: {
        primary: {
          'transition': transitionBase,
          'background': colorPrimary,
          ':hover': {
            background: colorPrimaryAlt,
          },
        },
        secondary: {
          'background': colorRed,
          ':hover': {
            background: colorRedLight,
          },
        },
      },
    },
  },
}
