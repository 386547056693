import styles from './styles.module.scss'

type MetricCardProps = {
  children: React.ReactNode
  className?: string
}

export const MetricCard = ({ children, className }: MetricCardProps) => {
  return <div className={`${styles.metricsCard} ${className}`}>{children}</div>
}
