import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react'

import { useLocalization } from 'locales/i18n'
import { Navbar } from 'ui/@components/navbar'
import { HeaderTags } from 'ui/@components/route-wrapper/@components/header-tags'
import { MarqueeBanner } from 'ui/@components/route-wrapper/@components/marquee-banner'
import { GlobalModal } from 'ui/@library/layout/global-modal'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { MobileTrader } from 'ui/master/@components/master-overview/@components/banner-trader/@components/mobile-trader'

import { BottomNavbar } from '../bottom-navbar'
import { MessageBanner } from '../message-banner'
import { useMessages } from '../message-banner/use-messages'

import styles from './styles.module.scss'
import { RouteWrapperProps } from './types'

export const RouteWrapperNative = ({
  headers,
  children,
  title,
  description,
  keywords,
  pageUrl,
  imageUrl,
  allowsBackNavigation,
  onRefresh,
  noPadding = false,
}: RouteWrapperProps) => {
  const { t } = useLocalization('components')
  const { refetchAuthUser } = useAuthUserLoadable()
  const { refetchMessages } = useMessages()

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    if (onRefresh) {
      refetchAuthUser()
      refetchMessages()
      onRefresh()
    } else {
      window.location.reload()
    }
    setTimeout(() => {
      event.detail.complete()
    }, 2000)
  }

  return (
    <>
      <IonHeader class={styles.IonHeader}>
        <MarqueeBanner />
        <Navbar allowsBackNavigation={allowsBackNavigation} />
      </IonHeader>
      <IonContent>
        <IonRefresher
          slot='fixed'
          onIonRefresh={handleRefresh}
          className={styles.refresher}
          pullFactor={0.5}
          pullMin={100}
          pullMax={200}
        >
          <IonRefresherContent />
        </IonRefresher>
        <div className={styles.wrapper}>
          <HeaderTags
            title={t('pageWrapperTitle', { pageTitle: title })}
            description={description}
            keywords={keywords}
            pageUrl={pageUrl}
            imageUrl={imageUrl}
          />
          <MessageBanner />
          {headers}
          <div className={`${styles.container} ${noPadding && styles.noPaddingContainer}`}>
            {children}
          </div>
        </div>
      </IonContent>
      <BottomNavbar />
      <MobileTrader />
      <GlobalModal />
    </>
  )
}
