import { select, pointer, scaleLinear, axisBottom } from 'd3'
import { useEffect, useRef } from 'react'

import { useLocalization } from 'locales/i18n'
import { useWindowSize } from 'ui/@hooks/use-window-size'

import { GraphConstants } from '../constants'

import styles from './styles.module.scss'

type SliderProps = {
  className?: string
  maxKey: number
  valueMultiplier: number
}

const roundToNearest = (value: number) => {
  const magnitudeOrder = Math.floor(Math.log10(value))
  const power = Math.pow(10, magnitudeOrder)
  const roundedValue = Math.ceil(value / power) * power
  return roundedValue
}

const getNumberOfTicks = (value: number) => {
  const numberOfDigits = Math.floor(Math.log10(value))
  const power = Math.pow(10, numberOfDigits)
  const tickCount = Math.floor(value / power)
  if (tickCount > 10 || tickCount === 1 || tickCount === 2 || tickCount === 5) {
    return 10
  } else if (tickCount < 5) {
    return tickCount * 2
  } else {
    return tickCount
  }
}

export const Slider = ({ className, maxKey, valueMultiplier }: SliderProps) => {
  const roundedMaxKey = roundToNearest(maxKey)
  const roundedMaxValue = roundedMaxKey * valueMultiplier
  const { windowSize } = useWindowSize()
  const { width: windowWidth } = windowSize
  const { t, f } = useLocalization('master.overviewTab.statistics.royaltyDistribution.slider')
  const lineGraphRef = useRef(null)
  const legendRef = useRef(null)
  const crosshairRef = useRef(null)
  const isDragging = useRef(false)

  const graphSvgWidth = lineGraphRef.current
    ? parseInt(select(lineGraphRef.current).style('width'))
    : 0
  const crosshair = select(crosshairRef.current)

  useEffect(() => {
    if (crosshair && lineGraphRef.current && roundedMaxKey) {
      const width = parseInt(select(lineGraphRef.current).style('width'))
      const height = parseInt(select(lineGraphRef.current).style('height'))
      const totalVerticalPadding =
        GraphConstants.PADDING.BOTTOM.LARGE + GraphConstants.PADDING.TOP.LARGE
      const innerHeight = height - totalVerticalPadding
      const totalHorizontalPadding =
        GraphConstants.PADDING.LEFT.LARGE + GraphConstants.PADDING.RIGHT.LARGE
      const innerWidth = width - totalHorizontalPadding
      const midPoint = innerWidth / 2 + totalHorizontalPadding / 2
      const lineGraph = select(lineGraphRef.current)
      const bisector = select(lineGraphRef.current).select(`.${styles.bisector}`)
      const legend = select(legendRef.current)
      const crosshair = select(crosshairRef.current)
      const transitionDuration = 200

      const boundingRect = {
        top: GraphConstants.PADDING.TOP.LARGE,
        left: GraphConstants.PADDING.LEFT.SMALL,
        bottom: height - GraphConstants.PADDING.BOTTOM.LARGE,
        right: width - GraphConstants.PADDING.RIGHT.SMALL,
      }

      const xScale = scaleLinear()
        .domain([0, roundedMaxKey])
        .range([boundingRect.left, boundingRect.right])

      const xAxis = axisBottom(xScale)
        .ticks(getNumberOfTicks(roundedMaxKey))
        .tickFormat((value, index) =>
          index === 0 || index === getNumberOfTicks(roundedMaxKey)
            ? f.number(value.valueOf(), { compact: 'short' })
            : '',
        )

      const highlightLine = lineGraph
        .select(`.${styles.xAxisHighlightLine}`)
        .attr('x1', boundingRect.left)
        .attr('y1', boundingRect.bottom)
        .attr('x2', boundingRect.left)
        .attr('y2', boundingRect.bottom)
        .raise()

      const bisectorLine = bisector
        .select(`.${styles.line}`)
        .attr('x1', boundingRect.left)
        .attr('y1', 20)
        .attr('x2', boundingRect.left)
        .attr('y2', innerHeight)

      const shadow = bisector
        .select(`.${styles.shadow}`)
        .attr('r', GraphConstants.BISECTOR.SLIDER.SHADOW_SIZE.DEFAULT)

      crosshair
        .attr('r', GraphConstants.BISECTOR.SLIDER.TOOLTIP_SIZE)
        .on('mouseover', () => {
          shadow
            .transition()
            .duration(transitionDuration)
            .attr('r', GraphConstants.BISECTOR.SLIDER.SHADOW_SIZE.HOVER)
        })
        .on('mouseleave', () => {
          shadow
            .transition()
            .duration(transitionDuration)
            .attr('r', GraphConstants.BISECTOR.SLIDER.SHADOW_SIZE.DEFAULT)
        })

      const moveBisector = (xValue: number, key: number, value: number) => {
        // Set limits
        if (xValue < boundingRect.left) xValue = boundingRect.left
        else if (xValue > boundingRect.right) xValue = boundingRect.right

        if (key < 0) key = 0
        else if (key > roundedMaxKey) key = roundedMaxKey

        if (value < 0) value = 0
        else if (value > roundedMaxValue) value = roundedMaxValue

        let legendPosition = xValue
        if (xValue < GraphConstants.LEGEND.WIDTH / 2 + boundingRect.left / 2)
          legendPosition = GraphConstants.LEGEND.WIDTH / 2 + boundingRect.left / 2
        else if (xValue > width - GraphConstants.LEGEND.WIDTH / 2 - boundingRect.left / 2)
          legendPosition = width - GraphConstants.LEGEND.WIDTH / 2 - boundingRect.left / 2

        // Update legend values
        legend.select('#royalty').text(`${f.currency(value, { compact: 'default' })}/${t('share')}`)

        // Update positions
        bisectorLine.attr('x1', xValue).attr('x2', xValue)
        highlightLine.attr('x2', xValue)
        legend.style('transform', `translateX(calc(${legendPosition}px - 50%)`)
        crosshair.style('transform', `translate(${xValue}px, ${innerHeight}px)`)
        shadow.style('transform', `translate(${xValue}px, ${innerHeight}px)`)
      }

      // Set default center position
      moveBisector(midPoint, roundedMaxKey / 2, roundedMaxValue / 2)

      const handleDragMovement = (event: TouchEvent & MouseEvent) => {
        const isTouchEvent =
          event.type === 'touchstart' || event.type === 'touchmove' || event.type === 'touchend'
        const mobileOffset = isTouchEvent ? 20 : 0

        // Get tooltip location from mouse event
        let xPos
        if (isTouchEvent && 'touches' in event) {
          xPos = event?.touches[0]?.clientX
        } else {
          xPos = pointer(event)[0]
        }

        const xValue = (xPos || 0) - mobileOffset
        const key = xScale.invert(xValue)
        const value = xScale.invert(xValue) * valueMultiplier

        if (event.type === 'mousedown' || event.type === 'touchstart') {
          isDragging.current = true
          moveBisector(xValue, key, value)
          shadow
            .transition()
            .duration(transitionDuration)
            .attr('class', `${styles.shadow} ${styles.active}`)
            .attr('r', GraphConstants.BISECTOR.SLIDER.SHADOW_SIZE.HOVER)
        } else if (
          (event.type === 'mousemove' || event.type === 'touchmove') &&
          isDragging.current
        ) {
          moveBisector(xValue, key, value)
          shadow
            .transition()
            .duration(transitionDuration)
            .attr('class', `${styles.shadow} ${styles.active}`)
            .attr('r', GraphConstants.BISECTOR.SLIDER.SHADOW_SIZE.HOVER)
        } else if (
          event.type === 'mouseup' ||
          event.type === 'mouseleave' ||
          event.type === 'touchend'
        ) {
          isDragging.current = false
          shadow
            .transition()
            .duration(transitionDuration)
            .attr('class', `${styles.shadow}`)
            .attr('r', GraphConstants.BISECTOR.SLIDER.SHADOW_SIZE.DEFAULT)
        }
      }

      // Mouse interactions
      lineGraph.on(
        'mousedown mousemove mouseup mouseleave touchstart touchmove touchend',
        handleDragMovement,
      )

      lineGraph.select(`.${styles.xAxis}`).remove()

      lineGraph
        .insert('g', ':first-child')
        .attr('class', `${styles.xAxis}`)
        .attr('transform', `translate(${0}, ${boundingRect.top + innerHeight})`)
        .call(xAxis)

      lineGraph
        .select(`.${styles.xAxis}`)
        .selectAll(`.${styles.tick}`)
        .style('transform', `translateY(-10px)`)

      lineGraph
        .select(`.${styles.lineGraph}`)
        .attr('transform', `translate(${0}, ${boundingRect.top})`)
    }
    // eslint-disable-next-line
  }, [roundedMaxKey, roundedMaxValue, crosshair, lineGraphRef.current, windowWidth, graphSvgWidth])

  return (
    <div className={`${styles.lineGraphContainer} ${className ? className : ''}`}>
      <div className={`${styles.graphLegend} ${styles.active}`} ref={legendRef}>
        <p id='royalty' className={styles.royalty} />
        <p className={styles.legendText}>{t('label')}</p>
      </div>
      <svg className={styles.lineGraphSvg} ref={lineGraphRef}>
        <line className={styles.xAxisHighlightLine} />
        <g className={styles.lineGraph}>
          <g className={`${styles.bisector} ${styles.active}`}>
            <circle className={styles.shadow} />
            <line className={styles.line} />
            <circle className={styles.crosshair} ref={crosshairRef} />
          </g>
        </g>
      </svg>
      <p className={styles.xAxisLabel}>{t('streams')}</p>
    </div>
  )
}
