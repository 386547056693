import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { setInterceptors, removeInterceptors } from 'repositories/mx-api'

export const useInitAuthSession = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) setInterceptors(getAccessTokenSilently, navigate)
    else removeInterceptors()
    // eslint-disable-next-line
  }, [isAuthenticated])
}
