import { useState, DragEventHandler, useRef, ReactNode } from 'react'

import { ReactComponent as DocumentIcon } from 'assets/icons/document-duplicate.svg'
import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type DragAndDropProps = {
  children: ReactNode
  error: string | undefined
  file: File | undefined
  setError: (error: string | undefined) => void
  setFile: (file: File | undefined) => void
  setFileWithAmpli?: (file: File | undefined) => void
}

const MIN_FILE_SIZE = 32 * 1024 // 32 KB
const MAX_FILE_SIZE = 7 * 1024 * 1024 // 7 MB

// format minimum and maximum file size to human readable format
const formatFileSize = (size: number) => {
  switch (true) {
    case size < 1024:
      return `${size} bytes`
    case size < 1024 * 1024:
      return `${(size / 1024).toFixed(2)} KB`
    case size < 1024 * 1024 * 1024:
      return `${(size / (1024 * 1024)).toFixed(2)} MB`
    default:
      return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`
  }
}

export const DragAndDrop = ({
  file,
  error,
  setFile,
  setFileWithAmpli,
  setError,
  children,
}: DragAndDropProps) => {
  const { t } = useLocalization('kyc')

  const [dragging, setDragging] = useState(false)

  const inputFile = useRef<HTMLInputElement>(null)

  const onDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }

  const onDragLeave: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleUpload = (file: File) => {
    setFile(undefined)
    if (
      file.type === 'application/pdf' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    ) {
      if (file.size < MIN_FILE_SIZE) {
        setError(
          t('uploadDocuments.tooSmall', {
            min: formatFileSize(MIN_FILE_SIZE),
            max: formatFileSize(MAX_FILE_SIZE),
          }),
        )
        return
      }
      if (file.size > MAX_FILE_SIZE) {
        setError(
          t('uploadDocuments.tooLarge', {
            min: formatFileSize(MIN_FILE_SIZE),
            max: formatFileSize(MAX_FILE_SIZE),
          }),
        )
        return
      }
      setFileWithAmpli ? setFileWithAmpli(file) : setFile(file)
      setError(undefined)
    }
  }

  const onDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    if (e.dataTransfer.files.length) {
      const file = e.dataTransfer.files[0] as File
      handleUpload(file)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0] as File
      handleUpload(file)
    }
  }

  const renderDocumentPreview = () => {
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        return (
          <img
            src={URL.createObjectURL(file)}
            className={styles.documentImage}
            alt={t('uploadDocuments.documentPreview')}
          />
        )
      } else {
        return (
          <object
            className={styles.documentImage}
            type='application/pdf'
            data={URL.createObjectURL(file)}
          >
            <p>{t('uploadDocuments.documentPreview')}</p>
          </object>
        )
      }
    } else {
      return (
        <>
          <DocumentIcon className={styles.documentIcon} />
          <div className={styles.dragAndDropText}>{t('uploadDocuments.dragAndDrop')}</div>
        </>
      )
    }
  }

  return (
    <div
      data-test-id='drag-and-drop'
      onClick={() => inputFile.current?.click()}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragEnter}
      onDrop={onDrop}
      className={styles.dragAndDrop}
    >
      <input
        type='file'
        id='file'
        ref={inputFile}
        placeholder='upload'
        className={styles.inputFile}
        accept='image/png, image/jpeg, image/jpg, application/pdf'
        onChange={handleInputChange}
      />
      <div className={dragging ? styles.dragging : styles.dragAndDropContents}>
        <div
          className={
            error ? styles.uploadBoxError : file ? styles.uploadBoxActive : styles.uploadBox
          }
        >
          {renderDocumentPreview()}
        </div>
        {children}
      </div>
    </div>
  )
}
