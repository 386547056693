import styles from './styles.module.scss'

type ProgressBarProps = {
  currentStep: {
    step: number
    subStep: number
  }
  totalSteps: {
    step: number
    subStep: number
  }
}

export const ProgressBar = ({ currentStep, totalSteps }: ProgressBarProps) => {
  if (!currentStep || !totalSteps) return null

  return (
    <div className={styles.progressBarContainer}>
      {Array.from({ length: totalSteps.step }, (_, i) => {
        const stepIndex = i + 1
        const isCurrentStep = stepIndex === currentStep.step
        const fillPercentage = isCurrentStep
          ? (currentStep.subStep / totalSteps.subStep) * 100
          : stepIndex < currentStep.step
          ? 100
          : 0

        return (
          <div key={stepIndex} className={styles.progressLevel}>
            <div className={styles.filled} style={{ width: `${fillPercentage}%` }}></div>
          </div>
        )
      })}
    </div>
  )
}
