import { Link, useNavigate } from 'react-router-dom'

import { ReactComponent as BackIcon } from 'assets/icons/chevron-left.svg'
import { Paths } from 'constants/paths'
import { useAppEnvironment } from 'ui/@hooks/use-app-environment'
import { useLandingPageLinks } from 'ui/@hooks/use-landing-page-links'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { usePlatform } from 'ui/@hooks/use-platform'
import { useWindowSize } from 'ui/@hooks/use-window-size'
import { useNavigationState } from 'ui/@store/navigation'

import { NavActions } from './@components/nav-actions'
import { NavbarHeaderLinks } from './@components/navbar-header-links'
import { NavbarLogo } from './@components/navbar-logo'
import styles from './styles.module.scss'

type NavbarProps = {
  allowsBackNavigation?: boolean
}

export const Navbar = ({ allowsBackNavigation }: NavbarProps) => {
  const navigate = useNavigate()
  const { isAuthenticated } = useMxAuth()
  const { viewports } = useWindowSize()
  const [, setNavigationState] = useNavigationState()
  const { isNative, isWeb } = usePlatform()
  const generateLandingPageLink = useLandingPageLinks()
  const { isProduction } = useAppEnvironment()

  const isMarqueeHidden = isProduction || isAuthenticated
  const showBackButton = isNative && allowsBackNavigation && viewports.isTablet

  const handleBackNavigation = () => {
    setNavigationState({ direction: -1 })
    navigate(-1)
  }

  return (
    <div className={`${styles.navbarWrapper} ${isMarqueeHidden && styles.navBarTop}`}>
      <div className={styles.navbarContainer}>
        {isWeb ? (
          <a href={generateLandingPageLink()}>
            <NavbarLogo />
          </a>
        ) : showBackButton ? (
          <BackIcon className={styles.backIcon} onClick={handleBackNavigation} />
        ) : (
          <Link to={Paths.HOME}>
            <NavbarLogo />
          </Link>
        )}
        <NavbarHeaderLinks />
        <NavActions />
      </div>
    </div>
  )
}
