import { ReactNode } from 'react'

import { ampli } from 'ampli'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useSignInAndSignUp } from 'ui/@hooks/use-signin-and-signup'
import { useGlobalModalState } from 'ui/@store/global-modal'

import { ModalKey } from './constants'
import { ModalSize, PayloadType } from './types'

const MODAL_RESET_TIMEOUT = 200 // Modal transitions are timed at 200ms

export const useGlobalModal = () => {
  const [modalState, setModalState] = useGlobalModalState()
  const { isAuthenticated } = useMxAuth()
  const { signUp } = useSignInAndSignUp()

  const renderModal = <T extends ModalKey>({
    content,
    modalKey,
    payload,
    enforceAuth = false,
    size = ModalSize.Medium,
    noPadding = false,
    closeOnClickingOutside = true,
    navigateBack = undefined,
  }: {
    content: ReactNode
    modalKey: T
    payload?: PayloadType[T]
    size?: ModalSize
    enforceAuth?: boolean
    noPadding?: boolean
    closeOnClickingOutside?: boolean
    navigateBack?: () => void
  }) => {
    ampli.viewModal({
      modalKey,
    })
    if (enforceAuth && !isAuthenticated) {
      signUp()
      return
    }
    if (modalState.payload) {
      setModalState({
        modalKey: modalKey ? modalKey : null,
        content,
        payload: payload ? { ...modalState.payload, ...payload } : modalState.payload,
        displayModal: true,
        size: size ? size : modalState.size,
        noPadding,
        closeOnClickingOutside,
        navigateBack,
      })
    } else {
      setModalState({
        modalKey: modalKey ? modalKey : null,
        content,
        payload: payload ? payload : modalState.payload,
        displayModal: true,
        size: size ? size : modalState.size,
        noPadding,
        closeOnClickingOutside,
        navigateBack,
      })
    }
  }

  const setDisplayModal = (displayModal: boolean) => {
    setModalState({
      ...modalState,
      displayModal,
    })
    if (displayModal === false) {
      setTimeout(() => {
        setModalState({
          modalKey: null,
          content: null,
          payload: null,
          displayModal,
          size: ModalSize.Medium,
          noPadding: false,
          closeOnClickingOutside: true,
        })
      }, MODAL_RESET_TIMEOUT)
    }
  }

  return {
    renderModal,
    setDisplayModal,
    modalKey: modalState.modalKey,
    displayModal: modalState.displayModal,
    content: modalState.content,
    payload: modalState.payload,
    size: modalState.size,
    noPadding: modalState.noPadding,
    closeOnClickingOutside: modalState.closeOnClickingOutside,
    navigateBack: modalState.navigateBack,
  }
}
