import { ReactComponent as TooltipIcon } from 'assets/icons/info.svg'
import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'
import { PayoutStatus, RoyaltyPayoutData } from 'types/royalty-payouts'
import { Loader } from 'ui/@library/feedback/loader'

import styles from './styles.module.scss'

type DesktopRoyaltyPayoutsProps = {
  handlePayoutsInfo: (information?: string) => void
  loading: boolean
  royaltyPayouts: RoyaltyPayoutData[]
}

export const DesktopRoyaltyPayouts = ({
  handlePayoutsInfo,
  loading,
  royaltyPayouts,
}: DesktopRoyaltyPayoutsProps) => {
  const { f, t } = useLocalization('master.overviewTab.sidebar.royaltyPayouts')

  return loading ? (
    <Loader />
  ) : (
    <table className={styles.royaltyPayoutTable}>
      <tr className={styles.tableHeaderContainer}>
        <th className={styles.tableHeader}>{t('listHeader.payoutDate')}</th>
        <th className={styles.tableHeader}>{t('listHeader.status')}</th>
        <th className={styles.tableHeader}>{t('listHeader.value')}</th>
        <th className={styles.tableHeader}>{t('listHeader.timestamp')}</th>
        <th className={styles.tableHeader}>{t('listHeader.royaltyPeriod')}</th>
      </tr>
      <tr className={styles.tableRow}>
        {royaltyPayouts?.map(({ value, payoutAt, status, timestamp, comment }) => (
          <td className={styles.tableData} key={`${value}-${timestamp}`}>
            <div>{payoutAt ? f.date(payoutAt, TextFormats.DATE.DATE) : '-'}</div>
            <div>
              <div className={status === PayoutStatus.Upcoming ? styles.upcomingStatus : ''}>
                {status === PayoutStatus.Upcoming
                  ? t('payoutStatus.upcoming')
                  : t('payoutStatus.paid')}
              </div>
            </div>
            <div>{f.currency(value)}</div>
            <div>{f.date(timestamp, TextFormats.DATE.DATE)}</div>
            <div>
              {comment ? (
                <span className={styles.infoIcon}>
                  <TooltipIcon onClick={() => handlePayoutsInfo(comment)} />
                </span>
              ) : (
                t('payoutPeriod.unavailable')
              )}
            </div>
          </td>
        ))}
      </tr>
    </table>
  )
}
