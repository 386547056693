import { BaseType, Line, Selection } from 'd3'
import { useEffect } from 'react'

import { CompositeTimeSeriesData, CompositeTimeSeriesDataArray } from 'types/metrics'

import { GraphConstants } from '../../constants'
import { GraphTransformationsType } from '../../types'

import styles from './styles.module.scss'

type BisectorProps<T extends BaseType> = {
  linePlot: Line<CompositeTimeSeriesData>
  graph: Selection<T, unknown, null, undefined>
  isTooltipActive: boolean
  getTransformations: GraphTransformationsType
  defaultBisectorCoordinates: CompositeTimeSeriesDataArray
}

export const Bisector = <T extends BaseType>({
  linePlot,
  graph,
  isTooltipActive,
  getTransformations,
  defaultBisectorCoordinates,
}: BisectorProps<T>) => {
  useEffect(() => {
    const handleBisectorMovement = (event: MouseEvent | TouchEvent) => {
      const expectedEvent = event instanceof MouseEvent ? event : event?.touches[0]
      const {
        bisectorPosition: { posX, posY },
      } = getTransformations(expectedEvent)

      // Move Bisector
      const bisector = graph.select(`.${styles.bisector}`)
      bisector
        .select(`.${styles.line}`)
        .datum(defaultBisectorCoordinates)
        .attr('d', linePlot)
        .style('transform', `translateX(${posX}px)`)
      bisector
        .select(`.${styles.highlight}`)
        .attr('r', GraphConstants.BISECTOR.LINE_GRAPH.TOOLTIP_SIZE)
        .style('transform', `translate(${posX}px, ${posY}px)`)
      bisector
        .select(`.${styles.shadow}`)
        .attr('r', GraphConstants.BISECTOR.LINE_GRAPH.SHADOW_SIZE)
        .style('transform', `translate(${posX}px, ${posY}px)`)
    }
    if (graph) {
      graph.on('mousemove.bisector touchmove.bisector', handleBisectorMovement)
    }
    // eslint-disable-next-line
  }, [graph])

  return (
    <g className={`${styles.bisector} ${isTooltipActive ? styles.active : ''}`}>
      <circle className={styles.shadow} />
      <path className={styles.line} />
      <circle className={styles.highlight} />
    </g>
  )
}
