import { KeyboardEvent, ChangeEvent, useState, useEffect, useRef } from 'react'

import { SharePrice } from 'constants/trading'
import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type CurrencyInputProps = {
  className?: string
  title?: string
  value: string | null
  displayError?: boolean
  errorInBottom?: boolean
  minAmount?: number
  maxAmount?: number
  startFromRight?: boolean
  setValue: (value: string | null) => void
  setDisplayError?: (displayError: boolean) => void
  onFocusChange?: (isFocused: boolean) => void
  disabled?: boolean
}

export const CurrencyInput = ({
  className,
  title,
  value,
  displayError = false,
  errorInBottom = false,
  minAmount = SharePrice.LOWER_LIMIT,
  maxAmount = SharePrice.UPPER_LIMIT,
  startFromRight = false,
  setValue,
  setDisplayError,
  onFocusChange,
  disabled,
}: CurrencyInputProps) => {
  const { f, t } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation.priceInput',
  )
  const [priceError, setPriceError] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const inputClassName = className ? className : styles.currencyInput

  useEffect(() => {
    if (displayError && (!Number(value) || isNaN(Number(value)))) setPriceError(t('error.min'))
    // eslint-disable-next-line
  }, [displayError])

  useEffect(() => {
    if (setDisplayError && value && Number(value) <= maxAmount) {
      setPriceError('')
      setDisplayError(false)
    }
    // eslint-disable-next-line
  }, [value])

  const handleTextToNumberFormatting = (text: string) => {
    const [lhsValue, rhsValue] = text.split('.')
    const hasDecimalPoint = text.includes('.')
    const hasOnlyZeros = lhsValue !== '' && Number(lhsValue) === 0
    const parsedLhsValue = hasOnlyZeros ? '0' : lhsValue
    const formattedLhsValue = hasDecimalPoint ? parsedLhsValue + '.' : parsedLhsValue
    const formattedRhsValue = rhsValue && rhsValue.length > 2 ? rhsValue.slice(0, -1) : rhsValue

    return formattedRhsValue ? formattedLhsValue + formattedRhsValue : formattedLhsValue
  }

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isCommaSeperated = event.target.value.includes(',')
    // Replace commas with periods
    const decimalStringValue = isCommaSeperated
      ? event.target.value.replaceAll(',', '.')
      : event.target.value
    // Remove multiple periods
    const slicedValues = decimalStringValue.split('.')
    const slicedStringValue =
      slicedValues.length === 1 ? slicedValues[0] : slicedValues[0] + '.' + slicedValues.slice(1)
    // Allow only positive numerical characters
    const parsedStringValue = slicedStringValue ? slicedStringValue.replace(/[^\d.]/g, '') : ''
    // Handle text as a number
    const formattedStringvalue = handleTextToNumberFormatting(parsedStringValue)
    // Check for max limit
    if (Number(formattedStringvalue) > maxAmount) {
      setDisplayError && setDisplayError(true)
      setPriceError(
        t('error.max', {
          maximumSharePrice: f.currency(maxAmount),
        }),
      )
    }
    // Check for min limit
    else if (Number(formattedStringvalue) < minAmount) {
      setDisplayError && setDisplayError(true)
      setPriceError(
        t('error.min', {
          minimumSharePrice: f.currency(minAmount),
        }),
      )
    } else {
      setDisplayError && setDisplayError(false)
      setPriceError('')
    }
    setValue(formattedStringvalue ? formattedStringvalue : null)
  }

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (onFocusChange) {
      onFocusChange(false)
    }

    const rawValue = event.target.value
    const stringValue = Number(rawValue).toFixed(2)
    if (rawValue === '' || rawValue === '.' || stringValue === '0.00') setValue(null)
    // Check for min limit
    else if (Number(stringValue) > maxAmount) setValue(maxAmount.toFixed(2))
    // Check for min limit
    else setValue(Number(stringValue) < minAmount ? minAmount.toFixed(2) : stringValue)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const numericalValue = isNaN(Number(value)) ? 0 : Number(value)
    if (event.code === 'ArrowUp')
      setValue(Math.min(numericalValue + minAmount, maxAmount).toFixed(2))
    else if (event.code === 'ArrowDown')
      setValue(Math.max(numericalValue - minAmount, minAmount).toFixed(2))
  }

  const handleFocus = () => {
    if (onFocusChange) {
      onFocusChange(true)
    }

    const input = inputRef.current
    if (startFromRight && input) {
      const cursorPosition = input.value.length
      input.type = 'text'
      input.setSelectionRange(cursorPosition, cursorPosition)
      input.type = 'number'
    }
  }

  return (
    <div
      className={`${styles.priceInputWrapper} ${errorInBottom && styles.reverseOrder}`}
      data-test-id='currency-input-wrapper'
    >
      {(displayError || title) && (
        <div className={styles.headerContainer}>
          {title && <label className={styles.priceLabel}>{title}</label>}

          {displayError && priceError && (
            <p
              className={`${styles.errorMessage} ${priceError ? styles.active : ''}`}
              data-test-id='error-message'
            >
              {priceError}
            </p>
          )}
        </div>
      )}
      <div className={styles.priceInputContainer}>
        <p
          className={`${styles.currencySymbol} ${value ? styles.highlighted : ''}`}
          data-test-id='currency-symbol'
        >
          {f.currencySymbol()}
        </p>
        <input
          ref={inputRef}
          lang={TextFormats.NUMBER.LOCALE}
          className={`${inputClassName} ${priceError && styles.error}`}
          type='text'
          inputMode='decimal'
          min={minAmount}
          max={maxAmount}
          step={SharePrice.LOWER_LIMIT}
          placeholder='0.00'
          value={value === null ? '' : value}
          onChange={handlePriceChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
