import * as Sentry from '@sentry/react'

export const initSentry = () => {
  Sentry.init({
    enabled: Boolean(process.env.REACT_APP_SENTRY_DSN),
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    release: process.env.REACT_APP_CLIENT_VERSION,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      'Script error.',
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
  })
}
