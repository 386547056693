import { sanitizeMessageFromRaw } from 'repositories/mx-api/@santizers/message'

export enum MessageTitle {
  Announcement = 'announcement',
  KycRequired = 'kyc_required',
  KycPending = 'kyc_pending',
  KycFailed = 'kyc_failed',
  Referral = 'referral',
  Affiliate = 'affiliate',
}

export enum MessageVariant {
  Regular = 'regular',
  Info = 'info',
  Success = 'success',
}

export enum MessageScope {
  Global = 'global',
  Personal = 'personal',
}

export enum MessageAction {
  VerifyIdentity = 'verify_identity',
  ConnectCard = 'connect_card',
}

export type MessageRaw = {
  title: MessageTitle
  content: string
  variant: MessageVariant
  scope: MessageScope
  is_sticky: boolean
  action: MessageAction | null
}

export type Message = ReturnType<typeof sanitizeMessageFromRaw>
