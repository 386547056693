import { MxApi } from '../mx-api'
import { downloadBlobAsFile } from '../utils'

export const fetchAuthUserAccountingSummary = async (startDate: string, endDate: string) => {
  const { data } = await MxApi.get('/auth-users/me/accounting/summary', {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    responseType: 'arraybuffer',
  })

  downloadBlobAsFile(data, 'mx-accounting-summary.pdf')
}
