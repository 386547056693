import { useEffect } from 'react'
import { selectorFamily, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchPriceHistory } from 'repositories/mx-api'
import { PriceHistory } from 'types/market'
import { GraphRange } from 'ui/master/@components/master-overview/@components/statistics/@components/price-graph/types'

import { masterUuidState } from './master'

const priceHistoryQuery = selectorFamily<PriceHistory, string>({
  key: 'priceHistoryQuery',
  get:
    (range) =>
    async ({ get }) => {
      const masterUuid = get(masterUuidState)
      return masterUuid ? fetchPriceHistory(masterUuid, range) : []
    },
})

export const usePriceHistoryLoadable = ({
  forceRefetch = false,
  range = GraphRange.Month,
} = {}) => {
  const { state, contents } = useRecoilValueLoadable(priceHistoryQuery(range))
  const refetch = useRecoilRefresher_UNSTABLE(priceHistoryQuery(range))

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    priceHistory: state === 'hasValue' ? contents : [],
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
