import { BankAccounts, BankAccountsRaw } from 'types/bank-accounts'

import { sanitizeBankAccountsFromRaw } from '../@santizers/bank-accounts'
import { MxApi } from '../mx-api'

export const createBankAccount = async (iban: string, name: string): Promise<BankAccounts> => {
  const { data } = await MxApi.post<BankAccountsRaw>('/auth-users/me/wallet/bankaccounts', {
    iban,
    name,
  })

  return sanitizeBankAccountsFromRaw(data)
}
