import { LandingPageLinks, LandingPageRoutes, WebflowQueryParams } from 'constants/external-links'

import { useMxAuth } from './use-mx-auth'

export type LandingPageRouteType = (typeof LandingPageRoutes)[keyof typeof LandingPageRoutes]

export const useLandingPageLinks = () => {
  const { isAuthenticated } = useMxAuth()

  const baseURL = LandingPageLinks.BASE_URL ? LandingPageLinks.BASE_URL : LandingPageLinks.MAIN
  const landingPageUrl = new URL(baseURL)

  if (isAuthenticated) landingPageUrl.searchParams.set(WebflowQueryParams.SIGNED_IN, '1')

  const generateLandingPageLinks = (landingPageRoute?: LandingPageRouteType) => {
    if (landingPageRoute) {
      landingPageUrl.pathname = landingPageRoute
    }
    return landingPageUrl.toString()
  }

  return generateLandingPageLinks
}
