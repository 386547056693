import styles from './styles.module.scss'

type IconWrapperProps = {
  children: React.ReactNode
  className?: string
}

export const IconWrapper = ({ children, className = '' }: IconWrapperProps) => {
  return <div className={`${styles.iconWrapper} ${className}`}>{children}</div>
}
