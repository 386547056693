import { useLocalization } from 'locales/i18n'
import { useImoLoadable } from 'ui/@store/master'

import { Paper } from '../paper'

import styles from './styles.module.scss'

export const ImoStatistics = () => {
  const { imo } = useImoLoadable()
  const { t, f } = useLocalization('master.overviewTab.banner.imo')

  if (!imo) return null

  return (
    <Paper className={styles.imoStatisticsContainer}>
      <p className={styles.title}>{t('title')}</p>
      <h3 className={styles.price}>{f.currency(imo.price)}</h3>
      <p className={styles.footnote}>
        {t('footnote', { numberOfShares: f.number(imo.maxAvailableShares) })}
      </p>
    </Paper>
  )
}
