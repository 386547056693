import { ChangeEventHandler } from 'react'

import styles from './styles.module.scss'

type RadioButtonProps = {
  name: string
  value: string
  onChange: ChangeEventHandler
  checked: boolean
  children: React.ReactNode | string | number
}

export const RadioButton = ({
  name,
  value,
  checked,
  onChange,
  children,
  ...props
}: RadioButtonProps) => {
  return (
    <div className={styles.radioWrapper}>
      <input
        id={value}
        className={styles.radio}
        type='radio'
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        {...props}
      />

      <label htmlFor={value} className={styles.label}>
        {children}
      </label>
    </div>
  )
}
