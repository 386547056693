import camelcaseKeys from 'camelcase-keys'

import { ShareOrderRaw, OrderRaw, OrderBookRaw, ShareOrderWithSharesRaw } from 'types/order'

export const sanitizeShareOrderWithSharesFromRaw = (raw: ShareOrderWithSharesRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeOrderFromRaw = (raw: OrderRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeOrderBookFromRaw = (raw: OrderBookRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeShareOrderFromRaw = (raw: ShareOrderRaw) => {
  return camelcaseKeys(raw, { deep: true })
}
