import { useLocalization } from 'locales/i18n'
import { useWalletLoadable } from 'ui/@store/wallet'

import { MetricCard, MetricContents, MetricTitle, MetricValue } from '../metric-card'

import styles from './styles.module.scss'

export const AvailableFunds = () => {
  const { t } = useLocalization('myAssets.overview.cards')
  const { wallet, loading: walletLoading } = useWalletLoadable({ forceRefetch: true })

  return (
    <MetricCard>
      <div className={styles.availableFundsWrapper}>
        <div className={styles.fundsContainer}>
          <MetricTitle>{t('balance')}</MetricTitle>
          <MetricContents loading={walletLoading}>
            <MetricValue>{wallet?.primaryBalance}</MetricValue>
          </MetricContents>
        </div>
        {wallet?.reservedBalance && wallet?.reservedBalance > 0 ? (
          <>
            <hr />
            <div className={styles.fundsContainer}>
              <MetricTitle>{t('reserved')}</MetricTitle>
              <MetricContents loading={walletLoading}>
                <MetricValue>{wallet?.reservedBalance}</MetricValue>
              </MetricContents>
            </div>
          </>
        ) : null}
      </div>
    </MetricCard>
  )
}
