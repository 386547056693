import { useState } from 'react'

import { useLocalization } from 'locales/i18n'
import { Spinner } from 'ui/@library/feedback/spinner'
import { useMasterLoadable } from 'ui/@store/master'
import { useTrader } from 'ui/master/@components/master-overview/@components/banner-trader/use-trader'

import { BuyButton } from './@components/buy-button'
import { SellButton } from './@components/sell-button'
import { TradeButton } from './@components/trade-button'
import styles from './styles.module.scss'

export function StickyTradingBar() {
  const { f, t } = useLocalization('master.overviewTab.sidebar.trader')
  const { master, loading: isMasterLoading } = useMasterLoadable()
  const { isImo, isLoading: isTraderLoading } = useTrader()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const sharePrice = isImo ? master?.imo.price : master?.market.lastPrice

  const isLoading = isMasterLoading || isTraderLoading

  return (
    <>
      <div className={`${styles.traderOverlay} ${isMenuOpen && styles.menuActive}`} />
      <div className={`${styles.stickyTrader} ${isMenuOpen && styles.menuActive}`}>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Spinner />
          </div>
        ) : (
          <>
            <div className={styles.infoWrapper}>
              <p className={styles.priceHeading}>{t('market.information.price.common')}</p>
              <p className={styles.amount}>{sharePrice ? f.currency(sharePrice) : ''}</p>
            </div>
            <div className={`${styles.buttonContainer} ${isMenuOpen && styles.active}`}>
              <BuyButton setIsMenuOpen={setIsMenuOpen} />
              <SellButton setIsMenuOpen={setIsMenuOpen} />
              <TradeButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            </div>
          </>
        )}
      </div>
    </>
  )
}
