export const SECURITY_CODE_MAX_LENGTH = 4
export const SECURITY_CODE_MIN_LENGTH = 3
export const CARD_HOLDER_NAME_MAX_LENGTH = 50
export const CARD_NUMBER_MAX_LENGTH = 19

export enum MangoPayCardType {
  CbVisaMasterCard = 'CB_VISA_MASTERCARD',
}

export type AddCardFormValues = {
  cardHolderName: string
  cardNumber: string
  expiryMonth: string
  expiryYear: string
  securityCode: string
}

export type CreditCardDetails = {
  cardNumber: string
  cardExpirationDate: string
  cardCvx: string
  cardType: MangoPayCardType
}
