import styles from './styles.module.scss'

type TableAccordionHeadingProps = {
  count: number
  title: string
}

export const TableAccordionHeading = ({ count, title }: TableAccordionHeadingProps) => {
  return (
    <div className={styles.accordionHeaderWrapper}>
      <p className={styles.accordionTitle}>{title}</p>
      <div className={styles.rowCount}>
        <p>{count}</p>
      </div>
    </div>
  )
}
