import { ReactComponent as Wave } from 'assets/icons/wave.svg'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

export const AlreadySold = () => {
  const { t } = useLocalization('order.alreadySold')

  const { setDisplayModal } = useGlobalModal()

  const handleClose = () => {
    setDisplayModal(false)
  }

  return (
    <ModalContent className={styles.alreadySoldContainer}>
      <div className={styles.topWrapper}>
        <div className={styles.waveIcon}>
          <Wave />
        </div>
        <h1 className={styles.alreadySoldTitle}>{t('title')}</h1>
        <p className={styles.alreadySoldSubtitle}>{t('subtitle')}</p>
      </div>
      <Button className={styles.closeButton} variant='primary' onClick={handleClose}>
        {t('close')}
      </Button>
    </ModalContent>
  )
}
