import { Form, Formik, FormikHelpers } from 'formik'
import { useEffect, useState } from 'react'
import { number, object, SchemaOf } from 'yup'

import { ampli } from 'ampli'
import { AddFundsAmount as amountLimit } from 'constants/funds'
import { useLocalization } from 'locales/i18n'
import { CardSelector } from 'ui/@components/card-selector'
import { CurrencyInput } from 'ui/@components/currency-input'
import { TermsAndConditions } from 'ui/@components/terms-and-conditions'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useCardsLoadable } from 'ui/@store/cards'
import { usePayIn } from 'ui/@store/pay-in'

import { Error as ErrorModal } from '../error'
import { PayInThreeDSecure } from '../pay-in-three-d-secure'

import styles from './styles.module.scss'

type FormValues = {
  amount?: number
}

export type AddFundsConfirmProps = {
  prefilledAmount?: number
  onAddFundsSuccessCallback?: () => void
}

export const AddFundsConfirm = () => {
  const { t, f } = useLocalization('addFunds.confirm')
  const {
    selectedCard,
    cards,
    loading: cardsLoading,
    error: cardsError,
  } = useCardsLoadable({ forceRefetch: true })

  const { createPayIn } = usePayIn()
  const { renderModal, payload: modalPayload } = useGlobalModal()
  const [amountError, setAmountError] = useState(false)

  useEffectOnce(() => {
    ampli.viewAddFundsModal()
  })

  const initialValues: FormValues = {
    amount:
      modalPayload?.prefilledAmount < amountLimit.MIN
        ? amountLimit.MIN
        : Math.ceil(modalPayload?.prefilledAmount),
  }

  const validationSchema: SchemaOf<FormValues> = object({
    amount: number()
      .typeError(t('amount.required'))
      .required(t('amount.required'))
      .min(amountLimit.MIN, t('amount.min', { min: amountLimit.MIN }))
      .max(amountLimit.MAX, t('amount.max', { max: amountLimit.MAX })),
  })

  const onSubmitHandler = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.validateForm(values)

    if (!selectedCard || !values.amount) throw new Error('Submitted with no card or amount chosen')

    ampli.requestAddFunds()

    await createPayIn(values.amount, selectedCard)

    renderModal({
      modalKey: ModalKey.PayInThreeDSecure,
      content: <PayInThreeDSecure />,
      payload: {
        onSuccessCallback: modalPayload?.onAddFundsSuccessCallback,
      },
    })
  }

  useEffect(() => {
    if (cardsError) renderModal({ modalKey: ModalKey.Error, content: <ErrorModal /> })

    // eslint-disable-next-line
  }, [cards, cardsError])

  return (
    <ModalContent className={styles.confirmationContainer} loading={cardsLoading}>
      <h1 className={styles.title}>{t('title')}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
        validateOnBlur
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form className={styles.form}>
            <div className={styles.topWrapper}>
              <CurrencyInput
                title={t('amount.label')}
                value={values.amount ? String(values.amount) : null}
                setValue={(val) => setFieldValue('amount', val, true)}
                displayError={amountError}
                setDisplayError={setAmountError}
                minAmount={amountLimit.MIN}
                maxAmount={amountLimit.MAX}
              />
              <div className={styles.totalLabel}>
                <div>{t('total')}</div>
                <div className={styles.totalValue}>
                  {f.currency(values.amount ? values.amount : 0)}
                </div>
              </div>
              <div className={styles.paymentMethodContainer}>
                <div className={styles.cardsContainer}>
                  <div className={styles.paymentMethod}>{t('paymentMethod')}</div>
                  <CardSelector cardLimitCallbackModal={ModalKey.AddFundsConfirm} />
                </div>
              </div>
              <p className={styles.terms}>
                <TermsAndConditions />
              </p>
            </div>
            <Button
              type='submit'
              loading={isSubmitting}
              disabled={!isValid || !selectedCard}
              width='fluid'
              className={styles.confirmButton}
            >
              {values.amount
                ? t('confirmAmount', {
                    amount: f.currency(values.amount),
                  })
                : t('confirm')}
            </Button>
          </Form>
        )}
      </Formik>
    </ModalContent>
  )
}
