import { Field, ErrorMessage } from 'formik'
import { InputHTMLAttributes } from 'react'

import styles from './styles.module.scss'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  label?: string
  placeholder?: string
  type?: string
  currentValue?: string | Date
  endAdornment?: React.ReactNode
  as?: string
}

export const Input = ({
  name,
  label,
  placeholder,
  type,
  endAdornment,
  autoComplete,
  as,
  disabled,
  ...props
}: InputProps) => {
  return (
    <div className={`${styles.formGroup} ${disabled ? styles.formGroupDisabled : ''}`}>
      <label>{label}</label>
      <div className={styles.fieldContainer}>
        <Field
          type={type}
          placeholder={placeholder}
          name={name}
          autoComplete={autoComplete}
          x-autocompletetype={autoComplete}
          as={as}
          {...props}
        />
        <div className={styles.endAdornment}>{endAdornment}</div>
      </div>
      <ErrorMessage name={name}>
        {(message: string) => <span className={styles.errorMessage}>{message}</span>}
      </ErrorMessage>
    </div>
  )
}
