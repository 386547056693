import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { useGlobalModal } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

type OrderHandlerProps = {
  handleOrderAction: () => Promise<void>
  isLoading: boolean
  isDisabled?: boolean
  isSellFlow?: boolean
  title: string
}

export const OrderHandler = ({
  handleOrderAction,
  isLoading,
  isDisabled,
  isSellFlow = false,
  title,
}: OrderHandlerProps) => {
  const { setDisplayModal } = useGlobalModal()
  const { t } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation',
  )

  const cancelOrderAction = () => {
    setDisplayModal(false)
  }

  return (
    <div className={styles.buttonContainer}>
      <Button
        width='fluid'
        variant='primary'
        className={`${isSellFlow ? styles.primaryButtonRed : ''}`}
        onClick={handleOrderAction}
        loading={isLoading}
        disabled={isDisabled}
      >
        {title}
      </Button>
      <Button
        width='fluid'
        variant='ghost'
        className={`${isSellFlow ? styles.secondaryButtonRed : ''}`}
        onClick={cancelOrderAction}
      >
        {t('buttons.cancel')}
      </Button>
    </div>
  )
}
