import { CardValidation, CardValidationRaw } from 'types/card'

import { sanitizeCardValidationFromRaw } from '../@santizers/card'
import { MxApi } from '../mx-api'

export const createCardValidation = async (cardId: string): Promise<CardValidation> => {
  const { data } = await MxApi.post<CardValidationRaw>(
    `/auth-users/me/wallet/cards/${cardId}/validate`,
    {
      browser_info: {
        java_enabled: window.navigator.javaEnabled(),
        language: window.navigator.language || 'en',
        color_depth: window.screen.colorDepth,
        screen_height: window.screen.height,
        screen_width: window.screen.width,
        timezone_offset: new Date().getTimezoneOffset(),
        user_agent: window.navigator.userAgent,
        javascript_enabled: true,
      },
    },
  )

  return sanitizeCardValidationFromRaw(data)
}
