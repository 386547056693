import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'

import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

const TIMER_DURATION = 30 // seconds

export const ImoEndedBanner = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.banner.imoEnded')
  const [displayBanner, setDisplayBanner] = useState(true)

  const variants = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setDisplayBanner(false)
    }, TIMER_DURATION * 1000)
  }, [])

  return (
    <div className={styles.container}>
      <AnimatePresence>
        {displayBanner && (
          <motion.div
            variants={variants}
            initial='hidden'
            animate='visible'
            exit='hidden'
            key='imoEndedBanner'
            className={styles.wrapper}
          >
            <p className={styles.title}>{t('title')}</p>
            <p className={styles.description}>{t('description')}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
