import { Button } from 'ui/@library/inputs/button'

type SectionButtonProps = {
  children: React.ReactNode
  onClick?: () => void
}

export const SectionButton = ({ children, onClick }: SectionButtonProps) => {
  return (
    <Button variant='small' onClick={onClick}>
      {children}
    </Button>
  )
}
