import { useState } from 'react'

import { ReactComponent as TooltipIcon } from 'assets/icons/info.svg'
import { useLocalization } from 'locales/i18n'
import { TimeSeriesData } from 'types/metrics'
import { CurveGraph } from 'ui/@library/graphs/curve-graph'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useStreamsDataLoadable } from 'ui/@store/streams-graph'
import { DialogBox } from 'ui/master/@components/modals/dialog-box'

import styles from './styles.module.scss'

export const StreamsGraph = () => {
  const { t } = useLocalization('master.overviewTab.streamsGraph')
  const { renderModal } = useGlobalModal()
  const [, setGraphTooltipValue] = useState<TimeSeriesData>({
    timestamp: 0,
    value: 0,
  })
  const [, setIsHovered] = useState(false)
  const { streamsData } = useStreamsDataLoadable({ forceRefetch: true })

  const handleStreamsInfo = () => {
    renderModal({
      modalKey: ModalKey.DialogBox,
      content: <DialogBox />,
      payload: {
        information: t('modal'),
      },
      closeOnClickingOutside: true,
    })
  }

  if (!streamsData?.length) return null

  return (
    <>
      <div className={styles.streamsGraphContainer}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>{t('title')}</h1>
          <div className={styles.infoIcon}>
            <TooltipIcon onClick={handleStreamsInfo} />
          </div>
        </div>
        <CurveGraph
          data={streamsData}
          setIsHovered={setIsHovered}
          setTooltipValue={setGraphTooltipValue}
        />
      </div>
    </>
  )
}
