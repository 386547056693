import { ReactNode } from 'react'

import styles from './styles.module.scss'

type SectionStatusProps = {
  children: ReactNode
  type?: 'refused' | 'approved'
}

export const SectionStatus = ({ children, type }: SectionStatusProps) => {
  const className = type ? `${styles.sectionStatus} ${styles[type]}` : styles.sectionStatus
  return <p className={className}>{children}</p>
}
