import { useLocation } from 'react-router-dom'

import { ampli } from 'ampli'
import { useLocalization } from 'locales/i18n'
import { useDiscoverLoadable } from 'ui/@store/discover'

import { ImoCard } from './@components/imo-card'
import { ImoEmptyState } from './@components/imo-empty-state'
import styles from './styles.module.scss'

export const Imo = () => {
  const { t } = useLocalization('exchange.spotlight')
  const location = useLocation()
  const { discover } = useDiscoverLoadable()
  const { imos } = discover
  const isImoTab = location.pathname.split('/').pop() === 'imos'

  if (isImoTab && imos.length === 0) return <ImoEmptyState />
  if (imos.length === 0) return null

  return (
    <div className={styles.imoContainer}>
      <div className={styles.imoTextContent}>
        <h1 className={styles.sectionTitle}>{t('title.imo')}</h1>
        <p className={styles.subtitle}>{t('subtitle.imo')}</p>
      </div>
      <div className={styles.imoCardGroup}>
        {imos.map((imo, index) => (
          <ImoCard
            master={imo}
            key={imo.uuid}
            onCardClick={() =>
              ampli.clickImoCard({
                imoName: imo.name,
                imoUuid: imo.uuid,
                index,
              })
            }
          />
        ))}
      </div>
    </div>
  )
}
