import { Selection } from 'd3'
import { useEffect, useState } from 'react'

import { TimeSeriesData } from 'types/metrics'
import { useD3 } from 'ui/@hooks/use-d3'

import { Bisector } from '../@components/bisector'
import { LinePlot } from '../@components/line-plot'
import { SvgCanvas } from '../@components/svg-canvas'
import { XAxis } from '../@components/x-axis'
import { YAxis } from '../@components/y-axis'
import { useGraph } from '../use-graph'

import styles from './styles.module.scss'

type CurveGraphProps = {
  className?: string
  data: TimeSeriesData[]
  setTooltipValue: (tooltipValue: TimeSeriesData) => void
  setIsHovered: (isHovered: boolean) => void
}

export const CurveGraph = ({ className, data, setIsHovered }: CurveGraphProps) => {
  const [isTooltipActive, setIsTooltipActive] = useState(false)
  const curveGraphContainerStyle = className
    ? `${styles.curveGraphContainer} ${className}`
    : styles.curveGraphContainer

  const [curveGraphFromState, setCurveGraph] =
    useState<Selection<SVGSVGElement, unknown, null, undefined>>()

  const renderCurveGraph = () => {
    if (data && curveGraph) {
      curveGraph
        .on('mouseover touchstart', () => {
          setIsTooltipActive(true)
          setIsHovered(true)
        })
        .on('mouseleave touchend', () => {
          setIsTooltipActive(false)
          setIsHovered(false)
        })
    }
  }

  const { d3Ref: curveGraphRef } = useD3<SVGSVGElement>(renderCurveGraph, [
    data,
    curveGraphFromState,
  ])
  const {
    graph: curveGraph,
    linePlot,
    dimension: { height, width },
    scale: {
      xAxis: { time: xScale },
      yAxis: yScale,
    },
    getTransformations,
    defaultBisectorCoordinates,
  } = useGraph({
    graphRef: curveGraphRef,
    data,
  })

  useEffect(() => {
    if (!curveGraphFromState) {
      setCurveGraph(curveGraph)
    }
    // eslint-disable-next-line
  }, [curveGraph])

  return (
    <div className={curveGraphContainerStyle}>
      <SvgCanvas
        graph={curveGraph}
        graphRef={curveGraphRef}
        isTooltipActive={isTooltipActive}
        getTransformations={getTransformations}
      >
        <XAxis xScale={xScale} graph={curveGraph} height={height} />
        <YAxis graph={curveGraph} width={width} yScale={yScale} />
        <LinePlot graph={curveGraph} data={data} linePlot={linePlot} />
        <Bisector
          linePlot={linePlot}
          graph={curveGraph}
          isTooltipActive={isTooltipActive}
          getTransformations={getTransformations}
          defaultBisectorCoordinates={defaultBisectorCoordinates}
        />
      </SvgCanvas>
    </div>
  )
}
