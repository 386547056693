import { ReactComponent as Check } from 'assets/icons/check.svg'
import { useLocalization } from 'locales/i18n'
import { IconWrapper } from 'ui/@library/data-display/icon-wrapper'
import { Button } from 'ui/@library/inputs/button'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import { AddCardStepProps } from '../../types'

import styles from './styles.module.scss'

export const AddCardSuccess = ({ onStepComplete }: AddCardStepProps) => {
  const { t } = useLocalization('onboarding.addCard.success')
  const { refetchAuthUser } = useAuthUserLoadable()

  const handleFlowCompletion = () => {
    refetchAuthUser()
    onStepComplete()
  }

  return (
    <>
      <div className={styles.wrapper}>
        <IconWrapper className={styles.iconWrapper}>
          <Check />
        </IconWrapper>
        <h1 className={styles.title}>{t('title')}</h1>
      </div>
      <Button width='fluid' className={styles.button} onClick={handleFlowCompletion}>
        {t('done')}
      </Button>
    </>
  )
}
