import { useEffect } from 'react'
import {
  selector,
  useRecoilRefresher_UNSTABLE,
  useRecoilValueLoadable,
  atom,
  useRecoilState,
} from 'recoil'

import { LocalStorage } from 'constants/local-storage'
import { fetchMessages } from 'repositories/mx-api/'
import { MessageTitle, Message } from 'types/message'

const messagesQuery = selector<Message[]>({
  key: 'messages',
  get: async () => fetchMessages(),
})

export const activeMessageState = atom<Message | null>({
  key: 'activeMessage',
  default: null,
})

export const useMessages = () => {
  const { state, contents: messages } = useRecoilValueLoadable(messagesQuery)
  const refetch = useRecoilRefresher_UNSTABLE(messagesQuery)
  const [activeMessage, setActiveMessage] = useRecoilState(activeMessageState)

  useEffect(() => {
    if (state === 'hasValue') {
      const activeMessage = getActiveMessage()
      if (activeMessage) setActiveMessage(activeMessage)
      else setActiveMessage(null)
    }
    // eslint-disable-next-line
  }, [state])

  const getDismissedMessagesList = (): MessageTitle[] => {
    const dismissedMessages = localStorage.getItem(LocalStorage.DISMISSED_MESSAGES)
    return dismissedMessages ? JSON.parse(dismissedMessages) : []
  }

  const getActiveMessage = () => {
    const filteredMessages = messages.filter((message: Message) => {
      const dismissedMessages = getDismissedMessagesList()
      return !dismissedMessages.includes(message.title)
    })
    return filteredMessages[0]
  }

  const closeBanner = () => {
    const dismissedMessages = getDismissedMessagesList()
    dismissedMessages.push(activeMessage?.title as MessageTitle)
    localStorage.setItem(LocalStorage.DISMISSED_MESSAGES, JSON.stringify(dismissedMessages))

    const nextActiveMessage = getActiveMessage()
    setActiveMessage(nextActiveMessage)
  }

  return {
    loading: state === 'loading',
    error: state === 'hasError' && messages,
    refetchMessages: refetch,
    activeMessage,
    closeBanner,
  }
}
