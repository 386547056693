import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'

import { ThreeDSecureEvent, ThreeDSecureQueryParams } from './constants'

export const ThreeDSecureRedirector = () => {
  const [queryParams] = useSearchParams()
  const status = queryParams.get(ThreeDSecureQueryParams.STATUS)
  const transactionId = queryParams.get(ThreeDSecureQueryParams.TRANSACTION_ID)
  const preAuthorizationId = queryParams.get(ThreeDSecureQueryParams.PREAUTHORIZATION_ID)
  const cardValidationId = queryParams.get(ThreeDSecureQueryParams.CARDVALIDATION_ID)

  useEffect(() => {
    if (preAuthorizationId) {
      window.parent.postMessage(
        {
          id: ThreeDSecureEvent.ID,
          status,
          preAuthorizationId,
        },
        '*',
      )
    }

    if (transactionId) {
      window.parent.postMessage(
        {
          id: ThreeDSecureEvent.ID,
          payInId: transactionId,
        },
        '*',
      )
    }

    if (cardValidationId) {
      window.parent.postMessage(
        {
          id: ThreeDSecureEvent.ID,
          status,
          cardValidationId,
        },
        '*',
      )
    }

    // eslint-disable-next-line
  }, [queryParams])

  return <FullPageLoader />
}
