import { AddCardError } from 'ui/@components/add-card/@components/add-card-error'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'

import { PaymentMethodModal } from '../payment-method-modal'

export type AddCard3DSModalProps = {
  cardId: string
}

export const AddCardErrorModal = () => {
  const { renderModal } = useGlobalModal()

  const handleRetry = () => {
    renderModal({
      modalKey: ModalKey.PaymentMethod,
      content: <PaymentMethodModal />,
    })
  }

  return <AddCardError onRetry={handleRetry} />
}
