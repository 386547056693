import { ModalContent } from 'ui/@library/layout/global-modal'

import { TraderError } from '../../../traders/@components/trader-error'

export const TraderErrorModal = () => {
  return (
    <ModalContent>
      <TraderError />
    </ModalContent>
  )
}
