import { ReactComponent as RightArrowIcon } from 'assets/icons/chevron-right.svg'

import styles from './styles.module.scss'

type DocumentOptionProps = {
  icon: React.ReactNode
  text: string
  onClick?: () => void
}

export const DocumentOption = ({ text, icon, onClick }: DocumentOptionProps) => {
  return (
    <div className={styles.documentOption} onClick={onClick}>
      <div className={styles.documentOptionIcon}>{icon}</div>
      <div className={styles.documentOptionText}>{text}</div>
      <RightArrowIcon className={styles.arrowIcon} />
    </div>
  )
}
