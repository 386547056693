import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { useMasterLoadable } from 'ui/@store/master'
import { MasterTab, TabParamType } from 'ui/master/types'

import styles from './styles.module.scss'

export const UniqueShares = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.uniqueShares')

  const { uuid } = useParams<TabParamType>()

  const { master } = useMasterLoadable()

  if (!master?.market.isActive) return null

  return (
    <div className={styles.uniqueSharesContainer}>
      <h1 className={styles.uniqueSharesTitle}>{t('title')}</h1>
      <div className={styles.uniqueSharesLink}>
        <Link to={`${Paths.MASTER.MAIN}/${uuid}/${MasterTab.Shares}`}>{t('link')}</Link>
      </div>
    </div>
  )
}
