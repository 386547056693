import { Selection, select, BaseType } from 'd3'
import { DependencyList, useEffect, useRef } from 'react'

export const useD3 = <T extends BaseType>(
  renderChart: (svg: Selection<T, unknown, null, undefined>) => void,
  dependencies: DependencyList,
) => {
  const d3Ref = useRef<T | null>(null)

  useEffect(() => {
    if (d3Ref.current) {
      const svg = select<T, unknown>(d3Ref.current)
      renderChart(svg)
    }
    // eslint-disable-next-line
  }, dependencies)

  return { d3Ref, xAxis: select(d3Ref.current) }
}
