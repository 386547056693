import { CreditCardBrandingIcon } from 'ui/@library/data-display/credit-card-branding-icon'
import { CardDetails } from 'ui/@library/utils/card-details'
import { useCardsLoadable } from 'ui/@store/cards'

import styles from './styles.module.scss'

type ActiveCardDetailsProps = {
  hasCards: boolean
}

export const ActiveCardDetails = ({ hasCards }: ActiveCardDetailsProps) => {
  const { selectedCard } = useCardsLoadable()

  if (hasCards && selectedCard) {
    return <CardDetails card={selectedCard} />
  } else {
    return (
      <div className={styles.emptyStateWrapper}>
        <CreditCardBrandingIcon />
      </div>
    )
  }
}
