import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { usePlatform } from 'ui/@hooks/use-platform'

type AuthProviderProps = {
  children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate()

  const { isNative, isWeb } = usePlatform()

  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE
  const callback = isNative ? process.env.REACT_APP_AUTH0_CALLBACK : window.location.origin

  const onRedirectCallback = (appState?: AppState) => {
    if (appState?.returnTo) navigate(appState?.returnTo)
  }

  if (!(domain && clientId && audience)) return null

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={isWeb ? onRedirectCallback : undefined}
      cacheLocation='localstorage'
      useRefreshTokens
      authorizationParams={{
        redirect_uri: callback,
        audience: audience,
        scope: 'read:current_user',
      }}
    >
      {children}
    </Auth0Provider>
  )
}
