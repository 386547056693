import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchAuthUserMastersWithFractions } from 'repositories/mx-api'
import { MasterWithFractionsAndMarket } from 'types/master'

import { authUserState } from './auth-user'

const authUserMastersWithFractionsQuery = selector<MasterWithFractionsAndMarket[]>({
  key: 'authUserMastersWithFractionsQuery',
  get: async ({ get }) => {
    const authUser = get(authUserState)
    return authUser ? fetchAuthUserMastersWithFractions() : []
  },
})

export const useAuthUserMastersWithFractionsLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(authUserMastersWithFractionsQuery)
  const refetch = useRecoilRefresher_UNSTABLE(authUserMastersWithFractionsQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    authUserMastersWithFractions: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
