import { motion, AnimatePresence } from 'framer-motion'

import { useLocalization } from 'locales/i18n'
import { OrderType } from 'types/order'
import { ShareWithOrders } from 'types/share'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { Button } from 'ui/@library/inputs/button'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { useWalletLoadable } from 'ui/@store/wallet'
import { SignInPrompt } from 'ui/master/@components/master-overview/@components/banner-trader/@components/sign-in-prompt'
import { InsufficientFunds } from 'ui/master/@components/modals/insufficient-funds'
import { ReviewLimitSell, ReviewBestPriceBuy } from 'ui/master/@components/modals/review-order'

import styles from './styles.module.scss'

type ReviewOrderProps = {
  selectedShares: Set<ShareWithOrders>
  setSelectedShares: (shares: Set<ShareWithOrders>) => void
}

export const ReviewOrderButton = ({ selectedShares, setSelectedShares }: ReviewOrderProps) => {
  const { t } = useLocalization('master.sharesTab')
  const { renderModal } = useGlobalModal()
  const { wallet } = useWalletLoadable()
  const { isAuthenticated } = useMxAuth()

  const accountBalance = wallet?.primaryBalance || 0

  const handleClick = () => {
    const sharesToTrade = Array.from(selectedShares)
    const numberOfShares = sharesToTrade.length
    const pricePerShare =
      sharesToTrade.reduce((total, share) => total + share?.askPrice, 0) / numberOfShares

    const totalPrice = sharesToTrade.reduce((total, share) => total + share?.askPrice, 0)

    if (!isAuthenticated) {
      renderModal({
        content: <SignInPrompt />,
        enforceAuth: false,
        modalKey: ModalKey.SignInPrompt,
      })
    } else if (accountBalance < totalPrice) {
      renderModal({
        modalKey: ModalKey.InsufficientFunds,
        content: <InsufficientFunds />,
        payload: {
          numberOfShares,
          sharesCost: totalPrice,
          retriggerReviewOrder: true,
        },
        enforceAuth: true,
      })
      setSelectedShares(new Set())
      return
    } else {
      if (sharesToTrade.length > 0 && sharesToTrade[0]?.owned) {
        renderModal({
          modalKey: ModalKey.ReviewLimitSell,
          content: <ReviewLimitSell />,
          payload: {
            orderType: OrderType.LimitAsk,
            numberOfShares,
            sharesToTrade,
            isUnique: true,
          },
          enforceAuth: true,
          size: ModalSize.Large,
        })
        setSelectedShares(new Set())
      } else {
        renderModal({
          modalKey: ModalKey.ReviewBestPriceBuy,
          content: <ReviewBestPriceBuy />,
          payload: {
            orderType: OrderType.BestBid,
            pricePerShare,
            numberOfShares,
            sharesToTrade: sharesToTrade.map((share) => ({
              uuid: share.uuid,
              tokenId: share.tokenId,
              price: share.askPrice,
            })),
            isUnique: true,
          },
          enforceAuth: true,
          size: ModalSize.Large,
        })
        setSelectedShares(new Set())
      }
    }
  }

  return (
    <Button
      className={styles.reviewOrderButton}
      onClick={handleClick}
      disabled={selectedShares.size === 0}
    >
      {t('reviewOrder.buy')}
      <AnimatePresence>
        {selectedShares.size > 0 && (
          <motion.span
            initial={{ opacity: 0, marginLeft: 0, width: 0, height: 0 }}
            animate={{ opacity: 1, marginLeft: 16, width: 20, height: 20 }}
            exit={{ opacity: 0, marginLeft: 0, width: 0, height: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={styles.selectedSharesCount}
          >
            {selectedShares.size}
          </motion.span>
        )}
      </AnimatePresence>
    </Button>
  )
}
