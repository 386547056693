import { useLocalization } from 'locales/i18n'
import { Divider } from 'ui/@library/layout/divider'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useMasterLoadable } from 'ui/@store/master'
import { getRoyaltyYield } from 'ui/master/utils'

import { MetricItem } from '../metric-item'
import { MetricsExplainerModal } from '../metrics-explainer-modal'
import { MetricType } from '../metrics-explainer-modal/types'

import styles from './styles.module.scss'
export const RevenueBreakdown = () => {
  const { t, f } = useLocalization('master.overviewTab.information.revenue')
  const { master } = useMasterLoadable()
  const { renderModal } = useGlobalModal()
  if (!master) return null
  const royaltyYield = getRoyaltyYield(master)
  const getFormattedForecastedAmount = () => {
    const forecast = master.metrics.forecastedRoyaltiesPerShare?.forecast
    if (!forecast?.length) {
      return
    }
    return forecast.length > 1
      ? `${f.currency(forecast[0] ?? 0)}-${f.currency(forecast[1] ?? 0).replace('€', '')}`
      : f.currency(forecast[0] ?? 0)
  }
  const handleRoyaltiesPerShareInfo = () => {
    renderModal({
      modalKey: ModalKey.MetricsExplainerModal,
      content: <MetricsExplainerModal />,
      enforceAuth: false,
      payload: {
        metricType: MetricType.RoyaltiesPerShare,
      },
    })
  }
  const handleAnnualYieldInfo = () => {
    renderModal({
      modalKey: ModalKey.MetricsExplainerModal,
      content: <MetricsExplainerModal />,
      enforceAuth: false,
      payload: {
        metricType: MetricType.AnnualYield,
      },
    })
  }
  if (
    !master.metrics.royaltiesPerShare &&
    !master.metrics.forecastedRoyaltiesPerShare &&
    !royaltyYield
  )
    return null
  return (
    <>
      <div className={styles.revenueSection}>
        {master.metrics.royaltiesPerShare && (
          <MetricItem
            title={t('royaltiesPerShare.title')}
            value={f.currency(master.metrics.royaltiesPerShare)}
            onClickInfo={handleRoyaltiesPerShareInfo}
            timePeriod={master.metrics.period}
          />
        )}
        {master.isNewRelease && master.metrics.forecastedRoyaltiesPerShare && (
          <MetricItem
            title={t('forecastedRoyaltiesPerShare.title')}
            value={getFormattedForecastedAmount()}
            footNote={t('forecastedShareDisclaimer')}
            timePeriod={t('forPeriod', {
              period: master.metrics.forecastedRoyaltiesPerShare.period,
            })}
          />
        )}
        <MetricItem
          title={t('annualYield.title')}
          value={master.isNewRelease ? t('unavailable') : f.percentage(royaltyYield)}
          footNote={master.isNewRelease ? t('yieldDisclaimer') : null}
          onClickInfo={handleAnnualYieldInfo}
          timePeriod={master.metrics.period}
        />
      </div>
      <Divider />
    </>
  )
}
