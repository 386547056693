import { useLocalization } from 'locales/i18n'
import { useCancelOpenOrder } from 'repositories/mx-api/hooks/use-cancel-open-order'
import { useCancelOrder } from 'repositories/mx-api/hooks/use-cancel-order'
import { useMessages } from 'ui/@components/message-banner/use-messages'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useAuthUserMasterOpenOrdersLoadable } from 'ui/@store/auth-user-master-open-orders'
import { useAuthUserMasterOffersMadeLoadable } from 'ui/@store/auth-user-masters-offers-made'
import { useAuthUserMastersWithFractionsLoadable } from 'ui/@store/auth-user-masters-with-fractions'
import { useAuthUserOpenOrdersLoadable } from 'ui/@store/auth-user-open-orders'
import { useMarketOverviewLoadable } from 'ui/@store/market-overview'
import { useOrderBookLoadable } from 'ui/@store/order-book'
import { useShareListLoadable } from 'ui/@store/share-list'
import { useWalletLoadable } from 'ui/@store/wallet'
import { TransactionFailed } from 'ui/my-assets/@components/overview/@components/modals/transaction-failed'

import styles from './styles.module.scss'

export enum CancelType {
  OpenOrder = 'open_order',
  OfferMade = 'offer_made',
  Ask = 'ask',
}

export type CancelOrderModalProps = {
  type?: CancelType
  bidUuid?: string
  shareUuid?: string
  orderUuid?: string
  fetchOpenOrdersWithMaster?: boolean
}

export const CancelOrderModal = () => {
  const { t } = useLocalization('myAssets.overview.cancelModal')
  const { renderModal, payload, setDisplayModal } = useGlobalModal()
  const { requestCancelOrder, loading: cancelBidLoading } = useCancelOrder(payload?.bidUuid)
  const { requestCancelOpenOrder, loading: cancelOrderLoading } = useCancelOpenOrder(
    payload?.orderUuid,
  )
  const { refetch: refetchOffersMade } = useAuthUserMasterOffersMadeLoadable()
  const { refetch: refetchMasterWithFractions } = useAuthUserMastersWithFractionsLoadable()
  const { refetch: refetchOpenOrders } = useAuthUserOpenOrdersLoadable()
  const { refetch: refetchAuthUserMasterOpenOrders } = useAuthUserMasterOpenOrdersLoadable()
  const { refetch: refetchWallet } = useWalletLoadable()
  const { refetch: refetchOrderBook } = useOrderBookLoadable()
  const { refetchMessages } = useMessages()
  const { refetch: refetchMarketOverview } = useMarketOverviewLoadable()
  const { refetch: refetchShareListAuth } = useShareListLoadable({
    isAuthUserShares: true,
    masterUuid: payload?.masterUuid,
  })
  const { refetch: refetchShareList } = useShareListLoadable({
    isAuthUserShares: false,
    masterUuid: payload?.masterUuid,
  })

  const refetchData = () => {
    refetchMasterWithFractions()
    refetchWallet()
    refetchShareListAuth()
    refetchShareList()
    refetchOrderBook()
    refetchMessages()
    refetchMarketOverview()

    payload?.type === CancelType.OpenOrder
      ? payload?.fetchOpenOrdersWithMaster
        ? refetchAuthUserMasterOpenOrders()
        : refetchOpenOrders()
      : refetchOffersMade()
  }

  const handleCancel = async () => {
    try {
      if (payload?.type === CancelType.OpenOrder) {
        await requestCancelOpenOrder()
      } else if (payload?.type === CancelType.OfferMade) {
        await requestCancelOrder()
      }
    } catch {
      renderModal({
        modalKey: ModalKey.TransactionFailed,
        content: <TransactionFailed />,
      })
    }
    refetchData()
    setDisplayModal(false)
  }

  return (
    <ModalContent className={styles.orderTypesContainer}>
      <div className={styles.messageContainer}>
        <h1 className={styles.title}>
          {payload?.type === CancelType.OpenOrder ? t('orderHeading') : t('offerHeading')}
        </h1>
        <p className={styles.message}>
          {payload?.type === CancelType.OpenOrder ? t('orderMessage') : t('offerMessage')}
        </p>
      </div>
      <div className={styles.btnContainer}>
        <Button
          width='fluid'
          variant='primary'
          onClick={handleCancel}
          className={styles.button}
          loading={cancelBidLoading || cancelOrderLoading}
        >
          {payload?.type === CancelType.OpenOrder ? t('orderYes') : t('offerYes')}
        </Button>
        <Button
          variant='ghost'
          width='fluid'
          onClick={() => {
            setDisplayModal(false)
          }}
          className={styles.button}
        >
          {payload?.type === CancelType.OpenOrder ? t('orderNo') : t('offerNo')}
        </Button>
      </div>
    </ModalContent>
  )
}
