import { IonApp } from '@ionic/react'
import { ReactElement } from 'react'

import { usePlatform } from 'ui/@hooks/use-platform'

export const IonAppWrapper = ({ children }: { children: ReactElement }) => {
  const { isNative } = usePlatform()

  if (isNative) {
    return <IonApp>{children}</IonApp>
  }

  return children
}
