import { useLocalization } from 'locales/i18n'
import { OrderType } from 'types/order'
import { useWindowSize } from 'ui/@hooks/use-window-size'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { ReviewImoBuy } from 'ui/master/@components/modals/review-order'

import { TraderModal } from '../../master-overview/@components/banner-trader/@components/mobile-trader/@components/trader-modal'
import { ImoBrief } from '../../master-overview/@components/imo-brief'

import styles from './styles.module.scss'

export type ReviewImoProps = {
  pricePerShare: number
  numberOfShares: number
  totalOrderAmount: number
}

export const ReviewImo = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader.modals.reviewImo')
  const { renderModal, payload } = useGlobalModal()
  const { viewports } = useWindowSize()
  const { isTablet } = viewports

  const handleContinueOrder = () => {
    if (isTablet) {
      renderModal({
        modalKey: ModalKey.Trader,
        content: <TraderModal />,
      })
    } else {
      renderModal({
        modalKey: ModalKey.ReviewImoBuy,
        content: <ReviewImoBuy />,
        enforceAuth: true,
        size: ModalSize.Large,
        payload: {
          orderType: OrderType.ImoBid,
          totalOrderAmount: payload?.totalOrderAmount,
        },
      })
    }
  }

  return (
    <ModalContent className={styles.reviewImoModal}>
      <ImoBrief isPadded={false} />
      <Button
        width='fluid'
        variant='primary'
        onClick={handleContinueOrder}
        className={styles.continueButton}
      >
        {t('buttons.continue')}
      </Button>
    </ModalContent>
  )
}
