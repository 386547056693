import { Form, Formik, FormikHelpers } from 'formik'
import { useEffect } from 'react'
import { object, SchemaOf, string } from 'yup'

import { ampli } from 'ampli'
import { SupportedCountries } from 'constants/supported-countries'
import { useLocalization } from 'locales/i18n'
import { updateAuthUser } from 'repositories/mx-api'
import { Button } from 'ui/@library/inputs/button'
import { Input } from 'ui/@library/inputs/input'
import { Select } from 'ui/@library/inputs/select'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { KycStepProps } from 'ui/kyc/types'

import styles from './styles.module.scss'

const ADDRESS_MAX_LENGTH = 50
const ADDRESS_MIN_LENGTH = 3
const ZIPCODE_MAX_LENGTH = 15
const ZIPCODE_MIN_LENGTH = 2

type FormValues = {
  street: string
  addressLine2?: string
  city: string
  zipcode: string
  country: string
}

const initialValues: FormValues = {
  street: '',
  addressLine2: '',
  city: '',
  zipcode: '',
  country: '',
}

const countryOptions = SupportedCountries.map((country) => ({
  value: country.code,
  label: country.name,
}))

export const UserAddress = ({ steps, setCurrentStep, setError, onSkip }: KycStepProps) => {
  const { t } = useLocalization('kyc')
  const { authUser, refetchAuthUser } = useAuthUserLoadable()

  useEffect(() => {
    refetchAuthUser()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      authUser?.address.street &&
      authUser?.address.city &&
      authUser?.address.zipcode &&
      authUser?.address.countryCode
    ) {
      onStepComplete()
    }
    // eslint-disable-next-line
  }, [])

  const onStepComplete = () => {
    setCurrentStep(steps.selectDocumentType)
  }

  const validationSchema: SchemaOf<FormValues> = object({
    street: string()
      .required(t('userAddress.form.street.required'))
      .max(ADDRESS_MAX_LENGTH, t('userAddress.form.street.max', { val: ADDRESS_MAX_LENGTH }))
      .min(ADDRESS_MIN_LENGTH, t('userAddress.form.street.min', { val: ADDRESS_MIN_LENGTH })),
    addressLine2: string().max(
      ADDRESS_MAX_LENGTH,
      t('userAddress.form.street.max', { val: ADDRESS_MAX_LENGTH }),
    ),
    city: string()
      .required(t('userAddress.form.city.required'))
      .min(ADDRESS_MIN_LENGTH, t('userAddress.form.city.min', { val: ADDRESS_MIN_LENGTH })),
    zipcode: string()
      .required(t('userAddress.form.zipcode.required'))
      .min(ZIPCODE_MIN_LENGTH, t('userAddress.form.zipcode.min', { val: ZIPCODE_MIN_LENGTH }))
      .max(ZIPCODE_MAX_LENGTH, t('userAddress.form.zipcode.max', { val: ZIPCODE_MAX_LENGTH })),
    country: string().required(t('userAddress.form.country.required')),
  })

  const onSubmitHandler = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.validateForm(values)
    try {
      await updateAuthUser({
        address: {
          street: values.street,
          addressLine2: values.addressLine2,
          city: values.city,
          zipcode: values.zipcode,
          countryCode: values.country,
        },
      })

      ampli.submitUserAddressSuccess()

      onStepComplete()
    } catch {
      setError(true)
    }
  }

  return (
    <>
      <h1 className={styles.title}>{t('userAddress.title')}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
        validateOnBlur
      >
        {({ isSubmitting, isValid, values }) => (
          <Form className={styles.form}>
            <Input
              name='street'
              label={t('userAddress.form.street.label')}
              type='text'
              placeholder={t('userAddress.form.street.placeholder')}
              autoComplete='street-address'
            />
            <Input
              name='addressLine2'
              label={t('userAddress.form.addressLine2.label')}
              type='text'
              placeholder={t('userAddress.form.addressLine2.placeholder')}
              autoComplete='address-line1'
            />
            <Input
              name='city'
              label={t('userAddress.form.city.label')}
              type='text'
              placeholder={t('userAddress.form.city.placeholder')}
              autoComplete='address-level2'
            />
            <Input
              name='zipcode'
              label={t('userAddress.form.zipcode.label')}
              type='text'
              placeholder={t('userAddress.form.zipcode.placeholder')}
              autoComplete='postal-code'
            />
            <Select
              name='country'
              label={t('userAddress.form.country.label')}
              options={countryOptions}
              placeholder={t('userAddress.form.country.placeholder')}
              currentValue={values.country}
              autoComplete='country'
            />
            <div className={styles.buttonContainer}>
              <Button width='fluid' type='submit' loading={isSubmitting} disabled={!isValid}>
                {t('userAddress.continue')}
              </Button>
              <Button width='fluid' variant='ghost' onClick={onSkip}>
                {t('skip')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
