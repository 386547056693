import { useState } from 'react'
import { Slide, ToastContainer, toast } from 'react-toastify'

import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg'
import { ReactComponent as CopyLogo } from 'assets/icons/duplicate.svg'
import { useLocalization } from 'locales/i18n'

import { Button } from '../button'

import styles from './styles.module.scss'
import 'react-toastify/dist/ReactToastify.css'

export enum ToastPosition {
  TOP_CENTER = 'top-center',
  BOTTOM_CENTER = 'bottom-center',
}

type CopyToCliploardProps = {
  text: string
  withInput?: boolean
  label?: string
  handleAmpliEvent?: () => void
  className?: string
  withToast?: boolean
  withButton?: boolean
  toastMessage?: string
  toastPosition?: ToastPosition
  disabled?: boolean
  disabledMessage?: string
}

const COPY_TIMEOUT = 3000

export const CopyToCliploard = ({
  text,
  label,
  handleAmpliEvent,
  withInput = false,
  className,
  withToast = false,
  withButton = false,
  toastMessage = 'Link copied',
  toastPosition = ToastPosition.TOP_CENTER,
  disabled = false,
  disabledMessage,
}: CopyToCliploardProps) => {
  const { t } = useLocalization('components')
  const [isCopied, setIsCopied] = useState(false)

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(text)
    setIsCopied(true)
    withToast && toast(toastMessage)

    if (handleAmpliEvent) {
      handleAmpliEvent()
    }

    setTimeout(() => {
      setIsCopied(false)
    }, COPY_TIMEOUT)
  }
  const showDisabledMessage = disabled && disabledMessage

  return (
    <div className={`${styles.copyToClipboardWrapper} ${className}`}>
      {label && <p className={styles.label}>{label}</p>}
      <div className={`${styles.copyWrapper} ${disabled && styles.disabledCopyWrapper}`}>
        {withInput ? (
          <input type='text' value={text} readOnly className={styles.readyOnlyInput} />
        ) : (
          <p>{text}</p>
        )}
        {showDisabledMessage && (
          <p className={`${styles.disabledMessage} ${styles.mobile}`}>{disabledMessage}</p>
        )}
        {withButton ? (
          <Button
            onClick={handleCopyToClipboard}
            disabled={disabled}
            className={styles.copyPrimaryButton}
          >
            {t('copyToClipboard')}
          </Button>
        ) : (
          <div className={styles.iconWrapper}>
            {isCopied ? (
              <CheckIcon className={styles.checkIcon} />
            ) : (
              <CopyLogo className={styles.copyButton} onClick={handleCopyToClipboard} />
            )}
          </div>
        )}
      </div>
      {showDisabledMessage && (
        <p className={`${styles.disabledMessage} ${styles.desktop}`}>{disabledMessage}</p>
      )}
      <ToastContainer
        position={toastPosition}
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        transition={Slide}
      />
    </div>
  )
}
