import { useEffect } from 'react'

import { LandingPageRoutes } from 'constants/external-links'
import { useLocalization } from 'locales/i18n'
import { useLandingPageLinks } from 'ui/@hooks/use-landing-page-links'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useSignInAndSignUp } from 'ui/@hooks/use-signin-and-signup'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { LocalizedLink } from 'ui/@library/utils/localized-link'

import { SongInfo } from './@components/song-info'
import { SongOwners } from './@components/song-owners'
import styles from './styles.module.scss'

export const SignInPrompt = () => {
  const { t } = useLocalization('master.overviewTab.signInPrompt')
  const { setDisplayModal } = useGlobalModal()
  const { isAuthenticated } = useMxAuth()
  const { signIn } = useSignInAndSignUp()
  const generateLandingPageLink = useLandingPageLinks()

  useEffect(() => {
    if (isAuthenticated) {
      setDisplayModal(false)
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  return (
    <ModalContent className={styles.container}>
      <div className={styles.topWrapper}>
        <h2 className={styles.title}>{t('title')}</h2>
        <div className={styles.songDetails}>
          <SongInfo />
          <SongOwners />
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button onClick={signIn}>{t('signIn')}</Button>
        <p className={styles.signIn}>
          <LocalizedLink
            href={generateLandingPageLink(LandingPageRoutes.WAITLIST)}
            linkClassName={styles.signInLink}
            translationKey='master.overviewTab.signInPrompt.joinWaitlistAction'
          />
        </p>
      </div>
    </ModalContent>
  )
}
