import { useMemo } from 'react'

import { routes } from '../../routes'
import { RouteLink } from '../route-link'

import styles from './styles.module.scss'

export const NavbarHeaderLinks = () => {
  const headerLinks = useMemo(() => routes.filter((link) => !link.dropdownOnly), [])

  return (
    <div className={styles.routesContainer}>
      {headerLinks.map((link) => (
        <RouteLink key={link.label} route={link} />
      ))}
    </div>
  )
}
