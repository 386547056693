import { AuthUser, AuthUserRaw } from 'types/auth-user'

import { sanitizeAuthUserFromRaw } from '../@santizers/auth-user'
import { MxApi } from '../mx-api'

export const fetchAuthUser = async (): Promise<AuthUser> => {
  const { data } = await MxApi.get<AuthUserRaw>('/auth-users/me')

  return sanitizeAuthUserFromRaw(data)
}
