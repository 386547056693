import { ChangeEvent, useEffect, useState, useRef } from 'react'

import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type QuantityInputProps = {
  limit?: number
  displayErrorMessage?: boolean
  setDisplayErrorMessage?: (displayErrorMessage: boolean) => void
  value: number
  setValue: (value: number) => void
  isDisabled: boolean
  startFromRight?: boolean
  onFocusChange?: (isFocused: boolean) => void
}

export const QuantityInput = ({
  value,
  setValue,
  isDisabled,
  limit,
  displayErrorMessage = false,
  setDisplayErrorMessage,
  startFromRight = false,
  onFocusChange,
}: QuantityInputProps) => {
  const { t } = useLocalization(
    'master.overviewTab.sidebar.trader.market.information.quantity.error',
  )
  const [errorMessage, setErrorMessage] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (displayErrorMessage) {
      if (limit && value > limit) setErrorMessage(t('max', { maxLimit: limit }))
      if (isNaN(value) || !value) setErrorMessage(t('min'))
    }

    // eslint-disable-next-line
  }, [displayErrorMessage])

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const quantity = Math.ceil(event.target.valueAsNumber)
    setValue(quantity <= 1 ? 1 : quantity)
    if (quantity) {
      setDisplayErrorMessage && setDisplayErrorMessage(false)
    }
    if (limit) {
      if (quantity > limit) {
        setDisplayErrorMessage && setDisplayErrorMessage(true)
        setErrorMessage(t('max', { maxLimit: limit }))
      } else {
        setDisplayErrorMessage && setDisplayErrorMessage(false)
      }
    }
  }

  const handleFocus = () => {
    if (onFocusChange) {
      onFocusChange(true)
    }

    const inputElem = inputRef.current
    if (startFromRight && inputElem) {
      const cursorPosition = inputElem.value.length
      inputElem.type = 'text'
      inputElem.setSelectionRange(cursorPosition, cursorPosition)
      inputElem.type = 'number'
    }
  }

  const handleOnBlur = () => {
    if (onFocusChange) {
      onFocusChange(false)
    }
  }

  return (
    <div className={styles.quantityInputContainer}>
      <input
        ref={inputRef}
        type='number'
        className={`${styles.quantityInput} ${displayErrorMessage && styles.error}`}
        value={isNaN(value) ? '' : value}
        step={1}
        min={0}
        placeholder='0'
        onChange={handleQuantityChange}
        disabled={isDisabled}
        inputMode='numeric'
        onFocus={handleFocus}
        onBlur={handleOnBlur}
      />
      {displayErrorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  )
}
