import styles from './styles.module.scss'

type StepProps = {
  number: number
  description: string
}

export const Step = ({ number, description }: StepProps) => {
  return (
    <div className={styles.step}>
      <div className={styles.stepNumber}>{number}</div>
      <div className={styles.stepDescription}>{description}</div>
    </div>
  )
}
