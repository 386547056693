import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table'

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg'

import styles from './styles.module.scss'

type TableProps<TData> = {
  data: TData[]
  columns: ColumnDef<TData>[]
  containerClassName?: string
  tableClassName?: string
  headerClassName?: string
  headerRowClassName?: string
  bodyClassName?: string
  rowClassName?: string
  hideInMobile?: boolean
  rowOnClick?: (row: Row<TData>) => void
}

export const Table = <TData extends object>({
  data,
  columns,
  containerClassName,
  tableClassName,
  headerClassName,
  headerRowClassName,
  bodyClassName,
  rowClassName,
  hideInMobile,
  rowOnClick,
}: TableProps<TData>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
  const concatenatedClassName = `${styles.tableContainer} ${
    hideInMobile && styles.hideInMobile
  } ${containerClassName}`
  const concatenatedTableClassName = tableClassName
    ? `${tableClassName} ${styles.flexTable}`
    : styles.flexTable
  const concatenatedHeaderClassName = headerClassName
    ? `${headerClassName} ${styles.thead}`
    : styles.thead
  const concatenatedHeaderRowClassName = headerRowClassName
    ? `${headerRowClassName} ${styles.tr}`
    : styles.tr
  const concatendatedBodyClassName = bodyClassName
    ? `${bodyClassName} ${styles.tbody}`
    : styles.tbody
  const concatendatedRowClassName = `${styles.tr} ${rowOnClick && styles.withClick} ${rowClassName}`

  return (
    <div>
      <div className={concatenatedClassName}>
        <div className={concatenatedTableClassName}>
          <div className={concatenatedHeaderClassName}>
            {table.getHeaderGroups().map((headerGroup) => (
              <div key={headerGroup.id} className={concatenatedHeaderRowClassName}>
                {headerGroup.headers.map((header) =>
                  header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, {
                        ...header.getContext(),
                        key: header.id,
                      }),
                )}
              </div>
            ))}
          </div>
          <div className={concatendatedBodyClassName}>
            {table.getRowModel().rows.map((row) => (
              <div
                key={row.id}
                className={concatendatedRowClassName}
                onClick={() => rowOnClick && rowOnClick(row)}
              >
                {row
                  .getVisibleCells()
                  .map((cell) =>
                    flexRender(cell.column.columnDef.cell, { ...cell.getContext(), key: cell.id }),
                  )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {table.getPageCount() > 1 ? (
        <div className={`${styles.pagination} ${hideInMobile && styles.hideInMobile}`}>
          <div
            className={styles.button}
            onClick={() => table.getCanPreviousPage() && table.previousPage()}
          >
            <ChevronLeft />
          </div>
          <p className={styles.paginationText}>
            <span>{table.getState().pagination.pageIndex + 1}</span> of{' '}
            <span>{table.getPageCount()}</span>
          </p>
          <div className={styles.button} onClick={() => table.getCanNextPage() && table.nextPage()}>
            <ChevronRight />
          </div>
        </div>
      ) : null}
    </div>
  )
}
