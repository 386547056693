import { ReactComponent as ShieldCheck } from 'assets/icons/shield-check.svg'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

export const AddCardSuccess = () => {
  const { t } = useLocalization('addFunds.addCardSuccess')
  const { setDisplayModal } = useGlobalModal()

  const handleCloseModal = () => {
    setDisplayModal(false)
  }

  return (
    <ModalContent className={styles.container}>
      <div className={styles.topWrapper}>
        <div className={styles.iconWrapper}>
          <ShieldCheck className={styles.shieldIcon} />
        </div>
        <h2 className={styles.title}>{t('title')}</h2>
        <p className={styles.description}>{t('description')}</p>
      </div>
      <Button onClick={handleCloseModal} className={styles.button}>
        {t('buttonText')}
      </Button>
    </ModalContent>
  )
}
