import { useLocalization } from 'locales/i18n'
import { Slider } from 'ui/@library/graphs/slider'
import { useMasterLoadable } from 'ui/@store/master'

import styles from './styles.module.scss'

export const RoyaltyDistribution = () => {
  const { t } = useLocalization('master.overviewTab.statistics.royaltyDistribution')
  const { master } = useMasterLoadable()

  if (
    !master ||
    !master.metrics.forecastedMaxStreams ||
    !master.metrics.royaltiesPerStreamPerShare
  ) {
    return null
  }

  return (
    <>
      <div className={styles.royaltyDistributionContainer}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>{t('title')}</h1>
        </div>
        <Slider
          className={styles.royaltySlider}
          maxKey={master.metrics.forecastedMaxStreams}
          valueMultiplier={master.metrics.royaltiesPerStreamPerShare}
        />
      </div>
    </>
  )
}
