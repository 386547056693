import { LandingPageRoutes } from 'constants/external-links'
import { useLandingPageLinks } from 'ui/@hooks/use-landing-page-links'
import { LocalizedLink } from 'ui/@library/utils/localized-link'

import styles from '../../styles.module.scss'

export const TermsAndConditionsLink = () => {
  const generateLandingPageLink = useLandingPageLinks()

  return (
    <LocalizedLink
      href={generateLandingPageLink(LandingPageRoutes.TERMS_AND_CONDITIONS)}
      translationKey='master.overviewTab.sidebar.trader.modals.agreementLinks.termsAndConditions'
      linkClassName={styles.agreementLink}
    />
  )
}
