import {
  Root,
  Item,
  Header,
  Trigger,
  Content,
  AccordionContentProps,
  AccordionItemProps,
  AccordionTriggerProps,
} from '@radix-ui/react-accordion'
import { forwardRef, Ref } from 'react'

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg'

import styles from './styles.module.scss'

export const AccordionContainer = ({
  children,
  value,
  setValue,
}: {
  children: React.ReactNode
  value?: string
  setValue?: (value: string) => void
}) => (
  <Root
    value={value}
    onValueChange={setValue}
    className={styles.accordionRoot}
    type='single'
    collapsible
  >
    {children}
  </Root>
)

export const AccordionItem = ({ children, className, value, ...props }: AccordionItemProps) => (
  <Item className={`${styles.accordionItem} ${className}`} value={value} {...props}>
    {children}
  </Item>
)

export const AccordionHeader = forwardRef(
  (
    { children, className, ...props }: AccordionTriggerProps,
    forwardedRef: Ref<HTMLButtonElement>,
  ) => (
    <Header className={styles.accordionHeader}>
      <Trigger
        className={`${styles.accordionHeader} ${styles.btnReset} ${className}`}
        ref={forwardedRef}
        {...props}
      >
        {children}
        <ChevronDownIcon className={styles.accordionChevron} aria-hidden />
      </Trigger>
    </Header>
  ),
)
AccordionHeader.displayName = 'AccordionHeader'

export const AccordionContent = forwardRef(
  ({ children, className, ...props }: AccordionContentProps, forwardedRef: Ref<HTMLDivElement>) => (
    <Content className={`${styles.accordionContent} ${className}`} ref={forwardedRef} {...props}>
      {children}
    </Content>
  ),
)
AccordionContent.displayName = 'AccordionContent'
