import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchRoyaltyPayouts } from 'repositories/mx-api'
import { RoyaltyPayoutData } from 'types/royalty-payouts'

import { masterUuidState } from './master'

const royaltyPayoutsQuery = selector<RoyaltyPayoutData[] | null>({
  key: 'royaltyPayoutsQuery',
  get: async ({ get }) => {
    const masterUuid = get(masterUuidState)
    if (!masterUuid) return null
    return fetchRoyaltyPayouts(masterUuid)
  },
})

export const useRoyaltyPayoutsLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(royaltyPayoutsQuery)
  const refetch = useRecoilRefresher_UNSTABLE(royaltyPayoutsQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    royaltyPayouts: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
