import { ReactNode } from 'react'

import styles from './styles.module.scss'

type SectionTitleProps = {
  children: ReactNode
}

export const SectionTitle = ({ children }: SectionTitleProps) => {
  return <h2 className={styles.sectionTitle}>{children}</h2>
}
