import { atom, useRecoilState, useRecoilValue } from 'recoil'

export enum Direction {
  Right = -1,
  Left = 1,
  None = 0,
}

type NavigationState = {
  direction: Direction
}

const navigationState = atom<NavigationState>({
  key: 'navigation',
  default: {
    direction: Direction.None,
  },
})

export const useNavigationState = () => {
  return useRecoilState(navigationState)
}

export const useNavigation = () => {
  return useRecoilValue(navigationState)
}
