import { useLocalization } from 'locales/i18n'
import { useFetchAuthUserIban } from 'repositories/mx-api'
import { Spinner } from 'ui/@library/feedback/spinner'
import { Button } from 'ui/@library/inputs/button'
import { Divider } from 'ui/@library/layout/divider'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'

import { AccountDetail } from './@components/account-detail'
import { Step } from './@components/step'
import styles from './styles.module.scss'

export const Iban = () => {
  const { t, f } = useLocalization('addFunds.iban')
  const { setDisplayModal } = useGlobalModal()
  const { authUserIban, loading } = useFetchAuthUserIban()

  const handleModalClose = () => {
    setDisplayModal(false)
  }

  const steps = [
    {
      number: 1,
      description: 'step1',
    },
    {
      number: 2,
      description: 'step2',
    },
    {
      number: 3,
      description: 'step3',
    },
  ]

  if (loading) return <Spinner className={styles.spinner} />

  return (
    <ModalContent className={styles.bankTransferContainer}>
      <div className={styles.bankTranserInfoWrapper}>
        <div className={styles.bankTransferInfo}>
          <h1 className={styles.title}>{t('title')}</h1>
          <div className={styles.stepContents}>
            <div className={styles.topWrapper}>
              <div>{t('description')}</div>
              {steps.map((step) => (
                <Step key={step.number} number={step.number} description={t(step.description)} />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.stickyButtonContainer}>
          <Button variant='primary' width='fluid' onClick={handleModalClose}>
            {t('done')}
          </Button>
        </div>
      </div>
      <Divider />
      <div className={styles.bankAccountContainer}>
        <div className={styles.headingWrapper}>
          <h2 className={styles.bankDetailsTitle}>{t('bankDetailsTitle')}</h2>
          <p className={styles.bankDetailsDescription}>{t('bankDetailsDescription')}</p>
        </div>
        <div className={styles.accountDetails}>
          <AccountDetail
            label={t('currencyAccepted')}
            value={`${f.currencySymbol()} ${t('euro')}`}
            disableCopy
          />
          <AccountDetail label={t('iban')} value={authUserIban?.iban} />
          <AccountDetail label={t('receiverName')} value={authUserIban?.receiverName} />
          <AccountDetail label={t('bic')} value={authUserIban?.bic} />
          <AccountDetail label={t('bankNameTitle')} value={authUserIban?.bankName} />
          <AccountDetail label={t('bankAddressTitle')} value={authUserIban?.bankAddress} />
        </div>
      </div>
    </ModalContent>
  )
}
