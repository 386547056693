import camelcaseKeys from 'camelcase-keys'

import { BidRaw, BestPriceShareRaw } from 'types/bid'
import { ShareWithOrdersRaw } from 'types/share'

export const sanitizeMasterOffersMadeFromRaw = (raw: BidRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeMasterOffersReceivedFromRaw = (raw: ShareWithOrdersRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeBestPriceShareFromRaw = (raw: BestPriceShareRaw) => {
  return camelcaseKeys(raw, { deep: true })
}
