import { captureException } from '@sentry/react'

import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { Spinner } from 'ui/@library/feedback/spinner'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { PreAuth3DS } from 'ui/@modals/add-funds/@components/preauth-3ds'
import { useCardPreAuthorization } from 'ui/@store/card-pre-authorization'

import { AddCardErrorModal } from '../add-card-error-modal'
import { AddCardSuccess } from '../add-card-success'

import styles from './styles.module.scss'

export type AddCard3DSModalProps = {
  cardId: string
}

const VALIDATE_CARD_AMOUNT = 0.1

export const AddCard3DSModal = () => {
  const { renderModal, payload } = useGlobalModal()

  const handleSuccess = () => {
    renderModal({ modalKey: ModalKey.AddCardSuccess, content: <AddCardSuccess /> })
  }

  const handleError = () => {
    renderModal({ modalKey: ModalKey.AddCardError, content: <AddCardErrorModal /> })
  }

  const { createCardPreAuthorization, cardPreAuthorization, loading } = useCardPreAuthorization()

  useEffectOnce(() => {
    if (payload?.cardId && !cardPreAuthorization && !loading) {
      try {
        createCardPreAuthorization(VALIDATE_CARD_AMOUNT, payload?.cardId)
      } catch (error) {
        captureException(error)
        handleError()
      }
    }
  })

  if (cardPreAuthorization && !loading) {
    return <PreAuth3DS onSuccess={handleSuccess} onError={handleError} />
  } else {
    return <Spinner className={styles.spinner} />
  }
}
