import { ReactComponent as TooltipIcon } from 'assets/icons/info.svg'
import { useLocalization } from 'locales/i18n'
import { Dropdown } from 'ui/@library/inputs/dropdown'
import { DropdownListItem } from 'ui/@library/inputs/dropdown/dropdown'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useMarketOverviewLoadable } from 'ui/@store/market-overview'
import {
  TradeActionType,
  OrderType,
} from 'ui/master/@components/master-overview/@components/banner-trader/types'
import { useTrader } from 'ui/master/@components/master-overview/@components/banner-trader/use-trader'
import { OrderTypeInfo } from 'ui/master/@components/modals/order-type'

import styles from '../../styles.module.scss'

type TradeTypeProps = {
  label: string
}

export const TradeType = ({ label }: TradeTypeProps) => {
  const { tradeActionType, orderType, setOrderType } = useTrader()
  const { marketOverview } = useMarketOverviewLoadable()
  const { t } = useLocalization('master.overviewTab.sidebar.trader.market')

  const { renderModal } = useGlobalModal()

  const handleOrderTypeInfo = () => {
    renderModal({
      modalKey: ModalKey.OrderType,
      content: <OrderTypeInfo />,
      enforceAuth: false,
    })
  }

  const dropdownOptions: DropdownListItem[] = [
    {
      label: 'market',
      value: <span>{t('information.orderType.market')}</span>,
      onClick: () => setOrderType(OrderType.BestPrice),
      disabled: !marketOverview?.bestBidMaxQuantity,
    },
    {
      label: 'limit',
      value: <span>{t('information.orderType.limit')}</span>,
      onClick: () => setOrderType(OrderType.Limit),
    },
  ]

  if (tradeActionType === TradeActionType.Buy) {
    return (
      <li>
        <span className={styles.orderType}>
          {label}
          <div className={styles.infoIcon}>
            <TooltipIcon onClick={handleOrderTypeInfo} />
          </div>
        </span>
        <span>
          <Dropdown
            options={dropdownOptions}
            color='secondary'
            className={styles.orderTypeDropdown}
            type='dropdown'
            horizontalAlign='right'
          >
            <div className={styles.dropdownValue}>
              {orderType === OrderType.Limit
                ? t('information.orderType.limit')
                : t('information.orderType.market')}
            </div>
          </Dropdown>
        </span>
      </li>
    )
  } else return null
}
