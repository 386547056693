import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Paths, QueryParams, Redirects } from 'constants/paths'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useSignInAndSignUp } from 'ui/@hooks/use-signin-and-signup'
import { useSignout } from 'ui/@hooks/use-signout'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'

import { AuthType } from './types'

type AuthRedirectProps = {
  type: AuthType
}

export const AuthRedirect = ({ type }: AuthRedirectProps) => {
  const { isAuthenticated } = useMxAuth()
  const { signIn, signUp } = useSignInAndSignUp()
  const signOut = useSignout()
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()

  const redirect = queryParams.get(QueryParams.REDIRECT)
  const method = queryParams.get(QueryParams[Paths.LOGGED_OUT].METHOD)

  useEffect(() => {
    switch (type) {
      case AuthType.SignOut:
        signOut()
        break
      case AuthType.SignOutNavigation:
        if (redirect === Redirects.LOGIN_METHOD_CONFLICT.KEY) {
          navigate(Redirects.LOGIN_METHOD_CONFLICT.PATH, { replace: true, state: { method } })
        } else if (redirect === Redirects.SIGNUPS_DISABLED.KEY) {
          navigate(Redirects.SIGNUPS_DISABLED.PATH, { replace: true })
        } else {
          navigate(Paths.HOME, { replace: true })
        }
        break
      case AuthType.SignIn:
        if (isAuthenticated) {
          navigate(Paths.HOME, { replace: true })
        } else {
          signIn()
        }
        break
      case AuthType.SignUp:
        if (isAuthenticated) {
          navigate(Paths.HOME, { replace: true })
        } else {
          navigate(Redirects.SIGNUPS_DISABLED.PATH, { replace: true })
        }
        break
      case AuthType.InviteSignUp:
        if (isAuthenticated) {
          navigate(Paths.HOME, { replace: true })
        } else {
          signUp()
        }
        break
      default:
        break
    }
    // eslint-disable-next-line
  }, [])

  return <FullPageLoader />
}
