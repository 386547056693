import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchBankAccounts } from 'repositories/mx-api'
import { BankAccounts } from 'types/bank-accounts'

const bankAccountsQuery = selector<BankAccounts[]>({
  key: 'bankAccountsQuery',
  get: async () => fetchBankAccounts(),
})

export const useBankAccountsLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(bankAccountsQuery)
  const refetch = useRecoilRefresher_UNSTABLE(bankAccountsQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    bankAccounts: state === 'hasValue' ? contents : [],
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
