import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { ampli } from 'ampli'
import { Paths } from 'constants/paths'
import { useAuthUserOnboardingStatus } from 'ui/@hooks/use-auth-user-onboarding-status'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'
import { ApplicationRoutes } from 'ui/application-routes'
import { usePlatformTourSetup } from 'ui/onboarding/plaform-tour/use-platform-tour-setup'

import { useAppUrlListener, useAuthErrors, useInitAuthUser, useStatusBarSetup } from './@hooks'
import { usePushNotifications } from './@hooks/use-push-notifications'

export const ApplicationWrapper = () => {
  const { isLoading } = useMxAuth()
  const { isAuthUserInitialised } = useInitAuthUser()
  const { firstUnfinishedStepRoute, loading: isOnboardingStatusLoading } =
    useAuthUserOnboardingStatus()
  const { error } = useAuthErrors()
  const location = useLocation()
  usePlatformTourSetup()
  useStatusBarSetup()
  useAppUrlListener()
  usePushNotifications()

  useEffect(() => {
    ampli.viewPage({
      '[Amplitude] Page Path': location.pathname,
    })
  }, [location.pathname])

  if (error) {
    return error
  }

  if (
    isLoading || // Loading state for auth0
    !isAuthUserInitialised || // Loading state for auth user init
    isOnboardingStatusLoading // Loading state for onboarding flow
  ) {
    return <FullPageLoader />
  }

  if (
    firstUnfinishedStepRoute &&
    !location.pathname.includes(firstUnfinishedStepRoute) &&
    !location.pathname.includes(Paths.SIGN_OUT) &&
    !location.pathname.includes(Paths.KYC)
  ) {
    return <Navigate to={`${Paths.ONBOARDING.MAIN}${firstUnfinishedStepRoute}`} />
  }

  return <ApplicationRoutes />
}
