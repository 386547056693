import { useNavigate } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { Divider } from 'ui/@library/layout/divider'

import { ListedSongsTableColumns } from '../../types'

import styles from './styles.module.scss'

type ListedSongsMobileTableProps = {
  tableData: ListedSongsTableColumns[]
}

export const ListedSongsMobileTable = ({ tableData }: ListedSongsMobileTableProps) => {
  const navigate = useNavigate()
  const { t, f } = useLocalization('myAssets.overview.listedSongsTable')

  return (
    <div className={styles.tableContainer}>
      {tableData.map((item) => {
        return (
          <div
            className={styles.rowWrapper}
            key={item.uuid}
            onClick={() => navigate(`${Paths.MASTER.MAIN}/${item.uuid}`)}
          >
            <div className={styles.header}>
              <img
                src={item.thumbnail}
                alt={`${item.masterName} thumbnail`}
                className={styles.thumbnail}
              />
              <p className={styles.songInfo}>{`${item.masterName} - ${item.artistName}`}</p>
            </div>
            <div className={styles.statContainer}>
              <div className={styles.statWrapper}>
                <p className={styles.statTitle}>{t('columns.totalShares')}</p>
                <p className={styles.statValue}>{item.totalShares ?? 0}</p>
              </div>
              <div className={styles.statWrapper}>
                <p className={styles.statTitle}>{t('columns.earningsFromImo')}</p>
                <p className={styles.statValue}>{f.currency(item.earningsFromImo)}</p>
              </div>
              <div className={styles.statWrapper}>
                <p className={styles.statTitle}>{t('columns.earningsFromTradingFees')}</p>
                <p className={styles.statValue}>{f.currency(item.earningsFromTradingFees)}</p>
              </div>
            </div>
            <Divider />
          </div>
        )
      })}
    </div>
  )
}
