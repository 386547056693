import { useLocalization } from 'locales/i18n'
import { Spinner } from 'ui/@library/feedback/spinner'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { AddFundsConfirm, PaymentMethodModal } from 'ui/@modals/add-funds'
import { useCardsLoadable } from 'ui/@store/cards'
import { useWalletLoadable } from 'ui/@store/wallet'

import styles from './styles.module.scss'

export type InsufficientFundsProps = {
  sharesCost: number
  numberOfShares?: number
  retriggerReviewOrder: boolean
  onAddFundsSuccessCallback?: () => void
}

export const InsufficientFunds = () => {
  const { t, f } = useLocalization('master.overviewTab.sidebar.trader.modals.insufficientFunds')
  const { cards, loading: cardsLoading } = useCardsLoadable({ forceRefetch: true })
  const { setDisplayModal, renderModal, payload: modalPayload } = useGlobalModal()
  const { wallet } = useWalletLoadable()

  const accountBalance = wallet?.primaryBalance

  const handleModalClose = () => {
    setDisplayModal(false)
  }

  const handleAddFunds = () => {
    if (cards.length !== 0 && accountBalance !== undefined)
      renderModal({
        modalKey: ModalKey.AddFundsConfirm,
        content: <AddFundsConfirm />,
        payload: { prefilledAmount: modalPayload?.sharesCost },
      })
    else
      renderModal({
        modalKey: ModalKey.PaymentMethod,
        content: <PaymentMethodModal />,
        payload: { onAddFundsSuccessCallback: modalPayload?.onAddFundsSuccessCallback },
      })
  }

  return (
    <ModalContent className={styles.insufficientFundsContainer}>
      {cardsLoading ? (
        <Spinner width='fluid' />
      ) : (
        <div className={styles.contentWrapper}>
          <div className={styles.topWrapper}>
            <h1 className={styles.title}>{t('title')}</h1>
            <p className={styles.subtitle}>{t('subtitle')}</p>
            <div className={styles.informationContainer}>
              <p className={styles.highlightedText}>{`${t('purchase')} ${f.currency(
                modalPayload?.sharesCost,
              )}`}</p>
              <p>{`${t('balance')} ${
                accountBalance !== undefined ? f.currency(accountBalance) : '--'
              }`}</p>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button width='fluid' variant='primary' onClick={handleAddFunds}>
              {t('buttons.deposit')}
            </Button>
            <Button width='fluid' variant='ghost' onClick={handleModalClose}>
              {t('buttons.cancel')}
            </Button>
          </div>
        </div>
      )}
    </ModalContent>
  )
}
