import { useLocalization } from 'locales/i18n'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'
import { MetricType } from './types'

export const MetricsExplainerModal = () => {
  const { t } = useLocalization('master.overviewTab.information')
  const { payload } = useGlobalModal()

  const getExplainerInfo = (metricType: MetricType) => {
    switch (metricType) {
      case MetricType.RoyaltiesPerShare:
        return {
          title: t('revenue.royaltiesPerShare.title'),
          description: t('revenue.royaltiesPerShare.description'),
        }
      case MetricType.ForecastedRoyaltiesPerShare:
        return {
          title: t('revenue.forecastedRoyaltiesPerShare.title'),
          description: t('revenue.forecastedRoyaltiesPerShare.description'),
        }
      case MetricType.AnnualYield:
        return {
          title: t('revenue.annualYield.title'),
          description: t('revenue.annualYield.description'),
        }
      case MetricType.NetMasterRoyaltyOwnershipPerShare:
        return {
          title: t('metrics.netMasterRoyaltyOwnershipPerShare.title'),
          description: t('metrics.netMasterRoyaltyOwnershipPerShare.description'),
        }
      case MetricType.TotalListedMarketCap:
        return {
          title: t('metrics.totalListedMarketCap.title'),
          description: t('metrics.totalListedMarketCap.description'),
        }
      case MetricType.TotalShares:
        return {
          title: t('metrics.totalShares.title'),
          description: t('metrics.totalShares.description'),
        }
      case MetricType.RoyaltyPayoutPeriod:
        return {
          title: t('metrics.royaltyPayout.title'),
          description: t('metrics.royaltyPayout.description'),
        }
      case MetricType.ApproximateStreams:
        return {
          title: t('streams.title'),
          description: t('streams.description'),
        }
      default:
        return {
          title: '',
          description: '',
        }
    }
  }

  const explainerInfo = getExplainerInfo(payload?.metricType as MetricType)

  return (
    <ModalContent>
      <h3 className={styles.heading}>{explainerInfo.title}</h3>
      <p className={styles.description}>{explainerInfo.description}</p>
    </ModalContent>
  )
}
