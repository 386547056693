import { Share } from 'types/share'

export enum TradeActionType {
  Buy = 'buy',
  Sell = 'sell',
  Bid = 'bid',
  Accept = 'accept',
}

export enum OrderType {
  Limit = 'limit',
  BestPrice = 'bestPrice',
}

export type TraderType = {
  sellableShareList: Share[]
  isImo: boolean
  tradeActionType: TradeActionType
  setTradeActionType: (tradeActionType: TradeActionType) => void
  orderType: OrderType | null
  setOrderType: (orderType: OrderType | null) => void
  validateTradeSpecification: boolean
  setValidateTradeSpecification: (validateTradeSpecification: boolean) => void
  isLoading: boolean
  hasAuthUserReachedShareLimit: boolean
  hideSellOption: boolean
  hasError: boolean
}
