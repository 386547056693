import {
  sanitizeCardFromRaw,
  sanitizeCardRegistrationFromRaw,
  sanitizeCardValidationFromRaw,
  sanitizePayInFromRaw,
  sanitizePreauthFromRaw,
} from 'repositories/mx-api/@santizers/card'

export enum CreditCardBrand {
  Visa = 'VISA',
  MasterCard = 'MASTERCARD',
}

export enum CardValidity {
  Valid = 'valid',
  Unknown = 'unknown',
}

export type CardRaw = {
  id: string
  type: CreditCardBrand
  status: CardValidity
  last_four_digits: string
  created_at: string
}

export type Card = ReturnType<typeof sanitizeCardFromRaw>

export type CardRegistrationRaw = {
  id: string
  card_registration_url: string
  access_key: string
  preregistration_data: string
  registration_data: string | null
}

export type CardRegistration = ReturnType<typeof sanitizeCardRegistrationFromRaw>

export enum ThreeDSecureEventStatus {
  Succeeded = 'SUCCEEDED',
  Created = 'CREATED',
  Failed = 'FAILED',
}

export type PayInRaw = {
  id: string
  debit_amount: number
  credit_amount: number
  fees_amount: number
  status: ThreeDSecureEventStatus
  secure_mode_redirect_url?: string
}

export type PreauthRaw = {
  id: string
  amount: number
  status: ThreeDSecureEventStatus
  secure_mode_redirect_url: string | null
}

export type CardValidationRaw = {
  id: string
  status: ThreeDSecureEventStatus
  secure_mode_redirect_url: string | null
}

export type PayIn = ReturnType<typeof sanitizePayInFromRaw>
export type Preauth = ReturnType<typeof sanitizePreauthFromRaw>
export type CardValidation = ReturnType<typeof sanitizeCardValidationFromRaw>
