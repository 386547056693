import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg'
import { useLocalization } from 'locales/i18n'
import { ModalContent } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

export const TransactionFailed = () => {
  const { t } = useLocalization('myAssets.overview.transactionFailedModal')

  return (
    <ModalContent className={styles.orderFailedContainer}>
      <div className={styles.exclamationIcon}>
        <ExclamationIcon />
      </div>
      <div className={styles.headerContainer}>
        <h1 className={styles.title}>{t('heading')}</h1>
        <p className={styles.subtitle}>{t('message')}</p>
      </div>
    </ModalContent>
  )
}
