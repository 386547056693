import { useLocalization } from 'locales/i18n'

type OrderQuantityProps = {
  quantity: number
}

export const OrderQuantity = ({ quantity }: OrderQuantityProps) => {
  const { t } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation',
  )
  return (
    <li>
      <span>{t('quantity')}</span>
      <span>{quantity}</span>
    </li>
  )
}
