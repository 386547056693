import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg'

import { GraphRange } from '../../types'

import styles from './styles.module.scss'

type Option = {
  value: string
  label: string
}

type RangeSelectorProps = {
  currentSelection: GraphRange
  setCurrentSelection: (range: GraphRange) => void
  name?: string
  options: Option[]
}

export const RangeSelector = ({
  currentSelection,
  setCurrentSelection,
  options,
  name,
}: RangeSelectorProps) => {
  return (
    <div className={styles.rangeSelectorContainer}>
      <select
        className={styles.rangeSelector}
        name={name}
        value={currentSelection}
        onChange={(event) => setCurrentSelection(event?.target.value as GraphRange)}
      >
        {options.map((option) => (
          <option key={option.label} value={option.value}>
            {option.value}
          </option>
        ))}
      </select>
      <ChevronDown className={styles.dropdownIcon} />
    </div>
  )
}
