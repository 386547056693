import { useLocation } from 'react-router-dom'

import { useLocalization } from 'locales/i18n'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useSignInAndSignUp } from 'ui/@hooks/use-signin-and-signup'

import { disabledRoutes } from '../../constants'

import styles from './styles.module.scss'

export const SignInLink = () => {
  const { t } = useLocalization('components.navbar')
  const { isAuthenticated } = useMxAuth()
  const { signIn } = useSignInAndSignUp()
  const { pathname } = useLocation()
  const isRouteDisabled = disabledRoutes.includes(pathname)

  if (isAuthenticated) return null

  return (
    <span className={`${styles.signInLink} ${isRouteDisabled && styles.disabled}`} onClick={signIn}>
      {t('signIn')}
    </span>
  )
}
