import MXLogo from 'assets/images/mx-logo.png'
import { useLocalization } from 'locales/i18n'
import { RouteWrapper } from 'ui/@components/route-wrapper'

import styles from './styles.module.scss'

export const ReferralPaused = () => {
  const { t } = useLocalization('referralPaused')

  return (
    <RouteWrapper title={t('pageTitle')} description={t('pageDescription')}>
      <div className={styles.container}>
        <img src={MXLogo} className={styles.logo} alt='' />
        <p className={styles.description}>{t('referralPaused')}</p>
      </div>
    </RouteWrapper>
  )
}
