import { AxiosError } from 'axios'
import {
  atom,
  DefaultValue,
  selector,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil'

import { updateOnboardingChecklist as updateOnboardingChecklistRequest } from 'repositories/mx-api'
import { fetchAuthUser } from 'repositories/mx-api/queries/fetch-auth-user'
import { AccessStatus, AuthUser, OnboardingChecklist } from 'types/auth-user'

export const authUserState = atom<AuthUser | null>({
  key: 'authUser',
  default: null,
})

const authUserErrorState = atom<string | AxiosError | unknown>({
  key: 'authUserError',
  default: undefined,
})

const authUserLoadingState = atom<boolean>({
  key: 'authUserLoading',
  default: false,
})

export const useAuthUserState = () => {
  return useRecoilState(authUserState)
}

export const useAuthUser = () => {
  return useRecoilValue(authUserState)
}

export const useResetAuthUser = () => {
  return useResetRecoilState(authUserState)
}

const onboardingChecklistSelector = selector<AuthUser['onboardingChecklist'] | undefined>({
  key: 'onboardingChecklistSelector',
  get: ({ get }) => {
    const authUser = get(authUserState)
    return authUser?.onboardingChecklist
  },
  set: ({ set, get }, onboardingChecklist) => {
    const authUser = get(authUserState)
    if (!authUser || !onboardingChecklist || onboardingChecklist instanceof DefaultValue)
      return null
    set(authUserState, { ...authUser, onboardingChecklist })
  },
})

export const useOnboardingChecklist = () => {
  const [onboardingChecklist, setOnboardingChecklist] = useRecoilState(onboardingChecklistSelector)

  const updateOnboardingChecklist = async (
    onboardingChecklistToUpdate: Partial<OnboardingChecklist>,
  ) => {
    const updatedChecklist = await updateOnboardingChecklistRequest(onboardingChecklistToUpdate)
    setOnboardingChecklist(updatedChecklist)
  }

  return { onboardingChecklist, updateOnboardingChecklist }
}

export const useAuthUserLoadable = () => {
  const [authUser, setAuthUser] = useRecoilState(authUserState)
  const [error, setError] = useRecoilState(authUserErrorState)
  const [loading, setLoading] = useRecoilState(authUserLoadingState)

  const refetchAuthUser = async () => {
    setLoading(true)
    setError(undefined)

    try {
      const authUser = await fetchAuthUser()
      setAuthUser(authUser)
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }

  const isEarlyAccessUser = authUser?.accessStatus === AccessStatus.EarlyAccess

  return {
    authUser,
    loading,
    error,
    refetchAuthUser,
    isEarlyAccessUser,
  }
}
