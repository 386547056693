import { LandingPageRoutes } from 'constants/external-links'
import { useLocalization } from 'locales/i18n'
import { NotificationCenter } from 'ui/@components/notification-center'
import { useLandingPageLinks } from 'ui/@hooks/use-landing-page-links'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { Button } from 'ui/@library/inputs/button'

import { NavbarDesktopDropdown } from './@components/navbar-desktop-dropdown'
import { NavbarMobileDropdown } from './@components/navbar-mobile-dropdown'
import { SignInLink } from './@components/sign-in-link'
import styles from './styles.module.scss'

export const NavActions = () => {
  const { t } = useLocalization('components.navbar')
  const { isAuthenticated } = useMxAuth()
  const generateLandingPageLink = useLandingPageLinks()

  return (
    <div className={styles.actionsContainer}>
      {!isAuthenticated && (
        <>
          <div className={styles.signInLink}>
            <SignInLink />
          </div>
          <a
            href={generateLandingPageLink(LandingPageRoutes.WAITLIST)}
            className={styles.link}
            rel='referrer noreferrer'
          >
            <Button className={styles.signUpButton}>{t('getStarted')}</Button>
          </a>
        </>
      )}
      {isAuthenticated && <NotificationCenter />}
      <NavbarDesktopDropdown />
      <NavbarMobileDropdown />
    </div>
  )
}
