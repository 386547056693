import { StatusBar, Style } from '@capacitor/status-bar'
import { useEffect } from 'react'

import { useAppEnvironment } from 'ui/@hooks/use-app-environment'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { usePlatform } from 'ui/@hooks/use-platform'

export const useStatusBarSetup = () => {
  const { isAndroid, isIOS } = usePlatform()
  const { isAuthenticated } = useMxAuth()
  const { isProduction } = useAppEnvironment()

  useEffect(() => {
    if (isIOS) {
      if (!isProduction && !isAuthenticated) {
        StatusBar.setStyle({ style: Style.Dark }) // status bar should be dark when marquee is displayed
      } else {
        StatusBar.setStyle({ style: Style.Light }) // default
      }
    }
    if (isAndroid) {
      StatusBar.setOverlaysWebView({ overlay: false })
      if (!isProduction && !isAuthenticated) {
        StatusBar.setStyle({ style: Style.Dark })
        StatusBar.setBackgroundColor({ color: '#000000' })
      } else {
        StatusBar.setStyle({ style: Style.Light })
        StatusBar.setBackgroundColor({ color: '#ffffff' })
      }
    }
    // eslint-disable-next-line
  }, [isAndroid, isIOS, isAuthenticated])
}
