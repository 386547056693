export type VideoPlayerProps = {
  src: string
  placeholder: string
  loop?: boolean
  height?: VideoPlayerSize
  className?: string
  autoplay: boolean
  trigger: VideoPlayerTrigger
  onLoaded?: (loading: boolean) => void
}

export enum VideoPlayerSize {
  Large = 'large',
  Short = 'short',
  Fluid = 'fluid',
}

export enum VideoFormat {
  Mp4 = 'mp4',
  Mpeg = 'mpeg',
  M4v = 'm4v',
}

export enum VideoPlayerTrigger {
  InView = 'InView', // play video when it is in view ( exchange page mobile )
  OnHover = 'OnHover', // play video when it is hovered ( exchange page desktop)
  OnLoad = 'OnLoad', // play video when it is loaded ( master page )
}
