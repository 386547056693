import { Formik, Form, FormikHelpers } from 'formik'
import { Link } from 'react-router-dom'
import { SchemaOf, object, string } from 'yup'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { requestAccountDeletion } from 'repositories/mx-api'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { Button } from 'ui/@library/inputs/button'
import { Input } from 'ui/@library/inputs/input'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import styles from './styles.module.scss'

type FormValues = {
  reason?: string
}

const initialValues: FormValues = {
  reason: '',
}

const MAX_REASON_LENGTH = 1500

export const DeleteAccount = () => {
  const { t } = useLocalization('deleteAccount')
  const { authUser } = useAuthUserLoadable()

  const validationSchema: SchemaOf<FormValues> = object({
    reason: string().max(MAX_REASON_LENGTH, t('reasonMaxLength', { max: MAX_REASON_LENGTH })),
  })

  const onSubmitHandler = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.validateForm(values)
    await requestAccountDeletion(values.reason)
    window.location.reload()
  }

  return (
    <RouteWrapper title={t('pageTitle')} allowsBackNavigation>
      <div className={styles.deleteAccountPageContainer}>
        <div className={styles.deleteAccountPage}>
          <h1 className={styles.header}>{t('header')}</h1>
          <p className={styles.description}>{t('description')}</p>
          <h2 className={styles.subHeader}>{t('subHeader')}</h2>
          <ol className={styles.stepsList}>
            {Array.from({
              length: Object.keys(t('steps', { returnObjects: true })).length,
            }).map((_, index) => (
              <li key={index}>{t(`steps.${index + 1}`)}</li>
            ))}
          </ol>
          <p className={styles.contact}>{t('contact', { email: authUser?.email })}</p>
          <p className={styles.reasonInputLabel}>{t('reason')}</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitHandler}
            validateOnBlur
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <Input
                  as='textarea'
                  name='reason'
                  placeholder={t('reasonPlaceholder')}
                  className={styles.reasonInput}
                />
                <div className={styles.buttonsContainer}>
                  <Button type='button' variant='ghost' className={styles.backButton}>
                    <Link to={Paths.ACCOUNT.MAIN}>{t('back')}</Link>
                  </Button>

                  <Button
                    type='submit'
                    variant='primary'
                    disabled={isSubmitting || !isValid}
                    className={styles.submitButton}
                  >
                    {t('requestAccountDeletion')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </RouteWrapper>
  )
}
