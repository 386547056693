import { Trans } from 'react-i18next'

type LocalizedLinkProps = {
  translationKey: string
  href: string | null
  openInNewTab?: boolean
  linkClassName?: string
}

export const LocalizedLink = ({
  translationKey,
  href,
  linkClassName,
  openInNewTab = true,
}: LocalizedLinkProps) => {
  const components = href
    ? [
        // eslint-disable-next-line
        <a
          className={linkClassName}
          key={translationKey}
          href={href}
          target={openInNewTab ? '_blank' : '_self'}
          rel='noopener noreferrer'
        />,
      ]
    : []
  return <Trans i18nKey={translationKey} components={components} />
}
