import styles from './styles.module.scss'

type PaperProps = {
  className?: string
  children: React.ReactNode
  isPadded?: boolean
}

export const Paper = ({ className, children, isPadded = true }: PaperProps) => {
  const paperStyles = className
    ? isPadded
      ? `${styles.paperWithPadding} ${className}`
      : `${styles.paper} ${className}`
    : isPadded
    ? styles.paperWithPadding
    : styles.paper
  return <div className={paperStyles}>{children}</div>
}
