import { useLocalization } from 'locales/i18n'
import { Card, CardValidity } from 'types/card'
import { CreditCardBrandingIcon } from 'ui/@library/data-display/credit-card-branding-icon'
import { Button } from 'ui/@library/inputs/button'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { AddCard3DSModal } from 'ui/@modals/add-funds/@components/add-card-3ds-modal'

import styles from './styles.module.scss'

type CardDetailsProps = {
  card?: Card
  showValidateButton?: boolean
}

export const CardDetails = ({ card, showValidateButton }: CardDetailsProps) => {
  const { renderModal } = useGlobalModal()
  const { t } = useLocalization('addFunds.paymentOptions')
  if (!card) return null

  const handleCardValidate = () => {
    renderModal({
      modalKey: ModalKey.AddCard3DSModal,
      content: <AddCard3DSModal />,
      payload: { cardId: card.id },
    })
  }

  return (
    <div className={styles.cardDetails}>
      <CreditCardBrandingIcon brand={card.type} />
      {`**** ${card.lastFourDigits}`}
      {card.status === CardValidity.Unknown && showValidateButton && (
        <Button variant='small' onClick={handleCardValidate}>
          {t('verifyCard')}
        </Button>
      )}
    </div>
  )
}
