import { LandingPageRoutes } from 'constants/external-links'
import { useLocalization } from 'locales/i18n'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { useLandingPageLinks } from 'ui/@hooks/use-landing-page-links'
import { ErrorWithAction } from 'ui/@library/errors/error-with-action'

export const SignupsDisabled = () => {
  const { t } = useLocalization('signupsDisabled')
  const generateLandingPageLink = useLandingPageLinks()

  return (
    <RouteWrapper title={t('pageTitle')}>
      <ErrorWithAction
        buttonTitle={t('buttonText')}
        header={t('pageTitle')}
        message={t('subHeading')}
        buttonOnClick={() => {
          window.location.href = generateLandingPageLink(LandingPageRoutes.WAITLIST)
        }}
        showSupportLink={false}
      />
    </RouteWrapper>
  )
}
