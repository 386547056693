import styles from './styles.module.scss'

type PillProps = {
  variant: 'primary' | 'info'
  text: string
  className?: string
}

export const Pill = ({ variant, text, className }: PillProps) => {
  return <div className={`${styles.pill} ${styles[variant]} ${className}`}>{text}</div>
}
