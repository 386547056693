import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type MetricValueProps = {
  children?: number
}

export const MetricValue = ({ children }: MetricValueProps) => {
  const { f } = useLocalization()

  return <div className={styles.metricValue}>{children !== undefined && f.currency(children)}</div>
}
