import styles from './styles.module.scss'

export type TabOption = {
  label: string
  value: string
  activeClassName?: string
  isHidden?: boolean
}

type TabBarProps = {
  options: TabOption[]
  onChange: (value: string) => void
  activeTab?: string
  className?: string
  tabClassName?: string
  variant: 'fill' | 'border'
}

export const TabBar = ({
  options,
  onChange,
  activeTab,
  className,
  tabClassName,
  variant,
}: TabBarProps) => {
  const handleTabChange = (value: string) => {
    onChange(value)
  }

  return (
    <ul className={`${styles.tabBar} ${styles[variant]} ${className}`}>
      {options.map((option) => {
        const activeTabClassNames = `${option.activeClassName} ${styles.active}`
        if (option.isHidden) return null
        return (
          <li
            className={`${styles.tab} ${
              activeTab === option.value ? activeTabClassNames : ''
            } ${tabClassName}`}
            key={option.value}
            onClick={() => handleTabChange(option.value)}
          >
            {option.label}
          </li>
        )
      })}
    </ul>
  )
}
