import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { AccessStatus } from 'types/auth-user'
import { ErrorWithHeader } from 'ui/@library/errors/error-with-header'
import { FullPageError } from 'ui/@library/errors/full-page-error'
import { ProgressBar } from 'ui/@library/feedback/progress-bar'
import { FormContainer } from 'ui/@library/layout/form-container'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import {
  OnboardingStepProps,
  TOTAL_STEPS_WITHOUT_ADD_CARD,
  TOTAL_STEPS_WITH_ADD_CARD,
  TOTAL_SUB_STEPS_WITHOUT_ADD_CARD,
  TOTAL_SUB_STEPS_WITH_ADD_CARD,
} from 'ui/onboarding/types'

import { AddCardDisclaimer } from './@components/add-card-disclaimer'
import { AddCardOnboardingError } from './@components/add-card-onboarding-error'
import { AddCardOnboardingForm } from './@components/add-card-onboarding-form'
import { AddCardSuccess } from './@components/add-card-success'
import { CardValidationStep } from './@components/card-validation-step'
import styles from './styles.module.scss'
import { Steps } from './types'

export const AddCardInOnboarding = ({ onStepComplete }: OnboardingStepProps) => {
  const { authUser, error: authUserError, refetchAuthUser } = useAuthUserLoadable()
  const { t } = useLocalization('onboarding.addCard')
  const navigate = useNavigate()

  const steps: Steps = {
    addCardDisclaimer: {
      title: t('disclaimer.pageTitle'),
      component: AddCardDisclaimer,
    },
    addCardForm: {
      title: t('form.pageTitle'),
      component: AddCardOnboardingForm,
    },
    addCardValidation: {
      title: 'Card Validation',
      component: CardValidationStep,
    },
    addCardSuccess: {
      title: t('success.pageTitle'),
      component: AddCardSuccess,
    },
    addCardError: {
      title: t('error.pageTitle'),
      component: AddCardOnboardingError,
    },
  }

  const [currentStep, setCurrentStep] = useState(steps.addCardDisclaimer)

  const [error, setError] = useState(false)

  const getCurrentStepIndex = () => {
    switch (currentStep?.title) {
      case steps.addCardDisclaimer?.title:
        return { step: 3, subStep: 3 }
      case steps.addCardForm?.title:
        return { step: 3, subStep: 6 }
      case steps.addCardValidation?.title:
        return { step: 3, subStep: 6 }
      case steps.addCardError?.title:
        return { step: 4, subStep: 6 }
      case steps.addCardSuccess?.title:
        return { step: 4, subStep: 6 }
      default:
        return { step: 0, subStep: 0 }
    }
  }

  const handleSkip = () => {
    onStepComplete()
    navigate(Paths.HOME)
  }

  useEffect(() => {
    refetchAuthUser()
    // eslint-disable-next-line
  }, [])

  const TOTAL_STEPS =
    authUser?.accessStatus === AccessStatus.EarlyAccess
      ? TOTAL_STEPS_WITH_ADD_CARD
      : TOTAL_STEPS_WITHOUT_ADD_CARD

  const TOTAL_SUB_STEPS =
    authUser?.accessStatus === AccessStatus.EarlyAccess
      ? TOTAL_SUB_STEPS_WITH_ADD_CARD
      : TOTAL_SUB_STEPS_WITHOUT_ADD_CARD

  if (authUserError) return <FullPageError error={authUserError} />

  return (
    <>
      <ProgressBar
        currentStep={getCurrentStepIndex()}
        totalSteps={{ step: TOTAL_STEPS, subStep: TOTAL_SUB_STEPS }}
      />
      <FormContainer
        className={`${styles.container} ${
          currentStep?.title === t('error.pageTitle') && styles.noBoxShadow
        }`}
      >
        {error ? (
          <ErrorWithHeader />
        ) : (
          currentStep && (
            <currentStep.component
              steps={steps}
              setCurrentStep={setCurrentStep}
              setError={setError}
              onStepComplete={onStepComplete}
              onSkip={handleSkip}
            />
          )
        )}
      </FormContainer>
    </>
  )
}
