import { isBefore, subYears } from 'date-fns'
import { Formik, Form, FormikHelpers, FormikErrors, FormikTouched } from 'formik'
import { Link } from 'react-router-dom'
import { SchemaOf, object, string, mixed } from 'yup'

import { ampli } from 'ampli'
import { ExternalLinks } from 'constants/external-links'
import { Paths } from 'constants/paths'
import { SupportedCountries } from 'constants/supported-countries'
import { useGTM, GTMEvents } from 'gtm'
import { useLocalization } from 'locales/i18n'
import { updateAuthUser, upsertAuthUser } from 'repositories/mx-api'
import { TermsAndConditions } from 'ui/@components/terms-and-conditions'
import { Button } from 'ui/@library/inputs/button'
import { Input } from 'ui/@library/inputs/input'
import { Select } from 'ui/@library/inputs/select'
import { FormContainer } from 'ui/@library/layout/form-container'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import styles from '../styles.module.scss'
import { OnboardingStepProps } from '../types'

const NAME_MAX_LENGTH = 35
const NAME_MIN_LENGTH = 2

type FormValues = {
  firstName: string
  lastName: string
  countryOfResidence: string
  nationality: string
  day: string
  month: string
  year: string
  dateOfBirth: Date | undefined
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  countryOfResidence: '',
  nationality: '',
  day: '',
  month: '',
  year: '',
  dateOfBirth: undefined,
}

const countryOptions = SupportedCountries.sort((a, b) => {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}).map((country) => ({
  label: country.name,
  value: country.code,
}))

const nationalityOptions = SupportedCountries.sort((a, b) => {
  if (a.nationality < b.nationality) return -1
  if (a.nationality > b.nationality) return 1
  return 0
}).map((country) => ({
  value: country.code,
  label: country.nationality,
}))

const dayOptions = Array.from({ length: 31 }, (_, i) => {
  return {
    label: (i + 1).toString(),
    value: (i + 1).toString(),
  }
})

const monthOptions = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
]

const yearOptions = Array.from({ length: 80 }, (_, i) => {
  const currentYear = new Date().getFullYear()
  const minAge = 18
  const year = currentYear - i - minAge
  return {
    value: year.toString(),
    label: year.toString(),
  }
})

export const PersonalDetails = ({ onStepComplete }: OnboardingStepProps) => {
  const { t } = useLocalization('onboarding')
  const { authUser } = useAuthUserLoadable()
  const { sendGTMEvent } = useGTM()

  const today = new Date()
  const eighteenYearsAgo = subYears(today, 18)

  const getDobErrorMessage = (
    errors: FormikErrors<FormValues>,
    touched: FormikTouched<FormValues>,
  ) => {
    if (Object.keys(errors).length === 0) return false
    if (errors.year && touched.year) return errors.year.toString()
    if (errors.month && touched.month) return errors.month.toString()
    if (errors.day && touched.day) return errors.day.toString()
    if (errors.dateOfBirth && touched.day && touched.month && touched.year)
      return errors.dateOfBirth.toString()
  }

  const validationSchema: SchemaOf<FormValues> = object({
    firstName: string()
      .required(t('personalDetails.firstName.required'))
      .trim()
      .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ-' ]+$/, t('personalDetails.firstName.invalid'))
      .min(NAME_MIN_LENGTH, t('personalDetails.firstName.min', { minLength: NAME_MIN_LENGTH }))
      .max(NAME_MAX_LENGTH, t('personalDetails.firstName.max', { maxLength: NAME_MAX_LENGTH })),
    lastName: string()
      .required(t('personalDetails.lastName.required'))
      .trim()
      .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ-' ]+$/, t('personalDetails.lastName.invalid'))
      .min(NAME_MIN_LENGTH, t('personalDetails.lastName.min', { minLength: NAME_MIN_LENGTH }))
      .max(NAME_MAX_LENGTH, t('personalDetails.lastName.max', { maxLength: NAME_MAX_LENGTH })),
    countryOfResidence: string().required(t('personalDetails.countryOfResidence.required')),
    nationality: string().required(t('personalDetails.nationality.required')),
    day: string().required(t('personalDetails.dateOfBirth.dayRequired')),
    month: string().required(t('personalDetails.dateOfBirth.monthRequired')),
    year: string().required(t('personalDetails.dateOfBirth.yearRequired')),
    dateOfBirth: mixed().test(
      'dateOfBirth',
      t('personalDetails.dateOfBirth.underage'),
      function () {
        const { day, month, year } = this.parent
        if (day && month && year) {
          const date = new Date(`${year}/${month}/${day}`)
          return isBefore(date, eighteenYearsAgo)
        }
        return false
      },
    ),
  })

  const onSubmitHandler = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.validateForm(values)
    const dateUTC = new Date(
      Date.UTC(parseInt(values.year), parseInt(values.month) - 1, parseInt(values.day)),
    )

    await updateAuthUser({
      firstName: values.firstName,
      lastName: values.lastName,
      address: { countryCode: values.countryOfResidence },
      nationality: values.nationality,
      dateOfBirth: dateUTC.toISOString(),
    })

    ampli.submitPersonalDetailsSuccess()
    sendGTMEvent(GTMEvents.UserSignUp, authUser?.uuid)
    await upsertAuthUser()
    await onStepComplete()
  }

  return (
    <>
      <FormContainer className={styles.onboardingContainer}>
        <h1 className={styles.title}>{t('personalDetails.heading')}</h1>
        <p className={styles.description}>{t('personalDetails.description')}</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
          validateOnBlur
        >
          {({ isSubmitting, isValid, values, errors, touched }) => (
            <Form className={styles.form}>
              <Input
                name='firstName'
                label={t('personalDetails.firstName.label')}
                type='text'
                placeholder={t('personalDetails.firstName.placeholder')}
                autoComplete='given-name'
              />
              <Input
                name='lastName'
                label={t('personalDetails.lastName.label')}
                type='text'
                placeholder={t('personalDetails.lastName.placeholder')}
                autoComplete='family-name'
              />
              <div className={styles.countryOfResidence}>
                <Select
                  name='countryOfResidence'
                  label={t('personalDetails.countryOfResidence.label')}
                  options={countryOptions}
                  placeholder={t('personalDetails.countryOfResidence.placeholder')}
                  currentValue={values.countryOfResidence}
                  autoComplete='country'
                />
                <a
                  href={ExternalLinks.SUPPORTED_COUNTRIES}
                  className={styles.supportedCountriesLink}
                  target='_blank'
                  rel='referrer noreferrer'
                >
                  {t('personalDetails.countryOfResidence.supportedCountries')}
                </a>
              </div>
              <Select
                name='nationality'
                label={t('personalDetails.nationality.label')}
                options={nationalityOptions}
                placeholder={t('personalDetails.nationality.placeholder')}
                currentValue={values.nationality}
              />
              <div className={styles.dobContainer}>
                <Select
                  name='year'
                  label={t('personalDetails.dateOfBirth.label')}
                  placeholder={t('personalDetails.dateOfBirth.yearPlaceholder')}
                  options={yearOptions}
                  currentValue={values.year}
                  autoComplete='bday-year'
                  noErrorMessage
                />
                <Select
                  name='month'
                  placeholder={t('personalDetails.dateOfBirth.monthPlaceholder')}
                  options={monthOptions}
                  currentValue={values.month}
                  autoComplete='bday-month'
                  noErrorMessage
                />
                <Select
                  name='day'
                  placeholder={t('personalDetails.dateOfBirth.dayPlaceholder')}
                  options={dayOptions}
                  currentValue={values.day}
                  autoComplete='bday-day'
                  noErrorMessage
                />
              </div>
              {getDobErrorMessage(errors, touched) && (
                <span className={styles.errorMessage}>{getDobErrorMessage(errors, touched)}</span>
              )}
              <p className={styles.footnote}>
                <TermsAndConditions translationKey='onboarding.personalDetails.termsAndConditions' />
              </p>
              <Button
                className={styles.submitButton}
                width='fluid'
                type='submit'
                loading={isSubmitting}
                disabled={!isValid}
              >
                {t('actionButtons.next')}
              </Button>
            </Form>
          )}
        </Formik>
        <div className={styles.buttonWrapper}>
          <Link to={Paths.SIGN_OUT}>
            <span className={styles.inlineLinkColor}>{t('actionButtons.signOut')}</span>
          </Link>
        </div>
      </FormContainer>
    </>
  )
}
