import * as amplitude from '@amplitude/analytics-browser'
import { useAuth0 } from '@auth0/auth0-react'
import { Browser } from '@capacitor/browser'
import { Preferences } from '@capacitor/preferences'
import { captureException } from '@sentry/react'

import { ampli } from 'ampli'
import { CapacitorPreferences } from 'constants/local-storage'
import { Paths } from 'constants/paths'
import { removeAllCookies } from 'cookiebot/utils'
import { deleteDeviceToken } from 'repositories/mx-api'
import { AccessStatus } from 'types/auth-user'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import { usePlatform } from './use-platform'

export const useSignout = () => {
  const { logout } = useAuth0()
  const { authUser } = useAuthUserLoadable()
  const { isNative } = usePlatform()

  const handleSignout = async (options: { returnTo?: string } = {}) => {
    const returnToUrl = options.returnTo
      ? options.returnTo
      : isNative
      ? process.env.REACT_APP_AUTH0_CALLBACK
      : `${window.location.origin}${Paths.LOGGED_OUT}`

    authUser &&
      ampli.identify(authUser.uuid, {
        authState: false,
      })
    ampli.signOut()
    sessionStorage.clear()
    amplitude.reset()

    // clear cookies
    removeAllCookies({ except: ['CookieConsent'] })

    // delete device token from server
    if (isNative && authUser?.accessStatus === AccessStatus.EarlyAccess) {
      const deviceToken = (await Preferences.get({ key: CapacitorPreferences.DEVICE_TOKEN })).value
      if (deviceToken) {
        try {
          await deleteDeviceToken(deviceToken)
        } catch (error) {
          captureException(error)
          // do nothing
        }
      }
    }

    await logout({
      logoutParams: {
        returnTo: returnToUrl,
      },
      async openUrl(url) {
        await Browser.open({
          url,
          windowName: '_self',
        })
      },
    })
  }

  return handleSignout
}
