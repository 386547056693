import { useNavigate } from 'react-router-dom'

import { Paths, QueryParams } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { KycStatus } from 'types/auth-user'
import { Loader } from 'ui/@library/feedback/loader'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { ReviewKyc } from 'ui/@modals/kyc/review-kyc'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import {
  SectionContainer,
  SectionHeader,
  SectionTitle,
  SectionButton,
  SectionDescription,
  SectionStatus,
} from '../section'

import { WithdrawFundsModal } from './withdraw-funds-modal'

export const WithdrawFunds = () => {
  const { t } = useLocalization('account')
  const { authUser, loading: isAuthUserLoading } = useAuthUserLoadable()
  const { renderModal } = useGlobalModal()
  const navigate = useNavigate()

  const isKycRequired =
    authUser?.kycStatus === KycStatus.NotCreated ||
    authUser?.kycStatus === KycStatus.Created ||
    authUser?.kycStatus === KycStatus.Refused ||
    authUser?.kycStatus === KycStatus.OutOfDate

  const handleWithdraw = () => {
    if (
      authUser?.kycStatus === KycStatus.ValidationAsked ||
      authUser?.kycStatus === KycStatus.Refused
    ) {
      renderModal({
        modalKey: ModalKey.ReviewKyc,
        content: <ReviewKyc />,
      })
    } else if (isKycRequired) {
      const queryParams = new URLSearchParams()
      queryParams.set(QueryParams[Paths.KYC].PAYMENT_FLOW, 'true')
      const pathWithQueryParams = `${Paths.KYC}?${queryParams}`
      navigate(pathWithQueryParams, { state: { setInitStep: true } })
    } else if (authUser?.kycStatus === KycStatus.Validated) {
      renderModal({ modalKey: ModalKey.WithdrawFunds, content: <WithdrawFundsModal /> })
    }
  }

  if (isAuthUserLoading) return <Loader />

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{t('withdraw.title')}</SectionTitle>
        <SectionButton onClick={handleWithdraw}>{t('withdraw.buttonText')}</SectionButton>
      </SectionHeader>
      <SectionDescription>
        <p>{t('withdraw.description')}</p>
      </SectionDescription>
      {(authUser?.kycStatus === KycStatus.ValidationAsked ||
        authUser?.kycStatus === KycStatus.Created) && (
        <SectionStatus type='refused'>{t('kyc.verify')}</SectionStatus>
      )}
    </SectionContainer>
  )
}
