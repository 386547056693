import { WithdrawableFundsRaw, WithdrawableFunds } from 'types/bank-accounts'

import { sanitizeWithdrawableFundsFromRaw } from '../@santizers/bank-accounts'
import { MxApi } from '../mx-api'

export const fetchWithdrawableFunds = async (): Promise<WithdrawableFunds> => {
  const { data } = await MxApi.get<WithdrawableFundsRaw>('/auth-users/me/wallet/withdawable-funds')

  return sanitizeWithdrawableFundsFromRaw(data)
}
