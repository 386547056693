import { CurrencyInput } from 'ui/@components/currency-input'

import styles from '../../styles.module.scss'

type TradeAmountProps = {
  label: string
  amount?: string
  inputAmount?: string | null
  setInputAmount?: (inputAmount: string | null) => void
  displayErrorMessage?: boolean
  setDisplayErrorMessage?: (displayErrorMessage: boolean) => void
  onFocusChange?: (isFocused: boolean) => void
}

export const TradeAmount = ({
  amount,
  label,
  inputAmount,
  setInputAmount,
  displayErrorMessage = false,
  setDisplayErrorMessage,
  onFocusChange,
}: TradeAmountProps) => {
  return (
    <li className={styles.orderQuantityItem}>
      <span className={styles.label}>{label}</span>
      <span>
        {inputAmount !== undefined && setInputAmount !== undefined && (
          <CurrencyInput
            className={styles.priceInput}
            value={inputAmount}
            setValue={setInputAmount}
            displayError={displayErrorMessage}
            errorInBottom={true}
            setDisplayError={setDisplayErrorMessage}
            startFromRight={true}
            onFocusChange={onFocusChange}
          />
        )}
        {amount && amount}
      </span>
    </li>
  )
}
