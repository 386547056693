import { PriceHistory } from 'types/market'

import { MxApi } from '../mx-api'

export const fetchPriceHistory = async (masterUuid: string, range: string) => {
  const { data } = await MxApi.get<PriceHistory>(`/masters/${masterUuid}/price-graph`, {
    params: { time_frame: range },
  })

  return data
}
