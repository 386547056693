import Lottie from 'lottie-react'

import LoadingAnimation from 'assets/lotties/mx-logo-loader.json'
import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type OrderLoadingProps = {
  className?: string
}

export const OrderLoading = ({ className }: OrderLoadingProps) => {
  const { t } = useLocalization('order')
  return (
    <div className={`${styles.orderLoading} ${className}`}>
      <Lottie className={styles.loadingAnimation} animationData={LoadingAnimation} loop />
      <div className={styles.loadingText}>{t('placingOrder')}</div>
    </div>
  )
}
