import { BaseType, Selection } from 'd3'
import { useEffect } from 'react'

import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'

import { GraphConstants } from '../../constants'
import { GraphTransformationsType } from '../../types'

import styles from './styles.module.scss'

type TooltipProps<T extends BaseType> = {
  graph: Selection<T, unknown, null, undefined>
  isTooltipActive: boolean
  getTransformations: GraphTransformationsType
}

export const Tooltip = <T extends BaseType>({
  graph,
  isTooltipActive,
  getTransformations,
}: TooltipProps<T>) => {
  const { f, t } = useLocalization('components.graph.tooltip')
  useEffect(() => {
    const handleTooltipMovement = (event: MouseEvent | TouchEvent) => {
      const tooltip = graph.select<SVGSVGElement>(`.${styles.tooltip}`)
      const legendNode = tooltip.node()
      if (legendNode) {
        const legendWidth = Math.ceil(legendNode.getBBox().width)
        const expectedEvent = event instanceof MouseEvent ? event : event?.touches[0]
        const {
          tooltipPosition,
          dataPoint: { timestamp, value },
        } = getTransformations(expectedEvent, legendWidth)

        // Move Legend
        tooltip.style(
          'transform',
          `translate(${tooltipPosition}px, ${GraphConstants.PADDING.TOP.SMALL}px)`,
        )

        if (Array.isArray(timestamp)) {
          const [startTimestamp, endTimestamp] = timestamp
          tooltip.select(`.${styles.legendText}`).text(
            value
              ? t('aggregatedData', {
                  value: f.number(value, { compact: 'short' }),
                  startDate: f.date(
                    new Date(startTimestamp * 1000),
                    TextFormats.DATE.DATE_WITH_MONTH,
                  ),
                  endDate: f.date(new Date(endTimestamp * 1000), TextFormats.DATE.DATE_WITH_MONTH),
                })
              : t('emptyData', {
                  startDate: f.date(
                    new Date(startTimestamp * 1000),
                    TextFormats.DATE.DATE_WITH_MONTH,
                  ),
                  endDate: f.date(new Date(endTimestamp * 1000), TextFormats.DATE.DATE_WITH_MONTH),
                }),
          )
        } else {
          tooltip.select(`.${styles.legendText}`).text(
            t('normalData', {
              value: f.number(value, { compact: 'short' }),
              date: f.date(new Date(timestamp * 1000), TextFormats.DATE.DATE_WITH_MONTH_LONG),
            }),
          )
        }
      }
    }
    if (graph) {
      graph.on('mousemove.tooltip touchmove.tooltip', handleTooltipMovement)
    }
    // eslint-disable-next-line
  }, [graph])

  return (
    <g className={`${styles.tooltip} ${isTooltipActive ? styles.active : ''}`}>
      <text className={styles.legendText} />
    </g>
  )
}
