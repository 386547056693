import { Link } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { MasterWithImoAndMarket } from 'types/master'
import { useWindowSize } from 'ui/@hooks/use-window-size'
import { ExchangeCardSize } from 'ui/exchange/types'

import { Hero, MasterOwners, MasterMarketDetailsStrip } from '../../@components/exchange-card'

import styles from './styles.module.scss'

type BrowseSongsProps = {
  master: MasterWithImoAndMarket
  onCardClick: () => void
}

export const BrowseSongsCard = ({ master, onCardClick }: BrowseSongsProps) => {
  const { viewports } = useWindowSize()

  const getHeroAsset = () => {
    if (viewports.isMobile && master.assets.heroCompact) {
      return master.assets.heroCompact
    }
    return master.assets.hero
  }

  return (
    <Link
      to={`${Paths.MASTER.MAIN}/${master.uuid}`}
      key={master.uuid}
      className={styles.browseSongCard}
      onClick={onCardClick}
    >
      <Hero
        mediaSrc={getHeroAsset()}
        placeholder={master.assets.placeholder}
        size={ExchangeCardSize.Small}
      >
        <MasterOwners
          masterOwners={master.metaData.ownedByWithAvatars}
          className={styles.masterOwnerWrapper}
        />
      </Hero>
      <MasterMarketDetailsStrip master={master} />
    </Link>
  )
}
