import { ReactComponent as CardIcon } from 'assets/icons/credit-card.svg'
import { useLocalization } from 'locales/i18n'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { Button } from 'ui/@library/inputs/button'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { PaymentMethodModal } from 'ui/@modals/add-funds'

import styles from './styles.module.scss'

type AddFundsButtonProps = {
  className?: string
}

export const AddFundsButton = ({ className }: AddFundsButtonProps) => {
  const { renderModal } = useGlobalModal()
  const { t } = useLocalization('addFunds')

  const { isAuthenticated } = useMxAuth()

  if (!isAuthenticated) return null

  const handleAddFunds = () => {
    renderModal({ modalKey: ModalKey.PaymentMethod, content: <PaymentMethodModal /> })
  }

  return (
    <Button variant='ghost' className={`${styles.addFunds} ${className}`} onClick={handleAddFunds}>
      <CardIcon />
      <p className={styles.action}>{t('buttonText')}</p>
    </Button>
  )
}
