import { useLocalization } from 'locales/i18n'
import { useMasterLoadable } from 'ui/@store/master'

import styles from './styles.module.scss'

export const ArtistDescription = () => {
  const { t } = useLocalization('master.overviewTab.description')
  const { master } = useMasterLoadable()

  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.artistSection}>
        <h1 className={styles.title}>{t('artistTitle')}</h1>
        <p className={styles.description}>{master?.artist.bio}</p>
      </div>
    </div>
  )
}
