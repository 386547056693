import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

export type DialogBoxProps = {
  information: string
  title?: string
  subtitle?: string
}

export const DialogBox = () => {
  const { payload } = useGlobalModal()

  return (
    <ModalContent className={styles.infoContainer}>
      {payload?.title && <h1 className={styles.title}>{payload.title}</h1>}
      {payload?.subtitle && <h2 className={styles.subtitle}>{payload.subtitle}</h2>}
      <p className={styles.message}>{payload?.information}</p>
    </ModalContent>
  )
}
