import { LanguageCode } from 'locales/types'
import {
  sanitizeAuthUserFromRaw,
  sanitizeAuthUserRoyaltiesFromRaw,
  sanitizeCommissionFromRaw,
  sanitizeNotificationSettingsFromRaw,
  sanitizeRoyaltyPayoutsFromRaw,
  sanitizeReferralCodeValidationFromRaw,
} from 'repositories/mx-api/@santizers/auth-user'
import { sanitizeAuthUserMasterSharesFromRaw } from 'repositories/mx-api/@santizers/master'

import { MasterBasicRaw } from './master'

export type AuthUserMasterSharesRaw = {
  master: MasterBasicRaw
  percentage_since_purchase: number
  quantity: number
  total_value: number
  last_market_price: number
  total_acquisition_cost: number
  average_acquisition_cost: number
}

export enum AuthUserAccountAuthenticationProvider {
  EmailPassword = 'auth0',
  GoogleSso = 'google-oauth2',
  AppleSso = 'apple',
}

export type OnboardingChecklistRaw = {
  verify_email: boolean | null
  add_personal_details: boolean | null
  verify_identity: boolean | null
  add_card: boolean | null
  add_funds: boolean | null
  platform_tour: boolean | null
  validate_card: boolean | null
}

export type NotificationTypesRaw = {
  email: boolean | null
  // DO NOT USE THIS UNTIL WE HAVE A WAY TO SEND PUSH NOTIFICATIONS
  // push_notification: boolean | null
}

export type NotificationSettingsRaw = {
  order_confirmation: NotificationTypesRaw
}

export type ReferralCodeValidationRaw = {
  name: string
  referral_code: string
}

export type AddressRaw = {
  street: string | null
  address_line_2: string | null
  city: string | null
  zipcode: string | null
  country_code: string | null
}

export type AuthUserRaw = {
  uuid: string
  first_name: string | null
  last_name: string | null
  picture_url: string | null
  email: string
  is_email_verified: boolean
  is_identity_verified: boolean
  is_master_owner: boolean
  is_first_login: boolean | null
  auth_id: string
  address: AddressRaw
  nationality: string | null
  date_of_birth: string | null
  onboarding_checklist: OnboardingChecklistRaw
  notification_settings: NotificationSettingsRaw
  access_status: AccessStatus
  kyc_status: KycStatus
  kyc_failure_reason: string | null
  has_business: boolean
  preferred_locale: LanguageCode
  auth_method: AuthUserAccountAuthenticationProvider
  acquisition_source: string | null
  referral_code: string | null
}

export type CommissionRaw = {
  monthly: number
  all_time: number
}

export type RoyaltyPayoutsRaw = {
  date: string
  share_count: number
  amount: number
}

export type AuthUserRoyaltiesRaw = {
  master: MasterBasicRaw
  royalty_payouts: RoyaltyPayoutsRaw[]
  total_amount: number
}

export enum AccessStatus {
  Opus = 'opus',
  ActiveOpus = 'active_opus',
  Regular = 'regular',
  Priority = 'priority',
  Vip = 'vip',
  EarlyAccess = 'early_access',
}

export enum KycStatus {
  NotCreated = 'not_created',
  Created = 'created',
  ValidationAsked = 'validation_asked',
  Validated = 'validated',
  Refused = 'refused',
  OutOfDate = 'out_of_date',
}

export enum Timeframe {
  Monthly = 'monthly',
  AllTime = 'all_time',
}

export type RoyaltyPayouts = ReturnType<typeof sanitizeRoyaltyPayoutsFromRaw>
export type AuthUserRoyalties = ReturnType<typeof sanitizeAuthUserRoyaltiesFromRaw>
export type AuthUser = ReturnType<typeof sanitizeAuthUserFromRaw>
export type Commission = ReturnType<typeof sanitizeCommissionFromRaw>
export type NotificationSettings = ReturnType<typeof sanitizeNotificationSettingsFromRaw>
export type OnboardingChecklist = AuthUser['onboardingChecklist']
export type Address = AuthUser['address']
export type AuthUserMasterSharesFromRaw = ReturnType<typeof sanitizeAuthUserMasterSharesFromRaw>
export type ReferralCodeValidation = ReturnType<typeof sanitizeReferralCodeValidationFromRaw>
