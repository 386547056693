import { UpcomingSongs } from '../@components/upcoming-songs'
import { BrowseSongs } from '../browse-songs'
import { Imo } from '../imo'

import styles from './styles.module.scss'

export const ExchangeAll = () => {
  return (
    <div className={styles.container}>
      <Imo />
      <BrowseSongs />
      <UpcomingSongs />
    </div>
  )
}
