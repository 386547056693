import { ShareWithOrders, ShareWithOrdersRaw } from 'types/share'

import { sanitizeShareWithBidsFromRaw } from '../@santizers/share'
import { MxApi } from '../mx-api'

export const fetchShare = async (shareUuid: string): Promise<ShareWithOrders> => {
  const { data } = await MxApi.get<ShareWithOrdersRaw>(`/fractions/${shareUuid}`)

  return sanitizeShareWithBidsFromRaw(data)
}
