import { Browser } from '@capacitor/browser'
import { useEffect, useState, useRef } from 'react'

import { ThreeDSecureEventStatus } from 'types/card'
import { OrderType } from 'types/order'
import { ThreeDSecureEvent } from 'ui/@components/three-d-secure-redirector/constants'
import { usePlatform } from 'ui/@hooks/use-platform'
import { Loader } from 'ui/@library/feedback/loader'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { IframeRenderer } from 'ui/@library/utils/iframe-renderer'
import { useCardPreAuthorization } from 'ui/@store/card-pre-authorization'

import { OrderFailed } from '../../../order-failed'
import { PlaceImoBid } from '../../place-imo-bid'

export type PreauthThreeDSecureProps = {
  tradeQuantity: number
  tradePrice: number
}

export const PreauthThreeDSecure = () => {
  const { renderModal, payload } = useGlobalModal()
  const { isNative, isIOS } = usePlatform()
  const { cardPreAuthorization, refetchCardPreAuthorization, resetState } =
    useCardPreAuthorization()

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [secureModeRedirectUrl, setSecureModeRedirectUrl] = useState('')
  const hasRunForSuccess = useRef(false)

  const handleBuyImo = async () => {
    if (payload) {
      try {
        renderModal({
          modalKey: ModalKey.PlaceImoBid,
          content: <PlaceImoBid />,
          size: ModalSize.Large,
          payload: {
            orderType: OrderType.ImoBid,
            tradeQuantity: payload.tradeQuantity,
            tradePrice: payload.tradePrice,
            paymentIdentifier: cardPreAuthorization?.id,
          },
        })
      } catch (e) {
        resetState()
        renderModal({
          modalKey: ModalKey.OrderFailed,
          content: <OrderFailed />,
        })
      }
    }
  }

  useEffect(() => {
    const eventHandler = async (
      event: MessageEvent<{ id: string; cardPreAuthorizationId: ThreeDSecureEventStatus }>,
    ) => {
      if (event.data.id !== ThreeDSecureEvent.ID) return
      await refetchCardPreAuthorization()
    }

    window.addEventListener(ThreeDSecureEvent.TYPE, eventHandler)

    const refetchPreAuthWithTimeout = () => {
      const timeoutId = setTimeout(async () => {
        const cardPreAuthorization = await refetchCardPreAuthorization()
        if (cardPreAuthorization?.secureModeRedirectUrl) {
          if (isNative) {
            Browser.open({ url: cardPreAuthorization?.secureModeRedirectUrl })
          } else {
            setSecureModeRedirectUrl(cardPreAuthorization?.secureModeRedirectUrl)
          }
        } else {
          renderModal({
            modalKey: ModalKey.OrderFailed,
            content: <OrderFailed />,
          })
        }
      }, ThreeDSecureEvent.TIMEOUT)

      setTimeoutId(timeoutId)
    }

    switch (cardPreAuthorization?.status) {
      case ThreeDSecureEventStatus.Succeeded:
        isIOS && Browser.close()
        resetState()
        if (!hasRunForSuccess.current) {
          hasRunForSuccess.current = true
          handleBuyImo()
        }
        break
      case ThreeDSecureEventStatus.Created:
        if (cardPreAuthorization?.secureModeRedirectUrl) {
          if (isNative) {
            Browser.open({ url: cardPreAuthorization?.secureModeRedirectUrl })
          } else {
            setSecureModeRedirectUrl(cardPreAuthorization?.secureModeRedirectUrl)
          }
        } else {
          refetchPreAuthWithTimeout()
        }
        break
      case ThreeDSecureEventStatus.Failed:
        isIOS && Browser.close()
        resetState()
        renderModal({
          modalKey: ModalKey.OrderFailed,
          content: <OrderFailed />,
        })
        break
      default:
        isIOS && Browser.close()
        resetState()
        renderModal({
          modalKey: ModalKey.OrderFailed,
          content: <OrderFailed />,
        })
    }

    return () => {
      window.removeEventListener(ThreeDSecureEvent.TYPE, eventHandler)
      clearTimeout(timeoutId)
      resetState()
    }
    // eslint-disable-next-line
  }, [cardPreAuthorization?.status])

  if (isNative) {
    return (
      <ModalContent>
        <Loader />
      </ModalContent>
    )
  }
  return (
    <ModalContent>
      <IframeRenderer url={secureModeRedirectUrl} />
    </ModalContent>
  )
}
