import { useState } from 'react'

import { Order } from 'constants/trading'
import { useLocalization } from 'locales/i18n'
import { KycStatus } from 'types/auth-user'
import { OrderType as OrderTypeGlobal } from 'types/order'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { Button } from 'ui/@library/inputs/button'
import { Divider } from 'ui/@library/layout/divider'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { KycTriggerModal } from 'ui/@modals/kyc/kyc-trigger-modal'
import { ReviewKyc } from 'ui/@modals/kyc/review-kyc'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useMarketOverviewLoadable } from 'ui/@store/market-overview'
import { useWalletLoadable } from 'ui/@store/wallet'
import { InsufficientFunds } from 'ui/master/@components/modals/insufficient-funds'
import { ReviewLimitBuy } from 'ui/master/@components/modals/review-order'

import { SignInPrompt } from '../sign-in-prompt'

import { TradeSpecification } from './@components/trade-specification'
import { TradeAmount } from './@components/trade-specification/@components/trade-amount/trade-amount'
import { TradeQuantity } from './@components/trade-specification/@components/trade-quantity'
import { TradeTotal } from './@components/trade-specification/@components/trade-total'
import { TradeType } from './@components/trade-specification/@components/trade-type'
import styles from './styles.module.scss'

export const TraderLimitBuy = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader.market')
  const { isAuthenticated } = useMxAuth()
  const { renderModal } = useGlobalModal()
  const { wallet } = useWalletLoadable()
  const { marketOverview } = useMarketOverviewLoadable()
  const { authUser } = useAuthUserLoadable()
  const [numberOfShares, setNumberOfShares] = useState(NaN)
  const [pricePerShare, setPricePerShare] = useState<string | null>(null)
  const [numberOfSharesError, setNumberOfSharesError] = useState(false)
  const [pricePerShareError, setPricePerShareError] = useState(false)
  const [isKeyboardActive, setIsKeyboardActive] = useState(false)

  const tradePrice = pricePerShare ? Number(pricePerShare) : 0
  const tradeQuantity = numberOfShares
  const accountBalance = wallet?.primaryBalance
  const serviceFee = Math.floor(Order.SERVICE_CHARGE.IN_PERCENT * tradePrice) / 100
  const isServiceFeeBelowMinimum = Math.abs(serviceFee) < Order.SERVICE_CHARGE.MINIMUM_PER_SHARE
  const totalWithoutFees = tradeQuantity * tradePrice
  const finalServiceFee =
    tradeQuantity * (isServiceFeeBelowMinimum ? Order.SERVICE_CHARGE.MINIMUM_PER_SHARE : serviceFee)
  const totalOrderAmount = finalServiceFee + totalWithoutFees
  const hasInsufficientFunds =
    !accountBalance || accountBalance === 0 || accountBalance < totalOrderAmount
  const isKycValidated = authUser?.kycStatus === KycStatus.Validated
  const isKycAttempted =
    authUser?.kycStatus === KycStatus.Refused || authUser?.kycStatus === KycStatus.ValidationAsked

  const validateTradeSpecification = () => {
    let isValid = true
    if (isNaN(numberOfShares) || !numberOfShares) {
      setNumberOfSharesError(true)
      isValid = false
    }
    if (!Number(pricePerShare) || isNaN(Number(pricePerShare))) {
      setPricePerShareError(true)
      isValid = false
    }
    if (marketOverview && numberOfShares > marketOverview.limitBidMaxQuantity) {
      setNumberOfSharesError(true)
      isValid = false
    }
    return isValid
  }

  const renderReviewLimitOrderModal = () =>
    renderModal({
      modalKey: ModalKey.ReviewLimitBuy,
      content: <ReviewLimitBuy />,
      payload: {
        pricePerShare: tradePrice,
        orderType: OrderTypeGlobal.LimitBid,
        serviceFee: finalServiceFee,
        numberOfShares,
        totalWithoutFees,
        totalOrderAmount,
      },
      enforceAuth: true,
      size: ModalSize.Large,
    })

  const handleReviewOrder = () => {
    if (validateTradeSpecification()) {
      if (!isAuthenticated) {
        renderModal({
          content: <SignInPrompt />,
          modalKey: ModalKey.SignInPrompt,
        })
      } else if (isKycAttempted) {
        renderModal({
          modalKey: ModalKey.ReviewKyc,
          content: <ReviewKyc />,
        })
      } else if (!isKycValidated) {
        renderModal({
          modalKey: ModalKey.KycTriggerModal,
          content: <KycTriggerModal />,
          payload: {
            title: t('kycTrigger.title'),
            description: t('kycTrigger.description'),
          },
        })
      } else if (hasInsufficientFunds) {
        renderModal({
          content: <InsufficientFunds />,
          modalKey: ModalKey.InsufficientFunds,
          payload: {
            sharesCost: accountBalance ? totalOrderAmount - accountBalance : totalOrderAmount,
            retriggerReviewOrder: true,
            onAddFundsSuccessCallback: () => renderReviewLimitOrderModal(),
          },
        })
      } else {
        renderReviewLimitOrderModal()
        setNumberOfShares(NaN)
        setPricePerShare(null)
      }
    }
  }

  const handleFocusChange = (isFocused: boolean) => {
    setIsKeyboardActive(isFocused)
  }

  const getTotalCost = () => {
    if (isNaN(numberOfShares) || numberOfShares === 0) return 0
    return pricePerShare ? numberOfShares * Number(pricePerShare) : 0
  }

  return (
    <form
      className={`${styles.traderVariantWrapper} ${isKeyboardActive ? styles.keyboardActive : ''}`}
      onSubmit={(e) => {
        e.preventDefault()
        handleReviewOrder()
      }}
    >
      <TradeSpecification>
        <TradeType label={t('information.orderType.title')} />
        <TradeQuantity
          label={t('information.quantity.label')}
          setQuantity={setNumberOfShares}
          quantity={numberOfShares}
          maxLimit={marketOverview?.limitBidMaxQuantity}
          displayErrorMessage={numberOfSharesError}
          setDisplayErrorMessage={setNumberOfSharesError}
          onFocusChange={handleFocusChange}
          displayMaxLimitLabel={false}
        />
        <TradeAmount
          label={t('information.price.buy.limit')}
          inputAmount={pricePerShare}
          setInputAmount={setPricePerShare}
          displayErrorMessage={pricePerShareError}
          setDisplayErrorMessage={setPricePerShareError}
          onFocusChange={handleFocusChange}
        />
        <Divider />
        <TradeTotal label={t('information.estimated')} price={getTotalCost()} />
      </TradeSpecification>
      <Button width='fluid' variant='primary' className={styles.buyButton} type='submit'>
        {t('actionCta')}
      </Button>
    </form>
  )
}
