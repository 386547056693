import { useState } from 'react'

import { ampli } from 'ampli'
import { useLocalization } from 'locales/i18n'
import { useBuyImo } from 'repositories/mx-api'
import { OrderType } from 'types/order'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { OrderLoading } from 'ui/@library/feedback/order-loading'
import { Spinner } from 'ui/@library/feedback/spinner'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { AddCardModal } from 'ui/@modals/add-funds'
import { useCardPreAuthorization } from 'ui/@store/card-pre-authorization'
import { useCardsLoadable } from 'ui/@store/cards'
import { useMasterLoadable } from 'ui/@store/master'
import { useWalletLoadable } from 'ui/@store/wallet'

import { OrderFailed } from '../order-failed'

import { AgreementLinks } from './@components/agreement-links'
import { CheckoutPaymentMethod } from './@components/checkout-payment-method'
import { OrderContainer } from './@components/order-container'
import { OrderHandler } from './@components/order-handler'
import { OrderHeader } from './@components/order-header'
import {
  OrderQuantity,
  OrderSpecification,
  SharePrice,
  OrderAmount,
} from './@components/order-specification'
import { PreauthThreeDSecure } from './@components/preauth-three-d-secure'
import { ShareList } from './@components/share-list'
import { PlaceImoBid } from './place-imo-bid'
import styles from './styles.module.scss'

export type ReviewImoBuyProps = {
  pricePerShare?: number
  numberOfShares?: number
  orderType: OrderType
  totalOrderAmount: number
}

export const ReviewImoBuy = () => {
  const { t } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation',
  )
  const { payload: modalPayload, renderModal } = useGlobalModal()
  const { master } = useMasterLoadable()
  const { selectedCard, cards, loading: cardsLoading } = useCardsLoadable({ forceRefetch: true })
  const { wallet, loading: walletLoading } = useWalletLoadable({ forceRefetch: true })

  const tradePrice = modalPayload?.pricePerShare
  const tradeQuantity = modalPayload?.numberOfShares

  const { loading: buyImoLoading } = useBuyImo()
  const { createCardPreAuthorization } = useCardPreAuthorization()

  const [preAuthLoading, setPreAuthLoading] = useState(false)

  useEffectOnce(() => {
    ampli.viewReviewImoOrderModal({
      masterName: master?.name || '',
      masterUuid: master?.uuid || '',
    })
  })

  const handleBuyImo = async () => {
    const selectedCardId = selectedCard?.id
    if (wallet && wallet.primaryBalance >= modalPayload?.totalOrderAmount) {
      renderModal({
        modalKey: ModalKey.PlaceImoBid,
        content: <PlaceImoBid />,
        size: ModalSize.Large,
        payload: {
          orderType: modalPayload?.orderType,
          tradeQuantity,
          tradePrice,
        },
      })
    } else if (cards.length === 0) {
      renderModal({
        modalKey: ModalKey.AddCard,
        content: <AddCardModal />,
        payload: {
          onSuccessCallback: () => {
            renderModal({
              modalKey: ModalKey.ReviewImoBuy,
              content: <ReviewImoBuy />,
              size: ModalSize.Large,
              payload: {
                pricePerShare: modalPayload?.pricePerShare,
                numberOfShares: modalPayload?.numberOfShares,
                orderType: modalPayload?.orderType,
                totalOrderAmount: modalPayload?.totalOrderAmount,
              },
            })
          },
        },
      })
    } else if (selectedCardId && wallet) {
      try {
        setPreAuthLoading(true)
        const preAuth = await createCardPreAuthorization(
          Number((modalPayload?.totalOrderAmount - wallet.primaryBalance).toFixed(2)),
          selectedCardId,
        )
        setPreAuthLoading(false)
        if (preAuth) {
          renderModal({
            modalKey: ModalKey.PreauthThreeDSecureModal,
            content: <PreauthThreeDSecure />,
            payload: {
              tradeQuantity,
              tradePrice,
            },
          })
        }
      } catch (error) {
        console.error(error)
        renderModal({
          modalKey: ModalKey.OrderFailed,
          content: <OrderFailed />,
        })
      }
    }
  }

  if (cardsLoading || walletLoading) {
    return (
      <ModalContent>
        <Spinner className={styles.spinner} />
      </ModalContent>
    )
  }

  if (buyImoLoading || preAuthLoading) {
    return (
      <ModalContent>
        <OrderLoading />
      </ModalContent>
    )
  }

  return (
    <ModalContent className={styles.reviewOrderContainer}>
      <OrderContainer>
        <OrderHeader title={t('title.buyImo')} />
        <OrderSpecification>
          <OrderQuantity quantity={tradeQuantity} />
          <SharePrice title={t('price.unique')} price={tradePrice} />
          <CheckoutPaymentMethod
            reviewImoBuyModalPayload={{
              pricePerShare: modalPayload?.pricePerShare,
              numberOfShares: modalPayload?.numberOfShares,
              orderType: modalPayload?.orderType,
              totalOrderAmount: modalPayload?.totalOrderAmount,
            }}
          />
          <OrderAmount
            title={t('total.withFees')}
            amount={modalPayload?.totalOrderAmount}
            highlight
          />
        </OrderSpecification>
        <OrderHandler
          title={t('buttons.payNow')}
          handleOrderAction={handleBuyImo}
          isLoading={walletLoading}
        />
        <AgreementLinks />
      </OrderContainer>
      <hr className={styles.dividerVertical} />
      <ShareList
        tradeQuantity={tradeQuantity}
        orderType={OrderType.ImoBid}
        tradePrice={tradePrice}
      />
    </ModalContent>
  )
}
