import { useEffect } from 'react'
import {
  atom,
  selector,
  useRecoilRefresher_UNSTABLE,
  useRecoilValueLoadable,
  useRecoilState,
} from 'recoil'

import { fetchCards } from 'repositories/mx-api'
import { Card, CardValidity } from 'types/card'

import { authUserState } from './auth-user'

const cardsQuery = selector<Card[]>({
  key: 'cardsQuery',
  get: async ({ get }) => {
    const authUser = get(authUserState)
    if (!authUser) return []
    return fetchCards()
  },
})

export const selectedCardState = atom<Card | null>({
  key: 'selectedCard',
  default: null,
})

export const useCardsLoadable = ({ forceRefetch = false } = {}) => {
  const [selectedCard, setSelectedCard] = useRecoilState(selectedCardState)
  const { state, contents } = useRecoilValueLoadable<Card[]>(cardsQuery)
  const refetch = useRecoilRefresher_UNSTABLE(cardsQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (contents && !selectedCard) {
      setSelectedCard(contents[0])
    }
    // eslint-disable-next-line
  }, [contents])

  const hasValidatedCard =
    state === 'hasValue' ? contents.some((card: Card) => card.status === CardValidity.Valid) : null

  return {
    selectedCard,
    hasValidatedCard,
    setSelectedCard,
    cards: state === 'hasValue' ? contents : [],
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
