import { AuthUserIbanRaw } from 'types/bank-accounts'

import { sanitizeAuthUserIbanFromRaw } from '../@santizers/bank-accounts'
import { useMxApi } from '../mx-api'

export const useFetchAuthUserIban = () => {
  const [{ data, loading, error }] = useMxApi<AuthUserIbanRaw>(
    {
      method: 'GET',
      url: '/auth-users/me/wallet/iban',
    },
    {
      useCache: false,
    },
  )

  return {
    authUserIban: data ? sanitizeAuthUserIbanFromRaw(data) : null,
    loading,
    error,
  }
}
