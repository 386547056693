import { Card, PayIn, PayInRaw } from 'types/card'

import { sanitizePayInFromRaw } from '../@santizers/card'
import { MxApi } from '../mx-api'

export const createPayIn = async (amount: number, card: Card): Promise<PayIn> => {
  const { data } = await MxApi.post<PayInRaw>('/auth-users/me/wallet/pay-in', {
    method: card.type,
    method_identifier: card.id,
    credit_amount: amount,
    browser_info: {
      java_enabled: window.navigator.javaEnabled(),
      language: window.navigator.language || 'en',
      color_depth: window.screen.colorDepth,
      screen_height: window.screen.height,
      screen_width: window.screen.width,
      timezone_offset: new Date().getTimezoneOffset(),
      user_agent: window.navigator.userAgent,
      javascript_enabled: true,
    },
  })

  return sanitizePayInFromRaw(data)
}
