import { ReactComponent as BackIcon } from 'assets/icons/chevron-left.svg'
import { ReactComponent as DriverLicense } from 'assets/svgs/driver-license.svg'
import { ReactComponent as NationalIdCard } from 'assets/svgs/national-identity-card.svg'
import { ReactComponent as Passport } from 'assets/svgs/passport.svg'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { DocumentType, KycStepProps } from 'ui/kyc/types'
import { getTranslationKey } from 'ui/kyc/utils'

import styles from './styles.module.scss'

export const DocumentInformation = ({
  selectedDocumentType,
  steps,
  setCurrentStep,
  onSkip,
}: KycStepProps) => {
  const { t } = useLocalization('kyc')

  const getDocumentWireframe = () => {
    switch (selectedDocumentType) {
      case DocumentType.PASSPORT:
        return <Passport />
      case DocumentType.DRIVING_LICENSE:
        return <DriverLicense />
      case DocumentType.NATIONAL_ID:
        return <NationalIdCard />
      default:
        return null
    }
  }

  return (
    <>
      <BackIcon
        className={styles.backIcon}
        onClick={() => setCurrentStep(steps.selectDocumentType)}
      />
      <h1 className={styles.header}>
        <span className={styles.title}>
          {t('documentInformation.title', {
            documentType: t(getTranslationKey(selectedDocumentType as DocumentType)),
          })}
        </span>
        <span className={styles.subtitle}>
          {t('documentInformation.subtitle', {
            documentType: t(getTranslationKey(selectedDocumentType as DocumentType)),
          })}
        </span>
      </h1>
      <div className={styles.mainContent}>
        <div className={styles.wireframeContainer}>{getDocumentWireframe()}</div>
        <ul className={styles.guidelines}>
          <li>{t('documentInformation.guidelines.noObstruction')}</li>
          <li>{t('documentInformation.guidelines.wellLit')}</li>
          <li>{t('documentInformation.guidelines.useCamera')}</li>
        </ul>
      </div>

      <div className={styles.buttonContainer}>
        <Button width='fluid' onClick={() => setCurrentStep(steps.uploadDocuments)}>
          {t('documentInformation.continue')}
        </Button>
        <Button width='fluid' variant='ghost' onClick={onSkip}>
          {t('skip')}
        </Button>
      </div>
    </>
  )
}
