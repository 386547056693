import { debounce } from 'lodash'

import { useLocalization } from 'locales/i18n'
import { updateNotificationSettings } from 'repositories/mx-api'
import { Switch } from 'ui/@library/inputs/switch'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import {
  SectionContainer,
  SectionDescription,
  SectionHeader,
  SectionTitle,
} from '../@components/section'

import styles from './styles.module.scss'
import { NotificationSetting } from './types'

type Setting = {
  id: NotificationSetting
  title: string
  description: string
  email: boolean | null
}

export const NotificationSettings = () => {
  const { t } = useLocalization('account')
  const { authUser, refetchAuthUser } = useAuthUserLoadable()

  const settings: Setting[] = [
    {
      id: NotificationSetting.OrderConfirmation,
      title: t('notificationSettings.orderPlaced.title'), // Add translation
      description: t('notificationSettings.orderPlaced.description'), // Add translation
      email: authUser?.notificationSettings?.orderConfirmation.email ?? null,
      // pushNotification: authUser?.notificationSettings?.orderConfirmation.pushNotification ?? null,
    },
  ]

  const handleToggle = debounce(async (setting: Setting) => {
    const newSettings = {
      ...authUser?.notificationSettings,
      [setting.id]: {
        email: !setting.email,
        pushNotification: null,
      },
    }

    try {
      await updateNotificationSettings(newSettings)
    } catch (error) {
      console.error(error)
    }

    refetchAuthUser()
  }, 300)

  return (
    <>
      {settings.map((setting) => (
        <SectionContainer displayDivider={false} key={setting.id}>
          <SectionHeader className={styles.notificationSettingsHeader}>
            <SectionTitle>{setting.title}</SectionTitle>
            <SectionDescription>{setting.description}</SectionDescription>
          </SectionHeader>
          {setting.email != null && (
            <div className={styles.notificationSettingsToggle}>
              <p>{t('notificationTypes.email')}</p>
              <Switch checked={setting.email} onChange={() => handleToggle(setting)} />
            </div>
          )}
        </SectionContainer>
      ))}
    </>
  )
}
