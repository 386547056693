import { AxiosPromise } from 'axios'
import { PartialDeep } from 'type-fest'

import { AuthUser, AuthUserRaw } from 'types/auth-user'

import { sanitizeAuthUserFromRaw, sanitizeAuthUserToRaw } from '../@santizers/auth-user'
import { MxApi } from '../mx-api'

let requestCache: AxiosPromise<AuthUserRaw> | undefined

const makeUpdateAuthUserRequest = async (userPayload: PartialDeep<AuthUserRaw>) => {
  if (requestCache) {
    return requestCache
  }

  const promise = MxApi.patch<AuthUserRaw>('/auth-users/me', userPayload)
    .then((response) => {
      requestCache = undefined
      return response
    })
    .catch((error) => {
      requestCache = undefined
      return error
    })

  requestCache = promise
  return promise
}

export const updateAuthUser = async (userData: PartialDeep<AuthUser>): Promise<AuthUser> => {
  const userPayload = sanitizeAuthUserToRaw(userData)
  const { data } = await makeUpdateAuthUserRequest(userPayload)

  return sanitizeAuthUserFromRaw(data)
}
