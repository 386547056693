import { useMemo } from 'react'

import { Paths } from 'constants/paths'
import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'
import { useFetchTransactionHistory } from 'repositories/mx-api/hooks/use-fetch-transaction-history'
import { TransactionType } from 'types/transaction'
import { Table } from 'ui/@library/data-display/table'
import { useColumnDefinition, useColumnHelper } from 'ui/@library/data-display/table/use-table'
import { Loader } from 'ui/@library/feedback/loader'
import { EmptyStatePlaceholder } from 'ui/@library/layout/empty-state-placeholder'
import { GraphicsToShow } from 'ui/@library/layout/empty-state-placeholder/states'

import { ExportAsCsv } from './@components/csv-export'
import { MobileTable } from './@components/mobile-table'
import styles from './styles.module.scss'
import { TransactionHistoryTableColumns } from './types'

export const TransactionHistory = () => {
  const { t, f } = useLocalization('myAssets.transactionHistory')
  const { data: transactionHistory, loading } = useFetchTransactionHistory()

  const snakeToCamel = (str: TransactionType | null) =>
    str ? str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase()) : ''

  const parsedTransactionHistory = useMemo(() => {
    return transactionHistory.map((item) => ({
      date: f.date(item.createdAt, TextFormats.DATE.DATE_WITH_TIME),
      transactionType: Object.values(TransactionType).includes(
        item.transactionType as TransactionType,
      )
        ? t(`transactionType.${snakeToCamel(item.transactionType)}`)
        : '-',
      title: item.masterName ? item.masterName : '-',
      tokenId: item.tokenId ? `#${item.tokenId}` : '-',
      price: f.currency(item.amount || 0),
      fees: f.currency(item.fees),
      total: f.currency(item.total, { showSign: true }),
    }))
    // eslint-disable-next-line
  }, [transactionHistory])

  const columnDefinition = useColumnDefinition({
    tableData: parsedTransactionHistory,
    styles,
    translationKey: 'myAssets.transactionHistory.columns',
  })
  const columns = useColumnHelper<TransactionHistoryTableColumns>(columnDefinition)

  if (loading) return <Loader />

  return (
    <>
      <h1 className={styles.pageTitle}>{t('heading')}</h1>
      {transactionHistory.length > 0 ? (
        <>
          <div className={styles.buttonWrapper}>
            <ExportAsCsv tableData={parsedTransactionHistory} />
          </div>
          <Table data={parsedTransactionHistory} columns={columns} hideInMobile />
          <MobileTable tableData={parsedTransactionHistory} />
        </>
      ) : (
        <EmptyStatePlaceholder
          message={t('emptyState.message')}
          button={t('emptyState.button')}
          path={Paths.EXCHANGE.MAIN}
          image={GraphicsToShow.Transactions}
        />
      )}
    </>
  )
}
