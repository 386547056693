import { MasterComingSoon } from 'types/master'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useWindowSize } from 'ui/@hooks/use-window-size'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { ExchangeCardSize } from 'ui/exchange/types'

import { Hero, MasterOwners, MasterInfo, MasterImoDetailsStrip } from '../../../exchange-card'
import { UpcomingSignUpPrompt } from '../upcoming-sign-up-prompt'

import styles from './styles.module.scss'

type UpcomingSongsProps = {
  master: MasterComingSoon
}

export const UpcomingSongCard = ({ master }: UpcomingSongsProps) => {
  const { renderModal } = useGlobalModal()
  const { viewports } = useWindowSize()

  const { isAuthenticated } = useMxAuth()

  const AutoScrollThreshold = viewports.isMobile ? 0.7 : 0.9

  const renderSignUpPrompt = () => {
    renderModal({
      content: <UpcomingSignUpPrompt />,
      enforceAuth: false,
      modalKey: ModalKey.UpcomingSignUpPrompt,
    })
  }

  const getHeroAsset = () => {
    if (viewports.isMobile && master.assets.heroCompact) {
      return master.assets.heroCompact
    }
    return master.assets.hero
  }

  return (
    <div className={styles.masterCard} key={master.name}>
      {!isAuthenticated && (
        <Hero
          mediaSrc={getHeroAsset()}
          placeholder={master.assets.placeholder}
          size={ExchangeCardSize.Small}
          onClick={renderSignUpPrompt}
        >
          <MasterInfo
            masterName={master.name}
            artistName={master.artist.name}
            size={ExchangeCardSize.Small}
          />
          <MasterOwners
            masterOwners={master.metaData.ownedByWithAvatars}
            className={styles.masterOwnerWrapper}
          />
        </Hero>
      )}
      <MasterImoDetailsStrip master={master} autoScrollThreshold={AutoScrollThreshold} />
    </div>
  )
}
