import { useLocalization } from 'locales/i18n'

type SharePriceProps = {
  title: string
  price: number
}

export const SharePrice = ({ title, price }: SharePriceProps) => {
  const { f } = useLocalization()
  return (
    <li>
      <span>{title}</span>
      <span>{f.currency(price)}</span>
    </li>
  )
}
