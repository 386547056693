import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchMarketOverview } from 'repositories/mx-api/'
import { ImoStatus } from 'types/imo'
import { MarketOverview } from 'types/market'

import { masterUuidState, masterQuery } from './master'

const marketOverviewQuery = selector<MarketOverview | null>({
  key: 'marketOverviewQuery',
  get: async ({ get }) => {
    const masterUuid = get(masterUuidState)
    const master = get(masterQuery)
    if (
      masterUuid &&
      master &&
      master.imo.status === ImoStatus.Completed &&
      master.market.isActive
    ) {
      return fetchMarketOverview(masterUuid)
    } else return null
  },
})

export const useMarketOverviewLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(marketOverviewQuery)
  const refetch = useRecoilRefresher_UNSTABLE(marketOverviewQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    marketOverview: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
