import { useState } from 'react'

import { ampli } from 'ampli'
import { ReactComponent as BackIcon } from 'assets/icons/chevron-left.svg'
import { useLocalization } from 'locales/i18n'
import { useCreateKyc } from 'repositories/mx-api/hooks/use-create-kyc'
import { useMessages } from 'ui/@components/message-banner/use-messages'
import { Button } from 'ui/@library/inputs/button'
import { DragAndDrop } from 'ui/@library/inputs/drag-and-drop'
import { DocumentType, KycStepProps } from 'ui/kyc/types'
import { getTranslationKey } from 'ui/kyc/utils'

import styles from './styles.module.scss'

export const UploadDocuments = ({
  selectedDocumentType,
  steps,
  setCurrentStep,
  setError,
  onSkip,
}: KycStepProps) => {
  const { refetchMessages } = useMessages()
  const [frontFile, setFrontFile] = useState<File | undefined>()
  const [backFile, setBackFile] = useState<File | undefined>()

  const [frontFileError, setFrontFileError] = useState<string | undefined>()
  const [backFileError, setBackFileError] = useState<string | undefined>()

  const { t } = useLocalization('kyc')
  const { loading, requestKyc } = useCreateKyc(frontFile, backFile)

  const handleAmpliEvents = (documentType: DocumentType, actionType: 'upload' | 'submit') => {
    switch (documentType) {
      case DocumentType.PASSPORT:
        actionType === 'upload' ? ampli.uploadPassport() : ampli.submitPassportSuccess()
        break
      case DocumentType.DRIVING_LICENSE:
        actionType === 'upload' ? ampli.uploadDrivingLicense() : ampli.submitDrivingLicenseSuccess()
        break
      case DocumentType.NATIONAL_ID:
        actionType === 'upload' ? ampli.uploadNationalIdCard() : ampli.submitNationalIdCardSuccess()
        break
    }
  }

  const buttonDisabled =
    loading || !frontFile || (selectedDocumentType !== DocumentType.PASSPORT && !backFile)

  const setFrontFileWithAmpli = (file: File | undefined) => {
    handleAmpliEvents(selectedDocumentType as DocumentType, 'upload')
    setFrontFile(file)
  }

  const submitKyc = async () => {
    await requestKyc()
    refetchMessages()
    setCurrentStep(steps.applicationSuccess)
  }

  const handleSubmit = async () => {
    handleAmpliEvents(selectedDocumentType as DocumentType, 'submit')
    try {
      if (selectedDocumentType === DocumentType.PASSPORT) {
        if (frontFile) {
          await submitKyc()
        }
      } else {
        if (frontFile && backFile) {
          await submitKyc()
        } else return
      }
    } catch {
      setError(true)
    }
  }

  return (
    <>
      <BackIcon
        className={styles.backIcon}
        onClick={() => setCurrentStep(steps.documentInformation)}
      />
      <h1 className={styles.uploadTitle}>
        {t('uploadDocuments.title', {
          documentType: t(getTranslationKey(selectedDocumentType as DocumentType)),
        })}
      </h1>
      <div className={styles.uploadContents}>
        <div className={styles.uploadBoxes}>
          <DragAndDrop
            file={frontFile}
            setFile={setFrontFile}
            setFileWithAmpli={setFrontFileWithAmpli}
            error={frontFileError}
            setError={setFrontFileError}
          >
            {selectedDocumentType !== DocumentType.PASSPORT && (
              <div className={styles.documentPage}>{t('uploadDocuments.front')}</div>
            )}
          </DragAndDrop>
          {selectedDocumentType !== DocumentType.PASSPORT && (
            <DragAndDrop
              file={backFile}
              setFile={setBackFile}
              error={backFileError}
              setError={setBackFileError}
            >
              <div className={styles.documentPage}>{t('uploadDocuments.back')}</div>
            </DragAndDrop>
          )}
        </div>
        <div className={styles.uploadError}>{frontFileError || backFileError}</div>
        <div className={styles.guidelines}>
          {t('uploadDocuments.guidelines', {
            documentType: t(getTranslationKey(selectedDocumentType as DocumentType)),
          })}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button width='fluid' onClick={handleSubmit} loading={loading} disabled={buttonDisabled}>
          {t('uploadDocuments.submit')}
        </Button>
        <Button width='fluid' variant='ghost' onClick={onSkip}>
          {t('skip')}
        </Button>
      </div>
    </>
  )
}
