import { sanitizeRoyaltyPayoutFromRaw } from 'repositories/mx-api/@santizers/royalty-payouts'

export enum PayoutStatus {
  Paid = 'paid',
  Upcoming = 'upcoming',
}

export type RoyaltyPayoutDataRaw = {
  payout_at: number | null
  status: PayoutStatus
  value: number
  period: string
  timestamp: number
  comment: string | null
}

export type RoyaltyPayoutData = ReturnType<typeof sanitizeRoyaltyPayoutFromRaw>
