import { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { AccessStatus } from 'types/auth-user'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { TabBar } from 'ui/@library/layout/tab-bar'
import { TabOption } from 'ui/@library/layout/tab-bar/tab-bar'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useAuthUserMasterSharesLoadable } from 'ui/@store/auth-user-master-shares'
import { useAuthUserMasterOffersMadeLoadable } from 'ui/@store/auth-user-masters-offers-made'
import { useAuthUserMastersOffersReceivedLoadable } from 'ui/@store/auth-user-masters-offers-received'
import { useAuthUserOpenOrdersLoadable } from 'ui/@store/auth-user-open-orders'
import { useWalletLoadable } from 'ui/@store/wallet'

import { SignedOut } from './@components/signed-out'
import styles from './styles.module.scss'

export const MyAssets = () => {
  const { t } = useLocalization('myAssets')
  const navigate = useNavigate()
  const location = useLocation()
  const currentTab = location.pathname.split('/').pop()
  const { isAuthenticated } = useMxAuth()
  const { authUser, refetchAuthUser } = useAuthUserLoadable()
  const { refetch: refetchAuthUserShares } = useAuthUserMasterSharesLoadable()
  const { refetch: refetchWallet } = useWalletLoadable()
  const { refetch: refetchOpenOrders } = useAuthUserOpenOrdersLoadable()
  const { refetch: refetchOffersMade } = useAuthUserMasterOffersMadeLoadable()
  const { refetch: refetchOffersRecieved } = useAuthUserMastersOffersReceivedLoadable()
  const [activeTab, setActiveTab] = useState(currentTab)

  const handleRefresh = () => {
    refetchAuthUser()
    refetchAuthUserShares()
    refetchWallet()
    refetchOpenOrders()
    refetchOffersMade()
    refetchOffersRecieved()
  }

  const tabOptions = useMemo<TabOption[]>(
    () => [
      {
        label: t('tabs.overview'),
        value: Paths.MY_ASSETS.OVERVIEW,
      },
      {
        label: t('tabs.transactionHistory'),
        value: Paths.MY_ASSETS.TRANSACTIONS,
      },
      ...(authUser?.accessStatus === AccessStatus.EarlyAccess
        ? [
            {
              label: t('tabs.statements'),
              value: Paths.MY_ASSETS.STATEMENTS,
            },
          ]
        : []),
    ],
    // eslint-disable-next-line
    [authUser?.accessStatus],
  )

  const handleTabChange = (value: string) => {
    navigate(`${Paths.MY_ASSETS.MAIN}/${value}`)
  }

  useEffect(() => {
    setActiveTab(currentTab)
  }, [currentTab])

  if (!isAuthenticated) return <SignedOut />

  return (
    <RouteWrapper
      title={t('pageTitle')}
      description={t('pageDescription')}
      onRefresh={handleRefresh}
    >
      <div className={styles.pageContainer}>
        <TabBar
          options={tabOptions}
          onChange={handleTabChange}
          variant='border'
          activeTab={activeTab}
          className={styles.tabBar}
        />
        <Outlet />
      </div>
    </RouteWrapper>
  )
}
