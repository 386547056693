import { useRef, useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'

import { ReactComponent as SpotifyIcon } from 'assets/icons/spotify.svg'
import { useLocalization } from 'locales/i18n'
import { MasterWithImoAndMarket } from 'types/master'
import { useWindowSize } from 'ui/@hooks/use-window-size'

import { PriceChange } from '../price-change/price-change'
import { Yield } from '../yield'

import styles from './styles.module.scss'

type MasterDetailsStripProps = {
  className?: string
  master: MasterWithImoAndMarket
  autoScrollThreshold?: number
}

const MIN_TRUNCATION = 5
const MARQUEE_SPEED = 30
const DEFAULT_AUTO_SCROLL_THRESHOLD = 0.5
const WIDTH_OFFSET = 64

export const MasterMarketDetailsStrip = ({
  className,
  master,
  autoScrollThreshold = DEFAULT_AUTO_SCROLL_THRESHOLD,
}: MasterDetailsStripProps) => {
  const { f } = useLocalization('exchange.spotlight.masterInfo')
  const { windowSize } = useWindowSize()
  const [artistNameOverflow, setArtistNameOverflow] = useState(0)
  const [autoScroll, setAutoScroll] = useState(false)

  const artistDetailRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)
  const masterNameRef = useRef<HTMLParagraphElement>(null)

  const [artistName, setArtistName] = useState(master.artist.name)

  const getTruncatedArtistName = () => {
    let charactersToTruncate = Math.ceil(artistNameOverflow / MIN_TRUNCATION)
    if (charactersToTruncate < MIN_TRUNCATION) charactersToTruncate = MIN_TRUNCATION
    let truncatedArtistName = master.artist.name
    if (master.artist.name.length > charactersToTruncate) {
      truncatedArtistName = truncatedArtistName.slice(0, charactersToTruncate) + '...'
    }
    return truncatedArtistName
  }

  useEffect(() => {
    if (parentRef.current && artistDetailRef.current) {
      const isOverflowing =
        artistDetailRef.current.clientWidth + WIDTH_OFFSET > parentRef.current.clientWidth
      setArtistNameOverflow(
        isOverflowing
          ? artistDetailRef.current.clientWidth + WIDTH_OFFSET - parentRef.current.clientWidth
          : 0,
      )
    }
    if (artistNameOverflow > 0) {
      setArtistName(getTruncatedArtistName())
    }
    // eslint-disable-next-line
  }, [artistDetailRef, windowSize.width, artistNameOverflow])

  useEffect(() => {
    if (parentRef.current && masterNameRef.current) {
      const isOverflowing =
        masterNameRef.current.clientWidth > parentRef.current.clientWidth * autoScrollThreshold
      setAutoScroll(isOverflowing)
    }
  }, [masterNameRef, autoScrollThreshold, windowSize.width])

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.info}>
        <div className={styles.infoWrapper} ref={parentRef}>
          <div className={styles.songInfo}>
            <div className={styles.subLine}>
              {autoScroll ? (
                <div className={styles.autoScrollWrapper}>
                  <Marquee speed={MARQUEE_SPEED} className={styles.marquee}>
                    <p className={`${styles.subHeading} ${styles.autoScroll}`} ref={masterNameRef}>
                      {master.name}
                    </p>
                  </Marquee>
                </div>
              ) : (
                <p className={styles.subHeading} ref={masterNameRef}>
                  {master.name}
                </p>
              )}
              {master.metrics.platformStreams.spotify && (
                <div className={styles.spotifyStreams}>
                  <SpotifyIcon className={styles.spotifyIcon} />
                  <p>{f.number(master.metrics.platformStreams.spotify, { compact: 'short' })}</p>
                </div>
              )}
            </div>
            <div ref={artistDetailRef}>
              <p className={styles.artistName}>{artistName}</p>
            </div>
            <Yield master={master} />
            <div className={styles.priceContainer}>
              <p className={styles.subHeading}>{f.currency(master.market.lastPrice)}</p>
              <PriceChange master={master} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
