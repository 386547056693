import { ReactElement } from 'react'

import styles from './styles.module.scss'

type RoyaltyTableRowProps = {
  header: ReactElement | string
  children: ReactElement | string
  isUpcoming?: boolean
}
export const RoyaltyTableRow = ({ header, isUpcoming, children }: RoyaltyTableRowProps) => {
  return (
    <div className={styles.listRow}>
      <div className={styles.listHeader}>{header}</div>
      <div className={`${styles.listValue} ${isUpcoming && styles.upcomingStatus}`}>{children}</div>
    </div>
  )
}
