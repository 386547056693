import { ampli } from 'ampli'
import { ReactComponent as DrivingLicenseIcon } from 'assets/icons/driving-license.svg'
import { ReactComponent as NationalIDIcon } from 'assets/icons/national-id-card.svg'
import { ReactComponent as PassportIcon } from 'assets/icons/passport.svg'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { DocumentType, KycStepProps } from 'ui/kyc/types'
import { getTranslationKey } from 'ui/kyc/utils'

import { DocumentOption } from './@components/document-option'
import styles from './styles.module.scss'

export const SelectDocumentType = ({
  steps,
  setCurrentStep,
  setSelectedDocumentType,
  onSkip,
}: KycStepProps) => {
  const { t } = useLocalization('kyc')

  const handleAmpliEvents = (documentType: DocumentType) => {
    switch (documentType) {
      case DocumentType.PASSPORT:
        ampli.selectPassport()
        break
      case DocumentType.DRIVING_LICENSE:
        ampli.selectDrivingLicense()
        break
      case DocumentType.NATIONAL_ID:
        ampli.selectNationalIdCard()
        break
    }
  }

  const handleClick = (documentType: DocumentType) => {
    handleAmpliEvents(documentType)
    setSelectedDocumentType(documentType)
    setCurrentStep(steps.documentInformation)
  }

  const getIcon = (documentType: DocumentType) => {
    switch (documentType) {
      case DocumentType.PASSPORT:
        return <PassportIcon />
      case DocumentType.DRIVING_LICENSE:
        return <DrivingLicenseIcon />
      case DocumentType.NATIONAL_ID:
        return <NationalIDIcon />
    }
  }

  return (
    <>
      <h1>{t('selectDocumentType.title')}</h1>
      <p className={styles.description}>{t('selectDocumentType.disclaimer')}</p>
      <p className={styles.description}>{t('selectDocumentType.description')}</p>
      <div className={styles.documentOptions}>
        {Object.keys(DocumentType).map((key) => (
          <DocumentOption
            key={key}
            icon={getIcon(key as DocumentType)}
            text={t(getTranslationKey(key as DocumentType))}
            onClick={() => handleClick(key as DocumentType)}
          />
        ))}
      </div>
      <Button width='fluid' variant='ghost' className={styles.skip} onClick={onSkip}>
        {t('skip')}
      </Button>
    </>
  )
}
