import { Order, OrderRaw } from 'types/order'

import { sanitizeOrderFromRaw } from '../@santizers/order'
import { MxApi } from '../mx-api'

export const fetchAuthUserOpenOrders = async (masterUuid?: string): Promise<Order[]> => {
  const requestConfig = masterUuid
    ? {
        params: {
          master_uuid: masterUuid,
        },
      }
    : {}
  const { data } = await MxApi.get<OrderRaw[]>('/orders/open-orders', requestConfig)
  return data.map(sanitizeOrderFromRaw)
}
