import { AxiosPromise } from 'axios'

import { AuthUser, AuthUserRaw } from 'types/auth-user'

import { sanitizeAuthUserFromRaw } from '../@santizers/auth-user'
import { MxApi } from '../mx-api'

let requestCache: AxiosPromise<AuthUserRaw> | undefined

const makeUpsertAuthUserRequest = async (referral_code?: string) => {
  if (requestCache) {
    return requestCache
  }
  const promise = MxApi.post<AuthUserRaw>(
    referral_code ? `/auth-users?referral_code=${referral_code}` : '/auth-users',
  )
    .then((response) => {
      requestCache = undefined
      return response
    })
    .catch((error) => {
      requestCache = undefined
      throw error
    })

  requestCache = promise

  return promise
}

export const upsertAuthUser = async (referral_code?: string): Promise<AuthUser> => {
  const { data } = await makeUpsertAuthUserRequest(referral_code)

  return sanitizeAuthUserFromRaw(data)
}
