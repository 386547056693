import { useEffect } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { ampli } from 'ampli'
import { useLocalization } from 'locales/i18n'
import { TriggeringAction } from 'types/ampli'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { FullPageError, NotFound } from 'ui/@library/errors'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { useAuthUserMasterOpenOrdersLoadable } from 'ui/@store/auth-user-master-open-orders'
import { useAuthUserMasterSharesLoadable } from 'ui/@store/auth-user-master-shares'
import { useAuthUserMasterOffersMadeLoadable } from 'ui/@store/auth-user-masters-offers-made'
import { useAuthUserMastersOffersReceivedLoadable } from 'ui/@store/auth-user-masters-offers-received'
import { masterUuidState, useMasterLoadable, useMasterStatusLoadable } from 'ui/@store/master'
import { useOrderBookLoadable } from 'ui/@store/order-book'
import { useRecentTradesLoadable } from 'ui/@store/recent-trades'
import { useRoyaltyPayoutsLoadable } from 'ui/@store/royalty-payouts'
import { useShareListLoadable } from 'ui/@store/share-list'

import { MasterHeader } from './@components/master-header'
import { MasterOverview } from './@components/master-overview'
import { QueryParamsValues } from './@components/master-overview/constants'
import { SharesTable } from './@components/shares-table'
import { SingleMasterShareDetail } from './@components/shares-table/@components/modals/single-master-share-detail'
import { SocialShare } from './@components/social-share'
import styles from './styles.module.scss'
import { MasterStatus, MasterTab, TabParamType } from './types'
export const Master = () => {
  const navigate = useNavigate()
  const setMasterUuid = useSetRecoilState(masterUuidState)
  const { uuid: masterUuid, tab } = useParams<TabParamType>()
  const [searchParams] = useSearchParams()
  const {
    loading,
    error,
    master,
    refetch: refetchMaster,
  } = useMasterLoadable(masterUuid, {
    forceRefetch: true,
  })
  const { masterStatus } = useMasterStatusLoadable()
  const { refetch: refetchOrderBook } = useOrderBookLoadable()
  const { refetch: refetchShareListAuth } = useShareListLoadable({
    isAuthUserShares: true,
    masterUuid: master?.uuid,
  })
  const { refetch: refetchShareList } = useShareListLoadable({
    isAuthUserShares: false,
    masterUuid: master?.uuid,
  })
  const { refetch: refetchRoyaltyPayouts } = useRoyaltyPayoutsLoadable()
  const { refetch: refetchRecentTrades } = useRecentTradesLoadable()
  const { refetch: refetchAuthUserShares } = useAuthUserMasterSharesLoadable()
  const { refetch: refetchMasterOpenOrders } = useAuthUserMasterOpenOrdersLoadable()
  const { refetch: refetchOffersMade } = useAuthUserMasterOffersMadeLoadable()
  const { refetch: refetchOffersRecieved } = useAuthUserMastersOffersReceivedLoadable()

  const { renderModal } = useGlobalModal()
  const { t } = useLocalization('master')
  const location = useLocation()

  const handleShare = async () => {
    ampli.openSocialShareModal({
      triggeringAction: TriggeringAction.EmailShare,
    })
    renderModal({
      content: <SocialShare />,
      modalKey: ModalKey.SocialShare,
      size: ModalSize.Medium,
      noPadding: false,
      closeOnClickingOutside: true,
    })
  }

  const handleRefresh = () => {
    refetchMaster()
    refetchOrderBook()
    refetchShareListAuth()
    refetchShareList()
    refetchRoyaltyPayouts()
    refetchRecentTrades()
    refetchAuthUserShares()
    refetchOffersMade()
    refetchOffersRecieved()
    refetchMasterOpenOrders()
  }

  useEffect(() => {
    if (
      searchParams.get('renderModal') === QueryParamsValues.UNIQUE_SHARE &&
      searchParams.get(QueryParamsValues.SHARE_UUID)
    ) {
      renderModal({
        modalKey: ModalKey.SingleMasterShareDetail,
        content: <SingleMasterShareDetail />,
        size: ModalSize.Medium,
        payload: {
          shareUuid: searchParams.get('shareUuid') || '',
        },
      })
    }

    if (searchParams.get(QueryParamsValues.EMAIL_SHARE)) {
      handleShare()
    }

    return () => {
      setMasterUuid(null)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!tab && masterStatus === MasterStatus.Market) {
      navigate(`${MasterTab.Overview}`, { replace: true })
    }
    window.scrollTo(0, 0)
  }, [tab, masterStatus, navigate])

  if (loading) return <FullPageLoader variant='secondary' />
  if (error?.response?.status === 404) return <NotFound />
  if (error) return <FullPageError error={error} />

  return (
    <>
      <RouteWrapper
        title={t('pageTitle', {
          songName: master?.name,
          artistName: master?.artist.name,
        })}
        description={t('pageDescription', {
          songName: master?.name,
          artistName: master?.artist.name,
        })}
        keywords={`${master?.name}, ${master?.artist.name}, ${master?.metaData?.ownedBy?.join(
          ', ',
        )}`}
        pageUrl={`https://app.masterexchange.com${location.pathname}`}
        headers={[<MasterHeader key='master-header' />]}
        onRefresh={handleRefresh}
        allowsBackNavigation
      >
        <div className={styles.masterPage}>
          {tab === MasterTab.Shares ? <SharesTable /> : <MasterOverview />}
        </div>
      </RouteWrapper>
    </>
  )
}
