import { sanitizeOrderFromRaw } from '../@santizers/order'
import { useMxApi } from '../mx-api'

export const useSellShare = (
  masterUuid: string,
  shareUuid: string,
  price: number,
  fillOrKill = false,
) => {
  const [{ data, loading, error }, requestSellShare] = useMxApi(
    {
      method: 'POST',
      url: `/masters/${masterUuid}/shares/asks`,
      data: {
        shares: [
          {
            share_uuid: shareUuid,
            price: price,
            fill_or_kill: fillOrKill,
          },
        ],
      },
    },
    {
      manual: true,
    },
  )

  return {
    order: sanitizeOrderFromRaw(data),
    requestSellShare,
    loading,
    error,
  }
}
