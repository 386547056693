import { CardValidation, CardValidationRaw } from 'types/card'

import { sanitizeCardValidationFromRaw } from '../@santizers/card'
import { MxApi } from '../mx-api'

export const fetchCardValidation = async (
  validationId: string,
  cardId: string,
): Promise<CardValidation> => {
  const { data } = await MxApi.get<CardValidationRaw>(
    `/auth-users/me/wallet/cards/${cardId}/validation/${validationId}`,
  )
  return sanitizeCardValidationFromRaw(data)
}
