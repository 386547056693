import { captureException } from '@sentry/react'

import { CardValidation3DS } from 'ui/@components/add-card/@components/card-validation-3ds'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { useOnboardingChecklist } from 'ui/@store/auth-user'
import { useCardValidation } from 'ui/@store/card-validation'
import { useCardsLoadable } from 'ui/@store/cards'
import { OnboardingStepIdentifier } from 'ui/onboarding/types'

import { AddCardStepProps } from '../../types'

export type CardValidation3DSProps = {
  tradeQuantity: number
  tradePrice: number
}

export const CardValidationStep = ({ steps, setCurrentStep }: AddCardStepProps) => {
  const { cards } = useCardsLoadable()
  const { updateOnboardingChecklist } = useOnboardingChecklist()

  const handleSuccess = async () => {
    await updateOnboardingChecklist({ [OnboardingStepIdentifier.ADD_CARD]: true })
    setCurrentStep(steps.addCardSuccess)
  }

  const handleError = () => {
    setCurrentStep(steps.addCardError)
  }

  const { createCardValidation, cardValidation, loading } = useCardValidation({
    onError: handleError,
  })

  useEffectOnce(() => {
    if (cards[0] && !cardValidation && !loading) {
      try {
        createCardValidation(cards[0].id)
      } catch (error) {
        captureException(error)
        handleError()
      }
    }
  })

  return <CardValidation3DS onSuccess={handleSuccess} onError={handleError} />
}
