import { atom } from 'recoil'

import { Share } from 'types/share'
import {
  OrderType,
  TradeActionType,
} from 'ui/master/@components/master-overview/@components/banner-trader/types'

export const sellableShareListState = atom<Share[]>({
  key: 'sellableShareList',
  default: [],
})

export const validateTradeSpecificationState = atom<boolean>({
  key: 'validateTradeSpecification',
  default: false,
})

export const orderTypeState = atom<OrderType | null>({
  key: 'orderType',
  default: OrderType.BestPrice,
})

export const tradeActionTypeState = atom<TradeActionType>({
  key: 'tradeActionType',
  default: TradeActionType.Buy,
})

export const hideSellOptionState = atom<boolean>({
  key: 'hideSellOption',
  default: false,
})
