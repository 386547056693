import { usePlatform } from 'ui/@hooks/use-platform'

type CookieBotProps = {
  autoBlock?: boolean
  noDeclaration?: boolean
}

export const CookieBotComponent = ({ autoBlock, noDeclaration }: CookieBotProps) => {
  const { isNative } = usePlatform()
  const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID

  if (isNative) return null
  if (typeof window === 'undefined' || !document) return null
  if (document.getElementById('CookieBot')) return null
  if (!domainGroupId) return null

  const script = document.createElement('script')
  script.setAttribute('id', 'CookieBot')
  script.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
  script.setAttribute('data-cbid', domainGroupId)
  if (autoBlock) script.setAttribute('data-blockingmode', 'auto')
  script.setAttribute('type', 'text/javascript')

  const head = document.querySelector('html > head')
  if (head) head.insertBefore(script, head.firstChild)

  if (noDeclaration) return null
  return (
    <script
      id='CookieDeclaration'
      src={`https://consent.cookiebot.com/${domainGroupId}/cd.js`}
      type='text/javascript'
      async
    />
  )
}
