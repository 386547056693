export enum AppEnvironment {
  Production = 'production',
  Staging = 'staging',
  Development = 'development',
}

export const useAppEnvironment = () => {
  const activeEnv = process.env.REACT_APP_ENVIRONMENT

  const isProduction = activeEnv === AppEnvironment.Production
  const isStaging = activeEnv === AppEnvironment.Staging
  const isDevelopment = activeEnv === AppEnvironment.Development

  return {
    activeEnv,
    isProduction,
    isStaging,
    isDevelopment,
  }
}
