import { axisTop, BaseType, Selection, ScaleBand, ScaleTime, ScaleLinear, timeMonth } from 'd3'
import { useEffect } from 'react'

import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'
import { useWindowSize } from 'ui/@hooks/use-window-size'

import styles from './styles.module.scss'

type XAxisProps<T extends BaseType> = {
  graph: Selection<T, unknown, null, undefined>
  height: number
  xScale: ScaleBand<string> | ScaleTime<number, number, never> | ScaleLinear<number, number, never>
}

export const XAxis = <T extends BaseType>({ graph, height, xScale }: XAxisProps<T>) => {
  const { windowSize, viewports } = useWindowSize()
  const { width: windowWidth } = windowSize
  const { f } = useLocalization()

  const isScaleBand = (scale: typeof xScale): scale is ScaleBand<string> => {
    return 'bandwidth' in scale && 'domain' in scale && 'range' in scale
  }

  useEffect(() => {
    if (graph) {
      graph.select(`.${styles.xAxis}`).remove()
      if (isScaleBand(xScale)) {
        const xAxisband = axisTop(xScale).tickSize(0)
        graph
          .append('g')
          .attr('class', `${styles.xAxis}`)
          .attr('transform', `translate(0, ${height})`)
          .call(xAxisband)
      } else {
        const xAxisTime = axisTop(xScale)
          .tickSize(0)
          .ticks(timeMonth.every(viewports.isDesktop ? 4 : 6))
          .tickFormat((value) =>
            f.date(new Date(value.valueOf()), TextFormats.DATE.QUARTER_AND_YEAR),
          )
        graph
          .append('g')
          .attr('class', `${styles.xAxis}`)
          .attr('transform', `translate(0, ${height})`)
          .call(xAxisTime)
      }
      graph.selectAll('.domain').remove()
    }
    // eslint-disable-next-line
  }, [graph, windowWidth])

  return <g className={styles.xAxis} />
}
