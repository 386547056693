import { useState } from 'react'
import { useRecoilValue } from 'recoil'

import { ReactComponent as TooltipIcon } from 'assets/icons/info.svg'
import { useLocalization } from 'locales/i18n'
import { Timeframe } from 'types/auth-user'
import { Dropdown } from 'ui/@library/inputs/dropdown'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { authUserState } from 'ui/@store/auth-user'
import { useCommissionLoadable } from 'ui/@store/commission'
import { TradingCommissionInfo } from 'ui/master/@components/modals/trading-commission-info'

import { MetricCard, MetricContents, MetricTitle, MetricValue } from '../metric-card'

import styles from './styles.module.scss'

export const Commission = () => {
  const { t } = useLocalization('myAssets.overview.cards.tradingFees')
  const { renderModal } = useGlobalModal()
  const [timeframe, setTimeframe] = useState(Timeframe.Monthly)

  const { commission, loading } = useCommissionLoadable()

  const authUser = useRecoilValue(authUserState)
  if (!authUser?.isMasterOwner) return null

  const handleTradingCommissionInfo = () => {
    renderModal({
      modalKey: ModalKey.TradingCommissionInfo,
      content: <TradingCommissionInfo />,
    })
  }

  const dropdownOptions = [
    {
      label: 'month',
      value: <span>{t('dropdown.monthly')}</span>,
      onClick: () => setTimeframe(Timeframe.Monthly),
    },
    {
      label: 'all_time',
      value: <span>{t('dropdown.allTime')}</span>,
      onClick: () => setTimeframe(Timeframe.AllTime),
    },
  ]

  const getCommissionValue = () => {
    if (loading) return 0
    if (timeframe === Timeframe.Monthly) return commission?.monthly || 0
    if (timeframe === Timeframe.AllTime) return commission?.allTime || 0
  }

  return (
    <MetricCard className={styles.commissionCard}>
      <div className={styles.commissionHeader}>
        <MetricTitle>{t('title')}</MetricTitle>
        <div className={styles.infoIcon}>
          <TooltipIcon onClick={handleTradingCommissionInfo} />
        </div>
        <Dropdown
          options={dropdownOptions}
          className={styles.commissionDropdown}
          type='dropdown'
          horizontalAlign='right'
        >
          <div className={styles.dropdownValue}>
            {timeframe === Timeframe.Monthly ? t('dropdown.monthly') : t('dropdown.allTime')}
          </div>
        </Dropdown>
      </div>
      <MetricContents loading={loading}>
        <MetricValue>{getCommissionValue()}</MetricValue>
      </MetricContents>
    </MetricCard>
  )
}
