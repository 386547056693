import { ampli } from 'ampli'
import { useGTM, GTMEvents } from 'gtm'
import { useLocalization } from 'locales/i18n'
import { useBuyLimit } from 'repositories/mx-api'
import { KycStatus } from 'types/auth-user'
import { OrderType } from 'types/order'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { OrderLoading } from 'ui/@library/feedback/order-loading'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useAuthUserMasterOpenOrdersLoadable } from 'ui/@store/auth-user-master-open-orders'
import { useAuthUserMasterSharesLoadable } from 'ui/@store/auth-user-master-shares'
import { useMasterLoadable } from 'ui/@store/master'
import { useOrderBookLoadable } from 'ui/@store/order-book'
import { useRecentTradesLoadable } from 'ui/@store/recent-trades'
import { useShareListLoadable } from 'ui/@store/share-list'
import { useWalletLoadable } from 'ui/@store/wallet'

import { ReviewKyc } from '../../../../@modals/kyc/review-kyc'
import { TradeActionType } from '../../master-overview/@components/banner-trader/types'
import { OrderCompleted } from '../order-completed'
import { OrderFailed } from '../order-failed'

import { AgreementLinks } from './@components/agreement-links'
import { OrderContainer } from './@components/order-container'
import { OrderHandler } from './@components/order-handler'
import { OrderHeader } from './@components/order-header'
import {
  OrderQuantity,
  OrderSpecification,
  ServiceFee,
  SharePrice,
  OrderAmount,
} from './@components/order-specification'
import { ShareList } from './@components/share-list'
import styles from './styles.module.scss'

export type ReviewLimitBuyProps = {
  pricePerShare?: number
  numberOfShares?: number
  orderType: OrderType
  totalWithoutFees?: number
  serviceFee?: number
  totalOrderAmount?: number
}

export const ReviewLimitBuy = () => {
  const { t } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation',
  )
  const { payload: modalPayload, renderModal } = useGlobalModal()
  const { master } = useMasterLoadable()
  const {
    wallet,
    refetch: refetchWallet,
    loading: walletLoading,
  } = useWalletLoadable({ forceRefetch: true })
  const { authUser } = useAuthUserLoadable()
  const { sendGTMEvent } = useGTM()

  const isKycValidated = authUser?.kycStatus === KycStatus.Validated

  useEffectOnce(() => {
    ampli.viewReviewBuyLimitOrderModal({
      masterName: master?.name || '',
      masterUuid: master?.uuid || '',
    })
  })

  if (!isKycValidated)
    renderModal({
      modalKey: ModalKey.ReviewKyc,
      content: <ReviewKyc />,
    })

  const tradePrice = modalPayload?.pricePerShare
  const tradeQuantity = modalPayload?.numberOfShares

  const { requestBuyLimit, loading: buyLimitLoading } = useBuyLimit(
    master?.uuid || '',
    tradeQuantity,
    tradePrice,
  )

  const { refetch: refetchAuthUserShareList } = useShareListLoadable({
    isAuthUserShares: true,
  })
  const { refetch: refetchShareList } = useShareListLoadable({
    isAuthUserShares: false,
  })

  const { refetch: refetchAuthUserMasterOpenOrders } = useAuthUserMasterOpenOrdersLoadable()
  const { refetch: refetchAuthUserMasterShares } = useAuthUserMasterSharesLoadable()
  const { refetch: refetchOrderBook } = useOrderBookLoadable()
  const { refetch: refetchRecentTrades } = useRecentTradesLoadable()

  const handleBuyLimitOrder = async () => {
    try {
      await requestBuyLimit()
      refetchWallet()
      refetchAuthUserMasterOpenOrders()
      refetchAuthUserMasterShares()
      refetchShareList()
      refetchAuthUserShareList()
      refetchOrderBook()
      refetchRecentTrades()
      ampli.buyLimitOrderPlacedSuccess({
        masterName: master?.name || '',
        masterUuid: master?.uuid || '',
      })
      sendGTMEvent(GTMEvents.OrderPlaced, authUser?.uuid)
      renderModal({
        modalKey: ModalKey.OrderCompleted,
        content: <OrderCompleted />,
        payload: {
          tradeActionType: TradeActionType.Buy,
          orderType: OrderType.LimitBid,
        },
      })
    } catch (error) {
      renderModal({
        modalKey: ModalKey.OrderFailed,
        content: <OrderFailed />,
      })
    }
  }

  if (buyLimitLoading) {
    return (
      <ModalContent>
        <OrderLoading />
      </ModalContent>
    )
  }

  return (
    <ModalContent className={styles.reviewOrderContainer}>
      <OrderContainer>
        <OrderHeader title={t('title.buyLimit')} accountBalance={wallet?.primaryBalance} />
        <OrderSpecification>
          <OrderQuantity quantity={tradeQuantity} />
          <SharePrice title={t('price.limit')} price={tradePrice} />
          <OrderAmount title={t('total.withoutFees')} amount={modalPayload?.totalWithoutFees} />
          <ServiceFee title={t('serviceFee')} serviceFee={modalPayload?.serviceFee} />
          <hr className={styles.dividerHorizontal} />
          <OrderAmount
            title={t('total.withFees')}
            amount={modalPayload?.totalOrderAmount}
            highlight
          />
        </OrderSpecification>
        <OrderHandler
          title={t('buttons.confirmOrder')}
          handleOrderAction={handleBuyLimitOrder}
          isLoading={buyLimitLoading || walletLoading}
        />
        <AgreementLinks />
      </OrderContainer>
      <hr className={styles.dividerVertical} />
      <ShareList tradePrice={tradePrice} tradeQuantity={tradeQuantity} />
    </ModalContent>
  )
}
