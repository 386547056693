import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as FacebookIcon } from 'assets/icons/facebook-footer.svg'
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram-footer.svg'
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin-footer.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/x.svg'
import { ReactComponent as AppStoreBadge } from 'assets/svgs/app-store-badge.svg'
import { ReactComponent as PlayStoreBadge } from 'assets/svgs/google-play-badge.svg'
import { ReactComponent as Logo } from 'assets/svgs/logo.svg'
import MangoPayLogo from 'assets/svgs/mangopay-logo.svg'
import { EMAILS } from 'constants/emails'
import {
  ExternalLinks,
  LandingPageRoutes,
  NativeAppLinks,
  SocialMediaLinks,
} from 'constants/external-links'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { useAppEnvironment } from 'ui/@hooks/use-app-environment'
import { useLandingPageLinks } from 'ui/@hooks/use-landing-page-links'
import { Divider } from 'ui/@library/layout/divider'

import styles from './styles.module.scss'

export const Footer = () => {
  const { t } = useLocalization('components.footer')
  const { isProduction } = useAppEnvironment()
  const location = useLocation()
  const isMasterPage = location.pathname.includes(Paths.MASTER.MAIN)
  const currentYear = new Date().getFullYear()
  const generateLandingPageLink = useLandingPageLinks()

  return (
    <div className={`${styles.footerContainer} ${isMasterPage && styles.withStickyTrader}`}>
      <Divider />
      <div className={styles.footerWrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.leftWrapper}>
            <Link to={`${Paths.HOME}`}>
              <Logo className={styles.logo} />
            </Link>
            <p className={styles.address}>{t('address')}</p>
          </div>
          <div className={styles.rightWrapper}>
            <div className={styles.listContainer}>
              <h4 className={styles.listHeading}>{t('heading.learnMore')}</h4>
              <a
                target='_blank'
                href={generateLandingPageLink(LandingPageRoutes.ABOUT)}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.about')}
              </a>
              <a
                target='_blank'
                href={generateLandingPageLink(LandingPageRoutes.HOW_IT_WORKS)}
                className={styles.link}
                rel='referrer referrer noreferrer'
              >
                {t('links.howItWorks')}
              </a>
              <a
                target='_blank'
                href={ExternalLinks.LIST_MUSIC_FORM}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.listYourMusic')}
              </a>
              <a
                target='_blank'
                href={generateLandingPageLink(LandingPageRoutes.OPUS)}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.opus')}
              </a>
              <a
                target='_blank'
                href={ExternalLinks.ROYALTIES_SCHEDULE}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.royaltiesSchedule')}
              </a>
              <a
                target='_blank'
                href={generateLandingPageLink(LandingPageRoutes.ARTICLES)}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.articles')}
              </a>
              <a
                target='_blank'
                href={generateLandingPageLink(LandingPageRoutes.JOBS)}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.careers')}
              </a>
            </div>

            <div className={styles.listContainer}>
              <h4 className={styles.listHeading}>{t('heading.support')}</h4>
              <a
                target='_blank'
                href={ExternalLinks.HELP_CENTER}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.helpCenter')}
              </a>
              <a
                target='_blank'
                href={generateLandingPageLink(LandingPageRoutes.FAQ)}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.faq')}
              </a>
              <a
                target='_blank'
                href={`mailto:${EMAILS.SUPPORT}`}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.supportEmail')}
              </a>
            </div>
            <div className={styles.listContainer}>
              <h4 className={styles.listHeading}>{t('heading.links')}</h4>
              <a
                target='_blank'
                href={ExternalLinks.PRIVACY_POLICY}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.privacyPolicy')}
              </a>
              <a
                target='_blank'
                href={ExternalLinks.TERMS_AND_CONDITIONS}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.termsAndConditions')}
              </a>
              <a
                target='_blank'
                href={ExternalLinks.MANGOPAY_TERMS_AND_CONDITIONS}
                className={styles.link}
                rel='referrer noreferrer'
              >
                {t('links.mangopay')}
              </a>
              <img src={MangoPayLogo} alt='mangopay' className={styles.mangoPayLogo} />
            </div>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.bottomContainer}>
          <p className={styles.copyright}>{t('copyright', { year: currentYear })}</p>
          <div className={styles.mobileAppLinksContainer}>
            <a
              target='_blank'
              href={isProduction ? NativeAppLinks.IOS_PRODUCTION : NativeAppLinks.IOS_STAGING}
              rel='referrer noreferrer'
            >
              <AppStoreBadge className={styles.mobileAppBadge} />
            </a>
            <a
              target='_blank'
              href={
                isProduction ? NativeAppLinks.ANDROID_PRODUCTION : NativeAppLinks.ANDROID_STAGING
              }
              rel='referrer noreferrer'
            >
              <PlayStoreBadge />
            </a>
          </div>
          <div className={styles.socialsContainer}>
            <a target='_blank' href={SocialMediaLinks.INSTAGRAM} rel='referrer noreferrer'>
              <InstagramIcon className={styles.socialIcon} />
            </a>
            <a target='_blank' href={SocialMediaLinks.TWITTER} rel='referrer noreferrer'>
              <TwitterIcon className={styles.socialIcon} />
            </a>
            <a target='_blank' href={SocialMediaLinks.LINKEDIN} rel='referrer noreferrer'>
              <LinkedinIcon className={styles.socialIcon} />
            </a>
            <a target='_blank' href={SocialMediaLinks.FACEBOOK} rel='referrer noreferrer'>
              <FacebookIcon className={styles.socialIcon} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
