import camelcaseKeys from 'camelcase-keys'
import i18n from 'i18next'
import BrowserLanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next, useTranslation } from 'react-i18next'

import { LocalStorage } from 'constants/local-storage'
import { LocaleFiles } from 'locales/constants'

import { LanguageCode, LanguageListType, LanguageResourceType } from './types'
import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPercentage,
  getCurrencySymbol,
} from './utils'

const LanguageResources: LanguageResourceType = {
  resources: {},
}

export const i18nInit = () => {
  importAllLanguages()

  i18n
    .use(Backend)
    .use(BrowserLanguageDetector)
    .use(initReactI18next)
    .init({
      resources: LanguageResources.resources,
      lng: 'en',
      fallbackLng: 'en',
      react: {
        useSuspense: false,
      },
      detection: {
        order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
        caches: ['cookie'],
      },
      interpolation: {
        escapeValue: false,
      },
    })

  const defaultLanguage = getDefaultLanguage()
  if (i18n.language !== defaultLanguage) {
    setLanguage(defaultLanguage)
  }
}

const importAllLanguages = () => {
  Object.values(LanguageCode).forEach((language) => {
    const translationBundle = {
      translation: {},
    }
    Object.values(LocaleFiles).forEach((fileName) => {
      // eslint-disable-next-line
      const resource = require(`./${language}/${fileName}.json`)
      translationBundle.translation = {
        ...translationBundle.translation,
        [fileName]: resource,
      }
      translationBundle.translation = camelcaseKeys(translationBundle.translation, { deep: true })
    })
    LanguageResources['resources'] = {
      ...LanguageResources.resources,
      [language]: translationBundle,
    }
  })
}

const getAllLanguages = (): LanguageListType[] => {
  return Object.entries(LanguageCode)
    .map(([, languageCode]) => {
      const languageName = new Intl.DisplayNames([languageCode], { type: 'language' }).of(
        languageCode,
      ) as string
      return {
        code: languageCode,
        name: languageName,
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))
}

const setLanguage = (languageCode: LanguageCode) => {
  i18n.changeLanguage(languageCode)
  localStorage.setItem(LocalStorage.I18, languageCode)
}

const getDefaultLanguage = () => {
  const languageCode = localStorage.getItem(LocalStorage.I18)?.slice(0, 2)
  if (
    languageCode &&
    Object.keys(LanguageResources.resources).find((language) => language === languageCode)
  ) {
    return languageCode as LanguageCode
  }

  localStorage.removeItem(LocalStorage.I18)
  return LanguageCode.EN
}

export const useLocalization = (keyPrefix?: string) => {
  const { t } = useTranslation('', { keyPrefix })

  return {
    f: {
      currencySymbol: getCurrencySymbol,
      currency: formatCurrency,
      number: formatNumber,
      date: formatDate,
      percentage: formatPercentage,
    },
    t,
    l: {
      getAllLanguages,
      setLanguage,
      activeLanguage: i18n.language as LanguageCode,
    },
  }
}
