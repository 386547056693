import { AuthUserRoyaltiesRaw } from '../../../types/auth-user'
import { sanitizeAuthUserRoyaltiesFromRaw } from '../@santizers/auth-user'
import { useMxApi } from '../mx-api'

export const useFetchAuthUserRoyalties = () => {
  const [{ data, loading, error }, requestFetchAuthUserRoyalties] = useMxApi<
    AuthUserRoyaltiesRaw[]
  >({
    method: 'GET',
    url: 'auth-users/me/royalty-payouts',
  })

  return {
    data: data ? data.map(sanitizeAuthUserRoyaltiesFromRaw) : [],
    requestFetchAuthUserRoyalties,
    loading,
    error,
  }
}
