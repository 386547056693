import { AnimatePresence, motion } from 'framer-motion'

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'

import styles from './styles.module.scss'

type FilterButtonProps = {
  active: boolean
  setActive: (active: boolean) => void
  children?: React.ReactNode
}

const closeIconVariants = {
  initial: { opacity: 0, marginLeft: 0, width: 0, height: 20 },
  animate: { opacity: 1, marginLeft: 8, width: 20, height: 20 },
  exit: { opacity: 0, marginLeft: 0, width: 0, height: 20 },
}

export const FilterButton = ({ children, active, setActive }: FilterButtonProps) => {
  const toggleActive = () => setActive(!active)

  const className = active ? `${styles.filterButton} ${styles.active}` : `${styles.filterButton}`

  return (
    <button className={className} onClick={toggleActive}>
      {children}
      <AnimatePresence>
        {active && (
          <motion.div
            variants={closeIconVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className={styles.closeIcon}
          >
            <CloseIcon />
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  )
}
