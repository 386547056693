import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchAuthUserMasterOffersReceived } from 'repositories/mx-api'

import { authUserState } from './auth-user'

const authUserMastersOffersReceivedQuery = selector({
  key: 'authUserMastersOffersReceivedQuery',
  get: async ({ get }) => {
    const authUser = get(authUserState)
    return authUser ? fetchAuthUserMasterOffersReceived() : []
  },
})

export const useAuthUserMastersOffersReceivedLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(authUserMastersOffersReceivedQuery)
  const refetch = useRecoilRefresher_UNSTABLE(authUserMastersOffersReceivedQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    authUserMastersOffersReceived: state === 'hasValue' ? contents : [],
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
