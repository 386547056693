import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type PercentageChangeProps = {
  percentage: number
}

export const PercentageChange = ({ percentage }: PercentageChangeProps) => {
  const { f } = useLocalization()

  return (
    <span className={percentage > 0 ? styles.green : percentage < 0 ? styles.red : ''}>
      {f.percentage(percentage, { showSign: true })}
    </span>
  )
}
