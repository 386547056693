import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type MetricChangeProps = {
  value?: number
  percentage?: number
}

export const MetricChange = ({ value, percentage }: MetricChangeProps) => {
  const { f } = useLocalization()
  return (
    <div className={styles.metricChange}>
      {value ? f.currency(value, { showSign: true }) : null}
      {percentage ? `(${f.percentage(percentage, { showSign: true })})` : null}
    </div>
  )
}
