import { GeneralPurchaseAgreementLink } from './@components/general-purchase-agreement-link'
import { OpusInvestorLink } from './@components/opus-investor-link'
import { TermsAndConditionsLink } from './@components/terms-and-conditions-link'
import { WhitepaperLink } from './@components/white-paper-link'
import styles from './styles.module.scss'

export const AgreementLinks = () => {
  return (
    <p className={styles.agreementWrapper}>
      <TermsAndConditionsLink />
      <OpusInvestorLink />
      <GeneralPurchaseAgreementLink />
      <WhitepaperLink />
    </p>
  )
}
