import { ButtonHTMLAttributes } from 'react'

import { Spinner } from 'ui/@library/feedback/spinner'

import styles from './styles.module.scss'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  width?: 'fixed' | 'fluid'
  variant?: 'primary' | 'secondary' | 'ghost' | 'outline' | 'danger' | 'small'
  loading?: boolean
  className?: string
}

export const Button = ({
  children,
  type,
  width,
  className,
  loading,
  variant = 'primary',
  ...props
}: ButtonProps) => {
  const buttonType = type ?? 'button'
  const btnStyles = styles[variant]
  const classNames = `${className} ${btnStyles} ${width === 'fluid' && styles.fluidButton}`

  const disabled = loading || props.disabled

  return (
    <button className={classNames} type={buttonType} {...props} disabled={disabled}>
      {loading ? <Spinner color={variant === 'primary' ? 'white' : 'primary'} /> : children}
    </button>
  )
}
