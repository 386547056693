import styles from './styles.module.scss'

type SwitchProps = {
  checked?: boolean
  onChange?: (checked: boolean) => void
  loading?: boolean
  disabled?: boolean
}

export const Switch = ({ checked, onChange, loading, disabled }: SwitchProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.checked)
  }

  return (
    <label className={`${styles.switch} ${(disabled || loading) && styles.disabled}`}>
      <input
        type='checkbox'
        className={styles.switchInput}
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
      />
      <span className={styles.slider}></span>
    </label>
  )
}
