import { DiscoverRaw } from 'types/discover'

import { sanitizeMasterWithImoAndMarketFromRaw, sanitizeMasterComingSoonFromRaw } from './master'

export const sanitizeDiscoverFromRaw = (raw: DiscoverRaw) => {
  return {
    imos: raw.imos.map(sanitizeMasterWithImoAndMarketFromRaw),
    masters: raw.masters.map(sanitizeMasterWithImoAndMarketFromRaw),
    comingSoon: raw.coming_soon.map(sanitizeMasterComingSoonFromRaw),
  }
}
