import { useNavigate } from 'react-router-dom'

import { ReactComponent as Check } from 'assets/icons/check.svg'
import { Paths } from 'constants/paths'
import { useGTM, GTMEvents } from 'gtm'
import { useLocalization } from 'locales/i18n'
import { IconWrapper } from 'ui/@library/data-display/icon-wrapper'
import { Button } from 'ui/@library/inputs/button'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import styles from './styles.module.scss'

export const ApplicationSuccess = () => {
  const { t } = useLocalization('kyc.applicationSuccess')
  const { refetchAuthUser, authUser, isEarlyAccessUser } = useAuthUserLoadable()
  const navigate = useNavigate()
  const { sendGTMEvent } = useGTM()

  const handleFlowCompletion = () => {
    refetchAuthUser()
    sendGTMEvent(GTMEvents.SuccessfulKYC, authUser?.uuid)
    if (isEarlyAccessUser) {
      navigate(`${Paths.ONBOARDING.MAIN}${Paths.ONBOARDING.ADD_CARD}`)
    } else {
      navigate(Paths.HOME)
    }
  }

  return (
    <>
      <div className={styles.wrapper}>
        <IconWrapper className={styles.iconWrapper}>
          <Check />
        </IconWrapper>
        <h1 className={styles.title}>{t('title')}</h1>
        <p className={styles.successDescription}>{t('description')}</p>
      </div>
      <Button width='fluid' className={styles.button} onClick={handleFlowCompletion}>
        {t('done')}
      </Button>
    </>
  )
}
