import { useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

import { AccessStatus } from 'types/auth-user'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useAuthUserMasterOpenOrdersLoadable } from 'ui/@store/auth-user-master-open-orders'
import { useMarketOverviewLoadable } from 'ui/@store/market-overview'
import { useMasterLoadable, useMasterStatusLoadable } from 'ui/@store/master'
import { useShareListLoadable } from 'ui/@store/share-list'
import {
  sellableShareListState,
  validateTradeSpecificationState,
  orderTypeState,
  tradeActionTypeState,
  hideSellOptionState,
} from 'ui/@store/trader'
import { useWalletLoadable } from 'ui/@store/wallet'

import { OrderType, TradeActionType, TraderType } from './types'

export const useTrader = (): TraderType => {
  const [sellableShareList, setSellableShareList] = useRecoilState(sellableShareListState)
  const [validateTradeSpecification, setValidateTradeSpecification] = useRecoilState(
    validateTradeSpecificationState,
  )
  const [orderType, setOrderType] = useRecoilState(orderTypeState)
  const [tradeActionType, setTradeActionType] = useRecoilState(tradeActionTypeState)
  const [hideSellOption, setHideSellOption] = useRecoilState(hideSellOptionState)

  const { master } = useMasterLoadable()
  const { isImo } = useMasterStatusLoadable()
  const { authUser } = useAuthUserLoadable()
  const { loading: walletLoading } = useWalletLoadable()
  const {
    marketOverview,
    loading: marketOverviewLoading,
    error: marketOverviewError,
  } = useMarketOverviewLoadable()

  const {
    authUserMasterOpenOrders,
    loading: masterOpenOrdersLoading,
    error: masterOpenOrdersError,
  } = useAuthUserMasterOpenOrdersLoadable()
  const {
    shareList: shareListAuth,
    loading: shareListAuthLoading,
    error: shareListError,
  } = useShareListLoadable({
    isAuthUserShares: true,
  })

  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (
      !shareListAuthLoading &&
      !masterOpenOrdersLoading &&
      !marketOverviewLoading &&
      !walletLoading
    ) {
      setIsLoading(false)
    }
  }, [shareListAuthLoading, masterOpenOrdersLoading, marketOverviewLoading, walletLoading])

  useEffect(() => {
    if (marketOverviewError || masterOpenOrdersError || shareListError) {
      setHasError(true)
    }
  }, [marketOverviewError, masterOpenOrdersError, shareListError])

  useEffect(() => {
    if (!orderType && marketOverview) {
      if (marketOverview.bestBidMaxQuantity) {
        setOrderType(OrderType.BestPrice)
      } else {
        setOrderType(OrderType.Limit)
      }
    }

    const hideSell =
      marketOverview &&
      (!marketOverview?.canSell ||
        (marketOverview?.limitAskMaxQuantity === null &&
          marketOverview?.bestAskMaxQuantity === null))

    setHideSellOption(hideSell ?? false)

    if (tradeActionType === TradeActionType.Sell && hideSell) {
      setTradeActionType(TradeActionType.Buy)
    }
    // eslint-disable-next-line
  }, [marketOverview])

  useEffect(() => {
    if (shareListAuth.length !== 0) {
      const filteredSellableShareList = shareListAuth.filter((share) => share.askPrice === null)
      setSellableShareList(filteredSellableShareList)
    }
    // eslint-disable-next-line
  }, [shareListAuth])

  const numberOfSharesOrdered = useMemo(
    () => authUserMasterOpenOrders.reduce((acc, order) => acc + order.shareOrders.length, 0),
    [authUserMasterOpenOrders],
  )

  const hasAuthUserReachedShareLimit =
    authUser?.accessStatus === AccessStatus.ActiveOpus
      ? numberOfSharesOrdered === master?.imo.maxAvailableShares
      : numberOfSharesOrdered === master?.imo.maxPerUser

  return {
    isImo,
    sellableShareList,
    validateTradeSpecification,
    setValidateTradeSpecification,
    orderType,
    setOrderType,
    tradeActionType,
    setTradeActionType,
    isLoading,
    hasAuthUserReachedShareLimit,
    hideSellOption,
    hasError,
  }
}
