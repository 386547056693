import { MasterWithImoAndMarket } from 'types/master'

import { sanitizeMasterWithImoAndMarketFromRaw } from '../@santizers/master'
import { MxApi } from '../mx-api'

export const fetchMaster = async (masterUuid: string): Promise<MasterWithImoAndMarket> => {
  const { data } = await MxApi.get(`/masters/${masterUuid}`)

  return sanitizeMasterWithImoAndMarketFromRaw(data)
}
