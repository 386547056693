import { useRef, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { useWindowSize } from 'ui/@hooks/use-window-size'
import { Skeleton } from 'ui/@library/feedback/skeleton'

import { VideoPlayerConfig } from './constants'
import styles from './styles.module.scss'
import { VideoPlayerSize, VideoPlayerProps, VideoFormat, VideoPlayerTrigger } from './types'

const isVideo = (fileName: string): fileName is VideoFormat => {
  const fileExtension = fileName?.split('.').pop()
  return (
    fileExtension !== undefined && Object.values(VideoFormat).includes(fileExtension as VideoFormat)
  )
}

export const VideoPlayer = ({
  src,
  placeholder,
  loop = true,
  height = VideoPlayerSize.Fluid,
  className,
  trigger,
  onLoaded,
}: VideoPlayerProps) => {
  const { viewports } = useWindowSize()

  const videoRef = useRef<HTMLVideoElement>(null)
  const [isHovered, setIsHovered] = useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  const [showPlaceholder, setShowPlaceholder] = useState(true)
  const [initialContentLoaded, setInitialContentLoaded] = useState(false)

  const { ref: playVideoRef, inView: playInView } = useInView({
    triggerOnce: true,
    threshold: VideoPlayerConfig.playThreshold,
  })

  const { ref: loadVideoRef, inView: loadInView } = useInView({
    triggerOnce: true,
    rootMargin: viewports.isMobile ? `${VideoPlayerConfig.loadOffset}px` : '0px',
    threshold: viewports.isMobile ? 0 : VideoPlayerConfig.loadThresholdDesktop,
  })

  const ref = (node: HTMLDivElement | null) => {
    playVideoRef(node)
    loadVideoRef(node)
  }

  useEffect(() => {
    if (trigger === VideoPlayerTrigger.InView) {
      setShowPlaceholder(false)
    }

    const updateVideoReadyStatus = () => {
      if (videoRef.current) {
        setIsVideoLoaded(videoRef.current.readyState >= VideoPlayerConfig.readyStateThreshold)
      }
    }

    const handleWaiting = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0
      }
    }

    const stopPropogation = (e: Event) => {
      e.stopPropagation()
    }

    const videoElement = videoRef.current
    if (videoElement) {
      videoElement.addEventListener('canplaythrough', updateVideoReadyStatus)
      videoElement.addEventListener('waiting', handleWaiting)
      videoElement.addEventListener('touchstart', stopPropogation)

      return () => {
        videoElement.removeEventListener('canplaythrough', updateVideoReadyStatus)
        videoElement.removeEventListener('waiting', handleWaiting)
        videoElement.removeEventListener('touchstart', stopPropogation)
        // destroy video element while unmounting
        if (videoElement) {
          videoElement.src = ''
          videoElement.load()
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (loadInView && videoRef.current && videoRef.current.getAttribute('data-src')) {
      videoRef.current.src = videoRef.current.getAttribute('data-src') || ''
      videoRef.current.load()
    }
  }, [loadInView])

  const handleMouseEnter = () => {
    !isHovered && setIsHovered(true)
  }

  const handleMouseLeave = () => {
    isHovered && setIsHovered(false)
  }

  const playVideo = () => {
    if (videoRef.current) {
      try {
        if (videoRef.current.play) {
          videoRef.current.play()
        } else {
          setShowPlaceholder(true)
        }
      } catch {
        setShowPlaceholder(true)
      }
    }
  }

  useEffect(() => {
    if (isVideoLoaded) {
      switch (trigger) {
        case VideoPlayerTrigger.OnLoad:
          setShowPlaceholder(false)
          break
        case VideoPlayerTrigger.OnHover:
          if (isHovered) {
            playVideo()
            setShowPlaceholder(false)
          } else {
            setShowPlaceholder(true)
          }
          break
        case VideoPlayerTrigger.InView:
          if (playInView) playVideo()
          break
        default:
          break
      }
    }
  }, [trigger, isVideoLoaded, isHovered, playInView])

  if (isVideo(src)) {
    return (
      <div
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`${styles[height]} ${className}`}
      >
        {!initialContentLoaded && <Skeleton className={styles.skeleton} />}
        <img
          src={placeholder}
          alt='Video Placeholder'
          className={`${styles.videoPlayerPlaceholder} ${
            showPlaceholder ? styles.visible : styles.hidden
          } ${initialContentLoaded ? styles.hidden : styles.visible}`}
          loading='eager'
          onLoad={() => {
            setInitialContentLoaded(true)
            onLoaded && onLoaded(initialContentLoaded)
          }}
        />
        {
          <video
            autoPlay={trigger !== VideoPlayerTrigger.InView}
            muted
            controls={false}
            preload='none'
            playsInline
            loop={loop}
            className={`${styles.videoPlayer} ${showPlaceholder ? styles.hidden : styles.visible}`}
            ref={videoRef}
            data-src={src}
          />
        }
      </div>
    )
  } else {
    return (
      <div>
        {!initialContentLoaded && <Skeleton className={styles.skeleton} />}
        <img
          src={src}
          alt='static content'
          className={`${styles.videoPlayerPlaceholder} ${styles[height]} ${className} ${
            initialContentLoaded ? styles.visible : styles.hidden
          }`}
          loading='eager'
          onLoad={() => {
            setInitialContentLoaded(true)
            onLoaded && onLoaded(initialContentLoaded)
          }}
        />
      </div>
    )
  }
}
