import { ReactComponent as MasterCard } from 'assets/svgs/mastercard.svg'
import { ReactComponent as Visa } from 'assets/svgs/visa.svg'
import { CreditCardBrand } from 'types/card'

import styles from './styles.module.scss'

type CreditCardBrandingIconProps = {
  brand?: CreditCardBrand | null
}

export const CreditCardBrandingIcon = ({ brand }: CreditCardBrandingIconProps) => {
  switch (brand) {
    case CreditCardBrand.MasterCard:
      return <MasterCard />
    case CreditCardBrand.Visa:
      return <Visa />
    default:
      return (
        <div className={styles.emptyStateCard}>
          <Visa />
          <MasterCard />
        </div>
      )
  }
}
