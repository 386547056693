import { ampli } from 'ampli'
import { Order } from 'constants/trading'
import { useLocalization } from 'locales/i18n'
import { useSellShare } from 'repositories/mx-api'
import { KycStatus } from 'types/auth-user'
import { OrderType } from 'types/order'
import { Loader } from 'ui/@library/feedback/loader'
import { OrderLoading } from 'ui/@library/feedback/order-loading'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useAuthUserMastersOffersReceivedLoadable } from 'ui/@store/auth-user-masters-offers-received'
import { useMasterLoadable } from 'ui/@store/master'
import { useShareLoadable } from 'ui/@store/share'
import { useShareListLoadable } from 'ui/@store/share-list'
import { useWalletLoadable } from 'ui/@store/wallet'

import { ReviewKyc } from '../../../../@modals/kyc/review-kyc'
import { TradeActionType } from '../../master-overview/@components/banner-trader/types'
import { OrderCompleted } from '../order-completed'
import { OrderFailed } from '../order-failed'

import { OrderContainer } from './@components/order-container'
import { OrderHandler } from './@components/order-handler'
import { OrderHeader } from './@components/order-header'
import {
  OrderQuantity,
  OrderSpecification,
  ServiceFee,
  SharePrice,
  OrderAmount,
} from './@components/order-specification'
import { ShareList } from './@components/share-list'
import styles from './styles.module.scss'

export type ReviewAcceptBidProps = {
  masterUuid: string
  shareUuid: string
}

export const ReviewAcceptBid = () => {
  const { t } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation',
  )
  const { refetch: refetchOffersReceived } = useAuthUserMastersOffersReceivedLoadable()
  const { payload: modalPayload, renderModal } = useGlobalModal()
  const { share, loading: isShareLoading } = useShareLoadable(modalPayload?.shareUuid, {
    forceRefetch: true,
  })

  const { master, loading: isMasterLoading } = useMasterLoadable(modalPayload?.masterUuid)
  const {
    wallet,
    refetch: refetchWallet,
    loading: walletLoading,
  } = useWalletLoadable({ forceRefetch: true })

  const { authUser } = useAuthUserLoadable()
  const isKycValidated = authUser?.kycStatus === KycStatus.Validated

  if (!isKycValidated)
    renderModal({
      modalKey: ModalKey.ReviewKyc,
      content: <ReviewKyc />,
    })

  const { refetch: refetchAuthUserShareList } = useShareListLoadable({
    isAuthUserShares: true,
  })

  const { refetch: refetchShareList } = useShareListLoadable({
    isAuthUserShares: false,
  })

  const tradePrice = share?.highestBid?.price || 0
  const accountBalance = wallet?.primaryBalance
  const serviceFee = -Math.floor(Order.SERVICE_CHARGE.IN_PERCENT * tradePrice) / 100
  const isServiceFeeBelowMinimum = Math.abs(serviceFee) < Order.SERVICE_CHARGE.MINIMUM_PER_SHARE
  const finalServiceFee = isServiceFeeBelowMinimum
    ? -Order.SERVICE_CHARGE.MINIMUM_PER_SHARE
    : serviceFee
  const totalOrderAmount = finalServiceFee + tradePrice

  const {
    requestSellShare: requestAcceptBid,
    loading: acceptBidLoading,
    error: acceptBidError,
  } = useSellShare(master?.uuid ?? '', share?.uuid ?? '', tradePrice, true)

  const handleAcceptBid = async () => {
    await requestAcceptBid()
    if (acceptBidError) {
      renderModal({
        modalKey: ModalKey.OrderFailed,
        content: <OrderFailed />,
      })
    } else {
      ampli.acceptUniqueBuyOfferSuccess({
        masterName: master?.name || '',
        masterUuid: master?.uuid || '',
      })
      refetchWallet()
      refetchAuthUserShareList()
      refetchShareList()
      refetchOffersReceived()
      renderModal({
        modalKey: ModalKey.OrderCompleted,
        content: <OrderCompleted />,
        payload: {
          tradeActionType: TradeActionType.Accept,
          orderType: OrderType.AcceptOffer,
        },
      })
    }
  }

  if (isShareLoading || isMasterLoading || walletLoading || !share) {
    return (
      <ModalContent>
        <Loader />
      </ModalContent>
    )
  }

  if (acceptBidLoading) {
    return (
      <ModalContent>
        <OrderLoading />
      </ModalContent>
    )
  }

  return (
    <ModalContent className={styles.reviewOrderContainer}>
      <OrderContainer>
        <OrderHeader title={t('title.accept')} accountBalance={accountBalance} />
        <OrderSpecification>
          <OrderQuantity quantity={1} />
          <SharePrice title={t('price.market')} price={tradePrice} />
          <OrderAmount title={t('total.withoutFees')} amount={tradePrice} />
          <ServiceFee title={t('serviceFee')} serviceFee={finalServiceFee} />
          <hr className={styles.dividerHorizontal} />
          <OrderAmount title={t('total.withFees')} amount={totalOrderAmount} highlight />
        </OrderSpecification>
        <OrderHandler
          title={t('buttons.confirmBid')}
          handleOrderAction={handleAcceptBid}
          isLoading={acceptBidLoading || walletLoading}
          isDisabled={!accountBalance}
        />
      </OrderContainer>
      <hr className={styles.dividerVertical} />
      <ShareList
        tradeQuantity={1}
        tradePrice={tradePrice}
        sharesToTrade={[
          {
            price: share.askPrice,
            tokenId: share.tokenId,
            uuid: share.uuid,
          },
        ]}
      />
    </ModalContent>
  )
}
