import { ReactComponent as AddCardSVG } from 'assets/svgs/add-card-onboarding.svg'
import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'

import { AddCardStepProps } from '../../types'

import styles from './styles.module.scss'

export const AddCardDisclaimer = ({ steps, setCurrentStep, onSkip }: AddCardStepProps) => {
  const { t } = useLocalization('onboarding.addCard')

  return (
    <>
      <div className={styles.contentWrapper}>
        <h1 className={styles.header}>{t('disclaimer.heading')}</h1>
        <div className={styles.mainContent}>
          <div className={styles.wireframeContainer}>
            <AddCardSVG />
          </div>
          <span className={styles.description}>{t('disclaimer.description')}</span>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button width='fluid' onClick={() => setCurrentStep(steps.addCardForm)}>
          {t('continue')}
        </Button>
        <Button width='fluid' variant='ghost' onClick={onSkip}>
          {t('skipForNow')}
        </Button>
      </div>
    </>
  )
}
