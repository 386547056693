import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { useLocalization } from 'locales/i18n'
import { RadioButton } from 'ui/@library/inputs/radio-button'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { CardDetails } from 'ui/@library/utils/card-details'
import { PaymentMethodModal } from 'ui/@modals/add-funds'
import { AddCardModal } from 'ui/@modals/add-funds/@components/add-card-modal'
import { AddFundsConfirm } from 'ui/@modals/add-funds/@components/add-funds-confirm'
import { CardLimitModal } from 'ui/@modals/add-funds/@components/card-limit'
import { CardOptionsModal } from 'ui/@modals/add-funds/@components/card-options-modal'
import { useCardsLoadable } from 'ui/@store/cards'

import styles from './styles.module.scss'

type CardSelectorProps = {
  cardLimitCallbackModal: ModalKey.AddFundsConfirm | ModalKey.CardOptionsModal
  validateCard?: boolean
  addCardSuccessCallback?: () => void
}

export const CardSelector = ({
  cardLimitCallbackModal,
  validateCard,
  addCardSuccessCallback,
}: CardSelectorProps) => {
  const { t } = useLocalization('addFunds.confirm')
  const { renderModal } = useGlobalModal()
  const { cards, selectedCard, setSelectedCard } = useCardsLoadable()

  const handleNavigateToPaymentMethodModal = () => {
    renderModal({ modalKey: ModalKey.PaymentMethod, content: <PaymentMethodModal /> })
  }

  const handleBackNavigation = () => {
    switch (cardLimitCallbackModal) {
      case ModalKey.AddFundsConfirm:
        renderModal({
          modalKey: ModalKey.AddFundsConfirm,
          content: <AddFundsConfirm />,
          navigateBack: handleNavigateToPaymentMethodModal,
        })
        break
      case ModalKey.CardOptionsModal:
        renderModal({
          modalKey: ModalKey.CardOptionsModal,
          content: <CardOptionsModal />,
          navigateBack: handleNavigateToPaymentMethodModal,
        })
        break
    }
  }

  const handleAddCard = () => {
    if (cards.length >= 2) {
      renderModal({
        modalKey: ModalKey.CardLimit,
        content: <CardLimitModal />,
        navigateBack: handleBackNavigation,
        payload: {
          backNavigationCallback: handleBackNavigation,
        },
      })
    } else {
      renderModal({
        modalKey: ModalKey.AddCard,
        content: <AddCardModal />,
        navigateBack: handleBackNavigation,
        payload: {
          onSuccessCallback: () => {
            if (addCardSuccessCallback) {
              addCardSuccessCallback()
            } else {
              handleNavigateToPaymentMethodModal()
            }
          },
          validateCard,
        },
      })
    }
  }

  return (
    <>
      <div className={styles.cardsContainer}>
        {cards.length > 0 &&
          cards.map((card, index) => (
            <RadioButton
              key={index}
              name='payment-card'
              value={`card-${index}`}
              onChange={() => setSelectedCard(card)}
              checked={card.id === selectedCard?.id}
            >
              <CardDetails card={card} showValidateButton />
            </RadioButton>
          ))}
      </div>

      <div className={styles.addNewCard} onClick={handleAddCard}>
        <PlusIcon className={styles.plusIcon} />
        <p>{t('addCard')}</p>
      </div>
    </>
  )
}
