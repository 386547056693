import { useAuth0 } from '@auth0/auth0-react'
import { Browser } from '@capacitor/browser'

export const useSignInAndSignUp = () => {
  const { loginWithRedirect } = useAuth0()

  const signIn = async () => {
    await loginWithRedirect({
      appState: { returnTo: window.location.pathname },
      authorizationParams: {
        screen_hint: 'signin',
        prompt: 'select_account',
      },
      async openUrl(url) {
        await Browser.open({ url, windowName: '_self' })
      },
    })
  }

  const signUp = async (email?: string) => {
    await loginWithRedirect({
      appState: { returnTo: window.location.pathname },
      authorizationParams: {
        screen_hint: 'signup',
        ...(email ? { login_hint: email } : {}),
      },
      async openUrl(url) {
        await Browser.open({ url, windowName: '_self' })
      },
    })
  }

  return {
    signIn,
    signUp,
  }
}
