import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share'

import { ampli } from 'ampli'
import { ReactComponent as EmailIcon } from 'assets/icons/email-icon.svg'
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook-footer.svg'
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin-footer.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/x.svg'
import { useLocalization } from 'locales/i18n'
import { useMasterLoadable } from 'ui/@store/master'

import {
  ClientType,
  ShareMethod,
  UtmCampaign,
  UTM_MEDIUM,
  UTM_SOURCE,
  TWITTER_HANDLE_TAG,
} from '../../constants'
import { getUtmUrl, getShareableCaption } from '../../utils'

import styles from './styles.module.scss'

type ShareButtonsProps = {
  url: string
}

export const ShareButtons = ({ url }: ShareButtonsProps) => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader.modals.socialShare')
  const { master } = useMasterLoadable()

  if (!master) return null

  const caption = getShareableCaption(master)

  const handleShare = () => {
    ampli.shareOnSocialMedia({
      clientType: ClientType.Desktop,
      method: ShareMethod.ReactShare,
    })
  }

  const getUrlWithUtm = (UtmCampaign: UtmCampaign) => {
    return getUtmUrl(url, {
      utm_source: UTM_MEDIUM,
      utm_medium: UTM_SOURCE,
      utm_campaign: UtmCampaign,
    })
  }

  return (
    <div className={styles.shareButtons}>
      <FacebookShareButton
        url={getUrlWithUtm(UtmCampaign.Facebook)}
        onClick={handleShare}
        quote={caption}
      >
        <div className={styles.shareIcon}>
          <FacebookIcon className={styles.facebookIcon} />
          <p className={styles.shareButtonLabel}>{t('socialMedia.facebook')}</p>
        </div>
      </FacebookShareButton>
      <TwitterShareButton
        url={getUrlWithUtm(UtmCampaign.Twitter)}
        className={styles.shareIcon}
        onClick={handleShare}
        title={getShareableCaption(master, TWITTER_HANDLE_TAG)}
      >
        <div className={styles.shareIcon}>
          <TwitterIcon className={styles.twitterIcon} />
          <p className={styles.shareButtonLabel}>{t('socialMedia.twitter')}</p>
        </div>
      </TwitterShareButton>
      <LinkedinShareButton
        url={getUrlWithUtm(UtmCampaign.Linkedin)}
        className={styles.shareIcon}
        onClick={handleShare}
        summary={caption}
      >
        <div className={styles.shareIcon}>
          <LinkedinIcon className={styles.linkedinIcon} />
          <p className={styles.shareButtonLabel}>{t('socialMedia.linkedin')}</p>
        </div>
      </LinkedinShareButton>
      <EmailShareButton
        url={getUrlWithUtm(UtmCampaign.Email)}
        className={styles.shareIcon}
        onClick={handleShare}
        body={caption}
      >
        <div className={styles.shareIcon}>
          <EmailIcon />
          <p className={styles.shareButtonLabel}>{t('socialMedia.email')}</p>
        </div>
      </EmailShareButton>
    </div>
  )
}
