import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchAuthUserOpenOrders } from 'repositories/mx-api'

import { authUserState } from './auth-user'

const authUserOpenOrdersQuery = selector({
  key: 'authUserOpenOrdersQuery',
  get: async ({ get }) => {
    const authUser = get(authUserState)
    return authUser ? fetchAuthUserOpenOrders() : []
  },
})

export const useAuthUserOpenOrdersLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(authUserOpenOrdersQuery)
  const refetch = useRecoilRefresher_UNSTABLE(authUserOpenOrdersQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    authUserOpenOrders: state === 'hasValue' ? contents : [],
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
