import gtm from 'react-gtm-module'

import { GTMEvents, GTMEventArgs } from './types'

const useGTM = () => {
  const sendGTMEvent = (event: GTMEvents, userId?: string): void => {
    const gtmEventArgs: GTMEventArgs = { dataLayer: { event } }
    if (userId) gtmEventArgs.dataLayer.userId = userId
    gtm.dataLayer(gtmEventArgs)
  }

  return { sendGTMEvent }
}

export { useGTM, GTMEvents }
