import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useMarketOverviewLoadable } from 'ui/@store/market-overview'
import { TradeActionType } from 'ui/master/@components/master-overview/@components/banner-trader/types'
import { useTrader } from 'ui/master/@components/master-overview/@components/banner-trader/use-trader'

import { TraderModal } from '../../../trader-modal'
import styles from '../../styles.module.scss'

type SellButtonProps = {
  setIsMenuOpen: (isMenuOpen: boolean) => void
}

export const SellButton = ({ setIsMenuOpen }: SellButtonProps) => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader')
  const { renderModal } = useGlobalModal()
  const { setTradeActionType } = useTrader()
  const { marketOverview } = useMarketOverviewLoadable()

  const handleSell = () => {
    setIsMenuOpen(false)
    setTradeActionType(TradeActionType.Sell)
    renderModal({
      content: <TraderModal />,
      enforceAuth: true,
      modalKey: ModalKey.Trader,
    })
  }

  if (!marketOverview?.canSell) return null

  return (
    <Button className={styles.sell} onClick={handleSell} variant='danger'>
      {t('market.title.sell')}
    </Button>
  )
}
