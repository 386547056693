import { useEffect, useState } from 'react'

import { ViewportWidth } from 'constants/layout'

type WindowDimensions = { width: number; height: number }

type WindowSize = {
  windowSize: WindowDimensions
  viewports: {
    isMobile: boolean
    isTablet: boolean
    isDesktop: boolean
  }
}

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const getWindowSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', getWindowSize)

    return () => {
      window.removeEventListener('resize', getWindowSize)
    }
  }, [])

  const viewports = {
    isMobile: windowSize.width < ViewportWidth.XS,
    isTablet: windowSize.width < ViewportWidth.SM,
    isDesktop: windowSize.width >= ViewportWidth.SM,
  }

  return { windowSize, viewports }
}
