import { useLocalization } from 'locales/i18n'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { Divider } from 'ui/@library/layout/divider'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { PaymentMethodModal } from 'ui/@modals/add-funds'
import { useWalletLoadable } from 'ui/@store/wallet'

import styles from './styles.module.scss'

export const AccountBalance = () => {
  const { t, f } = useLocalization('master.overviewTab.sidebar.trader')
  const { renderModal } = useGlobalModal()
  const { wallet } = useWalletLoadable()
  const { isAuthenticated } = useMxAuth()

  const accountBalance = wallet?.primaryBalance

  if (!isAuthenticated) return null

  return (
    <div className={styles.accountBalanceContainer}>
      <Divider />
      <p
        className={styles.balance}
        onClick={() => {
          renderModal({ modalKey: ModalKey.PaymentMethod, content: <PaymentMethodModal /> })
        }}
      >{`${t('balance')}: ${f.currency(accountBalance ?? NaN)}`}</p>
    </div>
  )
}
