import { MasterOwnersWithAvatar, MasterWithImoAndMarket } from 'types/master'
import { getOwnersFromOwnerList } from 'ui/master/utils'

import { IMAGE_FORMAT, IMAGE_QUALITY, X_OFFSET, Y_OFFSET, Y_OFFSET_INCREMENT } from './constants'
import shareableAssetTemplate from './shareable-asset-template.svg'
import { SvgReplacements, UtmParams } from './types'

// Load an image from a given URL.
async function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = (error) => reject(error)
    img.crossOrigin = 'anonymous'
    img.decoding = 'async'
    img.src = url
  })
}

// Convert an image URL to a data URL. Optionally resize the image for optimization.
async function getImageDataURL(
  imageUrl: string,
  format = IMAGE_FORMAT,
  quality = IMAGE_QUALITY,
  width: number,
): Promise<string> {
  const img = await loadImage(imageUrl)

  if (!img.complete || img.naturalWidth === 0) {
    throw new Error('Image did not load successfully.')
  }

  const targetWidth = Math.min(img.width, width)
  const targetHeight = img.height * (targetWidth / img.width)

  const canvas = document.createElement('canvas')
  canvas.width = targetWidth
  canvas.height = targetHeight
  const ctx = canvas.getContext('2d')

  if (ctx) {
    ctx.drawImage(img, 0, 0, targetWidth, targetHeight)
  } else {
    throw new Error('Canvas context could not be obtained.')
  }

  return canvas.toDataURL(format, quality)
}

// Convert a loaded image element into a data URL.
export async function getImageDataURLFromImage(image: HTMLImageElement): Promise<string> {
  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  const ctx = canvas.getContext('2d')

  if (ctx) {
    ctx.drawImage(image, 0, 0)
  }

  return canvas.toDataURL('image/jpeg')
}

// Generate a string representation of the master owners' names.
const generateTextContent = (masterOwners: MasterOwnersWithAvatar) => {
  const ownersList = masterOwners.map((owner) => owner.name)
  const numOwners = ownersList.length

  if (numOwners === 0) return ''
  if (numOwners === 1) return ownersList[0]

  const otherOwners = ownersList.slice(0, numOwners - 1).join(', ')
  const lastOwner = ownersList[numOwners - 1]

  return `${replaceSpecialCharacters(`${otherOwners} and ${lastOwner}`)}`
}

// Create an SVG group for each master owner avatar.
async function generateAvatarGroup(
  masterOwner: MasterOwnersWithAvatar[number],
  index: number,
  startingPoint: number,
) {
  const svgNamespace = 'http://www.w3.org/2000/svg'

  if (!masterOwner || !masterOwner.avatarUrl) {
    return null
  }

  const xOffset = startingPoint + index * 60
  // use size of the avatar
  const cX = xOffset + 48
  const avatarDataUrl = await getImageDataURL(
    masterOwner.avatarUrl,
    IMAGE_FORMAT,
    IMAGE_QUALITY,
    48,
  )

  const group = document.createElementNS(svgNamespace, 'g')
  const circle = document.createElementNS(svgNamespace, 'circle')
  // use half the size of avatar
  circle.setAttribute('cx', (cX - 24).toString())
  circle.setAttribute('cy', '100')
  // use half the size of avatar
  circle.setAttribute('r', '24')
  circle.setAttribute('fill', '#FFFFFF')
  group.appendChild(circle)

  const image = document.createElementNS(svgNamespace, 'image')
  image.setAttribute('x', xOffset.toString())
  // use cX-radius
  image.setAttribute('y', '76')
  // size of avatar's image
  image.setAttribute('width', '48')
  image.setAttribute('height', '48')
  image.setAttribute('href', avatarDataUrl)
  image.setAttribute('class', 'circle-image')
  // clip path at radius
  image.setAttribute('clip-path', 'circle(24px at center)')
  group.appendChild(image)

  return group
}

// Generate SVG content that includes dynamically created master owner avatars.
export const generateDynamicAvatarSvg = async (
  masterOwners: MasterOwnersWithAvatar,
): Promise<string | undefined> => {
  const svgNamespace = 'http://www.w3.org/2000/svg'
  const svgWidth = masterOwners.length * 60 + 300
  const svgHeight = 48
  const startingPoint = masterOwners.length > 2 ? X_OFFSET - masterOwners.length * 10 : X_OFFSET

  const parser = new DOMParser()
  const response = await fetch(shareableAssetTemplate)
  const shareableAssetTemplateText = await response.text()
  const svgDoc = parser.parseFromString(shareableAssetTemplateText, 'image/svg+xml')
  const avatarsGroup = svgDoc.querySelector('.avatars')

  if (!avatarsGroup) {
    return ''
  }

  avatarsGroup.setAttribute('width', `${svgWidth}`)
  avatarsGroup.setAttribute('height', `${svgHeight}`)

  const avatarPromises = masterOwners.map(async (masterOwner, index) => {
    return generateAvatarGroup(masterOwner, index, startingPoint)
  })

  const avatarGroups = await Promise.all(avatarPromises)
  avatarGroups.forEach((group) => group && avatarsGroup.appendChild(group))

  // Generate and add text content to the SVG.
  const text = document.createElementNS(svgNamespace, 'text')
  text.setAttribute('fill', 'white')
  text.setAttribute('font-family', 'Inter, sans-serif')
  text.setAttribute('font-size', '16.67')
  text.setAttribute('font-weight', '600')
  text.setAttribute('letter-spacing', '0.02em')
  const textSpan = document.createElementNS(svgNamespace, 'tspan')
  textSpan.textContent =
    masterOwners.length === 1
      ? `${generateTextContent(masterOwners)} brings you`
      : masterOwners.length > 1
      ? `${generateTextContent(masterOwners)} bring you`
      : ''
  textSpan.setAttribute('x', (masterOwners.length * 60 + startingPoint).toString())
  // cy+5
  textSpan.setAttribute('y', '105')
  text.appendChild(textSpan)

  avatarsGroup.appendChild(text)

  const existingSvg = svgDoc.querySelector('svg')
  existingSvg && existingSvg.appendChild(avatarsGroup)

  // Return the modified SVG as a string.
  const modifiedSvgString = new XMLSerializer().serializeToString(svgDoc)
  return modifiedSvgString
}

// Replace special characters with their SVG compatible entities.
function replaceSpecialCharacters(text: string): string {
  return text.replace(
    /[&<>"']/g,
    (match) =>
      ({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&apos;',
      }[match as '&' | '<' | '>' | '"' | "'"]),
  )
}

// Insert line breaks
function insertLineBreak(input: string, maxLength = 20): string[] {
  const lines: string[] = []
  let currentIndex = 0

  while (currentIndex < input.length) {
    let breakIndex =
      currentIndex + maxLength < input.length
        ? input.lastIndexOf(' ', currentIndex + maxLength)
        : input.length
    if (breakIndex <= currentIndex) {
      breakIndex = Math.min(currentIndex + maxLength, input.length)
    }
    lines.push(input.substring(currentIndex, breakIndex))
    currentIndex = breakIndex + 1
  }
  return lines
}

function handleMasterNameinSVG(masterName: string): [string, number] {
  const splitName = insertLineBreak(masterName, 20)
  let tspanElements = ''
  let yPos = Y_OFFSET

  splitName.forEach((line) => {
    tspanElements += `<tspan x="${X_OFFSET}" y="${yPos}">${line}</tspan>`
    yPos += Y_OFFSET_INCREMENT
  })
  return [tspanElements, yPos]
}

// Fill the SVG template with specific content like the thumbnail, master's name, and artist's name.
async function generateContentFilledSvg(
  thumbnail: string,
  masterName: string,
  artistName: string,
  masterOwners: MasterOwnersWithAvatar,
): Promise<string> {
  const dataURLThumbnail = await getImageDataURL(thumbnail, IMAGE_FORMAT, IMAGE_QUALITY, 380)
  const svgData = await generateDynamicAvatarSvg(masterOwners)

  if (!svgData) {
    return ''
  }

  masterName = replaceSpecialCharacters(masterName)
  artistName = replaceSpecialCharacters(artistName)

  const [tspanElements, yPos] = handleMasterNameinSVG(masterName)

  const replacements: SvgReplacements = {
    '{{masterName}}': tspanElements,
    '{{artistName}}': artistName,
    '{{artistNamePosition}}': yPos,
    '{{thumbnail}}': dataURLThumbnail,
  }

  const filledSvgData = svgData.replace(
    /{{masterName}}|{{artistName}}|{{artistNamePosition}}|{{thumbnail}}/g,
    (match: string) => {
      const replacement = replacements[match as keyof SvgReplacements]
      return typeof replacement === 'number' ? replacement.toString() : replacement || ''
    },
  )

  return filledSvgData
}

// Generate a data URL for the final image after filling the SVG with all the dynamic content.
export async function generateImageLink(
  thumbnail: string,
  masterName: string,
  artistName: string,
  masterOwners: MasterOwnersWithAvatar,
): Promise<HTMLImageElement> {
  const svgFinal = await generateContentFilledSvg(thumbnail, masterName, artistName, masterOwners)
  const svgBlob = new Blob([svgFinal], { type: 'image/svg+xml' })
  const svgImageURL = URL.createObjectURL(svgBlob)

  const img = await loadImage(svgImageURL)

  return img
}

// construct utm param url
export function getUtmUrl(base: string, utmParams: UtmParams) {
  const url = new URL(base)
  for (const [key, value] of Object.entries(utmParams)) {
    url.searchParams.append(key, value)
  }
  return url.toString()
}

// get shareable caption
export const getShareableCaption = (master: MasterWithImoAndMarket, tag = '') => {
  const hasOwners = master.metaData.ownedByWithAvatars.length
  const ownerCaption = hasOwners
    ? `brought by ${getOwnersFromOwnerList(master.metaData.ownedByWithAvatars)}`
    : ''
  return `I just invested in the master royalties of ${master.name} by ${master.artist.name} ${ownerCaption}${tag}. Check out the song here: `
}
