import { CellContext, Row } from '@tanstack/react-table'
import { forwardRef, useMemo } from 'react'

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg'
import { useLocalization } from 'locales/i18n'
import { Order, OrderSide } from 'types/order'
import { Table } from 'ui/@library/data-display/table'
import { useColumnHelper } from 'ui/@library/data-display/table/use-table'
import { Loader } from 'ui/@library/feedback/loader'
import {
  AccordionContainer as Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from 'ui/@library/layout/accordion'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { CancelOrderModal, CancelType } from 'ui/@modals/cancel-order-modal'
import { useAuthUserOpenOrdersLoadable } from 'ui/@store/auth-user-open-orders'

import { AccordionType } from '../../../../types'
import { MobileTable } from '../../../mobile-table'
import { StatisticType } from '../../../mobile-table/types'
import { ListFractionsModal } from '../../../modals/list-fractions-modal'
import { TableAccordionHeading } from '../table-accordion-heading'

import styles from './styles.module.scss'
import { OpenOrdersTableColumns } from './types'

type OpenOrdersTableProps = {
  isAccordionOpen: string
  setIsAccordionOpen: (isAccordionOpen: string) => void
}

// eslint-disable-next-line
export const OpenOrdersTable = forwardRef<HTMLDivElement, OpenOrdersTableProps>(
  ({ isAccordionOpen, setIsAccordionOpen }, ref) => {
    const { renderModal } = useGlobalModal()
    const { t, f } = useLocalization('myAssets.overview.openOrdersTable')
    const { authUserOpenOrders, loading } = useAuthUserOpenOrdersLoadable()

    const getOrderType = (orderType: OrderSide) => {
      switch (orderType) {
        case OrderSide.Ask:
          return t('columns.type.ask')
        case OrderSide.Bid:
          return t('columns.type.bid')
        case OrderSide.ImoAsk:
          return t('columns.type.imoAsk')
        case OrderSide.ImoBid:
          return t('columns.type.imoBid')
        default:
          return '--'
      }
    }

    const showAmount = (cell: CellContext<OpenOrdersTableColumns, unknown>) => {
      return <span>{f.currency(cell.getValue() as number)}</span>
    }

    const tableData = useMemo(() => {
      return authUserOpenOrders.map((order: Order): OpenOrdersTableColumns => {
        const ids = order.shareOrders.every((shareOrder) => !shareOrder.share)
          ? '-'
          : order.shareOrders
              .map((shareOrder) => (shareOrder.share ? `#${shareOrder.share.tokenId}` : '-'))
              .join(', ')

        const totalPrice =
          order.shareOrders.length !== 0 && order.shareOrders[0]
            ? order.shareOrders[0]?.price * order.shareOrders.length
            : 0

        const shares = order.shareOrders.every((shareOrder) => shareOrder.share)
          ? order.shareOrders.map((shareOrder) => shareOrder.share)
          : []

        return {
          uuid: order.uuid,
          thumbnail: order.master.assets.thumbnail,
          masterName: order.master.name,
          artistName: order.master.artist.name,
          type: getOrderType(order.orderSide),
          ids: ids,
          quantity: order.shareOrders.length,
          price: order.shareOrders[0]?.price || NaN,
          totalPrice: totalPrice,
          masterUuid: order.master.uuid,
          shares: shares,
          isCancellable: order.isCancellable,
        }
      })
      // eslint-disable-next-line
    }, [authUserOpenOrders])

    const showCancelOrderModal = (bidData: OpenOrdersTableColumns) => {
      if (bidData.isCancellable) {
        renderModal({
          modalKey: ModalKey.CancelOrderModal,
          content: <CancelOrderModal />,
          enforceAuth: true,
          payload: {
            type: CancelType.OpenOrder,
            orderUuid: bidData.uuid,
          },
        })
      }
    }

    const showFractionsModal = (rowData: Row<OpenOrdersTableColumns> | OpenOrdersTableColumns) => {
      const payload = 'shares' in rowData ? rowData : rowData.original
      renderModal({
        modalKey: ModalKey.ListFractions,
        content: <ListFractionsModal />,
        payload,
      })
    }

    const cancelOrderBtn = (cell: CellContext<OpenOrdersTableColumns, unknown>) => {
      if (cell.row.original.isCancellable) {
        return (
          <BinIcon
            onClick={(e) => {
              e.stopPropagation()
              showCancelOrderModal(cell.row.original)
            }}
          />
        )
      } else {
        return <></>
      }
    }

    const showThumbnail = (cell: CellContext<OpenOrdersTableColumns, unknown>) => {
      return <img className={styles.thumbnailImg} src={cell.getValue() as string} alt='' />
    }

    const columnDefinition = [
      {
        id: 'thumbnail',
        className: styles.thumbnail,
        cell: showThumbnail,
      },
      {
        id: 'masterName',
        label: t('columns.song'),
        className: styles.song,
      },
      {
        id: 'artistName',
        label: t('columns.artist'),
        className: styles.artist,
      },
      {
        id: 'type',
        label: t('columns.type.title'),
        className: styles.type,
        type: StatisticType.OrderType,
      },
      {
        id: 'ids',
        label: t('columns.id'),
        className: styles.id,
      },
      {
        id: 'quantity',
        label: t('columns.quantity'),
        className: styles.quantity,
        type: StatisticType.Text,
      },
      {
        id: 'price',
        label: t('columns.price'),
        className: styles.price,
        type: StatisticType.Price,
        cell: showAmount,
      },
      {
        id: 'totalPrice',
        label: t('columns.totalPrice'),
        className: styles.totalPrice,
        type: StatisticType.Price,
        cell: showAmount,
      },
      {
        id: 'uuid',
        className: styles.btnWrapper,
        cell: cancelOrderBtn,
        isDisplayColumn: true,
      },
    ]

    const columns = useColumnHelper<OpenOrdersTableColumns>(columnDefinition)

    if (loading) return <Loader />

    return (
      <div ref={ref}>
        <Accordion setValue={setIsAccordionOpen} value={isAccordionOpen}>
          <AccordionItem value={AccordionType.OpenOrders}>
            <AccordionHeader>
              <TableAccordionHeading title={t('title')} count={tableData.length} />
            </AccordionHeader>
            <AccordionContent>
              <Table
                data={tableData}
                columns={columns}
                rowOnClick={showFractionsModal}
                hideInMobile
              />
              <MobileTable
                columns={columnDefinition}
                tableData={tableData}
                rowOnClick={showFractionsModal}
                buttonElement={<BinIcon />}
                buttonOnClick={(order: OpenOrdersTableColumns) => {
                  showCancelOrderModal(order)
                }}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    )
  },
)
