import { formatDistance } from 'date-fns'

export const getCountDownTime = (date?: string) => {
  if (!date) return '--'
  const dateDistance = formatDistance(new Date(), new Date(date), { addSuffix: false })
  const formatedDist =
    dateDistance.split(' ')[0] === 'about'
      ? dateDistance.split(' ').splice(1, 2).join(' ')
      : dateDistance
  return formatedDist
}
