import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchDiscover } from 'repositories/mx-api'
import { Discover } from 'types/discover'

const discoverQuery = selector<Discover>({
  key: 'discoverQuery',
  get: async () => fetchDiscover(),
})

export const useDiscoverLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(discoverQuery)

  const refetch = useRecoilRefresher_UNSTABLE(discoverQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    discover: state === 'hasValue' ? contents : { imos: [], masters: [], comingSoon: [] },
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
