import { useMasterStatusLoadable } from 'ui/@store/master'
import { MasterStatus } from 'ui/master/types'

import { ImoStatistics } from './@components/imo-statistics'
import { PriceGraph } from './@components/price-graph'
import { RoyaltyYield } from './@components/yield'
import styles from './styles.module.scss'

export const Statistics = () => {
  const { masterStatus } = useMasterStatusLoadable()

  return (
    <div className={styles.statisticsContainer}>
      {masterStatus === MasterStatus.Market ? <PriceGraph /> : <ImoStatistics />}
      {masterStatus === MasterStatus.Imo && <RoyaltyYield />}
    </div>
  )
}
