import { captureException } from '@sentry/react'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { RouteWrapper } from 'ui/@components/route-wrapper'

import { Crash } from '../crash'

export const Auth0Error = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { t } = useLocalization('feedback.crash')

  useEffect(() => {
    const errorType = searchParams.get('error')
    const errorDescription = searchParams.get('error_description')
    const errorMessage = `Auth0 ${errorType}: ${errorDescription}`

    if (errorType) {
      captureException(new Error(errorMessage), {
        extra: { errorType, errorDescription },
      })
      navigate(Paths.ERROR, { replace: true })
    }

    // eslint-disable-next-line
  }, [])

  return (
    <RouteWrapper title={t('header')}>
      <Crash />
    </RouteWrapper>
  )
}
