import { useLocalization } from 'locales/i18n'
import { MasterWithImoAndMarket } from 'types/master'

import styles from './styles.module.scss'

type PriceChangeProps = {
  master: MasterWithImoAndMarket
}

export const PriceChange = ({ master }: PriceChangeProps) => {
  const { f } = useLocalization()

  const { imo, market } = master

  const priceChange = market.lastPrice - imo.price
  const percentageChange = (priceChange / imo.price) * 100

  const formattedPriceChange = f.currency(priceChange, { showSign: true })
  const formattedPercentageChange = f.percentage(percentageChange, { showSign: true })

  const getPriceChangeStyle = () => {
    if (priceChange > 0) return styles.positive
    if (priceChange < 0) return styles.negative
    return styles.neutral
  }
  return (
    <div className={styles.change}>
      <p className={getPriceChangeStyle()}>
        <span className={styles.priceChange}>{formattedPriceChange} </span>
        <span className={styles.percentageChangeDesktop}>{`(${formattedPercentageChange})`}</span>
        <span className={styles.percentageChangeMobile}>{formattedPercentageChange}</span>
      </p>
    </div>
  )
}
