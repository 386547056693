import { useNavigate } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { KycStatus } from 'types/auth-user'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import {
  SectionContainer,
  SectionHeader,
  SectionTitle,
  SectionButton,
  SectionDescription,
  SectionStatus,
} from '../section'

export const Kyc = () => {
  const { t } = useLocalization('account.kyc')
  const { authUser } = useAuthUserLoadable()

  const navigate = useNavigate()

  const isKycRequired =
    authUser?.kycStatus === KycStatus.NotCreated ||
    authUser?.kycStatus === KycStatus.Created ||
    authUser?.kycStatus === KycStatus.Refused ||
    authUser?.kycStatus === KycStatus.OutOfDate

  const handleKyc = () => {
    if (!isKycRequired) return
    else navigate(Paths.KYC)
  }

  const getKycStatusLabel = () => {
    switch (authUser?.kycStatus) {
      case KycStatus.NotCreated:
      case KycStatus.Created:
        return <SectionStatus>{t('incomplete')}</SectionStatus>
      case KycStatus.ValidationAsked:
        return <SectionStatus>{t('pending')}</SectionStatus>
      case KycStatus.Refused:
        return <SectionStatus type='refused'>{t('refused')}</SectionStatus>
      default:
        return null
    }
  }

  if (authUser?.kycStatus === KycStatus.Validated) return null

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{t('title')}</SectionTitle>
        {authUser?.kycStatus !== KycStatus.ValidationAsked && (
          <SectionButton onClick={handleKyc}>{t('buttonText')}</SectionButton>
        )}
      </SectionHeader>
      <SectionDescription>{t('description')}</SectionDescription>
      {getKycStatusLabel()}
    </SectionContainer>
  )
}
