import { Form, Formik } from 'formik'
import { SchemaOf, object, string } from 'yup'

import { useLocalization } from 'locales/i18n'
import {
  fetchAuthUserAccountingSummary,
  fetchAuthUserDepositsAndWithdrawals,
  fetchAuthUserTaxStatement,
} from 'repositories/mx-api'
import { AccessStatus } from 'types/auth-user'
import { Button } from 'ui/@library/inputs/button'
import { Input } from 'ui/@library/inputs/input'
import { Select } from 'ui/@library/inputs/select'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import styles from './styles.module.scss'

export enum StatementType {
  Summary = 'summary',
  Transactions = 'transactions',
  DepositsAndWithdrawals = 'depositsAndWithdrawals',
}

type DownloadFormValues = {
  startDate: string
  endDate: string
  statementType: string
}

const initialValues: DownloadFormValues = {
  startDate: '',
  endDate: '',
  statementType: StatementType.Summary,
}

export const Statements = () => {
  const { authUser } = useAuthUserLoadable()
  const { t } = useLocalization('myAssets.statements.downloadSection')

  if (authUser?.accessStatus !== AccessStatus.EarlyAccess) return null

  const statementTypeOptions = [
    {
      value: StatementType.Summary,
      label: t('inputs.statementType.label.summary'),
    },
    {
      value: StatementType.Transactions,
      label: t('inputs.statementType.label.transactions'),
    },
    {
      value: StatementType.DepositsAndWithdrawals,
      label: t('inputs.statementType.label.depositsAndWithdrawals'),
    },
  ]

  const validationSchema: SchemaOf<DownloadFormValues> = object({
    startDate: string()
      .required(t('inputs.startDate.required'))
      .test(
        'startDate',
        t('inputs.startDate.invalid', {
          // dd/mm/yyyy to match the date format in the input
          today: new Date().toLocaleDateString('en-GB'),
        }),
        (value) => {
          if (value) {
            const startDate = new Date(value)
            const today = new Date()
            return startDate <= today
          }
          return true
        },
      ),
    endDate: string()
      .required(t('inputs.endDate.required'))
      .test(
        'endDate',
        t('inputs.endDate.invalid', {
          // dd/mm/yyyy to match the date format in the input
          today: new Date().toLocaleDateString('en-GB'),
        }),
        (value, { parent }) => {
          if (value) {
            const startDate = new Date(parent.startDate)
            const endDate = new Date(value)
            return startDate <= endDate
          }
          return true
        },
      ),
    statementType: string().required(t('inputs.statementType.required')),
  })

  const handleDownload = async (values: DownloadFormValues) => {
    switch (values.statementType) {
      case StatementType.Summary:
        await fetchAuthUserAccountingSummary(values.startDate, values.endDate)
        return
      case StatementType.DepositsAndWithdrawals:
        await fetchAuthUserDepositsAndWithdrawals(values.startDate, values.endDate)
        return
      case StatementType.Transactions:
        await fetchAuthUserTaxStatement(values.startDate, values.endDate)
        return
      default:
        return
    }
  }

  return (
    <div className={styles.statementsContainer}>
      <h1>{t('title')}</h1>
      <p>{t('description')}</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleDownload}
        validateOnBlur
        validateOnMount
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <div>
              <Input
                name='startDate'
                type='date'
                label={t('inputs.startDate.label')}
                value={values.startDate}
                placeholder={new Date().toJSON().slice(0, 10)}
                pattern='\d{4}-\d{2}-\d{2}'
                autoFocus
              />
              <Input
                name='endDate'
                type='date'
                label={t('inputs.endDate.label')}
                value={values.endDate}
                placeholder={new Date().toJSON().slice(0, 10)}
                pattern='\d{4}-\d{2}-\d{2}'
                disabled={!values.startDate}
              />
              <Select
                name='statementType'
                label={t('inputs.statementType.label.title')}
                options={statementTypeOptions}
                currentValue={values.statementType}
                placeholder={t('inputs.statementType.label.placeholder')}
              />
            </div>
            <Button type='submit' loading={isSubmitting} disabled={!isValid}>
              {t('inputs.submit')}
            </Button>
          </Form>
        )}
      </Formik>
      <hr />
    </div>
  )
}
