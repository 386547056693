import {
  sanitizeTransactionRaw,
  sanitizeShareTransactionRaw,
} from 'repositories/mx-api/@santizers/transaction'

import { MasterBasic } from './master'
import { Share } from './share'

export enum TransactionType {
  Deposit = 'deposit',
  ImoTrade = 'imo_trade',
  Withdrawal = 'withdrawal',
  Commission = 'commission',
  RoyaltyPayout = 'royalty_payout',
  RoyaltyPayoutFee = 'royalty_payout_fee',
  Transfer = 'transfer',
  Sell = 'sell',
  Buy = 'buy',
  RefundTransfer = 'refund_transfer',
  RefundDeposit = 'refund_deposit',
  RefundWithdrawal = 'refund_withdrawal',
  GiftReceived = 'gift_received',
  GiftSent = 'gift_sent',
  ImoSellFee = 'imo_sell_fee',
  ImoListingFee = 'imo_listing_fee',
  ReferralReward = 'referral_reward',
  AffiliateReward = 'affiliate_reward',
}

export enum AuthUserSide {
  Credit = 'credit',
  Debit = 'debit',
}

export type TransactionRaw = {
  transaction_type: TransactionType | null
  amount: number
  created_at: string
  total: number
  fees: number
  auth_user_side: AuthUserSide
  master_name: string | null
  token_id: number | null
  order_id: string | null
}

export type ShareTransactionRaw = {
  uuid: string
  price: number
  fraction: Share
  master: MasterBasic
  created_at: string
}

export type Transaction = ReturnType<typeof sanitizeTransactionRaw>
export type ShareTransaction = ReturnType<typeof sanitizeShareTransactionRaw>
