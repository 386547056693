import { useWindowSize } from 'ui/@hooks/use-window-size'
import { AddFundsButton } from 'ui/@modals/add-funds/@components/add-funds-button'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import { ArtistDescription } from './@components/artist-description'
import { BannerTrader } from './@components/banner-trader'
import { Documents } from './@components/documents'
import { Information } from './@components/information'
import { InvestmentOpportunity } from './@components/information/@components/investment-opportunity'
import { MasterDescription } from './@components/master-description'
import { OngoingOrders } from './@components/ongoing-orders'
import { OrderBook } from './@components/order-book'
import { RecentTrades } from './@components/recent-trades'
import { RoyaltyDistribution } from './@components/royalty-distribution'
import { RoyaltyPayouts } from './@components/royalty-payouts'
import { Statistics } from './@components/statistics'
import { StreamsGraph } from './@components/streams-graph'
import { UniqueShares } from './@components/unique-shares'
import styles from './styles.module.scss'

export const MasterOverview = () => {
  const { authUser } = useAuthUserLoadable()
  const { viewports } = useWindowSize()
  const { isTablet } = viewports

  return (
    <div className={styles.overviewContainer}>
      {isTablet ? (
        <div className={styles.mainContainer}>
          <Statistics />
          <BannerTrader />
          <AddFundsButton />
          <OrderBook />
          {authUser && <OngoingOrders />}
          <Documents />
          <InvestmentOpportunity />
          <Information />
          <StreamsGraph />
          <RoyaltyDistribution />
          <RoyaltyPayouts />
          <MasterDescription />
          <ArtistDescription />
          <RecentTrades />
          <UniqueShares />
          <div />
        </div>
      ) : (
        <>
          <div className={styles.mainContainer}>
            <Statistics />
            {authUser && <OngoingOrders />}
            <InvestmentOpportunity />
            <Information />
            <StreamsGraph />
            <RoyaltyDistribution />
            <RoyaltyPayouts />
            <MasterDescription />
            <ArtistDescription />
          </div>
          <div className={styles.sidebarContainer}>
            <BannerTrader />
            <AddFundsButton />
            <OrderBook />
            <Documents />
            <RecentTrades />
            <UniqueShares />
          </div>
        </>
      )}
    </div>
  )
}
