import { Browser } from '@capacitor/browser'
import { useEffect, useState } from 'react'

import { ThreeDSecureEventStatus } from 'types/card'
import { useMessages } from 'ui/@components/message-banner/use-messages'
import { ThreeDSecureEvent } from 'ui/@components/three-d-secure-redirector/constants'
import { usePlatform } from 'ui/@hooks/use-platform'
import { IframeRenderer } from 'ui/@library/utils/iframe-renderer'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useCardValidation } from 'ui/@store/card-validation'

type CardValidation3DSProps = {
  onSuccess: () => void
  onError: () => void
}

export const CardValidation3DS = ({ onSuccess, onError }: CardValidation3DSProps) => {
  const { isIOS } = usePlatform()
  const { cardValidation, refetchCardValidation, resetState } = useCardValidation({ onError })
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [secureModeRedirectUrl, setSecureModeRedirectUrl] = useState('')
  const { refetchAuthUser } = useAuthUserLoadable()
  const { refetchMessages } = useMessages()

  const refetchCardValidationWithTimeout = () => {
    const timeoutId = setTimeout(async () => {
      const cardValidation = await refetchCardValidation()
      if (cardValidation?.secureModeRedirectUrl) {
        if (isIOS) {
          Browser.open({ url: cardValidation.secureModeRedirectUrl })
        } else {
          setSecureModeRedirectUrl(cardValidation.secureModeRedirectUrl)
        }
      }
    }, ThreeDSecureEvent.TIMEOUT)
    setTimeoutId(timeoutId)
  }

  const refetchMessagesWithTimeout = () => {
    refetchMessages()
    setTimeout(() => {
      refetchMessages()
    }, 5000)
  }

  const handleCardValidation = async () => {
    if (cardValidation) {
      switch (cardValidation.status) {
        case ThreeDSecureEventStatus.Succeeded:
          isIOS && Browser.close()
          refetchAuthUser()
          refetchMessagesWithTimeout()
          resetState()
          clearTimeout(timeoutId)
          onSuccess()
          break
        case ThreeDSecureEventStatus.Created:
          if (cardValidation.secureModeRedirectUrl) {
            if (isIOS) {
              Browser.open({ url: cardValidation.secureModeRedirectUrl })
            } else {
              setSecureModeRedirectUrl(cardValidation.secureModeRedirectUrl)
            }
          } else {
            refetchCardValidationWithTimeout()
          }
          break
        case ThreeDSecureEventStatus.Failed:
          isIOS && Browser.close()
          resetState()
          onError()
          break
        default:
          isIOS && Browser.close()
          resetState()
          onError()
      }
    }
  }

  useEffect(() => {
    const cardValidationEventHandler = async (
      event: MessageEvent<{
        id: string
        cardValidationId: string
        status: ThreeDSecureEventStatus
      }>,
    ) => {
      if (event.data.id !== ThreeDSecureEvent.ID) return
      await refetchCardValidation()
    }
    window.addEventListener(ThreeDSecureEvent.TYPE, cardValidationEventHandler)
    handleCardValidation()
    return () => {
      window.removeEventListener(ThreeDSecureEvent.TYPE, cardValidationEventHandler)
      clearTimeout(timeoutId)
      resetState()
    }
    // eslint-disable-next-line
  }, [cardValidation])

  return <IframeRenderer url={secureModeRedirectUrl} />
}
