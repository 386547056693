import { useMemo } from 'react'

import { routes } from 'ui/@components/navbar/routes'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { Dropdown } from 'ui/@library/inputs/dropdown'

import { RouteLink } from '../../../route-link'

import styles from './styles.module.scss'

export const NavbarDesktopDropdown = () => {
  const { isAuthenticated } = useMxAuth()

  const dropdownOptions = useMemo(() => {
    return routes
      .filter((route) => route.dropdownOnly)
      .map((route) => {
        return {
          label: route.label,
          value: <RouteLink route={route} />,
        }
      })
  }, [])

  if (!isAuthenticated) return null

  return (
    <Dropdown
      className={styles.navbarDesktopDropdown}
      options={dropdownOptions}
      color='secondary'
      type='menu'
      horizontalAlign='right'
    />
  )
}
