import { useMasterLoadable, useMasterStatusLoadable } from 'ui/@store/master'
import { MasterStatus } from 'ui/master/types'

import { BannerNoTradingForMasterCustodian, BannerTraderImo, BannerTraderMarket } from '../banners'

import styles from './styles.module.scss'

export const DesktopTrader = () => {
  const { masterStatus } = useMasterStatusLoadable()
  const { master } = useMasterLoadable()

  return (
    <div className={styles.desktopTraderContainer}>
      {masterStatus === MasterStatus.Imo ? (
        master?.isOwner ? (
          <BannerNoTradingForMasterCustodian />
        ) : (
          <BannerTraderImo />
        )
      ) : (
        <BannerTraderMarket />
      )}
    </div>
  )
}
