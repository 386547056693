import { useState } from 'react'

import { ampli } from 'ampli'
import { SharePrice as TradePrice, Order } from 'constants/trading'
import { useGTM, GTMEvents } from 'gtm'
import { useLocalization } from 'locales/i18n'
import { useBidShare } from 'repositories/mx-api'
import { KycStatus } from 'types/auth-user'
import { OrderType } from 'types/order'
import { ShareWithOrders } from 'types/share'
import { CurrencyInput } from 'ui/@components/currency-input'
import { OrderLoading } from 'ui/@library/feedback/order-loading'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { useAuthUserMasterOffersMadeLoadable } from 'ui/@store/auth-user-masters-offers-made'
import { useMasterLoadable } from 'ui/@store/master'
import { useShareListLoadable } from 'ui/@store/share-list'
import { useWalletLoadable } from 'ui/@store/wallet'

import { ReviewKyc } from '../../../../@modals/kyc/review-kyc'
import { TradeActionType } from '../../master-overview/@components/banner-trader/types'
import { InsufficientFunds } from '../insufficient-funds'
import { OrderCompleted } from '../order-completed'
import { OrderFailed } from '../order-failed'

import { AgreementLinks } from './@components/agreement-links'
import { OrderContainer } from './@components/order-container'
import { OrderHandler } from './@components/order-handler'
import { OrderHeader } from './@components/order-header'
import {
  OrderQuantity,
  OrderSpecification,
  ServiceFee,
  SharePrice,
  OrderAmount,
} from './@components/order-specification'
import { ShareList } from './@components/share-list'
import styles from './styles.module.scss'

export type ReviewMakeBidProps = {
  pricePerShare?: number
  shareUuid: string
  sharesToTrade: ShareWithOrders[]
  orderType: OrderType
}

export const ReviewMakeBid = () => {
  const { t } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation',
  )
  const { payload: modalPayload, renderModal } = useGlobalModal()
  const { master } = useMasterLoadable()
  const {
    wallet,
    refetch: refetchWallet,
    loading: walletLoading,
  } = useWalletLoadable({ forceRefetch: true })
  const [tradePrice, setTradePrice] = useState<string | null>(modalPayload?.pricePerShare)
  const [tradePriceError, setTradePriceError] = useState(false)
  const { authUser } = useAuthUserLoadable()
  const { sendGTMEvent } = useGTM()

  const isKycValidated = authUser?.kycStatus === KycStatus.Validated

  if (!isKycValidated)
    renderModal({
      modalKey: ModalKey.ReviewKyc,
      content: <ReviewKyc />,
    })

  const tradeQuantity = 1
  const accountBalance = wallet?.primaryBalance
  const totalWithoutFees = tradePrice ? tradeQuantity * Number(tradePrice) : 0
  const serviceFee = Math.floor(Order.SERVICE_CHARGE.IN_PERCENT * Number(tradePrice)) / 100
  const isServiceFeeBelowMinimum = Math.abs(serviceFee) < Order.SERVICE_CHARGE.MINIMUM_PER_SHARE
  const finalServiceFee =
    tradeQuantity * (isServiceFeeBelowMinimum ? Order.SERVICE_CHARGE.MINIMUM_PER_SHARE : serviceFee)
  const totalOrderAmount = finalServiceFee + totalWithoutFees
  const sharesToTrade = modalPayload?.sharesToTrade || []

  const {
    requestBidShare,
    loading: bidShareLoading,
    error: bidShareError,
  } = useBidShare(master?.uuid ?? '', modalPayload?.shareUuid, tradePrice ? Number(tradePrice) : 0)
  const { refetch: refetchAuthUserMasterOffersMadeLoadable } = useAuthUserMasterOffersMadeLoadable()

  const { refetch: refetchAuthUserShareList } = useShareListLoadable({
    isAuthUserShares: true,
  })

  const { refetch: refetchShareList } = useShareListLoadable({
    isAuthUserShares: false,
  })

  const handleBid = async () => {
    if (!accountBalance || accountBalance < totalOrderAmount) {
      renderModal({
        content: <InsufficientFunds />,
        modalKey: ModalKey.InsufficientFunds,
        payload: {
          sharesCost: accountBalance ? totalOrderAmount - accountBalance : totalOrderAmount,
          retriggerReviewOrder: true,
        },
      })
    } else {
      await requestBidShare()
      if (bidShareError) {
        renderModal({
          modalKey: ModalKey.OrderFailed,
          content: <OrderFailed />,
        })
      } else {
        ampli.placeUniqueBuyOfferSuccess({
          masterName: master?.name || '',
          masterUuid: master?.uuid || '',
        })
        sendGTMEvent(GTMEvents.OrderPlaced, authUser?.uuid)
        refetchWallet()
        refetchAuthUserMasterOffersMadeLoadable()
        refetchAuthUserShareList()
        refetchShareList()
        renderModal({
          modalKey: ModalKey.OrderCompleted,
          content: <OrderCompleted />,
          payload: {
            tradeActionType: TradeActionType.Bid,
            orderType: OrderType.Bid,
          },
        })
      }
    }
  }

  if (bidShareLoading) {
    return (
      <ModalContent>
        <OrderLoading />
      </ModalContent>
    )
  }

  return (
    <ModalContent className={styles.reviewOrderContainer}>
      <OrderContainer>
        <OrderHeader title={t('title.bid')} accountBalance={accountBalance} />
        <CurrencyInput
          displayError={tradePriceError}
          setDisplayError={setTradePriceError}
          title={t('priceInput.title')}
          value={tradePrice}
          setValue={setTradePrice}
        />
        <OrderSpecification>
          <OrderQuantity quantity={tradeQuantity} />
          <SharePrice title={t('price.market')} price={tradePrice ? Number(tradePrice) : 0} />
          <OrderAmount title={t('total.withoutFees')} amount={totalWithoutFees} />
          <ServiceFee title={t('serviceFee')} serviceFee={finalServiceFee} />
          <hr className={styles.dividerHorizontal} />
          <OrderAmount title={t('total.withFees')} amount={totalOrderAmount} highlight />
        </OrderSpecification>
        <OrderHandler
          title={t('buttons.confirmBid')}
          handleOrderAction={handleBid}
          isLoading={bidShareLoading || walletLoading}
          isDisabled={!tradePrice || Number(tradePrice) > TradePrice.UPPER_LIMIT}
        />
        <AgreementLinks />
      </OrderContainer>
      <hr className={styles.dividerVertical} />
      <ShareList
        tradeQuantity={tradeQuantity}
        tradePrice={tradePrice ? Number(tradePrice) : 0}
        sharesToTrade={sharesToTrade}
        orderType={OrderType.LimitAsk}
      />
    </ModalContent>
  )
}
