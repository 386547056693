import { useState } from 'react'

import { Loader } from 'ui/@library/feedback/loader'

import styles from './styles.module.scss'

type IframeRendererProps = {
  url: string
}

export const IframeRenderer = ({ url }: IframeRendererProps) => {
  const [iFrameLoading, setIFrameLoading] = useState(false)
  const isLoaded = !iFrameLoading && url

  return (
    <div className={styles.container}>
      {!isLoaded && <Loader />}
      {url && (
        <iframe
          title='3D secure'
          src={url}
          className={`${styles.iframe} ${isLoaded && styles.showIframe}`}
          onLoad={() => setIFrameLoading(false)}
        />
      )}
    </div>
  )
}
