import { ReactNode } from 'react'

import styles from './styles.module.scss'

type OrderContainerProps = {
  children: ReactNode
}

export const OrderContainer = ({ children }: OrderContainerProps) => {
  return <div className={styles.orderInformationContainer}>{children}</div>
}
