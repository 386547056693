import camelcaseKeys from 'camelcase-keys'

import { CardRaw, CardRegistrationRaw, CardValidationRaw, PayInRaw, PreauthRaw } from 'types/card'

export const sanitizeCardFromRaw = (raw: CardRaw) => {
  return camelcaseKeys(raw)
}

export const sanitizeCardRegistrationFromRaw = (raw: CardRegistrationRaw) => {
  return camelcaseKeys(raw)
}

export const sanitizePayInFromRaw = (raw: PayInRaw) => {
  return camelcaseKeys(raw)
}

export const sanitizePreauthFromRaw = (raw: PreauthRaw) => {
  return camelcaseKeys(raw)
}

export const sanitizeCardValidationFromRaw = (raw: CardValidationRaw) => {
  return camelcaseKeys(raw)
}
