import { useLocalization } from 'locales/i18n'
import { useCardsLoadable } from 'ui/@store/cards'
import { useWalletLoadable } from 'ui/@store/wallet'

import styles from './styles.module.scss'

export const AvailableFunds = () => {
  const { t, f } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation.paymentMethod.accountBalance',
  )
  const { wallet } = useWalletLoadable()
  const { cards } = useCardsLoadable()

  if (!wallet || wallet.primaryBalance === 0) return null

  return (
    <div>
      <p className={styles.title}>
        {t('title', {
          balance: f.currency(wallet.primaryBalance),
        })}
      </p>
      {cards.length > 0 && <p className={styles.info}>{t('info')}</p>}
    </div>
  )
}
