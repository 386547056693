import styles from '../styles.module.scss'

type BannerMessageProps = {
  content: string
  contentType?: 'text' | 'html'
  icon?: React.ReactNode
}

export const BannerMessage = ({ content, contentType = 'text', icon }: BannerMessageProps) => {
  return (
    <div className={styles.messageWrapper}>
      {icon}
      {contentType === 'text' ? (
        <p className={styles.title}>{content}</p>
      ) : (
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </div>
  )
}
