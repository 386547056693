import { ReactComponent as TooltipIcon } from 'assets/icons/info.svg'

import styles from './styles.module.scss'

type MetricItemProps = {
  title: string
  value?: string | number
  onClickInfo?: () => void
  infoIconClassName?: string
  footNote?: string | null
  timePeriod?: string | null
}

export const MetricItem = ({
  title,
  value,
  onClickInfo,
  infoIconClassName,
  footNote,
  timePeriod,
}: MetricItemProps) => {
  return (
    <div className={styles.container}>
      <span className={styles.titleContainer}>
        <h4 className={styles.title}>{title}</h4>
        {onClickInfo && (
          <div className={`${styles.infoIcon} ${infoIconClassName}`}>
            <TooltipIcon onClick={onClickInfo} />
          </div>
        )}
      </span>
      {timePeriod && <p className={styles.timePeriod}>{timePeriod}</p>}
      <p className={styles.value}>{value}</p>
      {footNote && <p className={styles.footNote}>{footNote}</p>}
    </div>
  )
}
