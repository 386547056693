export const SharePrice = {
  UPPER_LIMIT: 10e9,
  LOWER_LIMIT: 10e-3,
} as const

export const Order = {
  SERVICE_CHARGE: {
    IN_PERCENT: 2,
    MINIMUM_PER_SHARE: 0.01,
  },
} as const
