import { ShareWithOrdersRaw } from 'types/share'

import { sanitizeShareListFromRaw } from '../@santizers/share'
import { MxApi } from '../mx-api'

export const fetchShareList = async (masterUuid: string) => {
  const { data } = await MxApi.get<ShareWithOrdersRaw[]>(`/masters/${masterUuid}/shares`)

  return sanitizeShareListFromRaw(data)
}
