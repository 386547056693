import { useEffect } from 'react'
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { fetchRecentTrades } from 'repositories/mx-api/'
import { RecentTrades } from 'types/recent-trades'

import { masterUuidState } from './master'

const recentTradesQuery = selector<RecentTrades[]>({
  key: 'recentTradesQuery',
  get: async ({ get }) => {
    const masterUuid = get(masterUuidState)
    if (!masterUuid) return []
    return fetchRecentTrades(masterUuid)
  },
})

export const useRecentTradesLoadable = ({ forceRefetch = false } = {}) => {
  const { state, contents } = useRecoilValueLoadable(recentTradesQuery)
  const refetch = useRecoilRefresher_UNSTABLE(recentTradesQuery)

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    recentTrades: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
