import { KycStatus } from 'types/auth-user'
import { OrderType } from 'types/order'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import { ReviewKyc } from '../../../../@modals/kyc/review-kyc'

import { ReviewAcceptBid } from './review-accept-bid'
import { ReviewBestPriceBuy } from './review-best-price-buy'
import { ReviewImoBuy } from './review-imo-buy'
import { ReviewLimitBuy } from './review-limit-buy'
import { ReviewLimitSell } from './review-limit-sell'
import { ReviewMakeBid } from './review-make-bid'

export const ReviewOrder = () => {
  const { renderModal, payload: modalPayload } = useGlobalModal()
  const { authUser } = useAuthUserLoadable()
  const isKycValidated = authUser?.kycStatus === KycStatus.Validated

  if (modalPayload?.orderType === OrderType.ImoBid)
    renderModal({
      modalKey: ModalKey.ReviewImoBuy,
      content: <ReviewImoBuy />,
      enforceAuth: true,
      size: ModalSize.Large,
    })
  else {
    if (!isKycValidated)
      renderModal({
        modalKey: ModalKey.ReviewKyc,
        content: <ReviewKyc />,
      })
    else {
      switch (modalPayload?.orderType) {
        case OrderType.LimitAsk:
          renderModal({
            modalKey: ModalKey.ReviewLimitSell,
            content: <ReviewLimitSell />,
            enforceAuth: true,
            size: ModalSize.Large,
          })
          break
        case OrderType.AcceptOffer:
          renderModal({
            modalKey: ModalKey.ReviewAcceptBid,
            content: <ReviewAcceptBid />,
            enforceAuth: true,
            size: ModalSize.Large,
          })
          break
        case OrderType.ImoBid:
          renderModal({
            modalKey: ModalKey.ReviewImoBuy,
            content: <ReviewImoBuy />,
            enforceAuth: true,
            size: ModalSize.Large,
          })
          break
        case OrderType.LimitBid:
          renderModal({
            modalKey: ModalKey.ReviewLimitBuy,
            content: <ReviewLimitBuy />,
            enforceAuth: true,
            size: ModalSize.Large,
          })
          break
        case OrderType.Bid:
          renderModal({
            modalKey: ModalKey.ReviewMakeBid,
            content: <ReviewMakeBid />,
            enforceAuth: true,
            size: ModalSize.Large,
          })
          break
        case OrderType.BestBid:
          renderModal({
            modalKey: ModalKey.ReviewBestPriceBuy,
            content: <ReviewBestPriceBuy />,
            enforceAuth: true,
            size: ModalSize.Large,
          })
          break
        default:
          return null
      }
    }
  }

  return null
}
