import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LandingPageLinks } from 'constants/external-links'
import { Paths, QueryParams, Redirects } from 'constants/paths'
import {
  ThreeDSecureEvent,
  ThreeDSecureQueryParams,
} from 'ui/@components/three-d-secure-redirector/constants'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { usePlatform } from 'ui/@hooks/use-platform'

export const useAppUrlListener = () => {
  const navigate = useNavigate()

  const { isNative, isIOS } = usePlatform()

  const { handleRedirectCallback } = useMxAuth()

  const handle3dsRedirect = async (url: string) => {
    const threeDSCallbackUrl = new URL(url)
    const status = threeDSCallbackUrl.searchParams.get(ThreeDSecureQueryParams.STATUS)
    const transactionId = threeDSCallbackUrl.searchParams.get(
      ThreeDSecureQueryParams.TRANSACTION_ID,
    )
    const preAuthorizationId = threeDSCallbackUrl.searchParams.get(
      ThreeDSecureQueryParams.PREAUTHORIZATION_ID,
    )
    const cardValidationId = threeDSCallbackUrl.searchParams.get(
      ThreeDSecureQueryParams.CARDVALIDATION_ID,
    )

    if (transactionId) {
      window.postMessage(
        {
          id: ThreeDSecureEvent.ID,
          payInId: transactionId,
        },
        '*',
      )
    }

    if (preAuthorizationId) {
      window.postMessage(
        {
          id: ThreeDSecureEvent.ID,
          status,
          preAuthorizationId,
        },
        '*',
      )
    }

    if (cardValidationId) {
      window.postMessage(
        {
          id: ThreeDSecureEvent.ID,
          status,
          cardValidationId,
        },
        '*',
      )
    }

    isIOS && (await Browser.close())
  }

  const callback = process.env.REACT_APP_AUTH0_CALLBACK

  useEffect(() => {
    if (isNative) {
      App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
        // Handle auth0 callback
        if (callback) {
          if (event.url.startsWith(callback)) {
            if (
              event.url.includes('state') &&
              (event.url.includes('code') || event.url.includes('error'))
            ) {
              await handleRedirectCallback(event.url)
            }
            const url = new URL(event.url)
            const redirect = url.searchParams.get(QueryParams.REDIRECT)
            if (redirect === Redirects.LOGIN_METHOD_CONFLICT.KEY) {
              const method = url.searchParams.get(QueryParams[Paths.LOGGED_OUT].METHOD)
              navigate(Redirects.LOGIN_METHOD_CONFLICT.PATH, { replace: true, state: { method } })
              isIOS && (await Browser.close())
              window.location.reload()
              return
            } else if (redirect === Redirects.SIGNUPS_DISABLED.KEY) {
              navigate(Redirects.SIGNUPS_DISABLED.PATH, { replace: true })
              isIOS && (await Browser.close())
              window.location.reload()
              return
            }
            navigate(Paths.HOME)
            window.location.reload()
            isIOS && (await Browser.close())
            return
          }
        }

        // Handle landing page links
        if (LandingPageLinks.BASE_URL && event.url.startsWith(LandingPageLinks.BASE_URL)) {
          await Browser.open({ url: event.url })
          return
        }

        const customUrlParser = (url: string) => {
          //  remove scheme and host
          const allowedCustomSchemes = [
            'masterexchange://',
            'masterexchange-staging://',
            'capacitor://',
          ]
          const customScheme = allowedCustomSchemes.find((scheme) => url.startsWith(scheme))
          if (customScheme) {
            const { pathname, search } = new URL(url.replace(customScheme, 'https://'))
            return { pathname, search }
          }
          return { pathname: '', search: '' }
        }

        // Cannot directly parse event.url with new URL() because of inconsistency between chrome and safari implementation
        const url = customUrlParser(event.url)
        const slug = url.pathname + url.search

        if (slug) {
          if (slug.includes(Paths.REDIRECTION_3DS)) {
            await handle3dsRedirect(event.url)
            return
          }
          navigate(slug, { replace: true })
          if (!url.pathname || url.pathname === '/') {
            // Refresh the page
            navigate(0)
          }
        } else {
          navigate(0)
        }
      })

      App.addListener('backButton', (data) => {
        if (data.canGoBack) window.history.back()
        else App.exitApp()
      })
    }
    // eslint-disable-next-line
  }, [])

  return
}
