import { addSeconds, differenceInSeconds } from 'date-fns'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as MailIcon } from 'assets/icons/mail-icon.svg'
import { CustomErrorCodes } from 'constants/error-codes'
import { Paths } from 'constants/paths'
import { useGTM, GTMEvents } from 'gtm'
import { useLocalization } from 'locales/i18n'
import { useResendVerificationEmail } from 'repositories/mx-api'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { IconWrapper } from 'ui/@library/data-display/icon-wrapper'
import { Button } from 'ui/@library/inputs/button'
import { FormContainer } from 'ui/@library/layout/form-container'

import styles from '../styles.module.scss'

const RESEND_EMAIL_VERIFICATION_TIMEOUT = 15

export const EmailVerification = () => {
  const { t } = useLocalization('onboarding')
  const { sendGTMEvent } = useGTM()
  const { resendVerificationEmail, error } = useResendVerificationEmail()
  const [resendBlockedUntil, setResendBlockedUntil] = useState<Date | null>(null)
  const [countDown, setCountDown] = useState(0)
  const tooManyEmailsError =
    error?.response?.data?.code === CustomErrorCodes.TOO_MANY_VERIFICATION_EMAILS_SENT

  useEffect(() => {
    if (resendBlockedUntil) {
      const interval = setInterval(() => {
        const secondsLeft = differenceInSeconds(resendBlockedUntil, new Date())
        setCountDown(differenceInSeconds(resendBlockedUntil, new Date()))
        if (secondsLeft < 1) {
          clearInterval(interval)
          setResendBlockedUntil(null)
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [resendBlockedUntil])

  useEffectOnce(() => {
    sendGTMEvent(GTMEvents.Lead)
  })

  return (
    <div>
      <FormContainer className={styles.onboardingContainer}>
        <IconWrapper className={styles.iconSpacing}>
          <MailIcon />
        </IconWrapper>
        <h1 className={styles.title}>{t('verifyEmail.heading')}</h1>
        <p className={styles.description}>{t('verifyEmail.message')}</p>
        <hr className={styles.divider} />
        <Button
          width='fluid'
          variant='ghost'
          disabled={!!resendBlockedUntil || tooManyEmailsError}
          onClick={() => {
            resendVerificationEmail().catch(() => null)
            setResendBlockedUntil(addSeconds(new Date(), RESEND_EMAIL_VERIFICATION_TIMEOUT))
          }}
        >
          {tooManyEmailsError
            ? t('verifyEmail.tooManyEmailsError')
            : countDown > 0
            ? countDown
            : t('verifyEmail.resendEmail')}
        </Button>
        <div className={styles.buttonWrapper}>
          <Link to={Paths.SIGN_OUT}>
            <span className={styles.inlineLinkColor}>{t('actionButtons.signOut')}</span>
          </Link>
        </div>
      </FormContainer>
      <div className={styles.footerWrapper}>
        <p className={styles.footerText}>{t('verifyEmail.footer')}</p>
      </div>
    </div>
  )
}
