import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'

import styles from './styles.module.scss'

export type SuccessProps = {
  amount?: number
  onSuccessCallback?: () => void
}

export const Success = () => {
  const { t, f } = useLocalization('withdrawFunds.success')
  const { setDisplayModal, payload: modalPayload } = useGlobalModal()

  const handleCloseModal = () => {
    setDisplayModal(false)
  }

  return (
    <ModalContent className={styles.successContainer}>
      <div className={styles.topWrapper}>
        <h1 className={styles.title}>{t('title')}</h1>
        <h2 className={styles.subtitle}>
          {f.currency(modalPayload?.amount ? modalPayload.amount : '')}
        </h2>
        <div className={styles.description}>{t('description')}</div>
      </div>
      <Button width='fluid' onClick={handleCloseModal} className={styles.doneButton}>
        {t('done')}
      </Button>
    </ModalContent>
  )
}
