import camelcaseKeys from 'camelcase-keys'

import { TransactionRaw, ShareTransactionRaw } from 'types/transaction'

export const sanitizeTransactionRaw = (raw: TransactionRaw) => {
  return { ...camelcaseKeys(raw, { deep: true }), createdAt: new Date(raw.created_at) }
}

export const sanitizeShareTransactionRaw = (raw: ShareTransactionRaw) => {
  return { ...camelcaseKeys(raw, { deep: true }), createdAt: new Date(raw.created_at) }
}
