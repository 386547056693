import { Navigate, Route, Routes } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { ThreeDSecureRedirector } from 'ui/@components/three-d-secure-redirector'
import { NotFound } from 'ui/@library/errors'
import { Auth0Error } from 'ui/@library/errors/auth0-error'
import { Account } from 'ui/account'
import { AccountDetails } from 'ui/account/account-details'
import { NotificationSettings } from 'ui/account/notification-settings'
import { DeleteAccount } from 'ui/delete-account'
import { DeleteAccountRequestReceived } from 'ui/delete-account-request-received'
import { BrowseSongs } from 'ui/exchange/browse-songs'
import { Exchange } from 'ui/exchange/exchange'
import { ExchangeAll } from 'ui/exchange/exchange-all'
import { Imo } from 'ui/exchange/imo'
import { Kyc } from 'ui/kyc'
import { LoginMethodConflict } from 'ui/login-method-conflict'
import { Maintenance } from 'ui/maintenance'
import { Master } from 'ui/master'
import { MyAssets } from 'ui/my-assets'
import { Overview } from 'ui/my-assets/@components/overview'
import { Statements } from 'ui/my-assets/@components/statements'
import { TransactionHistory } from 'ui/my-assets/@components/transactions'
import { Onboarding } from 'ui/onboarding'
import { ReferralPaused } from 'ui/referral-paused'
import { SignupsDisabled } from 'ui/signups-disabled'
import { UnsupportedClient } from 'ui/unsupported-client'

import { AuthRedirect } from './@components/auth-redirect'
import { AuthType } from './@components/auth-redirect/types'
import { EnforceAuth } from './@components/enforce-auth'
import { Health } from './@components/health'

export const ApplicationRoutes = () => {
  return (
    <Routes>
      <Route path='*' element={<NotFound />} />

      <Route path={Paths.HEALTH} element={<Health />} />

      <Route path={Paths.HOME} element={<Navigate to={Paths.EXCHANGE.MAIN} />} />

      <Route path={Paths.SIGN_IN} element={<AuthRedirect type={AuthType.SignIn} />} />

      <Route path={Paths.SIGN_UP} element={<AuthRedirect type={AuthType.SignUp} />} />

      <Route path={Paths.INVITE_SIGN_UP} element={<AuthRedirect type={AuthType.InviteSignUp} />} />

      <Route path={Paths.SIGN_OUT} element={<AuthRedirect type={AuthType.SignOut} />} />

      <Route path={Paths.LOGGED_OUT} element={<AuthRedirect type={AuthType.SignOutNavigation} />} />

      <Route path={Paths.REDIRECTION_3DS} element={<ThreeDSecureRedirector />} />

      <Route
        path={`${Paths.ONBOARDING.MAIN}*`}
        element={<EnforceAuth element={<Onboarding />} />}
      />

      <Route path={Paths.ACCOUNT.MAIN} element={<EnforceAuth element={<Account />} />}>
        <Route index element={<Navigate to={Paths.ACCOUNT.DETAILS} replace />} />
        <Route path={Paths.ACCOUNT.DETAILS} element={<AccountDetails />} />
        <Route path={Paths.ACCOUNT.NOTIFICATION_SETTINGS} element={<NotificationSettings />} />
      </Route>

      <Route path={Paths.DELETE_ACCOUNT} element={<EnforceAuth element={<DeleteAccount />} />} />
      <Route
        path={Paths.DELETE_ACCOUNT_REQUEST_RECEIVED}
        element={<DeleteAccountRequestReceived />}
      />

      <Route path={Paths.EXCHANGE.MAIN} element={<Exchange />}>
        <Route index element={<Navigate to={Paths.EXCHANGE.ALL} replace />} />
        <Route path={Paths.EXCHANGE.ALL} element={<ExchangeAll />} />
        <Route path={Paths.EXCHANGE.BROWSE_SONGS} element={<BrowseSongs />} />
        <Route path={Paths.EXCHANGE.IMO} element={<Imo />} />
      </Route>

      <Route
        path={`${Paths.MASTER.MAIN}${Paths.MASTER.UUID}${Paths.MASTER.TAB}`}
        element={<Master />}
      />

      <Route path={`${Paths.MASTER.MAIN}${Paths.MASTER.UUID}`} element={<Master />} />

      <Route path={Paths.KYC} element={<EnforceAuth element={<Kyc />} />} />

      <Route path={Paths.MY_ASSETS.MAIN} element={<MyAssets />}>
        <Route index element={<Navigate to={Paths.MY_ASSETS.OVERVIEW} replace />} />
        <Route path={Paths.MY_ASSETS.OVERVIEW} element={<Overview />} />
        <Route path={Paths.MY_ASSETS.TRANSACTIONS} element={<TransactionHistory />} />
        <Route path={Paths.MY_ASSETS.STATEMENTS} element={<Statements />} />
      </Route>

      <Route path={Paths.REFERRAL} element={<EnforceAuth element={<ReferralPaused />} />} />

      <Route path={Paths.WELCOME_INVITEE} element={<ReferralPaused />} />

      <Route path={Paths.MAINTENANCE} element={<Maintenance />} />

      <Route path={Paths.UNSUPPORTED_CLIENT} element={<UnsupportedClient />} />

      <Route path={Paths.LOGIN_METHOD_CONFLICT} element={<LoginMethodConflict />} />

      <Route path={Paths.SIGNUPS_DISABLED} element={<SignupsDisabled />} />

      <Route path={Paths.ERROR} element={<Auth0Error />} />
    </Routes>
  )
}
