import { AxiosError } from 'axios'
import { ReactElement, useEffect, useState } from 'react'

import { CustomErrorCodes } from 'constants/error-codes'
import { QueryParams, Paths, Redirects } from 'constants/paths'
import { MxErrorResponse } from 'types/mx-api'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { usePlatform } from 'ui/@hooks/use-platform'
import { useSignout } from 'ui/@hooks/use-signout'
import { FullPageError } from 'ui/@library/errors'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'
import { DeleteAccountRequestReceived } from 'ui/delete-account-request-received'
import { Maintenance } from 'ui/maintenance'
import { UnsupportedClient } from 'ui/unsupported-client'

import { useInitAuthUser } from '.'

export const useAuthErrors = () => {
  const [error, setError] = useState<ReactElement | null>(null)
  const signOut = useSignout()
  const { isNative } = usePlatform()
  const { error: auth0Error } = useMxAuth()
  const { error: mxAuthError } = useInitAuthUser()
  const authError = auth0Error || mxAuthError

  useEffect(() => {
    if (window.location.pathname === Paths.SIGN_OUT) {
      if (isNative) {
        setTimeout(() => signOut(), 1500)
      } else {
        signOut()
      }
    } else if (authError instanceof AxiosError) {
      const errorResponse: MxErrorResponse = authError?.response?.data

      switch (errorResponse?.code) {
        case CustomErrorCodes.UNSUPPORTED_CLIENT:
          setError(<UnsupportedClient />)
          break

        case CustomErrorCodes.UNDER_MAINTENANCE:
          setError(<Maintenance />)
          break

        case CustomErrorCodes.ACCOUNT_PENDING_FOR_DELETION:
          setError(<DeleteAccountRequestReceived email={errorResponse.payload?.email as string} />)
          break

        case CustomErrorCodes.BLOCKED_USER:
        case CustomErrorCodes.INVALIDATED_SESSION:
          if (isNative) {
            setTimeout(() => signOut(), 1500)
          } else {
            signOut()
          }
          setError(<FullPageLoader />)
          break

        case CustomErrorCodes.AUTH0_USER_ACCOUNT_CONFLICT: {
          const method = errorResponse.payload.method as string
          if (isNative) {
            setTimeout(() => {
              const returnTo = new URL(`${process.env.REACT_APP_AUTH0_CALLBACK}`)
              returnTo.searchParams.set(QueryParams[Paths.LOGGED_OUT].METHOD, method)
              returnTo.searchParams.set(QueryParams.REDIRECT, Redirects.LOGIN_METHOD_CONFLICT.KEY)
              signOut({
                returnTo: returnTo.toString(),
              })
            }, 1500)
          } else {
            const returnTo = new URL(`${window.location.origin}${Paths.LOGGED_OUT}`)
            returnTo.searchParams.set(QueryParams[Paths.LOGGED_OUT].METHOD, method)
            returnTo.searchParams.set(QueryParams.REDIRECT, Redirects.LOGIN_METHOD_CONFLICT.KEY)
            signOut({
              returnTo: returnTo.toString(),
            })
          }
          setError(<FullPageLoader />)
          break
        }

        case CustomErrorCodes.SIGNUPS_DISABLED: {
          if (isNative) {
            setTimeout(() => {
              const returnTo = new URL(`${process.env.REACT_APP_AUTH0_CALLBACK}`)
              returnTo.searchParams.set(QueryParams.REDIRECT, Redirects.SIGNUPS_DISABLED.KEY)
              signOut({
                returnTo: returnTo.toString(),
              })
            }, 1500)
          } else {
            const returnTo = new URL(`${window.location.origin}${Paths.LOGGED_OUT}`)
            returnTo.searchParams.set(QueryParams.REDIRECT, Redirects.SIGNUPS_DISABLED.KEY)
            signOut({
              returnTo: returnTo.toString(),
            })
          }
          setError(<FullPageLoader />)
          break
        }

        default: {
          // Handle cancellation in interceptors
          if (authError.code === 'ERR_CANCELED') {
            if (isNative) {
              setTimeout(() => signOut(), 1500)
            } else {
              signOut()
            }
            setError(<FullPageLoader />)
          } else {
            setError(<FullPageError error={authError} isAuthenticated />)
          }
        }
      }
    } else if (authError) {
      setError(<FullPageError error={authError} isAuthenticated />)
    }
    // eslint-disable-next-line
  }, [authError, window.location.pathname])

  return { error }
}
