import { useEffect } from 'react'

import { ReactComponent as MaintenanceIllustration } from 'assets/svgs/maintenance.svg'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { fetchRoot } from 'repositories/mx-api'
import { RouteWrapper } from 'ui/@components/route-wrapper'

import styles from './styles.module.scss'

export const Maintenance = () => {
  const { t } = useLocalization('maintenance')

  const init = async () => {
    const data = await fetchRoot()
    if (data) {
      window.location.replace(Paths.HOME)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <RouteWrapper title={t('pageTitle')}>
      <div className={styles.maintenanceContainer}>
        <MaintenanceIllustration className={styles.illustration} />
        <h2 className={styles.heading}>{t('heading')}</h2>
        <p className={styles.subHeading}>{t('subHeading')}</p>
      </div>
    </RouteWrapper>
  )
}
