import { useState, useEffect } from 'react'

import { ampli } from 'ampli'
import { useLocalization } from 'locales/i18n'
import { ErrorWithHeader } from 'ui/@library/errors/error-with-header'
import { Spinner } from 'ui/@library/feedback/spinner'
import { Button } from 'ui/@library/inputs/button'
import { CopyToCliploard } from 'ui/@library/inputs/copy-to-clipboard'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { useMasterLoadable } from 'ui/@store/master'

import { ShareButtons } from './@components/share-buttons'
import { ClientType, ShareMethod, utmParams, ABORTION_ERROR, MASTER_BASE_URL } from './constants'
import styles from './styles.module.scss'
import {
  generateImageLink,
  getImageDataURLFromImage,
  getUtmUrl,
  getShareableCaption,
} from './utils'

export const SocialShare = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.trader.modals.socialShare')
  const { setDisplayModal } = useGlobalModal()
  const { master } = useMasterLoadable()
  const [shareableAssetUrl, setShareableAssetUrl] = useState<HTMLImageElement>()
  const [isAssetLoaded, setIsAssetLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [sharingInProgress, setSharingInProgress] = useState(false)
  const [isEventLogged, setIsEventLogged] = useState(false)

  useEffect(() => {
    if (master) {
      getImageLink()
    }
    // eslint-disable-next-line
  }, [master])

  if (!master) return null
  if (error) return <ErrorWithHeader />

  const shareURL = getUtmUrl(`${MASTER_BASE_URL}/${master.uuid}`, utmParams)

  const handleOnShare = () => {
    ampli.shareOnSocialMedia({
      clientType: ClientType.Mobile,
      method: ShareMethod.WebShareApi,
    })
  }

  const getImageLink = async () => {
    try {
      const assetUrl = await generateImageLink(
        master.assets.thumbnail,
        master.name,
        master.artist.name,
        master.metaData.ownedByWithAvatars,
      )
      setShareableAssetUrl(assetUrl)
      setIsAssetLoaded(true)
      return assetUrl
    } catch (err) {
      setError(true)
    }
  }

  const handleShareClick = async () => {
    if (!shareableAssetUrl || sharingInProgress) {
      return
    }
    setSharingInProgress(true)

    try {
      const dataURL = await getImageDataURLFromImage(shareableAssetUrl)
      const blob = await (await fetch(dataURL)).blob()
      const imageFile = new File([blob], 'image.png', { type: 'image/png' })

      const shareData = {
        files: [imageFile],
        text: getShareableCaption(master),
      }

      handleOnShare()
      await navigator.share(shareData)
    } catch (error) {
      const isAbortError = (error as Error).name === ABORTION_ERROR
      isAbortError ? setDisplayModal(false) : setError(true)
    } finally {
      setSharingInProgress(false)
    }
  }

  const handleCopyToClipboard = () => {
    if (isEventLogged) return
    ampli.shareOnSocialMedia({
      clientType: ClientType.Desktop,
      method: ShareMethod.CopyLink,
    })
    setIsEventLogged(true)
  }

  return (
    <ModalContent className={styles.modalContainer}>
      <div className={styles.topWrapper}>
        <h1 className={styles.modalTitle}>{t('title')}</h1>
        <div className={styles.assetContainer}>
          {!isAssetLoaded && <Spinner width='fluid' />}
          {shareableAssetUrl && <img src={shareableAssetUrl.src} alt={master.name} />}
        </div>
      </div>
      <div className={styles.bottomWrapper}>
        <ShareButtons url={shareURL} />
        <div className={styles.copyToShare}>
          <p className={styles.copyLabel}>{t('copyToClipboard')}</p>
          <CopyToCliploard
            text={shareURL}
            withInput={true}
            handleAmpliEvent={handleCopyToClipboard}
          />
        </div>
        {navigator.canShare !== undefined && (
          <Button
            width='fluid'
            variant='primary'
            onClick={handleShareClick}
            disabled={sharingInProgress}
          >
            {t('share')}
          </Button>
        )}
      </div>
    </ModalContent>
  )
}
