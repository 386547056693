import camelcaseKeys from 'camelcase-keys'

import { ImoRaw, ImoWithMasterRaw } from 'types/imo'

import { sanitizeMasterFromRaw } from './master'

export const sanitizeImoFromRaw = (raw: ImoRaw) => {
  return {
    ...camelcaseKeys(raw, { deep: true }),
    launchDate: raw.launch_date ? new Date(raw.launch_date) : null,
    expiryDate: raw.expiry_date ? new Date(raw.expiry_date) : null,
  }
}

export const sanitizeImoWithMasterFromRaw = (raw: ImoWithMasterRaw) => {
  return {
    ...camelcaseKeys(raw, { deep: true }),
    launchDate: raw.launch_date ? new Date(raw.launch_date) : null,
    expiryDate: raw.expiry_date ? new Date(raw.expiry_date) : null,
    master: sanitizeMasterFromRaw(raw.master),
  }
}
