import { BaseType, line, pointer, scaleBand, scaleLinear, scaleTime, select } from 'd3'

import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'
import { CompositeTimeSeriesData, CompositeTimeSeriesDataArray } from 'types/metrics'

import { GraphConstants } from './constants'
import { UseGraphProps, isAggregatedTimeSeriesData, isAggregatedTimeSeriesDataPoint } from './types'

export const useGraph = <T extends BaseType>({ graphRef, data }: UseGraphProps<T>) => {
  const { f } = useLocalization()

  /* Graph variables */
  const graph = graphRef.current && select(graphRef.current)
  const width = graph && parseInt(graph.style('width'))
  const height = graph && parseInt(graph.style('height'))
  const innerWidth = width - GraphConstants.PADDING.RIGHT.MEDIUM
  const totalPadding = GraphConstants.PADDING.BOTTOM.MEDIUM + GraphConstants.PADDING.TOP.MEDIUM
  const innerHeight = height - totalPadding
  const valueList = data.map(({ value }) => value)
  const timestampList = isAggregatedTimeSeriesData(data)
    ? data.map(({ timestamp: [bandStart] }) => bandStart)
    : data.map(({ timestamp }) => timestamp)
  const scaledMinTimestamp =
    Math.min(...timestampList) * (1 - GraphConstants.SCALE_FACTOR.X_AXIS / 100)
  const scaledMaxTimestamp =
    Math.max(...timestampList) * (1 + GraphConstants.SCALE_FACTOR.X_AXIS / 100)
  const minValue = Math.min(...valueList)
  const maxValue = Math.max(...valueList)
  const xAxisTicks = isAggregatedTimeSeriesData(data)
    ? data.map(
        ({ timestamp: [startTimestamp, endTimestamp] }) =>
          `${f.date(new Date(startTimestamp * 1000), TextFormats.DATE.DATE_WITH_MONTH)} - ${f.date(
            new Date(endTimestamp * 1000),
            TextFormats.DATE.DATE_WITH_MONTH,
          )}`,
      )
    : data.map(
        ({ timestamp }) =>
          `${f.date(new Date(timestamp * 1000), TextFormats.DATE.DATE_WITH_MONTH)}`,
      )

  const defaultBisectorCoordinates: CompositeTimeSeriesDataArray = isAggregatedTimeSeriesData(data)
    ? [
        {
          timestamp: [scaledMinTimestamp, scaledMinTimestamp],
          value: minValue,
        },
        {
          timestamp: [scaledMinTimestamp, scaledMinTimestamp],
          value: maxValue,
        },
      ]
    : [
        {
          timestamp: scaledMinTimestamp,
          value: minValue,
        },
        {
          timestamp: scaledMinTimestamp,
          value: maxValue,
        },
      ]

  /* Scales */
  const xScaleTime = scaleTime()
    .domain([scaledMinTimestamp * 1000, scaledMaxTimestamp * 1000])
    .range([0, innerWidth])
  const xScaleLinear = scaleLinear()
    .domain([scaledMinTimestamp, scaledMaxTimestamp])
    .range([0, innerWidth])
  const xScaleBand = scaleBand().domain(xAxisTicks).range([0, innerWidth]).paddingInner(0)
  const yScaleLinear = scaleLinear().domain([minValue, maxValue]).range([innerHeight, 0])

  /* Plots */
  const linePlot = line<CompositeTimeSeriesData>()
    .x(({ timestamp }) =>
      Array.isArray(timestamp) ? xScaleLinear(timestamp[0]) : xScaleLinear(timestamp),
    )
    .y(({ value }) => yScaleLinear(value))

  /* Helpers fns */
  const bisectClosest = (data: CompositeTimeSeriesDataArray, timestamp: number) => {
    if (isAggregatedTimeSeriesData(data))
      return data.reduce((previous, current) =>
        Math.abs(current.timestamp[0] - timestamp) <= Math.abs(previous.timestamp[0] - timestamp)
          ? current
          : previous,
      )
    else
      return data.reduce((previous, current) =>
        Math.abs(current.timestamp - timestamp) <= Math.abs(previous.timestamp - timestamp)
          ? current
          : previous,
      )
  }

  const getTransformations = (event: Touch | MouseEvent | undefined, tooltipWidth?: number) => {
    /* Get tooltip location from mouse or touch event */
    let value
    let timestamp
    const [xPosition] = pointer(event, graphRef.current)
    const currentDatapoint = bisectClosest(data, xScaleLinear.invert(xPosition))
    if (isAggregatedTimeSeriesDataPoint(currentDatapoint)) {
      const {
        timestamp: [startTimestamp],
        value: currentValue,
      } = currentDatapoint
      value = currentValue
      timestamp = startTimestamp
    } else {
      const { timestamp: currentTimeStamp, value: currentValue } = currentDatapoint
      value = currentValue
      timestamp = currentTimeStamp
    }
    const [xPositionBisector, yPositionBisector] = [xScaleLinear(timestamp), yScaleLinear(value)]

    /* Prevent Legend from overflowing GraphContainer */
    const defaultTooltipWidth = tooltipWidth ? tooltipWidth : 0
    const defaultOffset = defaultTooltipWidth / 2
    const legendLeftOffset = xPositionBisector - defaultTooltipWidth / 2
    const legendRightOffset = width - xPositionBisector - defaultTooltipWidth / 2
    const transformLeft = 0.5 * defaultTooltipWidth - Math.abs(legendLeftOffset)
    const transformRight = 0.5 * defaultTooltipWidth + Math.abs(legendRightOffset)
    const legendOffset =
      legendLeftOffset <= 0
        ? transformLeft
        : legendRightOffset <= 0
        ? transformRight
        : defaultOffset

    return {
      tooltipPosition: xPositionBisector - legendOffset,
      dataPoint: currentDatapoint,
      bisectorPosition: { posX: xPositionBisector, posY: yPositionBisector },
    }
  }

  return {
    graph,
    linePlot,
    parsedData: {
      values: valueList,
      timestamps: timestampList,
    },
    dimension: {
      width,
      height,
      innerWidth,
      innerHeight,
    },
    scale: {
      xAxis: { linear: xScaleLinear, band: xScaleBand, time: xScaleTime },
      yAxis: yScaleLinear,
    },
    valueDomain: { minValue, maxValue },
    timestampDomain: { scaledMinTimestamp, scaledMaxTimestamp },
    getTransformations,
    defaultBisectorCoordinates,
  }
}
