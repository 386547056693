import { ChangeEvent } from 'react'

import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'

import styles from './styles.module.scss'

type AmountPickerProps = {
  value: number
  setValue: (value: number) => void
  className?: string
  limit: number
  setQuantityError: (amountError: boolean) => void
  onFocusChange?: (isFocused: boolean) => void
}

export const AmountPicker = ({
  className,
  limit,
  value,
  setValue,
  setQuantityError,
  onFocusChange,
}: AmountPickerProps) => {
  const handleError = () => {
    setQuantityError(true)
    setTimeout(() => {
      setQuantityError(false)
    }, 1000)
  }

  const handleQuantityChange = (quantity: number) => {
    if (quantity > limit) {
      setValue(limit)
      handleError()
    } else if (quantity < 1) setValue(1)
    else setValue(quantity)
  }

  const handleIncrement = () => {
    if (isNaN(value)) setValue(1)
    else {
      if (value !== limit) setValue(value + 1)
      else if (value === limit) handleError()
    }
  }

  const handleDecrement = () => {
    if (value > 1) setValue(value - 1)
  }

  const handleOnFocus = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.select()
    if (onFocusChange) {
      onFocusChange(true)
    }
  }

  const handleOnBlur = () => {
    if (onFocusChange) {
      onFocusChange(false)
    }
  }

  return (
    <div className={`${styles.counterContainer} ${className && className}`}>
      <div
        className={`${styles.button} ${(value === 1 || isNaN(value)) && styles.disabled}`}
        onClick={handleDecrement}
      >
        <MinusIcon />
      </div>
      <input
        type='number'
        className={styles.quantityInput}
        value={value}
        step={1}
        placeholder={'0'}
        min={0}
        inputMode='numeric'
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={(e) => {
          handleQuantityChange(Math.ceil(e.target.valueAsNumber))
        }}
      />
      <div
        className={`${styles.button} ${value === limit && styles.disabled}`}
        onClick={handleIncrement}
      >
        <PlusIcon />
      </div>
    </div>
  )
}
