import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalContent } from 'ui/@library/layout/global-modal/@components/modal-content/modal-content'

import { RoyaltyEarnedTableColumns } from '../../royalties-earned/royalties-earned'

import styles from './styles.module.scss'

export type RoyaltyPayoutListProps = RoyaltyEarnedTableColumns

export const RoyaltyPayoutsList = () => {
  const { f, t } = useLocalization('myAssets.overview.royaltyPayoutsModal')
  const { payload: modalPayload, setDisplayModal } = useGlobalModal()

  if (!modalPayload) return null

  const masterRoyalties = modalPayload as RoyaltyPayoutListProps

  return (
    <ModalContent className={styles.royaltyPayoutsModal}>
      <h1>{t('title')}</h1>
      <div className={styles.songSection}>
        <img
          src={masterRoyalties.thumbnail}
          alt={`${masterRoyalties.artistName}-${masterRoyalties.masterName}`}
        />
        <div>
          <h2>{masterRoyalties.masterName}</h2>
          <p>{masterRoyalties.artistName}</p>
        </div>
      </div>
      <ul className={styles.payoutsList}>
        {masterRoyalties?.royalties.map((payout) => (
          <li key={`${payout.date}-${payout.amount}`}>
            <p>{payout.date.toDateString()}</p>
            <p>
              <span>
                {payout.shareCount === 1
                  ? t('singleShare')
                  : t('multipleShares', { total: payout.shareCount })}
              </span>
              <span>{f.currency(payout.amount)}</span>
            </p>
          </li>
        ))}
      </ul>
      <Button onClick={() => setDisplayModal(false)}>{t('button')}</Button>
    </ModalContent>
  )
}
