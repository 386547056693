import { Browser } from '@capacitor/browser'
import { useEffect, useState } from 'react'

import { ampli } from 'ampli'
import { useGTM, GTMEvents } from 'gtm'
import { ThreeDSecureEventStatus } from 'types/card'
import { useMessages } from 'ui/@components/message-banner/use-messages'
import { ThreeDSecureEvent } from 'ui/@components/three-d-secure-redirector/constants'
import { usePlatform } from 'ui/@hooks/use-platform'
import { Loader } from 'ui/@library/feedback/loader'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { IframeRenderer } from 'ui/@library/utils/iframe-renderer'
import { useAuthUserLoadable } from 'ui/@store/auth-user'
import { usePayIn } from 'ui/@store/pay-in'

import { Error } from '../error'
import { Success } from '../success'

export type PayInThreeDSecureProps = {
  onSuccessCallback?: () => void
}

export const PayInThreeDSecure = () => {
  const { payIn, resetState, refetch: refetchPayIn } = usePayIn()
  const { renderModal, payload: modalPayload } = useGlobalModal()
  const { isNative, isIOS } = usePlatform()
  const { authUser } = useAuthUserLoadable()
  const { sendGTMEvent } = useGTM()
  const { refetchMessages } = useMessages()

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [secureModeRedirectUrl, setSecureModeRedirectUrl] = useState('')

  useEffect(() => {
    const eventHandler = async (event: MessageEvent<{ id: string; payInId: string }>) => {
      if (event.data.id !== ThreeDSecureEvent.ID) return
      await refetchPayIn()
    }

    window.addEventListener(ThreeDSecureEvent.TYPE, eventHandler)

    const refetchPayInWithTimeout = () => {
      const timeoutId = setTimeout(async () => {
        const payIn = await refetchPayIn()
        if (payIn?.secureModeRedirectUrl) {
          if (isNative) {
            Browser.open({ url: payIn?.secureModeRedirectUrl })
          } else {
            setSecureModeRedirectUrl(payIn?.secureModeRedirectUrl)
          }
        } else renderModal({ modalKey: ModalKey.Error, content: <Error /> })
      }, ThreeDSecureEvent.TIMEOUT)
      setTimeoutId(timeoutId)
    }

    switch (payIn?.status) {
      case ThreeDSecureEventStatus.Succeeded:
        ampli.fundsAddedSuccess()
        sendGTMEvent(GTMEvents.DepositFunds, authUser?.uuid)
        resetState()
        refetchMessages()
        isIOS && Browser.close()
        renderModal({
          modalKey: ModalKey.Success,
          content: <Success />,
          payload: {
            onSuccessCallback: modalPayload?.onSuccessCallback,
          },
        })
        break
      case ThreeDSecureEventStatus.Created:
        if (payIn?.secureModeRedirectUrl) {
          if (isNative) {
            Browser.open({ url: payIn?.secureModeRedirectUrl })
          } else {
            setSecureModeRedirectUrl(payIn?.secureModeRedirectUrl)
          }
        } else refetchPayInWithTimeout()
        break
      case ThreeDSecureEventStatus.Failed:
        isIOS && Browser.close()
        resetState()
        renderModal({ modalKey: ModalKey.Error, content: <Error /> })
        break
      default:
        isIOS && Browser.close()
        resetState()
        renderModal({ modalKey: ModalKey.Error, content: <Error /> })
    }

    return () => {
      window.removeEventListener(ThreeDSecureEvent.TYPE, eventHandler)
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line
  }, [payIn])

  if (isNative) {
    return (
      <ModalContent>
        <Loader />
      </ModalContent>
    )
  }

  return (
    <ModalContent>
      <IframeRenderer url={secureModeRedirectUrl} />
    </ModalContent>
  )
}
