import { useNavigate } from 'react-router-dom'

import { ReactComponent as ExclamationIcon } from 'assets/icons/shield-exclamation-solid.svg'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { MessageTitle } from 'types/message'

import { useMessages } from '../../use-messages'
import { BannerContainer, BannerMessage, BannerButton } from '../banner'

import styles from './styles.module.scss'

export const KycBanner = () => {
  const { t } = useLocalization('components.banner.kyc.buttonText')
  const navigate = useNavigate()
  const { activeMessage } = useMessages()

  const handleKycVerification = () => {
    navigate(Paths.KYC, { state: { setInitStep: true } })
  }

  if (!activeMessage) return null

  return (
    <BannerContainer
      variant={activeMessage.variant}
      className={activeMessage.title === MessageTitle.KycFailed ? styles.kycRefusedContainer : ''}
    >
      <BannerMessage content={activeMessage.content} icon={<ExclamationIcon />} />
      {(activeMessage.title === MessageTitle.KycRequired ||
        activeMessage.title === MessageTitle.KycFailed) && (
        <BannerButton onClick={handleKycVerification}>
          {activeMessage.title === MessageTitle.KycRequired ? t('required') : t('refused')}
        </BannerButton>
      )}
    </BannerContainer>
  )
}
