import { CellContext, Row } from '@tanstack/react-table'
import { forwardRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right-purple.svg'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { PercentageChange } from 'ui/@library/data-display/percentage-change'
import { Table } from 'ui/@library/data-display/table'
import { useColumnHelper } from 'ui/@library/data-display/table/use-table'
import { Loader } from 'ui/@library/feedback/loader'
import { EmptyStatePlaceholder } from 'ui/@library/layout/empty-state-placeholder'
import { GraphicsToShow } from 'ui/@library/layout/empty-state-placeholder/states'
import { useAuthUserMasterSharesLoadable } from 'ui/@store/auth-user-master-shares'

import { MobileTable } from '../mobile-table'

import styles from './styles.module.scss'
import { RoyaltySharesTableColumns } from './types'

// eslint-disable-next-line
export const RoyaltySharesTable = forwardRef<HTMLDivElement>((_, ref) => {
  const navigate = useNavigate()
  const { t, f } = useLocalization('myAssets.overview.royaltySharesTable')
  const { authUserMasterShares, loading } = useAuthUserMasterSharesLoadable({ forceRefetch: true })

  const tableData = useMemo(() => {
    return authUserMasterShares
      ? authUserMasterShares.map((master) => {
          return {
            uuid: master.master.uuid,
            thumbnail: master.master.assets.thumbnail,
            masterName: master.master.name,
            artistName: master.master.artist.name,
            quantity: master.quantity,
            lastMarketPrice: master.lastMarketPrice,
            avgPurchasePrice: master.averageAcquisitionCost,
            sincePurchase: master.percentageSincePurchase,
            totalValue: master.totalValue,
          }
        })
      : []
    // eslint-disable-next-line
  }, [authUserMasterShares])

  const showPercentageChange = (cell: CellContext<RoyaltySharesTableColumns, unknown>) => {
    const value = cell.getValue() as number
    return <PercentageChange percentage={value} />
  }

  const showThumbnail = (cell: CellContext<RoyaltySharesTableColumns, unknown>) => {
    return <img className={styles.thumbnailImg} src={cell.getValue() as string} alt='' />
  }

  const showPrice = (cell: CellContext<RoyaltySharesTableColumns, unknown>) => {
    return <span>{f.currency(cell.getValue() as number)}</span>
  }

  const navigateToMaster = (row: Row<RoyaltySharesTableColumns> | RoyaltySharesTableColumns) => {
    const uuid = 'uuid' in row ? row.uuid : row.original.uuid
    navigate(`${Paths.MASTER.MAIN}/${uuid}`)
  }

  const columnDefinition = [
    {
      id: 'thumbnail',
      className: styles.thumbnail,
      cell: showThumbnail,
    },
    {
      id: 'masterName',
      label: t('columns.song'),
      className: styles.title,
    },
    {
      id: 'artistName',
      label: t('columns.artist'),
      className: styles.artist,
    },
    {
      id: 'quantity',
      label: t('columns.quantity'),
      className: styles.quantity,
      type: 'text',
    },
    {
      id: 'lastMarketPrice',
      label: t('columns.price'),
      className: styles.price,
      type: 'price',
      cell: showPrice,
    },
    {
      id: 'avgPurchasePrice',
      label: t('columns.avgPurchase'),
      className: styles.price,
      cell: showPrice,
    },
    {
      id: 'sincePurchase',
      label: t('columns.sincePurchase'),
      className: styles.sincePurchase,
      type: 'percentage',
      cell: showPercentageChange,
    },
    {
      id: 'totalValue',
      label: t('columns.value'),
      className: styles.value,
      type: 'price',
      cell: showPrice,
    },
  ]

  const columns = useColumnHelper<RoyaltySharesTableColumns>(columnDefinition)

  if (loading) return <Loader />

  return (
    <div className={styles.sharesTableWrapper} ref={ref}>
      {tableData.length > 0 ? (
        <>
          <Table
            data={tableData}
            columns={columns}
            rowClassName={styles.tableRow}
            rowOnClick={navigateToMaster}
            hideInMobile
          />
          <MobileTable
            columns={columnDefinition}
            tableData={tableData}
            buttonElement={<ChevronRight />}
            rowOnClick={navigateToMaster}
          />
        </>
      ) : (
        <EmptyStatePlaceholder
          message={t('emptyState.message')}
          button={t('emptyState.button')}
          path={Paths.EXCHANGE.MAIN}
          image={GraphicsToShow.Standard}
        />
      )}
    </div>
  )
})
