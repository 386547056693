import { useEffect } from 'react'

import { useLocalization } from 'locales/i18n'
import { AddCardError } from 'ui/@components/add-card'
import { Loader } from 'ui/@library/feedback/loader'
import { Button } from 'ui/@library/inputs/button'
import { useCardValidation } from 'ui/@store/card-validation'
import { useCardsLoadable } from 'ui/@store/cards'

import { AddCardStepProps } from '../../types'

export const AddCardOnboardingError = ({ steps, setCurrentStep, onSkip }: AddCardStepProps) => {
  const { t } = useLocalization('onboarding.addCard')
  const { cards, refetch, loading } = useCardsLoadable()
  const { resetState: resetCardValidationState } = useCardValidation()

  useEffect(() => {
    refetch()
    // eslint-disable-next-line
  }, [])

  const handleRetry = () => {
    if (cards.length > 0) {
      resetCardValidationState()
      setCurrentStep(steps.addCardValidation)
    } else {
      setCurrentStep(steps.addCardForm)
    }
  }

  if (loading) return <Loader />

  return (
    <>
      <AddCardError onRetry={handleRetry} />
      <Button width='fluid' variant='ghost' onClick={onSkip}>
        {t('skipForNow')}
      </Button>
    </>
  )
}
