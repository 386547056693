import { useLocalization } from 'locales/i18n'
import { Button } from 'ui/@library/inputs/button'
import { Divider } from 'ui/@library/layout/divider'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { AddCardModal } from 'ui/@modals/add-funds'
import { useCardsLoadable } from 'ui/@store/cards'
import { useWalletLoadable } from 'ui/@store/wallet'

import { CardOptionsModal } from '../../../../../../@modals/add-funds/@components/card-options-modal'
import { ReviewImoBuyProps, ReviewImoBuy } from '../../review-imo-buy'

import { ActiveCardDetails } from './@components/active-card-details'
import { AvailableFunds } from './@components/available-funds'
import styles from './styles.module.scss'

type CheckoutPaymentMethodProps = {
  reviewImoBuyModalPayload: ReviewImoBuyProps
}

export const CheckoutPaymentMethod = ({ reviewImoBuyModalPayload }: CheckoutPaymentMethodProps) => {
  const { t } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.reviewOrder.orderInformation.paymentMethod',
  )
  const { renderModal } = useGlobalModal()
  const { cards } = useCardsLoadable()
  const { wallet } = useWalletLoadable()

  if (!cards || !wallet) return null

  const hasCards = cards.length > 0
  const isCardRequired =
    reviewImoBuyModalPayload.totalOrderAmount > wallet.primaryBalance || hasCards

  const handleBackNavigationForCardOptions = () => {
    renderModal({
      modalKey: ModalKey.ReviewImoBuy,
      content: <ReviewImoBuy />,
      payload: reviewImoBuyModalPayload,
      size: ModalSize.Large,
    })
  }

  const handleAddEdit = () => {
    if (hasCards) {
      renderModal({
        modalKey: ModalKey.CardOptionsModal,
        content: <CardOptionsModal />,
        navigateBack: handleBackNavigationForCardOptions,
        payload: {
          onSaveCallBack: handleBackNavigationForCardOptions,
          onAddCardSuccess: handleBackNavigationForCardOptions,
        },
      })
    } else {
      renderModal({
        modalKey: ModalKey.AddCard,
        content: <AddCardModal />,
        navigateBack: handleBackNavigationForCardOptions,
        payload: {
          onSuccessCallback: handleBackNavigationForCardOptions,
        },
      })
    }
  }

  return (
    <div className={styles.container}>
      <Divider />
      {isCardRequired && (
        <div className={hasCards ? styles.editCardWrapper : styles.addCardWrapper}>
          <div className={styles.titleWrapper}>
            {hasCards && <p className={styles.title}>{t('title')}</p>}
            <Button variant='small' onClick={handleAddEdit}>
              {t(hasCards ? 'edit' : 'add')}
            </Button>
          </div>
          <ActiveCardDetails hasCards={hasCards} />
        </div>
      )}
      <AvailableFunds />
      <Divider />
    </div>
  )
}
