import { IonIcon, IonLabel, IonTabBar, IonTabButton } from '@ionic/react'
import { Link, useLocation } from 'react-router-dom'

import CollectionIcon from 'assets/icons/collection.svg'
import MusicalNoteIcon from 'assets/icons/musical-note.svg'
import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { usePlatform } from 'ui/@hooks/use-platform'

import styles from './styles.module.scss'

export const BottomNavbar = () => {
  const { t } = useLocalization('components.bottomNavbar')
  const { isWeb } = usePlatform()
  const location = useLocation()

  if (isWeb) return null

  const isExchangePathActive = location.pathname.includes(Paths.EXCHANGE.MAIN)
  const isMyAssetsPathActive = location.pathname.includes(Paths.MY_ASSETS.MAIN)

  if (location.pathname.includes(Paths.MASTER.MAIN)) return null

  return (
    <div className={styles.tabBarContainer}>
      <IonTabBar slot='bottom' className={styles.tabBar}>
        <IonTabButton className={styles.tabButton}>
          <Link
            to={Paths.EXCHANGE.MAIN}
            className={`${styles.tabLink} ${isExchangePathActive ? styles.active : ''}`}
          >
            <IonIcon icon={MusicalNoteIcon} className={styles.tabIcon} />
            <IonLabel className={styles.tabLabel}>{t('exchange')}</IonLabel>
          </Link>
        </IonTabButton>

        <IonTabButton className={styles.tabButton}>
          <Link
            to={Paths.MY_ASSETS.MAIN}
            className={`${styles.tabLink} ${isMyAssetsPathActive ? styles.active : ''}`}
          >
            <IonIcon icon={CollectionIcon} className={styles.tabIcon} />
            <IonLabel className={styles.tabLabel}>{t('myAssets')}</IonLabel>
          </Link>
        </IonTabButton>
      </IonTabBar>
    </div>
  )
}
