import camelcaseKeys from 'camelcase-keys'

import {
  BankAccountsRaw,
  WithdrawalRaw,
  AuthUserIbanRaw,
  WithdrawableFundsRaw,
} from 'types/bank-accounts'

export const sanitizeBankAccountsFromRaw = (raw: BankAccountsRaw) => {
  return camelcaseKeys(raw, { deep: true })
}

export const sanitizeAuthUserIbanFromRaw = (raw: AuthUserIbanRaw) => {
  return camelcaseKeys(raw)
}

export const sanitizeWithdrawalFromRaw = (raw: WithdrawalRaw) => {
  return camelcaseKeys(raw)
}

export const sanitizeWithdrawableFundsFromRaw = (raw: WithdrawableFundsRaw) => {
  return camelcaseKeys(raw)
}

export const sanitizeWithdrawalReturnFromRaw = (raw: WithdrawalRaw) => {
  return camelcaseKeys(raw)
}
