import { useMasterLoadable } from 'ui/@store/master'

import styles from './styles.module.scss'

export const SongInfo = () => {
  const { master } = useMasterLoadable()
  return (
    <div className={styles.wrapper}>
      <img src={master?.assets.thumbnail} className={styles.thumbnail} alt='' />
      <p className={styles.songName}>{master?.name}</p>
      <p className={styles.artistName}>{master?.artist.name}</p>
    </div>
  )
}
