import {
  AddCardModal,
  AddFundsConfirm,
  Error,
  PaymentMethodModal,
  Success,
  PayInThreeDSecure,
} from 'ui/@modals/add-funds'
import { CancelOrderModal } from 'ui/@modals/cancel-order-modal'
import { CancelOrder } from 'ui/@modals/order/cancel-order'
import { Withdrawal, AddIban } from 'ui/@modals/withdraw-funds'
import { AlreadySold } from 'ui/master/@components/modals/already-sold'
import { DialogBox } from 'ui/master/@components/modals/dialog-box'
import { InsufficientFunds } from 'ui/master/@components/modals/insufficient-funds'
import { OrderCompleted } from 'ui/master/@components/modals/order-completed'
import { OrderFailed } from 'ui/master/@components/modals/order-failed'
import { OrderTypeInfo } from 'ui/master/@components/modals/order-type'
import { ReviewImo } from 'ui/master/@components/modals/review-imo'
import {
  ReviewAcceptBid,
  ReviewImoBuy,
  ReviewLimitBuy,
  ReviewLimitSell,
  ReviewMakeBid,
  ReviewBestPriceBuy,
  ReviewOrder,
} from 'ui/master/@components/modals/review-order'
import { RoyaltyDistributionInfo } from 'ui/master/@components/modals/royalty-distribution-info'
import { SingleMasterShareDetail } from 'ui/master/@components/shares-table/@components/modals/single-master-share-detail'
import { ListFractionsModal } from 'ui/my-assets/@components/overview/@components/modals/list-fractions-modal'
import { TransactionFailed } from 'ui/my-assets/@components/overview/@components/modals/transaction-failed'

import { ModalKey } from './types'

export const AllModals = {
  [ModalKey.ReviewOrder]: ReviewOrder,
  [ModalKey.ReviewImoBuy]: ReviewImoBuy,
  [ModalKey.ReviewBestPriceBuy]: ReviewBestPriceBuy,
  [ModalKey.ReviewLimitBuy]: ReviewLimitBuy,
  [ModalKey.ReviewLimitSell]: ReviewLimitSell,
  [ModalKey.ReviewMakeBid]: ReviewMakeBid,
  [ModalKey.ReviewAcceptBid]: ReviewAcceptBid,
  [ModalKey.ReviewImo]: ReviewImo,
  [ModalKey.AlreadySold]: AlreadySold,
  [ModalKey.InsufficientFunds]: InsufficientFunds,
  [ModalKey.OrderCompleted]: OrderCompleted,
  [ModalKey.OrderFailed]: OrderFailed,
  [ModalKey.OrderType]: OrderTypeInfo,
  [ModalKey.CancelOrderModal]: CancelOrderModal,
  [ModalKey.ListFractions]: ListFractionsModal,
  [ModalKey.TransactionFailed]: TransactionFailed,
  [ModalKey.CancelOrder]: CancelOrder,
  [ModalKey.AddCard]: AddCardModal,
  [ModalKey.AddFundsConfirm]: AddFundsConfirm,
  [ModalKey.Error]: Error,
  [ModalKey.PaymentMethod]: PaymentMethodModal,
  [ModalKey.Success]: Success,
  [ModalKey.PayInThreeDSecure]: PayInThreeDSecure,
  [ModalKey.SingleMasterShareDetail]: SingleMasterShareDetail,
  [ModalKey.Withdrawal]: Withdrawal,
  [ModalKey.AddIban]: AddIban,
  [ModalKey.RoyaltyDistributionInfo]: RoyaltyDistributionInfo,
  [ModalKey.DialogBox]: DialogBox,
} as const

export const backgroundVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 0.65,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
  },
}

export const modalVariants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
  },
}

export const modalVariantsMobile = {
  hidden: {
    opacity: 0,
    y: window.innerHeight,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    y: window.innerHeight,
  },
}

export const modalContentVariants = {
  hidden: {
    opacity: 0,
    x: 16,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
  exit: {
    x: -16,
    opacity: 0,
  },
}
export { ModalKey }
