import { useEffect, useState } from 'react'

import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

type MasterSearchInputProps = {
  value: string
  debounce?: number
  onChange: (value: string) => void
}

export const MasterSearchInput = ({
  value: initialValue,
  debounce = 500,
  onChange,
}: MasterSearchInputProps) => {
  const [value, setValue] = useState(initialValue)

  const { t } = useLocalization('master.sharesTab.filters')

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value?.toString() || '')
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value, onChange, debounce])

  return (
    <input
      type='number'
      placeholder={t('search')}
      value={value}
      className={styles.masterSearchInput}
      onChange={(e) => {
        setValue(e.target.value)
      }}
    />
  )
}
