import { ReactNode } from 'react'

import { usePlatform } from 'ui/@hooks/use-platform'

import { StaticPageWrapperNative } from './static-page-wrapper-native'
import { StaticPageWrapperWeb } from './static-page-wrapper-web'

type StaticPageWrapperProps = {
  children: ReactNode
  title?: string
}

export const StaticPageWrapper = ({ children, title }: StaticPageWrapperProps) => {
  const { isNative } = usePlatform()

  if (isNative) {
    return <StaticPageWrapperNative title={title}>{children}</StaticPageWrapperNative>
  } else {
    return <StaticPageWrapperWeb title={title}>{children}</StaticPageWrapperWeb>
  }
}
