import AmazonMusicIcon from 'assets/icons/amazon-music.svg'
import AppleMusicIcon from 'assets/icons/apple-music.svg'
import FacebookIcon from 'assets/icons/facebook.svg'
import MusicalNoteIcon from 'assets/icons/musical-note.svg'
import SpotifyIcon from 'assets/icons/spotify.svg'
import YoutubeIcon from 'assets/icons/youtube.svg'
import { useLocalization } from 'locales/i18n'
import { Divider } from 'ui/@library/layout/divider'
import { useMasterLoadable } from 'ui/@store/master'

import { PlatformMetric } from '../platform-metric'

import styles from './styles.module.scss'

const IconsList = {
  amazon: AmazonMusicIcon,
  appleMusic: AppleMusicIcon,
  facebook: FacebookIcon,
  spotify: SpotifyIcon,
  youtube: YoutubeIcon,
  other: MusicalNoteIcon,
}
type IconKey = keyof typeof IconsList

export const StreamMetrics = () => {
  const { master } = useMasterLoadable()
  const { t, f } = useLocalization('master.overviewTab.information')

  if (!master) return null

  // eslint-disable-next-line
  const { total: totalStreams, ...platformStreams } = master.metrics.platformStreams
  const isValueEmpty = (value: number | null) => value === null || value === 0
  const areStreamsEmpty = Object.values(master.metrics.platformStreams).every(isValueEmpty)

  if (areStreamsEmpty) return null

  return (
    <>
      <div>
        <h1 className={styles.metricHeader}>{t('streams.title')}</h1>
        {totalStreams !== null && totalStreams !== 0 && (
          <p className={styles.totalStreams}>
            <span className={styles.boldText}>{t('streams.total')}</span>
            <span className={styles.boldText}>{f.number(totalStreams, { compact: 'short' })}</span>
          </p>
        )}

        <div className={styles.metricsContent}>
          <div className={styles.platformMetricsContainer}>
            {Object.entries(platformStreams).map(
              ([platform, value]) =>
                value !== null &&
                value !== 0 && (
                  <PlatformMetric
                    key={`${platform}-streams`}
                    icon={IconsList[platform as IconKey]}
                    name={t(`streams.platforms.${platform}`)}
                    value={value}
                  />
                ),
            )}
          </div>
        </div>
      </div>
      <Divider />
    </>
  )
}
