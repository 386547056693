import { BestPriceShareRaw } from 'types/bid'

import { sanitizeBestPriceShareFromRaw } from '../@santizers/bid'
import { useMxApi } from '../mx-api'

export const useGenerateBestBid = () => {
  const [{ data, loading, error }, execute] = useMxApi<BestPriceShareRaw[]>({}, { manual: true })

  const requestGeneratebestBid = (masterUuid: string, quantity: number) => {
    return execute({
      method: 'GET',
      url: `/masters/${masterUuid}/shares/generate-best-bid?quantity=${quantity}`,
    })
  }

  return {
    shares: data ? data.map(sanitizeBestPriceShareFromRaw) : [],
    loading,
    error,
    requestGeneratebestBid,
  }
}
