import { ReactNode } from 'react'

import { Divider } from 'ui/@library/layout/divider'

import styles from './styles.module.scss'

type SectionContainerProps = {
  children: ReactNode
  displayDivider?: boolean
}

export const SectionContainer = ({ children, displayDivider = true }: SectionContainerProps) => {
  return (
    <div className={styles.sectionContainer}>
      {displayDivider && <Divider className={styles.divider} />}
      {children}
    </div>
  )
}
